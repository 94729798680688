import React from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import s from './InterestsTestScreen.module.scss';
import {
  TEST_MODE_OLD,
  TEST_VERSION,
  TEST_VERSION_OLD,
  TEST_VERSION_PILOT,
  TEST_VERSION_PILOT_OLD,
  TEST_VERSION_VERIFICATION,
  TEST_VERSION_VERIFICATION_OLD,
} from '../../constants/testModes';
import getAssetLink from '../../util/getAssetLink';

const FADE_IN_DURATION = 1200;
const SLIDER_HINT_DELAY = 1000;
const SLIDER_HINT_INTERVAL = 5000;
const SLIDER_HINT_DURATION = 750;

interface InterestCardProps {
  initialFade?: boolean;
  data: {
    id: number;
    interest: string;
    image: string;
  };
  testMode: string;
  render?: boolean;
  showSlider?: boolean;
  startValue?: number;
  isPilot?: boolean;
  isVerification?: boolean;
  // eslint-disable-next-line no-unused-vars
  onValueChange: (value: any) => void;
}

class InterestCard extends React.Component<InterestCardProps> {
  // eslint-disable-next-line react/state-in-constructor
  state: any;

  fadeInStarted: any = null;

  fadeInTimer: any = null;

  sliderHintTimer: any = null;

  sliderHintInterval: any = null;

  removeSliderHintTimer: any = null;

  static defaultProps = {
    initialFade: false,
    render: false,
    showSlider: false,
    startValue: -1,
    isPilot: false,
    isVerification: false,
  };

  constructor(props: any) {
    super(props);

    this.state = {
      fadeIn: props.initialFade ? false : props.showSlider,
      showSliderHint: false,
    };

    this.onValueChange = this.onValueChange.bind(this);
    this.startFadeIn = this.startFadeIn.bind(this);
    this.clearAllTimers = this.clearAllTimers.bind(this);
  }

  componentDidMount() {
    if (this.props.initialFade) {
      this.startFadeIn(true);
    }
  }

  componentDidUpdate() {
    this.startFadeIn();
  }

  componentWillUnmount() {
    this.clearAllTimers();
  }

  onValueChange(value: any) {
    this.props.onValueChange(value);
  }

  startFadeIn(force = false) {
    if (
      force ||
      (!this.fadeInStarted && !this.state.fadeIn && this.props.showSlider)
    ) {
      this.clearAllTimers();

      this.fadeInStarted = true;

      this.fadeInTimer = setTimeout(() => {
        this.setState({ fadeIn: true });
      }, FADE_IN_DURATION);

      this.sliderHintTimer = setTimeout(() => {
        this.sliderHintInterval = setInterval(() => {
          this.setState({ showSliderHint: true });
          this.removeSliderHintTimer = setTimeout(() => {
            this.setState({ showSliderHint: false });
          }, SLIDER_HINT_DURATION);
        }, SLIDER_HINT_INTERVAL);
      }, SLIDER_HINT_DELAY);
    }
  }

  clearAllTimers() {
    if (this.fadeInTimer) {
      clearTimeout(this.fadeInTimer);
    }

    if (this.sliderHintTimer) {
      clearTimeout(this.sliderHintTimer);
    }
    if (this.sliderHintInterval) {
      clearInterval(this.sliderHintInterval);
    }
    if (this.removeSliderHintTimer) {
      clearTimeout(this.removeSliderHintTimer);
    }
  }

  render() {
    const { render, testMode, isPilot, isVerification } = this.props;

    if (!render) return <div className={s.interestCard} />;

    const { data, startValue } = this.props;
    const { fadeIn, showSliderHint } = this.state;

    const thumbClass = showSliderHint ? s.sliderHint : null;

    let imageUrl;
    if (isPilot) {
      if (testMode === TEST_MODE_OLD) {
        imageUrl = `interests-old/${TEST_VERSION_PILOT_OLD}`;
      } else {
        imageUrl = `interests-young/${TEST_VERSION_PILOT}`;
      }
    } else if (isVerification) {
      if (testMode === TEST_MODE_OLD) {
        imageUrl = `interests-old/${TEST_VERSION_VERIFICATION_OLD}`;
      } else {
        imageUrl = `interests-young/${TEST_VERSION_VERIFICATION}`;
      }
    } else if (testMode === TEST_MODE_OLD) {
      imageUrl = `interests-old/${TEST_VERSION_OLD}`;
    } else {
      imageUrl = `interests-young/${TEST_VERSION}`;
    }

    return (
      <div className={s.interestCard}>
        <img
          className={s.interestImage}
          src={`https://www.jopsy.at/appimages/${imageUrl}/${data.image}`}
          alt=""
        />
        {/* eslint-disable-next-line prettier/prettier */}
        <div className={`${s.sliderContainer} ${fadeIn ? s.fadedIn : s.fadedOut}`}>
          <div className={s.titleWrapper}>{data.interest}</div>
          <div className={s.sliderWrapper}>
            <img
              className={s.thumbsDownUp}
              src={getAssetLink('ThumbsDown.png')}
              alt=""
            />
            <div className={s.sliderInner}>
              <Slider
                disabled={!fadeIn} // disable to be sure slider cannot be accessed while fading in has not started yet
                defaultValue={(startValue as number) <= 0 ? 50 : startValue}
                min={1}
                max={100}
                onChange={() => {
                  if (this.fadeInTimer) {
                    clearTimeout(this.fadeInTimer);
                  }

                  if (this.sliderHintTimer) {
                    clearTimeout(this.sliderHintTimer);
                  }
                  if (this.sliderHintInterval) {
                    clearInterval(this.sliderHintInterval);
                  }
                  if (this.removeSliderHintTimer) {
                    clearTimeout(this.removeSliderHintTimer);
                  }

                  if (this.state.showSliderHint) {
                    this.setState({ showSliderHint: false });
                  }
                }}
                onAfterChange={this.onValueChange}
                // eslint-disable-next-line react/no-unstable-nested-components
                handle={(handleProps: any) => (
                  <div
                    className={`${handleProps.className} ${s.thumb}`}
                    role="slider"
                    aria-valuemin={handleProps.min}
                    aria-valuemax={handleProps.max}
                    aria-valuenow={handleProps.value}
                    aria-disabled={handleProps.disabled}
                    style={{
                      left: `${handleProps.offset}%`,
                      right: 'auto',
                      transform: 'translateX(-50%) translateY(-50%)',
                    }}
                  >
                    <img
                      className={thumbClass}
                      src={getAssetLink('Thumb.png')}
                      alt=""
                      draggable="false"
                    />
                    <div className={s.overlay} />
                  </div>
                )}
              />
            </div>
            <img
              className={s.thumbsDownUp}
              src={getAssetLink('ThumbsUp.png')}
              alt=""
            />
          </div>
          <div className={s.additionalTextWrapper}>interessiert mich</div>
        </div>
      </div>
    );
  }
}

export default InterestCard;
