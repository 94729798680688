export const evaluateRegisterInformation = (
  username: string,
  password: string,
  confirmPassword: string,
) => {
  if (
    !username ||
    username === '' ||
    !password ||
    password === '' ||
    !confirmPassword ||
    confirmPassword === ''
  ) {
    return {
      valid: false,
      errors: ['Bitte alle Felder ausfüllen.'],
    };
  }

  if (password !== confirmPassword) {
    return {
      valid: false,
      errors: ['Die Passwörter stimmen nicht überein.'],
    };
  }

  if (username.match(/\W+/g)) {
    return {
      valid: false,
      errors: [
        'Der Benutzername darf keine Leerzeichen oder Sonderzeichen enthalten.',
      ],
    };
  }
  if (
    !password.match(
      // eslint-disable-next-line no-useless-escape
      /^(?=.+[0-9])(?=.+[a-z])(?=.*[A-Z])(?=.*[*.!@$%^&(){}[\]:;<>,.?\/~_+-=|]).{8,32}$/g,
    )
  ) {
    return {
      valid: false,
      errors: [
        'Dein Passwort muss mindestens 8 Zeichen lang sein und zumindest einen Großbuchstaben, einen Kleinbuchstaben und eine Zahl enthalten.',
      ],
    };
  }
  return {
    valid: true,
    errors: [],
  };
};
