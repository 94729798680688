/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { flowRight as compose } from 'lodash';
import { NavigateFunction } from 'react-router-dom';

import s from './FavoriteProfessions.module.scss';
import { ROUTE_PREFIX } from '../../constants/router';
import { JOBS_VERSION, E_TYPE } from '../../constants/professions';
import AppContext from '../../context';
import withAuthentication from '../Auth/AuthenticationWrapper';
import withInterestTestResults from '../HomeScreen/DataWrappers/InterestTestResultsWrapper';
import testCompletionRequired from '../HomeScreen/DataWrappers/TestCompletionRequiredWrapper';
import withSuggestedProfessions, {
  recommendedProfessionType,
} from '../HomeScreen/DataWrappers/SuggestedProfessionsWrapper';
import withFavoriteProfessions from '../HomeScreen/DataWrappers/FavoriteProfessionsWrapper';
import ContentBody from '../ContentBody';
import Header from '../Header';
import Footer from '../Footer';
import FilterMenu from '../FilterMenu';
import { withRouter } from '../../util/withRouter';
import getAssetLink from '../../util/getAssetLink';

interface FavoriteProfessionsProps {
  loggedMode: string;
  meData?: any;
  recommendedProfessions: Array<recommendedProfessionType>;
  favoriteProfessions: Array<string>;
  // eslint-disable-next-line no-unused-vars
  setFavoriteProfession: (professionId: string, favorite: boolean) => void;
  favoriteProfessionsFilter: string;
  // eslint-disable-next-line no-unused-vars
  setFavoriteProfessionsFilter: (filterKey: string) => void;
  navigate: NavigateFunction;
}

class FavoriteProfessions extends React.Component<FavoriteProfessionsProps> {
  static contextType = AppContext;

  // eslint-disable-next-line react/state-in-constructor
  state: any;

  static defaultProps = {
    meData: null,
  };

  constructor(props: any) {
    super(props);

    this.state = {
      favoriteProfessions: props.favoriteProfessions,
      favoriteProfessionsFilter: props.favoriteProfessionsFilter,
      showFilterMenu: false,
    };

    this.openFilterMenu = this.openFilterMenu.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
    this.renderContent = this.renderContent.bind(this);
    this.getFavoriteIcon = this.getFavoriteIcon.bind(this);
    this.toggleFavorite = this.toggleFavorite.bind(this);
  }

  // eslint-disable-next-line react/sort-comp
  openFilterMenu() {
    const { favoriteProfessionsFilter } = this.state;
    const filter = favoriteProfessionsFilter
      ? E_TYPE[favoriteProfessionsFilter]
      : 'ALLE';

    return this.state.showFilterMenu ? (
      <FilterMenu applyFilter={this.applyFilter} filter={filter} />
    ) : (
      <div />
    );
  }

  async applyFilter({ filter }: any) {
    const { setFavoriteProfessionsFilter } = this.props;

    const filterKey = Object.keys(E_TYPE).filter(
      (x) => E_TYPE[x] === filter,
    )[0];

    setFavoriteProfessionsFilter(filterKey);

    this.setState({
      favoriteProfessionsFilter: filterKey,
      showFilterMenu: false,
    });
  }

  getFavoriteIcon(profession: string) {
    return this.state.favoriteProfessions.includes(profession)
      ? getAssetLink('heart_selected.png')
      : getAssetLink('heart.png');
  }

  async toggleFavorite(professionId: string) {
    const { setFavoriteProfession } = this.props;
    const { favoriteProfessions } = this.state;

    setFavoriteProfession(
      professionId,
      !favoriteProfessions.includes(professionId),
    );

    const newFavoriteProfessions = [...favoriteProfessions];

    const index = newFavoriteProfessions.indexOf(professionId);

    if (index === -1) {
      newFavoriteProfessions.push(professionId);
    } else {
      newFavoriteProfessions.splice(index, 1);
    }

    this.setState({
      favoriteProfessions: newFavoriteProfessions,
    });
  }

  renderContent() {
    const { favoriteProfessionsFilter } = this.state;
    const filter = favoriteProfessionsFilter
      ? E_TYPE[favoriteProfessionsFilter]
      : 'ALLE';

    const filteredList = this.props.recommendedProfessions.filter(
      (profession) =>
        filter === 'ALLE' ||
        String(profession.education)
          .toLowerCase()
          .includes(filter.toLowerCase()),
    );

    return filteredList.map((profession) => (
      <div
        className={s.tileContainer}
        key={profession.key}
        onClick={() => {
          this.props.navigate(
            `${ROUTE_PREFIX}/profession/${profession.key.split('-')[1]}`,
          );
        }}
      >
        <div className={s.professionImage}>
          <img
            src={`https://www.jopsy.at/appimages/jobs/${JOBS_VERSION}/${profession.imageName}`}
            alt=""
          />
        </div>
        <div className={s.tileDescription}>
          <h2 className={s.title}>{profession.title}</h2>
          <h2 className={s.education}>
            {profession.education.map((key, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div className={s.educationItem} key={`${key} ${index}`}>
                {key}
              </div>
            ))}
          </h2>
        </div>
        <div className={s.favoriteIcon}>
          <img
            src={this.getFavoriteIcon(profession.key)}
            alt=""
            onClick={(e) => {
              e.stopPropagation();
              this.toggleFavorite(profession.key);
            }}
          />
        </div>
      </div>
    ));
  }

  render() {
    const { loggedMode, meData } = this.props;

    const { favoriteProfessionsFilter } = this.state;

    const filter = favoriteProfessionsFilter
      ? E_TYPE[favoriteProfessionsFilter]
      : 'ALLE';

    const { showFilterMenu } = this.state;

    return (
      <div className={s.favoriteProfessionsContainer}>
        <Header>
          <div className={s.buttonWrapper}>
            <button
              type="button"
              className="btn-no-style"
              onClick={() => {
                this.setState({ showFilterMenu: !showFilterMenu });
              }}
            >
              <img src={getAssetLink('academyHat.png')} alt="filter" />
            </button>
          </div>
          <div className={s.filterMenuContainer}>{this.openFilterMenu()}</div>
        </Header>
        <ContentBody>
          <div className={s.headerSection}>
            <div className={s.mainHeader}>
              <h1>DEINE BERUFE</h1>
            </div>
            <div className={s.subHeader}>
              <h3>{`(Filter: ${filter})`}</h3>
            </div>
          </div>
          <div className={s.contentList}>{this.renderContent()}</div>
        </ContentBody>
        <Footer loggedMode={loggedMode} meData={meData} />
      </div>
    );
  }
}

export default compose(
  withAuthentication,
  withInterestTestResults,
  testCompletionRequired,
  withSuggestedProfessions,
  (comp) => withFavoriteProfessions(comp, true),
  withRouter,
)(FavoriteProfessions);
