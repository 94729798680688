import React from 'react';
import { Helmet } from 'react-helmet-async';

interface SeoData {
  title: string;
  description: string;
}

export const withSEO = (seoData: SeoData) => {
  function WithSEOWrapper(Component: any) {
    function ComponentWithSEO(props: any) {
      return (
        <>
          <Helmet>
            <title>{seoData.title}</title>
            <meta name="description" content={seoData.description} />
          </Helmet>
          <Component {...props} />
        </>
      );
    }

    return ComponentWithSEO;
  }

  return WithSEOWrapper;
};
