/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
  Field,
  formValueSelector,
  reduxForm,
  change,
  InjectedFormProps,
} from 'redux-form';

import s from './EditActivityModal.module.scss';
import render from '../../../ReduxForm/renderField';
import getAssetLink from '../../../../util/getAssetLink';

const validate = () => {
  const errors = {};
  return errors;
};

interface EditActivityType {
  name: string;
  hours: number;
  type: string;
  favorite: boolean;
  zeitfresser: boolean;
  misc: boolean;
}

interface EditActivityModalProps {
  showModal: boolean;
  closeModal: () => void;
  dispatch: any;
  // eslint-disable-next-line no-unused-vars
  onSubmit: (data: any) => void;
  defaultValues: any;
  createNew: boolean;
  name?: string;
  hours?: number;
  type?: string;
  favorite?: boolean;
  zeitfresser?: boolean;
  misc?: boolean;
}

class EditActivityModal extends React.Component<
  InjectedFormProps<EditActivityType, EditActivityModalProps> &
    EditActivityModalProps
> {
  // eslint-disable-next-line react/state-in-constructor
  state: any;

  constructor(props: any) {
    super(props);

    this.handleTypeChange = this.handleTypeChange.bind(this);
  }

  handleTypeChange(e: any) {
    const type = e.target.id;
    const { dispatch } = this.props;
    const active = (this.props as any)[type];
    dispatch(change('editActivity', 'type', active ? '' : type));
    dispatch(
      change('editActivity', 'favorite', !active && type === 'favorite'),
    );
    dispatch(
      change('editActivity', 'zeitfresser', !active && type === 'zeitfresser'),
    );
    dispatch(change('editActivity', 'misc', !active && type === 'misc'));
  }

  render() {
    return (
      <div>
        <Modal
          centered
          show={this.props.showModal}
          backdrop="static"
          dialogClassName={s.modal}
        >
          <Modal.Header className={s.header}>
            <Modal.Title>
              <h3>
                {this.props.createNew
                  ? 'Neue Aktivität hinzufügen'
                  : 'Aktivität bearbeiten'}
              </h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className={s.body}>
            <form onSubmit={this.props.handleSubmit}>
              <fieldset>
                <Field
                  className={s.formFieldContainer}
                  name="name"
                  placeholder="Name der Aktivität"
                  component={render.renderTextarea}
                  id="name"
                  style={{
                    borderRadius: '3px',
                    padding: '7px',
                    width: '100%',
                    marginTop: '10px',
                    resize: 'none',
                  }}
                />
                <div className={s.hoursContainer}>
                  <p>Wie viel Zeit beansprucht die Aktivität?</p>
                </div>
                <div className={s.hoursContainer}>
                  <Field
                    id="hours"
                    type="number"
                    name="hours"
                    placeholder="1-70"
                    min="1"
                    max="70"
                    component={render.renderInput}
                    parse={(value: any) => Number(value)}
                    style={{
                      verticalAlign: 'middle',
                    }}
                  />
                  <label
                    htmlFor="hours"
                    style={{
                      height: '30px',
                      marginTop: '5px',
                    }}
                  >
                    <span
                      style={{ marginLeft: '15px', verticalAlign: 'middle' }}
                    >
                      Stunden / Woche
                    </span>
                  </label>
                </div>
                <div className={s.formFieldContainer}>
                  <Field
                    type="checkbox"
                    id="favorite"
                    name="favorite"
                    component={render.renderCheckbox}
                    style={{ margin: '0 15px' }}
                    onChange={this.handleTypeChange}
                  />
                  <span className={s.checkboxLabel}>Lieblingsaktivität</span>
                  <img
                    src={getAssetLink('heart_selected.png')}
                    className={s.images}
                    alt=""
                  />
                </div>
                <p>
                  Deine Lieblingsaktivitäten sind Tätigkeiten, die dir Spaß
                  machen und dich weiter bringen.
                </p>
                <div className={s.formFieldContainer}>
                  <Field
                    id="zeitfresser"
                    type="checkbox"
                    name="zeitfresser"
                    className={s.zeitfresser}
                    component={render.renderCheckbox}
                    onChange={this.handleTypeChange}
                  />
                  <span className={s.checkboxLabel}>Zeitfresser</span>
                  <img
                    src={getAssetLink('Zeitfresser.png')}
                    className={s.images}
                    alt=""
                  />
                </div>
                <p>
                  Zeitfresser sind Aktivitäten, die deine Aufmerksamkeit rauben
                  und dich eher aufhalten.
                </p>
                <div className={s.formFieldContainer}>
                  <Field
                    id="misc"
                    type="checkbox"
                    name="misc"
                    className={s.misc}
                    component={render.renderCheckbox}
                    onChange={this.handleTypeChange}
                  />
                  <label htmlFor="misc">
                    <span className={s.checkboxLabel}>Sonstige Aktivität</span>
                    <img
                      src={getAssetLink('Welle_rose.png')}
                      className={s.images}
                      alt=""
                    />
                  </label>
                </div>
                <div className={s.buttonContainer}>
                  <Button
                    type="submit"
                    variant="btn btn-primary"
                    disabled={
                      !this.props.name || !this.props.hours || !this.props.type
                    }
                    style={{ width: '100%' }}
                  >
                    {this.props.createNew ? 'HINZUFÜGEN' : 'SPEICHERN'}
                  </Button>
                </div>
                <div className={s.buttonContainer}>
                  <Button
                    variant="btn btn-secondary"
                    onClick={this.props.closeModal}
                    style={{ width: '100%' }}
                  >
                    ABBRUCH
                  </Button>
                </div>
              </fieldset>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const editActivityForm = reduxForm<EditActivityType, EditActivityModalProps>({
  form: 'editActivity',
  enableReinitialize: true,
  validate,
  pure: false,
})(EditActivityModal);

export default connect((state: any, ownProps: any) => {
  const selector = formValueSelector('editActivity');
  return {
    name: selector(state, 'name'),
    hours: selector(state, 'hours'),
    type: selector(state, 'type'),
    favorite: selector(state, 'favorite'),
    zeitfresser: selector(state, 'zeitfresser'),
    misc: selector(state, 'misc'),
    initialValues: {
      name: ownProps.defaultValues.name,
      hours: ownProps.defaultValues.hours,
      favorite: ownProps.defaultValues.type === 'favorite',
      zeitfresser: ownProps.defaultValues.type === 'zeitfresser',
      misc: ownProps.defaultValues.type === 'misc',
      type: ownProps.defaultValues.type,
    },
  };
})(editActivityForm);
