import React from 'react';
import { Modal, Button } from 'react-bootstrap';

import s from './ConfirmRestartModal.module.scss';

interface ConfirmRestartModalProps {
  showModal: boolean;
  restartTest: () => void;
  closeModal: () => void;
}

class ConfirmRestartModal extends React.Component<ConfirmRestartModalProps> {
  render() {
    return (
      <div>
        <Modal
          centered
          show={this.props.showModal}
          backdrop="static"
          dialogClassName={s.restartModal}
        >
          <Modal.Header className={s.header}>
            <Modal.Title>
              <h3>
                <strong>Test erneut durchführen?</strong>
              </h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className={s.body}>
            Ein Neustart löscht deine aktuellen Ergebnisse.
            <br />
            <br />
            Du kannst deine Ergebnisse auch vorher exportieren, indem du sie als
            PDF herunterlädst. Klicke dafür auf das Zahnrad unten rechts und
            wähle „Export“.
          </Modal.Body>
          <Modal.Footer className={s.footer}>
            <Button variant="btn btn-secondary" onClick={this.props.closeModal}>
              ABBRECHEN
            </Button>
            <Button variant="btn btn-primary" onClick={this.props.restartTest}>
              NEUSTART
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default ConfirmRestartModal;
