import React from 'react';
import { flowRight as compose } from 'lodash';
import { NavigateFunction } from 'react-router-dom';

import s from './InfographicScreen.module.scss';
import { ROUTE_PREFIX } from '../../constants/router';
import withAuthentication from '../Auth/AuthenticationWrapper';
import withInterestTestResults from '../HomeScreen/DataWrappers/InterestTestResultsWrapper';
import testIncompletionRequired from '../HomeScreen/DataWrappers/TestIncompletionRequiredWrapper';
import getAssetLink from '../../util/getAssetLink';
import { withRouter } from '../../util/withRouter';

interface InfographicScreenProps {
  location: Location;
  navigate: NavigateFunction;
}

class InfographicScreen extends React.Component<InfographicScreenProps> {
  render() {
    const { location, navigate } = this.props;

    const isPilot = location.pathname.includes('normalization');
    const isVerification = location.pathname.includes('verification');

    return (
      // eslint-disable-next-line
      <div
        className={s.infographicScreenContainer}
        onClick={() => {
          let url;
          if (isPilot) {
            url = `${ROUTE_PREFIX}/normalization/interests-test`;
          } else if (isVerification) {
            url = `${ROUTE_PREFIX}/verification/interests-test`;
          } else {
            url = `${ROUTE_PREFIX}/interests-test`;
          }
          navigate(url);
        }}
      >
        <img src={getAssetLink('infographic.png')} alt="" />
      </div>
    );
  }
}

export default compose(
  withAuthentication,
  withInterestTestResults,
  testIncompletionRequired,
  withRouter,
)(InfographicScreen);
