import React from 'react';
import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import { flowRight as compose } from 'lodash';

import { NavigateFunction } from 'react-router-dom';
import AppContext from '../../context';
import { withLogin, meQuery } from './withLogin';
import { withLogout } from './withLogout';
import Loading from '../Loading';
import { submitLoginForm } from './login';
import LoginForm from './login/LoginForm';
import { ROUTE_PREFIX } from '../../constants/router';
import { withRouter } from '../../util/withRouter';

const isAdminQuery = gql`
  query isAdmin {
    admin
  }
`;

interface WithAdminAuthenticationProps {
  meData: {
    loading: boolean;
    me: {
      id: string;
      name: string;
    };
  };
  isAdminCheck: {
    loading: boolean;
    admin: boolean;
  };
  // eslint-disable-next-line no-unused-vars
  login: (name: string, password: string) => Promise<boolean>;
  // eslint-disable-next-line no-unused-vars
  logout: (redirectUrl?: string, shouldTriggerNewVisit?: boolean) => void;
  navigate: NavigateFunction;
}

const AdminWrapper = (WrappedComponent: any) => {
  class WithAdminAuthentication extends React.Component<WithAdminAuthenticationProps> {
    static contextType = AppContext;

    // eslint-disable-next-line react/state-in-constructor
    state: any;

    constructor(props: any) {
      super(props);

      this.state = {
        loading: false,
        errors: [],
      };

      this.onSubmit = this.onSubmit.bind(this);
      this.onCancel = this.onCancel.bind(this);
    }

    onCancel() {
      const { navigate } = this.props;

      this.setState({ errors: [] }, () => {
        navigate({ pathname: `${ROUTE_PREFIX}/` });
      });
    }

    async onSubmit({ username, password }: any) {
      const { login } = this.props;
      const success = await submitLoginForm({
        context: this,
        loginFunction: login,
        username,
        password,
      });

      if (success) {
        this.setState(
          {
            errors: [],
          },
          () => {
            // eslint-disable-next-line no-restricted-globals
            location.reload();
          },
        );
      }
    }

    render() {
      const { meData, isAdminCheck, logout } = this.props;

      if (meData.loading || isAdminCheck.loading) return <Loading />;

      if (!meData.me) {
        return [
          <div
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
            key="adminPageContainer"
          >
            <div>
              <LoginForm
                onSubmit={this.onSubmit}
                onCancel={this.onCancel}
                errors={this.state.errors}
                isAdminLogin
              />
            </div>
          </div>,
          this.state.loading && <Loading key="loading" />,
        ];
      }

      if (!isAdminCheck.admin) {
        return [
          <div
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
            key="adminPageContainer"
          >
            <div>
              <div>Diese Seite ist nur für Admins zugänglich.</div>
              <br />
              <button
                type="button"
                className="btn btn-primary btn-wide"
                onClick={() => {
                  this.setState({ loading: true });
                  logout('/admin/dashboard', false);
                }}
              >
                LOGOUT
              </button>
            </div>
          </div>,
          this.state.loading && <Loading key="loading" />,
        ];
      }

      return <WrappedComponent {...this.props} />;
    }
  }

  return compose(
    graphql(meQuery, {
      name: 'meData',
    }),
    graphql(isAdminQuery, {
      name: 'isAdminCheck',
    }),
    withLogin,
    withLogout,
    withRouter,
  )(WithAdminAuthentication);
};

export default AdminWrapper;
