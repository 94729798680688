/* eslint-disable react/sort-comp */
import React from 'react';
import gql from 'graphql-tag';

import Loading from '../../Loading';
import AppContext, { AppContextType } from '../../../context';
import {
  LOGGED_MODE_USER,
  LOGGED_MODE_GUEST,
} from '../../Auth/AuthenticationWrapper';
import { getGuestLocalStorageData } from '../../Auth/withLogin';
import { TEST_MODE_YOUNG } from '../../../constants/testModes';

export interface itestResultsType {
  interestPercentages: {
    R: number;
    I: number;
    A: number;
    S: number;
    E: number;
    C: number;
  };
  interestStandardValues: {
    R: number;
    I: number;
    A: number;
    S: number;
    E: number;
    C: number;
  };
  diffScore: {
    percentage: number;
    raw: number;
    standard: number;
  };
}

const itestResultsQuery = gql`
  query getUserItestResults {
    userItestResults
  }
`;

const itestModeQuery = gql`
  query getUserItestMode {
    userItestMode
  }
`;

interface WithInterestTestResultsProps {
  loggedMode: string;
}

const InterestTestResultsWrapper = (WrappedComponent: any) => {
  class WithInterestTestResults extends React.Component<WithInterestTestResultsProps> {
    static contextType = AppContext;

    // eslint-disable-next-line react/state-in-constructor
    state: any;

    constructor(props: any) {
      super(props);

      this.state = {
        loading: true,
        userItestResults: null,
        userItestMode: null,
      };

      /* eslint-disable prettier/prettier */
      this.sendTestResultsEventToParentWindow = this.sendTestResultsEventToParentWindow.bind(this);
      this.fetchItestResults = this.fetchItestResults.bind(this);
      this.getDataForUser = this.getDataForUser.bind(this);
      this.getDataForGuest = this.getDataForGuest.bind(this);
      /* eslint-enable prettier/prettier */
    }

    componentDidMount() {
      this.fetchItestResults(false);
    }

    // eslint-disable-next-line class-methods-use-this
    sendTestResultsEventToParentWindow(userItestResults: any) {
      if ((this.context as AppContextType).allowItestResultPosts) {
        window.parent.postMessage(
          {
            type: 'onUserItestResultsFetch',
            data: userItestResults,
          },
          '*',
        );
      }
    }

    // eslint-disable-next-line react/sort-comp
    async fetchItestResults(forceNetwork = true) {
      if (this.props.loggedMode === LOGGED_MODE_USER) {
        await this.getDataForUser(forceNetwork);
      } else if (this.props.loggedMode === LOGGED_MODE_GUEST) {
        this.getDataForGuest();
      }
    }

    async getDataForUser(forceNetwork: boolean) {
      const itestResultsResponse = await (
        this.context as AppContextType
      ).client.query({
        query: itestResultsQuery,
        fetchPolicy: forceNetwork ? 'network-only' : 'cache-first',
      });
      const itestModeResponse = await (
        this.context as AppContextType
      ).client.query({
        query: itestModeQuery,
        fetchPolicy: forceNetwork ? 'network-only' : 'cache-first',
      });

      let userItestResults = null;
      let userItestMode = null;

      if (
        itestResultsResponse &&
        itestResultsResponse.data &&
        'userItestResults' in itestResultsResponse.data
      ) {
        userItestResults = itestResultsResponse.data.userItestResults;

        // call event in parent window for iframe integrations
        this.sendTestResultsEventToParentWindow(userItestResults);
      }

      if (
        itestModeResponse &&
        itestModeResponse.data &&
        'userItestMode' in itestModeResponse.data
      ) {
        userItestMode = itestModeResponse.data.userItestMode;
      }

      this.setState({
        loading: false,
        userItestResults,
        userItestMode,
      });
    }

    getDataForGuest() {
      const guestData = getGuestLocalStorageData();

      if (guestData) {
        // call event in parent window for iframe integrations
        this.sendTestResultsEventToParentWindow(guestData.userItestResults);

        this.setState({
          loading: false,
          userItestResults: guestData.userItestResults,
          userItestMode: guestData.userItestMode,
        });
      }
    }

    render() {
      const { loading, userItestResults, userItestMode } = this.state;

      if (loading) return <Loading />;

      return (
        <WrappedComponent
          {...this.props}
          userItestResults={
            userItestResults && userItestResults !== ''
              ? JSON.parse(userItestResults)
              : null
          }
          userItestMode={userItestMode || TEST_MODE_YOUNG}
          refetchItestResults={this.fetchItestResults}
        />
      );
    }
  }

  return WithInterestTestResults;
};

export default InterestTestResultsWrapper;
