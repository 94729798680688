/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/sort-comp */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import getAssetLink from '../../../../util/getAssetLink';

import s from './ActivityListModal.module.scss';

interface ActivityListModalProps {
  showModal: boolean;
  closeModal: () => void;
  // eslint-disable-next-line no-unused-vars
  delete: (activity: any) => void;
  // eslint-disable-next-line no-unused-vars
  edit: (activity: any) => void;
  activities: Array<{
    name: string;
    type: string;
    hours: number;
  }>;
}

class ActivityListModal extends React.Component<ActivityListModalProps> {
  constructor(props: any) {
    super(props);

    this.renderList = this.renderList.bind(this);
    this.renderRow = this.renderRow.bind(this);
    this.renderCell = this.renderCell.bind(this);
    this.getIcon = this.getIcon.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onEdit = this.onEdit.bind(this);
  }

  renderList() {
    return this.props.activities.map((activity, index) =>
      this.renderRow(activity, index),
    );
  }

  renderRow(activity: any, index: number) {
    return Object.entries(activity).map((item, itemIndex) =>
      this.renderCell(item, index, itemIndex, activity),
    );
  }

  // eslint-disable-next-line consistent-return
  renderCell(
    item: any,
    activityIndex: number,
    itemIndex: number,
    activity: any,
  ) {
    switch (item[0]) {
      case 'name':
        return (
          <div
            key={`row-${activityIndex}-item-${itemIndex}`}
            style={{
              gridColumnStart: '1',
              gridColumnEnd: '2',
              gridRowStart: `${activityIndex + 2}`,
              gridRowEnd: `${activityIndex + 3}`,
              fontWeight: 'normal',
              padding: '15px 0',
              backgroundColor: 'white',
              marginTop: '8px',
              overflowWrap: 'break-word',
            }}
          >
            {item[1]}
          </div>
        );
      case 'hours':
        return (
          <div
            key={`row-${activityIndex}-item-${itemIndex}`}
            style={{
              gridColumnStart: '2',
              gridColumnEnd: '3',
              gridRowStart: `${activityIndex + 2}`,
              gridRowEnd: `${activityIndex + 3}`,
              fontWeight: 'normal',
              padding: '15px 10px',
              backgroundColor: 'white',
              marginTop: '8px',
              display: 'flex',
            }}
          >
            <div className={s.hoursContainer}>{`${item[1]} h`}</div>
          </div>
        );
      case 'type': {
        const icon = this.getIcon(item[1]);
        return (
          <div
            key={`row-${activityIndex}-item-${itemIndex}`}
            style={{
              gridColumnStart: '3',
              gridColumnEnd: 'end',
              gridRowStart: `${activityIndex + 2}`,
              gridRowEnd: `${activityIndex + 3}`,
              marginTop: '8px',
              display: 'flex',
              backgroundColor: 'white',
            }}
          >
            <img className={s.icon} src={icon} alt="" />
            <img
              className={s.images}
              src={getAssetLink('edit.png')}
              onClick={() => this.onEdit(activity)}
              alt=""
            />
            <img
              className={s.images}
              src={getAssetLink('delete.png')}
              onClick={() => this.onDelete(activity.name)}
              alt=""
            />
          </div>
        );
      }
      default:
        return null;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getIcon(type: string) {
    switch (type) {
      case 'favorite':
        return getAssetLink('heart_selected.png');
      case 'zeitfresser':
        return getAssetLink('Zeitfresser.png');
      case 'misc':
        return getAssetLink('Welle_rose.png');
      default:
        return '';
    }
  }

  async onDelete(activity: any) {
    await this.props.delete(activity);
  }

  onEdit(activity: any) {
    this.props.edit(activity);
    this.props.closeModal();
  }

  render() {
    return (
      <div>
        <Modal
          centered
          show={this.props.showModal}
          backdrop="static"
          dialogClassName={s.modal}
        >
          <Modal.Header className={s.header}>
            <Modal.Title>
              <h3>Übersicht deiner Aktivitäten</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className={s.body}>
            <div className={s.info}>
              Hier kannst du Aktivitäten bearbeiten oder löschen
            </div>
            <div className={s.background}>
              <div className={s.list}>
                <div
                  className={s.listHeaderItem}
                  style={{
                    gridColumnStart: '1',
                    gridColumnEnd: '2',
                    gridRowStart: '1',
                    gridRowEnd: '2',
                  }}
                >
                  Name
                </div>
                <div
                  className={s.listHeaderItem}
                  style={{
                    gridColumnStart: '2',
                    gridColumnEnd: '3',
                    gridRowStart: '1',
                    gridRowEnd: '2',
                    paddingLeft: '10px',
                    overflowWrap: 'break-word',
                  }}
                >
                  Zeit
                </div>
                <div
                  className={s.listHeaderItem}
                  style={{
                    gridColumnStart: '3',
                    gridColumnEnd: 'end',
                    gridRowStart: '1',
                    gridRowEnd: '2',
                  }}
                />
                {this.renderList()}
              </div>
            </div>
            <div className={s.buttonContainer}>
              <Button
                onClick={this.props.closeModal}
                variant="btn btn-secondary"
                style={{ width: '100%' }}
              >
                ZURÜCK
              </Button>
            </div>
            <div className={s.buttonContainer}>
              <Button
                variant="btn btn-primary"
                onClick={() => this.onEdit('')}
                style={{ width: '100%' }}
              >
                NEU
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default ActivityListModal;
