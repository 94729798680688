import React from 'react';

import s from './Spinner.module.scss';

interface SpinnerProps {
  show: boolean;
}

class Spinner extends React.Component<SpinnerProps> {
  render() {
    return this.props.show ? <div className={s.loader}>Lädt ...</div> : null;
  }
}

export default Spinner;
