import React from 'react';
import gql from 'graphql-tag';

import AppContext, { AppContextType } from '../../context';

const logAccessMutation = gql`
  mutation logAccess($trackKey: String!) {
    logAccess(trackKey: $trackKey)
  }
`;

const withAccessTracker = (trackKey: string) => (WrappedComponent: any) => {
  class AccessTrackerWrapper extends React.Component {
    static contextType = AppContext;

    constructor(props: any) {
      super(props);
      this.logAccess = this.logAccess.bind(this);
    }

    componentDidMount() {
      this.logAccess();
    }

    async logAccess() {
      await (this.context as AppContextType).client.mutate({
        mutation: logAccessMutation,
        variables: { trackKey },
      });
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return AccessTrackerWrapper;
};

export default withAccessTracker;
