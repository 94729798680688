import React from 'react';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';
import { Document, Page } from 'react-pdf';
import { Params } from 'react-router-dom';

import s from './ShareScreen.module.scss';
import ContentBody from '../ContentBody/index';
import Header from '../Header/index';
import Footer from '../Footer/index';
import Loading from '../Loading';
import AppContext, { AppContextType } from '../../context';
import withAuthentication from '../Auth/AuthenticationWrapper';
import { b64toBlob } from '../ExportScreen';

const portfolioPdfQuery = gql`
  query createShareAkooePortfolioPdf($shareId: String!) {
    createShareAkooePortfolioPdf(shareId: $shareId)
  }
`;

interface PortfolioShareScreenProps {
  loggedMode: string;
  meData?: any;
  params: Readonly<Params<string>>;
}

class PortfolioShareScreen extends React.Component<PortfolioShareScreenProps> {
  static contextType = AppContext;

  // eslint-disable-next-line react/state-in-constructor
  state: any;

  static defaultProps = {
    meData: null,
  };

  constructor(props: any) {
    super(props);

    this.state = {
      numPages: null,
      loadingShare: true,
      shareData: null,
      blobUrl: null,
    };
    this.getPdfData = this.getPdfData.bind(this);
  }

  componentDidMount() {
    if (this.props.params && this.props.params.shareId) this.getPdfData();
  }

  async getPdfData() {
    const response = await (this.context as AppContextType).client.query({
      query: portfolioPdfQuery,
      variables: { shareId: this.props.params.shareId },
      fetchPolicy: 'network-only',
    });
    if (response && response.data) {
      const blob = b64toBlob(response.data.createShareAkooePortfolioPdf, 'pdf');
      const blobUrl = URL.createObjectURL(blob);
      this.setState({
        loadingShare: false,
        shareData: response.data.createShareAkooePortfolioPdf,
        blobUrl,
      });
    }
  }

  onDocumentLoadSuccess = ({ numPages }: any) => {
    this.setState({ numPages });
  };

  itestPdfViewer = (pdf: string) => (
    <Document
      key="itestPdfViewer"
      file={`data:application/pdf;base64,${pdf}`}
      onLoadSuccess={this.onDocumentLoadSuccess}
      className={s.pdfContainer}
    >
      {Array.from(new Array(this.state.numPages), (el, index) => (
        <Page
          key={`page_${index + 1}`}
          pageNumber={index + 1}
          className={s.pageContainer}
          scale={1.4}
          renderTextLayer={false}
          renderAnnotationLayer={false}
        />
      ))}
    </Document>
  );

  render() {
    const { loggedMode, meData } = this.props;
    const { shareData, loadingShare, blobUrl } = this.state;
    if (loadingShare) return <Loading />;

    return (
      <div className={s.exportScreenContainer}>
        <Header withBackButton />
        <ContentBody>
          {shareData && [
            this.itestPdfViewer(shareData),
            <div
              key="downloadButtonWrapper"
              className={s.downloadButtonWrapper}
            >
              <a href={blobUrl} download="E-Portfolio.pdf">
                <button type="button" className={`btn btn-primary ${s.button}`}>
                  Download PDF
                </button>
              </a>
            </div>,
          ]}
          {!shareData && (
            <div className={s.invalidShare}>Inkorrekter Share-Link</div>
          )}
        </ContentBody>
        <Footer loggedMode={loggedMode} meData={meData} finishedTest={false} />
      </div>
    );
  }
}
export default compose(withAuthentication)(PortfolioShareScreen);
