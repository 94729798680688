import desiredJobOrSchoolQuestionData from '../DesiredJobOrSchoolComponent/DesiredJobOrSchoolQuestions';

export const NOT_EDITED = 'not edited';
export const EDITED = 'edited';
export const COMPLETED = 'completed';

export const getJobStatus = (moduleData: any) => {
  const { job } = moduleData;
  if (!moduleData.job) return NOT_EDITED;
  let answeredQuestions = 0;
  const questions = desiredJobOrSchoolQuestionData.desiredJobQuestions;
  questions.forEach((q) => {
    if (job[q.id]) answeredQuestions += 1;
  });
  if (answeredQuestions >= questions.length) return COMPLETED;
  if (answeredQuestions > 0) return EDITED;
  return NOT_EDITED;
};

export const getSchoolStatus = (moduleData: any) => {
  const { school } = moduleData;
  if (!moduleData.school) return NOT_EDITED;
  let answeredQuestions = 0;
  const questions = desiredJobOrSchoolQuestionData.desiredSchoolQuestions;
  questions.forEach((q) => {
    if (school[q.id]) answeredQuestions += 1;
  });
  if (answeredQuestions >= questions.length) return COMPLETED;
  if (answeredQuestions > 0) return EDITED;
  return NOT_EDITED;
};
