import React from 'react';
import { flowRight as compose } from 'lodash';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';

import s from '../Faq/Faq.module.scss';
import withAuthentication from '../Auth/AuthenticationWrapper';
import withInterestTestResults, {
  itestResultsType,
} from '../HomeScreen/DataWrappers/InterestTestResultsWrapper';
import { checkIfTestResultsAreInvalid } from '../HomeScreen/DataWrappers/TestCompletionRequiredWrapper';
import ContentBody from '../ContentBody/index';
import Header from '../Header/index';
import Footer from '../Footer/index';
import withAccessTracker from '../AccessTracker';
import { COOKIE_CONSENT_SETTINGS } from '../../constants/cookies';
import CookieConsentModal from '../CookieConsentModal';
import { withSEO } from '../SEO/withSEO';

interface DataProtectionInfoScreenProps {
  loggedMode: string;
  meData?: any;
  userItestResults?: itestResultsType;
}

class DataProtectionInfoScreen extends React.Component<DataProtectionInfoScreenProps> {
  // eslint-disable-next-line react/state-in-constructor
  state: any;

  static defaultProps = {
    meData: null,
    userItestResults: null,
  };

  constructor(props: any) {
    super(props);

    this.state = {
      showCookieConsentModal: false,
      trackingDataAccepted: this.checkForConsentCookie(),
    };

    this.openCookieConsentModal = this.openCookieConsentModal.bind(this);
    this.checkForConsentCookie = this.checkForConsentCookie.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  openCookieConsentModal() {
    this.setState({ showCookieConsentModal: true });
  }

  // eslint-disable-next-line class-methods-use-this
  checkForConsentCookie() {
    const consentCookie = Cookies.get(COOKIE_CONSENT_SETTINGS);
    if (!consentCookie) {
      return false;
    }
    const consentCookieParsed = JSON.parse(consentCookie);
    if (!consentCookieParsed) {
      return true;
    }

    return consentCookieParsed.trackingAccepted;
  }

  render() {
    const { loggedMode, meData, userItestResults } = this.props;
    const { trackingDataAccepted } = this.state;

    const testResultsInvalid = checkIfTestResultsAreInvalid(userItestResults);
    const finishedTest = !testResultsInvalid;
    const consentCookie = Cookies.get(COOKIE_CONSENT_SETTINGS);

    let yourChoice = '';
    if (!consentCookie) {
      yourChoice = 'Du hast noch keine Auswahl getroffen.';
    } else if (consentCookie && trackingDataAccepted) {
      yourChoice = 'Du hast Tracking gewählt.';
    } else if (consentCookie && !trackingDataAccepted) {
      yourChoice = 'Du hast Tracking geblockt.';
    }

    return (
      <div className={s.faqContainer}>
        <Header withBackButton />
        <ContentBody>
          <div className={s.faqHeader}>
            <h1>Informationen zur Datenverarbeitung</h1>
          </div>
          <div className={s.section}>
            <p>
              Gemäß Artikel 13 und 14 der Datenschutzgrundverordnung (DSGVO) ist
              Datenschutz Vertrauenssache und dein Vertrauen ist uns wichtig. Es
              ist uns daher ein Anliegen, transparent zu arbeiten und dabei
              deine berechtigten Interessen auf Geheimhaltung deiner
              personenbezogenen Daten zu schützen. Deshalb informieren wir Dich
              mit dieser Datenschutzerklärung, wie wir mit deinen Daten umgehen.
            </p>
          </div>
          <div className={s.section}>
            <h3>Technische Informationen zum Internetauftritt</h3>
            <p>
              {/* eslint-disable-next-line prettier/prettier */}
              <Link to="/" target="_blank" rel="noopener noreferrer"> www.jopsy.at </Link>
              wird von der Kammer für Arbeiter und Angestellte für Wien als Büro
              der Bundesarbeitskammer, Prinz-Eugen-Straße 20-22, Telefon: +43 1
              501 65-0, E-Mail:
              <a href="mailto: mailbox@akwien.at"> mailbox@akwien.at </a>
              als Diensteanbieter betrieben.
            </p>
          </div>
          <div className={s.section}>
            <h3>Medieninhaber und Herausgeber</h3>
            <p>
              Medieninhaber und Herausgeber der Website
              {/* eslint-disable-next-line prettier/prettier */}
              <Link to="/" target="_blank" rel="noopener noreferrer"> www.jopsy.at </Link>
              ist die Kammer Kammer für Arbeiter und Angestellte für Wien als
              Bundesarbeitskammer.
            </p>
            <p>
              Den Datenschutzbeauftragen der Bundesarbeitskammer kannst du unter
              <a href="mailto:datenschutz@akwien.at"> datenschutz@akwien.at </a>
              erreichen.
            </p>
          </div>
          <div className={s.section}>
            <h3>
              Wo und wofür verarbeitet die AK deine personenbezogenen Daten?
            </h3>
            <p>
              Die Bundesarbeitskammer speichert dein Passwort und deine
              Abfragergebnisse von der Website auf ihrem Server, damit du immer
              Zugriff auf deine auf der Website erzielten Ergebnisse hast.
            </p>
          </div>
          <div className={s.section}>
            <h3>Deine Auswahl</h3>
            <p>{yourChoice}</p>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={this.openCookieConsentModal}
            >
              Cookie Einstellungen ändern
            </button>
            <br />
            {this.state.showCookieConsentModal && <CookieConsentModal />}
          </div>
          <div className={s.section}>
            <h3>Löschung der Daten</h3>
            <p>
              Du hast das Recht, deinen Account jederzeit ohne Angabe von
              Gründen löschen zu lassen, indem du dich mit dem Ersuchen um
              Löschung deines Accounts an
              <a href="mailto:mailbox@akwien.at"> mailbox@akwien.at </a>
              wendest. Dein Account wird jedenfalls 3 Jahre nach der ersten
              Registrierung gelöscht.
            </p>
            <p>
              Die Bundesarbeitskammer weist dich darauf hin, dass du bezüglich
              deiner Daten ein Recht auf Auskunft, Berichtigung, Löschung,
              Einschränkung der Verarbeitung, Datenübertragbarkeit (Art. 15-21
              DSGVO), sowie auf Beschwerde bei der Datenschutzbehörde hast (Art.
              77 DSGVO).
            </p>
          </div>
        </ContentBody>
        <Footer
          loggedMode={loggedMode}
          meData={meData}
          finishedTest={finishedTest}
        />
      </div>
    );
  }
}

export default compose(
  withSEO({
    title: 'Jopsy | Datenschutz',
    description: 'Informationen zur Datenverarbeitung',
  }),
  withAccessTracker('Datenschutz'),
  withAuthentication,
  withInterestTestResults,
)(DataProtectionInfoScreen);
