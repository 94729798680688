import getAssetLink from '../../../../util/getAssetLink';

/* eslint-disable prettier/prettier */
const image0 = getAssetLink('strengthCards/Musik_machen.jpg');
const image1 = getAssetLink('strengthCards/technisches_Verständnis.jpg');
const image2 = getAssetLink('strengthCards/Sprachkenntnisse.jpg');
const image3 = getAssetLink('strengthCards/rechnen.jpg');
const image4 = getAssetLink('strengthCards/handwerkliches_Geschick.jpg');
const image5 = getAssetLink('strengthCards/Computerkenntnisse.jpg');
const image6 = getAssetLink('strengthCards/Textverständnis.jpg');
const image7 = getAssetLink('strengthCards/räumliches_Denken.jpg');
const image8 = getAssetLink('strengthCards/Organisationsfähigkeit.jpg');
const image9 = getAssetLink('strengthCards/gerne_vor_anderen_reden.jpg');
const image10 = getAssetLink('strengthCards/ein_Ziel_verfolgen.jpg');
const image11 = getAssetLink('strengthCards/zeichnen.jpg');
const image12 = getAssetLink('strengthCards/konzentriert_arbeiten.jpg');
const image13 = getAssetLink('strengthCards/recherchieren.jpg');
const image14 = getAssetLink('strengthCards/Ordnung_halten.jpg');
const image15 = getAssetLink('strengthCards/genaues_Arbeiten.jpg');
const image16 = getAssetLink('strengthCards/Hilfsbereitschaft.jpg');
const image17 = getAssetLink('strengthCards/Einfühlungsvermögen.jpg');
const image18 = getAssetLink('strengthCards/kontaktfreudig_sein.jpg');
const image19 = getAssetLink('strengthCards/Anpassungsfähigkeit.jpg');
const image20 = getAssetLink('strengthCards/Teamfähigkeit.jpg');
const image21 = getAssetLink('strengthCards/Kommunikationsfähigkeit.jpg');
const image22 = getAssetLink('strengthCards/Verantwortungsbewusstsein.jpg');
const image23 = getAssetLink('strengthCards/Selbstständigkeit.jpg');
const image24 = getAssetLink('strengthCards/Selbstbewusstsein.jpg');
const image25 = getAssetLink('strengthCards/Lernbereitschaft.jpg');
const image26 = getAssetLink('strengthCards/Kreativität.jpg');
const image27 = getAssetLink('strengthCards/Neugier.jpg');
const image28 = getAssetLink('strengthCards/sportliche_Leistungsfähigkeit.jpg');
const image29 = getAssetLink('strengthCards/Zuverlässigkeit.jpg');
const image30 = getAssetLink('strengthCards/Entscheidungsfähigkeit.jpg');
const image31 = getAssetLink('strengthCards/Konfliktfähigkeit.jpg');
const image32 = getAssetLink('strengthCards/Durchhaltevermögen.jpg');
const image33 = getAssetLink('strengthCards/Eigeninitiative.jpg');
const image34 = getAssetLink('strengthCards/Disziplin.jpg');
const image35 = getAssetLink('strengthCards/Allgemeinwissen.jpg');
/* eslint-enable prettier/prettier */

export default [
  {
    id: 'strength-card-0',
    title: 'Musik machen',
    description: 'Ich spiele ein Instrument oder kann gut singen.',
    image: image0,
  },
  {
    id: 'strength-card-1',
    title: 'technisches Verständnis',
    description:
      'Ich verstehe, wie Maschinen oder mechanische Apparate aufgebaut sind und wie ihre Einzelteile zusammenarbeiten.',
    image: image1,
  },
  {
    id: 'strength-card-2',
    title: 'Sprachkenntnisse',
    description: 'Ich spreche fließend eine Fremdsprache.',
    image: image2,
  },
  {
    id: 'strength-card-3',
    title: 'rechnen',
    description:
      'Ich kann Matheaufgaben wie Bruchrechnen oder Prozentrechnen leicht lösen.',
    image: image3,
  },
  {
    id: 'strength-card-4',
    title: 'handwerkliches Geschick',
    description:
      'Ich bin geschickt beim Basteln und kann mit Werkzeugen gut umgehen.',
    image: image4,
  },
  {
    id: 'strength-card-5',
    title: 'Computerkenntnisse',
    description: 'Ich gestalte mit PowerPoint meine Präsentationen.',
    image: image5,
  },
  {
    id: 'strength-card-6',
    title: 'Textverständnis',
    description:
      'Ich verstehe Texte gut und kann das Wichtigste daraus wiedergeben.',
    image: image6,
  },
  {
    id: 'strength-card-7',
    title: 'räumliches Denken',
    description:
      'Ich kann Pläne von Räumen und Gegenständen lesen und sie verstehen.',
    image: image7,
  },
  {
    id: 'strength-card-8',
    title: 'Organisationsfähigkeit',
    description:
      'Ich überlege mir vorher genau, was alles zu tun ist, damit ich eine Aufgabe gut schaffe.',
    image: image8,
  },
  {
    id: 'strength-card-9',
    title: 'gerne vor anderen reden',
    description:
      'Ich kann frei vor einer Gruppe sprechen und gut präsentieren.',
    image: image9,
  },
  {
    id: 'strength-card-10',
    title: 'ein Ziel verfolgen',
    description: 'Ich setze mir ein Ziel und verliere es nicht aus den Augen.',
    image: image10,
  },
  {
    id: 'strength-card-11',
    title: 'zeichnen',
    description: 'Ich kann meine Phantasien in einem Bild umsetzen.',
    image: image11,
  },
  {
    id: 'strength-card-12',
    title: 'konzentriert arbeiten',
    description: 'Ich bleibe bei der Sache und lasse mich nicht ablenken.',
    image: image12,
  },
  {
    id: 'strength-card-13',
    title: 'recherchieren',
    description: 'Ich kann mir selbständig Informationen beschaffen.',
    image: image13,
  },
  {
    id: 'strength-card-14',
    title: 'Ordnung halten',
    description: 'Ich kann Dinge nach einem bestimmten System sortieren.',
    image: image14,
  },
  {
    id: 'strength-card-15',
    title: 'genaues Arbeiten',
    description: 'Ich erledige Sachen gewissenhaft und möglichst ohne Fehler.',
    image: image15,
  },
  {
    id: 'strength-card-16',
    title: 'Hilfsbereitschaft',
    description: 'Wenn mich jemand braucht, bin ich für ihn da.',
    image: image16,
  },
  {
    id: 'strength-card-17',
    title: 'Einfühlungsvermögen',
    description:
      'Es fällt mir leicht, mich in die Situation und in die Gefühle von anderen Menschen hineinzuversetzen.',
    image: image17,
  },
  {
    id: 'strength-card-18',
    title: 'kontaktfreudig sein',
    description: 'Ich gehe gerne offen auf neue Leute zu.',
    image: image18,
  },
  {
    id: 'strength-card-19',
    title: 'Anpassungsfähigkeit',
    description:
      'Ich komme mit unterschiedlichen Menschen zurecht und kann mich in verschiedene Gruppen einfügen.',
    image: image19,
  },
  {
    id: 'strength-card-20',
    title: 'Teamfähigkeit',
    description:
      'Ich kann mit anderen gemeinsam eine Aufgabe lösen. Das gemeinsame Ziel ist wichtiger als mein eigenes.',
    image: image20,
  },
  {
    id: 'strength-card-21',
    title: 'Kommunikationsfähigkeit',
    description: 'Ich kann gut zuhören und mich gut ausdrücken.',
    image: image21,
  },
  {
    id: 'strength-card-22',
    title: 'Verantwortungsbewusstsein',
    description:
      'Ich überlege vorher, welche Folgen mein Verhalten haben kann.',
    image: image22,
  },
  {
    id: 'strength-card-23',
    title: 'Selbstständigkeit',
    description:
      'Ich sehe selbst, was zu tun ist und versuche Aufgaben ohne fremde Hilfe zu lösen.',
    image: image23,
  },
  {
    id: 'strength-card-24',
    title: 'Selbstbewusstsein',
    description: 'Ich traue mir selbst etwas zu und vertrete meine Meinung.',
    image: image24,
  },
  {
    id: 'strength-card-25',
    title: 'Lernbereitschaft',
    description: 'Ich lerne gerne etwas Neues hinzu.',
    image: image25,
  },
  {
    id: 'strength-card-26',
    title: 'Kreativität',
    description:
      'Ich habe außergewöhnliche Ideen, die sich von anderen unterscheiden.',
    image: image26,
  },
  {
    id: 'strength-card-27',
    title: 'Neugier',
    description:
      'Ich beobachte meine Umgebung aufmerksam und entdecke gerne etwas Neues.',
    image: image27,
  },
  {
    id: 'strength-card-28',
    title: 'sportliche Leistungsfähigkeit',
    description: 'Ich mache regelmäßig Sport.',
    image: image28,
  },
  {
    id: 'strength-card-29',
    title: 'Zuverlässigkeit',
    description: 'Ich halte mich an Abmachungen.',
    image: image29,
  },
  {
    id: 'strength-card-30',
    title: 'Entscheidungsfähigkeit',
    description: 'Ich kann gut Entscheidungen treffen.',
    image: image30,
  },
  {
    id: 'strength-card-31',
    title: 'Konfliktfähigkeit',
    description:
      'Ich kann mit Meinungsverschiedenheiten umgehen und versuche, gemeinsam eine Lösung zu ﬁnden.',
    image: image31,
  },
  {
    id: 'strength-card-32',
    title: 'Durchhaltevermögen',
    description:
      'Ich kann Dinge, die ich mir vorgenommen habe, auch zu Ende bringen.',
    image: image32,
  },
  {
    id: 'strength-card-33',
    title: 'Eigeninitiative',
    description:
      'Ich werde selbst aktiv und setze meine Ideen um, um etwas zu verändern.',
    image: image33,
  },
  {
    id: 'strength-card-34',
    title: 'Disziplin',
    description: 'Ich kann mich an Vorgaben halten.',
    image: image34,
  },
  {
    id: 'strength-card-35',
    title: 'Allgemeinwissen',
    description: 'Ich habe großes Wissen in unterschiedlichen Bereichen.',
    image: image35,
  },
];
