import React, { useContext } from 'react';
import { ApolloProvider } from '@apollo/client';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';

import '../styles/app.scss';
import AppContext, { AppContextType } from '../context';
import Layout from './Layout';
import RouteBundler from './RouteBundler';
import routes from '../routes';
import { COOKIE_CONSENT_SETTINGS } from '../constants/cookies';
import CookieConsentModal from './CookieConsentModal';

export function App() {
  const { client } = useContext(AppContext) as AppContextType;
  const location = useLocation();

  const isPilot = location.pathname.includes('normalization');
  const isVerification = location.pathname.includes('verification');
  const isDataProterction = location.pathname.includes('datenschutz');

  const consentCookie = Cookies.get(COOKIE_CONSENT_SETTINGS);

  return (
    <ApolloProvider client={client}>
      <Layout>
        <RouteBundler routes={routes} />
        {!isPilot &&
          !isVerification &&
          !isDataProterction &&
          !consentCookie && <CookieConsentModal />}
      </Layout>
    </ApolloProvider>
  );
}

export default App;
