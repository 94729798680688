export const I_TYPE: any = {
  REALISTIC: 'R',
  INVESTIGATIVE: 'I',
  ARTISTIC: 'A',
  SOCIAL: 'S',
  ENTERPRISING: 'E',
  CONVENTIONAL: 'C',
};

export const I_TYPE_NAME: any = {
  R: 'Macher/in',
  I: 'Denker/in',
  A: 'Gestalter/in',
  S: 'Helfer/in',
  E: 'Überzeuger/in',
  C: 'Organisierte',
};

export const I_TYPE_NAME_18_PLUS: any = {
  R: 'Macher/in',
  I: 'Denker/in',
  A: 'Gestalter/in',
  S: 'Soziale',
  E: 'Überzeuger/in',
  C: 'Organisierte',
};

export const I_TYPE_COLOR: any = {
  R: '#8CA068',
  I: '#9471A5',
  A: '#D67C31',
  S: '#EAB934',
  E: '#7DAEAC',
  C: '#6986AF',
};

export const I_TEXTS: any = {
  R: {
    do: 'Personen, die gut zu diesem Interessenstyp passen, sind echte „MacherInnen“. Wer gerne mit seinen Händen arbeitet, Sachen baut, Computer auseinandernimmt oder an Autos rumschraubt, ist bei diesem Typ richtig aufgehoben. Wenn du ein oder eine „Macher/in“ bist, gefällt es dir, nach deiner Arbeit direkt etwas zum Anfassen, Ausprobieren oder Präsentieren zu haben.',
    profession:
      'Hier passen Berufe gut, wo man anpacken kann und etwas selber erschafft, wie zum Beispiel im Handwerk oder in der Landwirtschaft. Es gehören aber auch Jobs dazu, in denen man selber etwas plant und dies dann von anderen umgesetzt wird (z.B. als Bauingenieur/in).',
    'do_18+':
      'Personen die gut zu diesem Interessenstyp passen sind sehr praktisch veranlagt. Wer gerne mit seinen Händen arbeitet, neue Dinge baut, Computer aufrüstet oder Bäume pflanzt, ist bei diesem Typ richtig aufgehoben. Einem oder einer „Macher/in“ gefällt es, nach der Arbeit direkt etwas zum Anfassen, Ausprobieren oder Präsentieren zu haben.',
    'profession_18+':
      'Hier passen Berufe gut, in denen eigenständig Neues geschaffen wird, wie zum Beispiel im Handwerk oder in der Landwirtschaft. Es gehören aber auch Jobs dazu, in denen man selbst etwas plant und dies dann von anderen umgesetzt wird (z.B. als Bauingenieur/in).',
  },
  I: {
    do: 'Menschen, die richtige „DenkerInnen“ sind, mögen es Probleme zu lösen, Dinge zu hinterfragen und einer Sache auf den Grund zu gehen. „DenkerInnen“ interessiert es zum Beispiel, worin sich Tierarten unterscheiden, wie Computerprogramme funktionieren oder wie unser Universum aufgebaut ist.',
    profession:
      '„DenkerInnen“ arbeiten im Allgemeinen gerne in Berufen, wo es darum geht, einem Problem so lange auf den Grund zu gehen, bis es gelöst ist. Dies ist sowohl in akademischen Feldern, z.B. als Mathematiker/in, Biologe/in oder Chemiker/in, aber auch in handwerklichen Berufen, z.B. als Automatisierungstechniker/in, möglich.',
    'do_18+':
      'Menschen die richtige „DenkerInnen“ sind, mögen es Probleme zu lösen, Dinge kritisch zu hinterfragen und einer Sache auf den Grund zu gehen. „DenkerInnen“ interessiert es zum Beispiel, worin sich Tierarten unterscheiden, wie Computerprogramme funktionieren oder wie unser Universum aufgebaut ist.',
    'profession_18+':
      '„DenkerInnen“ arbeiten im Allgemeinen gerne in Berufen, in denen einem Problem so lange auf den Grund gegangen wird, bis es gelöst ist. Dies ist sowohl in akademischen Feldern, z.B. als Mathematiker/-in, Biologe/in oder Chemiker/in, aber auch in handwerklichen Berufen, z.B. als Automatisierungstechniker/in, möglich.',
  },
  A: {
    do: '„GestalterInnen“ mögen Aktivitäten, in denen sie viel Freiraum haben und ihre Kreativität ausleben können. Das kann sowohl in Form von Kunst, Schauspiel oder Musik, als auch im Schaffen von innovativen und kreativen Produkten sein.',
    profession:
      'Zu „GestalternInnen“ passen einerseits künstlerische und freie Berufe, z.B. Schauspieler/in, Grafikdesigner/in und Musiker/in, aber andererseits auch Handwerksberufe, in denen man sich ausprobieren kann und kreative Ideen geschätzt werden, z.B. als Keramiker/in.',
    'do_18+':
      '„GestalterInnen“ mögen Aktivitäten, in denen sie viel Freiraum haben und ihre Kreativität ausleben können. Das kann sowohl in Form von Kunst, Schauspiel oder Musik, als auch im Schaffen von innovativen und kreativen Produkten sein.',
    'profession_18+':
      'Zu „GestalterInnen“ passen einerseits künstlerische und freie Berufe, z.B. Schauspieler/in, Grafikdesigner/in und Musiker/in, aber andererseits auch Handwerksberufe, in denen kreative Ideen umgesetzt werden können, z.B. als Keramiker/in.',
  },
  S: {
    do: '„HelferInnen“ möchten gern mit anderen Menschen zusammenarbeiten. Ihnen gefallen Aktivitäten, in denen sie anderen Menschen helfen, ihnen etwas beibringen oder sie trainieren.',
    profession:
      '„HelferInnen“ arbeiten gerne im Gesundheits- und Sozialsystem, zum Beispiel als Lehrer/in, Pfleger/in, Arzt/Ärztin oder Psychologe/in. Aber auch die Arbeit in der Personalabteilung von Unternehmen kann das Richtige sein.',
    'do_18+':
      '„Soziale“ arbeiten gern mit anderen Menschen zusammen. Ihnen gefallen Aktivitäten, in denen sie anderen Menschen helfen, ihnen etwas beibringen oder sie trainieren.',
    'profession_18+':
      '„Soziale“ arbeiten gerne im Gesundheits- und Sozialsystem, zum Beispiel als Lehrer/in, Pfleger/in, Arzt/Ärztin oder Psychologe/in. Aber auch die Arbeit in der Personalabteilung von Unternehmen kann das Richtige sein.',
  },
  E: {
    do: '„ÜberzeugerInnen“ nehmen gerne die Verantwortung in die eigene Hand und mögen es, Gruppen zu leiten. Ihnen gefällt es in der Zusammenarbeit mit anderen die eigene Meinung durchzusetzen, Einfluss zu haben und Menschen zu überzeugen.',
    profession:
      '„ÜberzeugerInnen“ arbeiten oft in Berufen, wo sie ihre Führungs- und Überzeugungskraft nutzen können. Dazu gehören unter anderem Verkaufsberufe, aber auch selbständige Jobs wie Handwerksmeister/in oder Start-Up Gründer/in.',
    'do_18+':
      '„ÜberzeugerInnen“ übernehmen gerne Verantwortung und mögen es, Gruppen zu leiten. Ihnen gefällt es in der Zusammenarbeit mit anderen die eigene Meinung durchzusetzen, Einfluss zu haben und Menschen zu überzeugen.',
    'profession_18+':
      '„ÜberzeugerInnen“ arbeiten oft in Berufen, in denen sie ihre Führungs- und Überzeugungskraft nutzen können. Dazu gehören unter anderem Verkaufsberufe, aber auch selbständige Tätigkeiten wie Handwerksmeister/in oder Start-Up Gründer/in.',
  },
  C: {
    do: '„Organisierte“ mögen es, sich sehr detailreich mit Zahlen oder Texten auseinanderzusetzen. Dazu gehört beispielsweise das Erstellen von Sparplänen, das Sortieren von Unterlagen oder das genaue Dokumentieren von Dingen.',
    profession:
      '„Organisierte“ mögen es in Jobs zu arbeiten, wo sie ihre Detailtreue und ihre verwaltenden Fähigkeiten einsetzen können. Da eignen sich zum Beispiel Berufe in der Buchhaltung, in der Steuerberatung oder in juristischen Feldern.',
    'do_18+':
      '„Organisierte“ mögen es sich im Detail mit Zahlen oder Texten auseinanderzusetzen. Dazu gehört beispielsweise das Erstellen von Sparplänen, das Sortieren von Unterlagen oder das genaue Dokumentieren von Dingen.',
    'profession_18+':
      '„Organisierte“ mögen es in Jobs zu arbeiten, bei denen sie ihre Detailtreue und ihre verwaltenden Fähigkeiten einsetzen können. Dafür eignen sich zum Beispiel Berufe in der Buchhaltung, in der Steuerberatung oder in juristischen Feldern.',
  },
};

export const DIFF_SCORE_ALERT_THRESHOLD = 89;
