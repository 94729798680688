import React from 'react';
import { flowRight as compose } from 'lodash';
import { Modal } from 'react-bootstrap';

import s from './DesiredJobOrSchoolComponent.module.scss';
import { checkIfTestResultsAreInvalid } from '../../HomeScreen/DataWrappers/TestCompletionRequiredWrapper';
import withInterestTestResults, {
  itestResultsType,
} from '../../HomeScreen/DataWrappers/InterestTestResultsWrapper';
import withAuthentication from '../../Auth/AuthenticationWrapper';
import withBundesland from '../../HomeScreen/DataWrappers/BundeslandWrapper';
import withFavoriteProfessions from '../../HomeScreen/DataWrappers/FavoriteProfessionsWrapper';
import withSuggestedProfessions, {
  recommendedProfessionType,
} from '../../HomeScreen/DataWrappers/SuggestedProfessionsWrapper';
import withAkooeData, {
  akooeDataType,
} from '../../HomeScreen/DataWrappers/AkooeDataWrapper';
import ContentBody from '../../ContentBody/index';
import Footer from '../../Footer';
import OffersHeader from '../../OffersScreen/OffersHeader';
import desiredJobOrSchoolQuestionData from './DesiredJobOrSchoolQuestions';
import { SmallBubble, SimpleBubble, QuestionBubble } from './QuestionBubble';
import SelectJobOrSchoolModal from './Modals/SelectJobOrSchoolModal';
import { ROUTE_PREFIX } from '../../../constants/router';

const bubblePositionsAndScales = [
  { top: `calc(350px)`, left: '50%', size: [160, 20] }, // middle bubble
  { top: `calc(190px)`, left: 'calc(-60px + 35%)', size: [40, 15] }, // top left
  { top: `calc(280px)`, left: 'calc(-80px + 40%)', size: [40, 15] }, // top left
  { top: `calc(220px)`, left: 'calc(-30px + 45%)', size: [30, 15] }, // top left
  { top: `calc(205px)`, right: 'calc(-80px + 45%)', size: [30, 15] }, // top right
  { top: `calc(280px)`, right: 'calc(-145px + 40%)', size: [45, 15] }, // top right
  { top: `calc(170px)`, right: 'calc(-120px + 36%)', size: [30, 15] }, // top right
  { top: `calc(430px)`, left: 'calc(-65px + 36%)', size: [50, 15] }, // bottom left
  { top: `calc(500px)`, left: 'calc(-40px + 42%)', size: [40, 15] }, // bottom left
  { top: `calc(480px)`, right: 'calc(-20px + 37%)', size: [34, 15] }, // bottom right
  { top: `calc(510px)`, right: 'calc(-90px + 32%)', size: [45, 15] }, // bottom right
  { top: `calc(410px)`, right: 'calc(-110px + 35%)', size: [34, 15] }, // bottom right
];

interface DesiredJobOrSchoolComponentProps {
  schoolOrJob: '' | 'job' | 'school';
  loggedMode: string;
  meData?: any;
  userItestResults?: itestResultsType;
  refetchSelectedBundesland: () => Promise<void>;
  selectedBundesland: string;
  // eslint-disable-next-line no-unused-vars
  setSelectedBundesland: (bundesland: string) => Promise<void>;
  favoriteProfessions: Array<string>;
  recommendedProfessions: Array<recommendedProfessionType>;
  name: string;
  link: string;
  // eslint-disable-next-line no-unused-vars
  setDesiredJobOrSchoolModuleData: (data: any) => Promise<void>;
  userAkooeData: akooeDataType;
  refetchAkooeData: () => Promise<void>;
}

class DesiredJobOrSchoolComponent extends React.Component<DesiredJobOrSchoolComponentProps> {
  // eslint-disable-next-line react/state-in-constructor, react/sort-comp
  state: any;

  static defaultProps = {
    meData: null,
    userItestResults: null,
  };

  static specialCardIsSet(key: string, moduleData: any) {
    if (!moduleData) return false;
    const answeredQuestions = Object.keys(moduleData);
    return moduleData[key] && answeredQuestions.includes(key);
  }

  constructor(props: any) {
    super(props);
    // get already saved data
    const moduleData =
      props.userAkooeData && props.userAkooeData.desiredJobOrSchoolModuleData
        ? JSON.parse(props.userAkooeData.desiredJobOrSchoolModuleData)
        : {};

    this.state = {
      showSelectJobOrSchoolModal: false,
      showBubbleModal: false,
      activeBubbleData: null,
      activeBubbleIsSpecial: false,
      activeBubbleSuggestions: null,
      currentBubbleAnswer: {},
      moduleData,
    };

    this.onBubbleClose = this.onBubbleClose.bind(this);
    this.setInitialJobState = this.setInitialJobState.bind(this);
    this.setInitialSchoolState = this.setInitialSchoolState.bind(this);

    // default open school or job modal if not set yet
    if (props.schoolOrJob === 'job') {
      this.setInitialJobState(moduleData);
    } else if (props.schoolOrJob === 'school') {
      this.setInitialSchoolState(moduleData);
    }
  }

  onBubbleClose() {
    const { setDesiredJobOrSchoolModuleData, schoolOrJob } = this.props;

    const { currentBubbleAnswer, moduleData } = this.state;
    if (!moduleData[schoolOrJob]) moduleData[schoolOrJob] = {};
    moduleData[schoolOrJob][currentBubbleAnswer.id] =
      currentBubbleAnswer.data || '';
    setDesiredJobOrSchoolModuleData(JSON.stringify(moduleData));
    this.setState({
      showBubbleModal: false,
      moduleData,
    });
  }

  setInitialJobState(moduleData: any) {
    const desiredJobData: any =
      desiredJobOrSchoolQuestionData.desiredJobQuestions.filter(
        (questionData) => questionData.id === 'desired-job',
      )[0];
    const specialCardIsSet = DesiredJobOrSchoolComponent.specialCardIsSet(
      'desired-job',
      moduleData[this.props.schoolOrJob],
    );
    if (!desiredJobData.answer || desiredJobData.answer.trim() === '') {
      this.state.showBubbleModal = !specialCardIsSet;
      this.state.activeBubbleData = desiredJobData;
      this.state.activeBubbleIsSpecial = true;
      const professionNames: any = this.props.recommendedProfessions.reduce(
        (names, prof) => {
          const allNames: any = names;
          allNames[prof.key] = prof.title;
          return allNames;
        },
        {},
      );
      const favoriteProfessionNames = this.props.favoriteProfessions.map(
        (prof) => professionNames[prof],
      );
      this.state.favoriteProfessionNames = favoriteProfessionNames;
      this.state.activeBubbleSuggestions = favoriteProfessionNames;
      this.state.currentBubbleAnswer = {
        id: 'desired-job',
        data: moduleData['desired-job'],
      };
    }
  }

  setInitialSchoolState(moduleData: any) {
    const desiredSchoolData: any =
      desiredJobOrSchoolQuestionData.desiredSchoolQuestions.filter(
        (questionData) => questionData.id === 'desired-school',
      )[0];
    const specialCardIsSet = DesiredJobOrSchoolComponent.specialCardIsSet(
      'desired-school',
      moduleData[this.props.schoolOrJob],
    );
    if (!desiredSchoolData.answer || desiredSchoolData.answer.trim() === '') {
      this.state.showBubbleModal = !specialCardIsSet;
      this.state.activeBubbleData = desiredSchoolData;
      this.state.activeBubbleIsSpecial = true;
      this.state.activeBubbleSuggestions = null;
      this.state.currentBubbleAnswer = {
        id: 'desired-school',
        data: moduleData['desired-school'],
      };
    }
  }

  render() {
    const {
      loggedMode,
      meData,
      refetchSelectedBundesland,
      selectedBundesland,
      setSelectedBundesland,
      userItestResults,
      name,
      link,
      schoolOrJob,
    } = this.props;
    const { favoriteProfessionNames, moduleData } = this.state;

    // get questions and answers
    let questions: Array<any> = [];
    let mainQuestionKey = '';
    if (schoolOrJob === 'school') {
      questions = desiredJobOrSchoolQuestionData.desiredSchoolQuestions;
      mainQuestionKey = 'desired-school';
    } else if (schoolOrJob === 'job') {
      questions = desiredJobOrSchoolQuestionData.desiredJobQuestions;
      mainQuestionKey = 'desired-job';
    }

    const testResultsInvalid = checkIfTestResultsAreInvalid(userItestResults);
    const finishedTest = !testResultsInvalid;
    return (
      <div className={s.overviewContainer}>
        <OffersHeader
          bundesland={selectedBundesland}
          setSelectedBundesland={setSelectedBundesland}
          refetchSelectedBundesland={refetchSelectedBundesland}
          backUrl={`${ROUTE_PREFIX}/orientation/offers/${selectedBundesland}/modules/desired-job-or-school`}
        />
        <ContentBody>
          <div style={{ position: 'relative', width: '100%', height: '100%' }}>
            <div className={s.headerSection}>
              <div className={s.headerWrapper}>
                <div className={s.headerLine}>
                  <h1>{name}</h1>
                </div>
                <div className={s.headerLine}>
                  <span>Infos findest du unter:</span>
                </div>
                <div className={s.headerLine}>
                  <a href={`http://${link}`} target="_blank" rel="noreferrer">
                    {link}
                  </a>
                </div>
                <div className={s.headerLine}>
                  <a
                    className={s.headerLine}
                    href="http://www.berufslexikon.at"
                    target="_blank"
                    rel="noreferrer"
                  >
                    www.berufslexikon.at
                  </a>
                </div>
              </div>
            </div>

            {questions.map((questionData, index) => {
              const isSpecialCard =
                questionData.id === 'desired-school' ||
                questionData.id === 'desired-job';
              const positionAndScale = bubblePositionsAndScales[index];
              const style: any = {
                position: 'absolute',
                top: positionAndScale.top,
                width: `calc(${positionAndScale.size[0]}px + ${positionAndScale.size[1]} * (100vw / 400))`,
                height: `calc(${positionAndScale.size[0]}px + ${positionAndScale.size[1]} * (100vw / 400))`,
                maxWidth: `calc(${positionAndScale.size[0]}px + ${positionAndScale.size[1]}px * 2.5)`,
                maxHeight: `calc(${positionAndScale.size[0]}px + ${positionAndScale.size[1]}px * 2.5)`,
              };
              let questionState = 'not_viewed';
              const questionKey = questionData.id;

              if (
                moduleData[schoolOrJob] &&
                Object.keys(moduleData[schoolOrJob]).includes(questionKey)
              ) {
                if (moduleData[schoolOrJob][questionKey]) {
                  questionState = 'answered';
                } else {
                  questionState = 'viewed';
                }
              }

              if (positionAndScale.left) {
                style.left = positionAndScale.left;
              } else if (positionAndScale.right) {
                style.right = positionAndScale.right;
              }
              return (
                <div
                  key={questionKey}
                  className={s.bubbleWrapper}
                  style={style}
                >
                  {!isSpecialCard && (
                    <SmallBubble
                      size={positionAndScale.size}
                      questionState={questionState as any}
                      onClick={() => {
                        const specialCardIsSet =
                          DesiredJobOrSchoolComponent.specialCardIsSet(
                            mainQuestionKey,
                            moduleData[schoolOrJob],
                          );
                        this.setState({
                          showSelectJobOrSchoolModal: !specialCardIsSet,
                          activeBubbleData: questionData,
                          activeBubbleIsSpecial: true,
                          activeBubbleSuggestions:
                            questionData.id === 'desired-job'
                              ? favoriteProfessionNames
                              : null,
                          showBubbleModal: specialCardIsSet,
                          currentBubbleAnswer: {
                            id: questionData.id,
                            data: moduleData[schoolOrJob]
                              ? moduleData[schoolOrJob][questionKey]
                              : '',
                          },
                        });
                      }}
                    />
                  )}
                  {isSpecialCard && (
                    <SimpleBubble
                      questionData={questionData}
                      size={positionAndScale.size}
                      currentAnswer={
                        moduleData[schoolOrJob]
                          ? moduleData[schoolOrJob][questionKey]
                          : ''
                      }
                      onClick={() => {
                        this.setState({
                          activeBubbleData: questionData,
                          activeBubbleIsSpecial: true,
                          activeBubbleSuggestions:
                            questionData.id === 'desired-job'
                              ? favoriteProfessionNames
                              : null,
                          showBubbleModal: true,
                          currentBubbleAnswer: {
                            id: questionData.id,
                            data: moduleData[schoolOrJob]
                              ? moduleData[schoolOrJob][questionKey]
                              : '',
                          },
                        });
                      }}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </ContentBody>
        <Footer
          loggedMode={loggedMode}
          meData={meData}
          finishedTest={finishedTest}
          forceShowExport
          exportUrl={`${ROUTE_PREFIX}/orientation/offers/${selectedBundesland}/export`}
          withEPortfolio
          refetchAkooeData={this.props.refetchAkooeData}
        />
        <Modal
          show={this.state.showBubbleModal}
          onHide={this.onBubbleClose}
          className={s.bubbleModal}
        >
          <QuestionBubble
            schoolOrJob={schoolOrJob}
            questionData={this.state.activeBubbleData}
            suggestions={this.state.activeBubbleSuggestions}
            currentAnswer={this.state.currentBubbleAnswer.data}
            updateBubbleData={(id, data) => {
              this.setState({
                currentBubbleAnswer: { id, data },
              });
            }}
            close={this.onBubbleClose}
          />
        </Modal>
        <SelectJobOrSchoolModal
          schoolOrJob={schoolOrJob}
          showModal={this.state.showSelectJobOrSchoolModal}
          onClose={() => {
            this.setState({ showSelectJobOrSchoolModal: false });
          }}
        />
      </div>
    );
  }
}

export default compose(
  withAuthentication,
  withInterestTestResults,
  withBundesland,
  withAkooeData,
  withSuggestedProfessions,
  (comp) => withFavoriteProfessions(comp, true),
)(DesiredJobOrSchoolComponent);
