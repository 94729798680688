import React from 'react';
import getAssetLink from '../../../../util/getAssetLink';

import s from './StrengthCard.module.scss';

interface CreateCustomStrengthCardProps {
  // eslint-disable-next-line no-unused-vars
  onResize?: (width: any) => void;
  onCreate: () => void;
}

class CreateCustomStrengthCard extends React.Component<CreateCustomStrengthCardProps> {
  // eslint-disable-next-line react/state-in-constructor
  state: any;

  cardRef: any = null;

  static defaultProps = {
    onResize: () => {},
  };

  constructor(props: any) {
    super(props);

    this.state = {
      headerFontSize: 0,
    };

    this.cardRef = React.createRef();
    this.onResize = this.onResize.bind(this);
  }

  componentDidMount() {
    this.onResize();
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize() {
    if (this.cardRef && this.cardRef.current) {
      const width = this.cardRef.current.clientWidth;
      this.setState(
        {
          headerFontSize: width * 0.09,
        },
        () => {
          if (this.props.onResize) {
            this.props.onResize(width);
          }
        },
      );
    }
  }

  render() {
    const { onCreate } = this.props;
    const { headerFontSize } = this.state;

    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
      <div
        ref={this.cardRef}
        className={s.card}
        style={{ cursor: 'pointer' }}
        onClick={onCreate}
      >
        <div className={s.cardContent}>
          <div
            className={`${s.headerContainer} ${s.whiteHeaderContainer}`}
            style={{ fontSize: `${headerFontSize}px` }}
          >
            <div className={s.createCustomCardHeader}>
              Ergänze weitere Stärken
            </div>
          </div>
          <div className={s.imageContainer}>
            <img
              className={s.plusIcon}
              src={getAssetLink('PlusIcon.png')}
              alt=""
              draggable="false"
            />
            <div className={s.imageOverlay} />
          </div>
          <div className={s.descriptionContainer} />
        </div>
      </div>
    );
  }
}

export default CreateCustomStrengthCard;
