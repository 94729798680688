import React from 'react';
import { NavigateFunction } from 'react-router-dom';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';

import s from './IdentificationScreen.module.scss';
import { withRouter } from '../../util/withRouter';
import { ROUTE_PREFIX } from '../../constants/router';
import { setGuestLocalStorageData } from '../Auth/withLogin';
import { TEST_MODE_PILOT } from '../../constants/testModes';
import withAuthentication from '../Auth/AuthenticationWrapper';
import withInterestTestResults from '../HomeScreen/DataWrappers/InterestTestResultsWrapper';

export const createItestDataMutation = gql`
  mutation createUserItestData($itestMode: String!, $override: Boolean!) {
    createUserItestData(itestMode: $itestMode, override: $override)
  }
`;

interface IdentificationScreenProps {
  navigate: NavigateFunction;
  refetchItestResults: () => Promise<void>;
}

class IdentificationScreen extends React.Component<IdentificationScreenProps> {
  // eslint-disable-next-line react/state-in-constructor
  state: any;

  constructor(props: any) {
    super(props);

    this.state = {
      code: '',
      showError: false,
      inputBlocked: false,
    };

    this.startTest = this.startTest.bind(this);
  }

  async startTest(code: string) {
    const newGuestData: any = {
      userItestMode: TEST_MODE_PILOT,
    };
    setGuestLocalStorageData(newGuestData);

    sessionStorage.setItem('normalization_data', JSON.stringify({ code }));

    await this.props.refetchItestResults();

    this.props.navigate(`${ROUTE_PREFIX}/normalization/infographic`);
  }

  render() {
    return (
      <div className={s.identificationScreenContainer}>
        <div className={s.textContainer}>
          Bitte gib deinen Personencode ein.
        </div>
        <div className={s.inputContainer}>
          <input
            value={this.state.code}
            onChange={(e) => {
              if (this.state.inputBlocked) return;

              const newCode = e.target.value
                ? e.target.value.toUpperCase().substring(0, 6)
                : '';

              if (newCode.length === 6) {
                // validate code
                const valid = /[a-zA-Z]{2}[0-9]{4}/g.test(newCode);
                if (valid) {
                  this.setState({
                    code: newCode,
                    showError: false,
                    inputBlocked: true,
                  });
                  setTimeout(() => {
                    this.startTest(newCode);
                  }, 200);
                } else {
                  this.setState({
                    code: newCode,
                    inputBlocked: true,
                  });
                  setTimeout(() => {
                    this.setState({
                      code: '',
                      showError: true,
                      inputBlocked: false,
                    });
                  }, 200);
                }
                e.target.blur();
              } else {
                this.setState({
                  code: newCode,
                  showError: false,
                });
              }
            }}
          />
        </div>
        <div className={s.errorContainer}>
          {this.state.showError && (
            <span>
              Personencode ungültig. Ein gültiger Personencode besteht aus 2
              Buchstaben gefolgt von 4 Zahlen.
            </span>
          )}
        </div>
      </div>
    );
  }
}

export default compose(
  withAuthentication,
  withInterestTestResults,
  withRouter,
)(IdentificationScreen);
