/* eslint-disable no-nested-ternary */
import React from 'react';
import { Link, Params } from 'react-router-dom';
import { flowRight as compose } from 'lodash';
import { FaPlayCircle } from 'react-icons/fa';

import { ROUTE_PREFIX } from '../../constants/router';
import s from '../OffersScreen/Offers.module.scss';
import withAuthentication from '../Auth/AuthenticationWrapper';
import withInterestTestResults, {
  itestResultsType,
} from '../HomeScreen/DataWrappers/InterestTestResultsWrapper';
import { checkIfTestResultsAreInvalid } from '../HomeScreen/DataWrappers/TestCompletionRequiredWrapper';
import ContentBody from '../ContentBody/index';
import OffersHeader from '../OffersScreen/OffersHeader';
import withBundesland from '../HomeScreen/DataWrappers/BundeslandWrapper';
import Footer from '../Footer';
import Loading from '../Loading';
import { I_TYPE_NAME_18_PLUS } from '../../constants/interests';
import { withRouter } from '../../util/withRouter';

interface ReortientationOffersProps {
  loggedMode: string;
  meData?: any;
  userItestResults?: itestResultsType;
  refetchSelectedBundesland: () => Promise<void>;
  // eslint-disable-next-line no-unused-vars
  setSelectedBundesland: (bundesland: string | null) => Promise<void>;
  params: Readonly<Params<string>>;
}

class ReortientationOffers extends React.Component<ReortientationOffersProps> {
  // eslint-disable-next-line react/state-in-constructor
  state: any;

  static defaultProps = {
    meData: null,
    userItestResults: null,
  };

  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
    };

    this.renderBasicOffers = this.renderBasicOffers.bind(this);
    this.renderWienOffers = this.renderWienOffers.bind(this);
    this.renderAkooeOffers = this.renderAkooeOffers.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  renderBasicOffers(linkList: Array<any>) {
    return (
      <div key="offersContent">
        <div className={s.headerSection}>
          <h1>ANGEBOTE DER AK</h1>
        </div>
        <div className={s.info}>
          {linkList ? (
            <div>
              Mehr Information und Unterstützung bei der Bildungswegentscheidung
              findest du unter:
              <br />
              {linkList.map((listItem) => (
                <a
                  href={listItem.hyperlinkUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  {listItem.hyperlinkTitle}
                </a>
              ))}
            </div>
          ) : (
            <div>
              Die Angebote der Arbeiterkammer stehen momentan noch nicht zur
              Verfügung.
            </div>
          )}
        </div>
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderWienOffers() {
    return (
      <div key="offersContent">
        <div className={s.headerSection}>
          <h1>ANGEBOTE DER AK</h1>
        </div>
        <div className={s.info}>
          <div>
            Mehr Information und Unterstützung bei der Bildungswegentscheidung
            findest du unter:
            <br />
            <a
              href="https://wien.arbeiterkammer.at/bildungsundberufswahl"
              target="_blank"
              rel="noreferrer"
            >
              Bildungs- und Berufswahl | Arbeiterkammer Wien
            </a>
          </div>
        </div>
        <div className={s.afterInfo}>
          <div className={s.test}>
            <div className={s.buttonInfo}>
              Diese Videos geben dir mehr Informationen zu den sechs
              Interessenstypen:
            </div>
            <div className={s.buttonBox}>
              <div className={s.buttonContainer}>
                <Link
                  className={s.buttonLink}
                  to={`${ROUTE_PREFIX}/reorientation/interest-video/R`}
                >
                  <button type="button" className="btn R-button">
                    {I_TYPE_NAME_18_PLUS.R}
                    <span>
                      <FaPlayCircle />
                    </span>
                  </button>
                </Link>
              </div>
              <div className={s.buttonContainer}>
                <Link
                  className={s.buttonLink}
                  to={`${ROUTE_PREFIX}/reorientation/interest-video/I`}
                >
                  <button type="button" className="btn I-button">
                    {I_TYPE_NAME_18_PLUS.I}
                    <span>
                      <FaPlayCircle />
                    </span>
                  </button>
                </Link>
              </div>
              <div className={s.buttonContainer}>
                <Link
                  className={s.buttonLink}
                  to={`${ROUTE_PREFIX}/reorientation/interest-video/A`}
                >
                  <button type="button" className="btn A-button">
                    {I_TYPE_NAME_18_PLUS.A}
                    <span>
                      <FaPlayCircle />
                    </span>
                  </button>
                </Link>
              </div>
              <div className={s.buttonContainer}>
                <Link
                  className={s.buttonLink}
                  to={`${ROUTE_PREFIX}/reorientation/interest-video/S`}
                >
                  <button type="button" className="btn S-button">
                    {I_TYPE_NAME_18_PLUS.S}
                    <span>
                      <FaPlayCircle />
                    </span>
                  </button>
                </Link>
              </div>
              <div className={s.buttonContainer}>
                <Link
                  className={s.buttonLink}
                  to={`${ROUTE_PREFIX}/reorientation/interest-video/E`}
                >
                  <button type="button" className="btn E-button">
                    {I_TYPE_NAME_18_PLUS.E}
                    <span>
                      <FaPlayCircle />
                    </span>
                  </button>
                </Link>
              </div>
              <div className={s.buttonContainer}>
                <Link
                  className={s.buttonLink}
                  to={`${ROUTE_PREFIX}/reorientation/interest-video/C`}
                >
                  <button type="button" className="btn C-button">
                    {I_TYPE_NAME_18_PLUS.C}
                    <span>
                      <FaPlayCircle />
                    </span>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderAkooeOffers() {
    return (
      <div key="offersContent">
        <div className={s.headerSection}>
          <h1>
            Herzlich willkommen bei der AK Bildungsberatung Oberösterreich!
          </h1>
        </div>
        <div className={s.info}>
          <p>
            Möchten Sie Ihre Testergebnisse mit unseren Berater/-innen
            besprechen oder sich zum Thema Aus- und Weiterbildung beraten
            lassen? Wir unterstützen Sie bei der Klärung Ihrer Ziele, der
            Planung beruflicher Entwicklungsschritte und bieten Ihnen neue
            Impulse für Bildung und Beruf. Sprechen Sie mit uns!
          </p>
          <p style={{ marginBottom: 0 }}>
            Bitte wählen Sie aus unserem kostenlosen, hochwertigen
            Beratungsangebot!
          </p>
        </div>
        <div className={s.listItem}>
          <div className={s.leftPart}>
            <h3>Bildungsberatung</h3>
          </div>
          <div className={s.rightPart}>
            <button type="button" className="btn btn-secondary">
              <a
                href="https://ooe.arbeiterkammer.at/ueberuns/kontakte/beratung/Bildungsberatung.html"
                target="_blank"
                rel="noreferrer"
              >
                Mehr Info
              </a>
            </button>
          </div>
        </div>
        <div className={s.listItem}>
          <div className={s.leftPart}>
            <h3>Persönliche Bildungsberatung</h3>
            <p>
              In Linz und allen AK Bezirksstellen. Terminvereinbarung: 050
              6906-1601
            </p>
          </div>
          <div className={s.rightPart}>
            <button type="button" className="btn btn-secondary">
              <a
                href="https://ooe.arbeiterkammer.at/bildungsberatung"
                target="_blank"
                rel="noreferrer"
              >
                Mehr Info
              </a>
            </button>
          </div>
        </div>
        <div className={s.listItem}>
          <div className={s.leftPart}>
            <h3>Bildungsberatung - jetzt auch online</h3>
          </div>
          <div className={s.rightPart}>
            <button type="button" className="btn btn-secondary">
              <a
                href="https://ooe.arbeiterkammer.at/beratung/bildung/ausundweiterbildungfuererwachsene/Bildungsberatung_-_jetzt_auch_online.html"
                target="_blank"
                rel="noreferrer"
              >
                Anmeldung
              </a>
            </button>
          </div>
        </div>
        <div className={s.listItem}>
          <div className={s.leftPart}>
            <h3>AK Potenzialanalyse</h3>
            <p>
              Testverfahren zu Interessen, Eignung, Leistungsfähigkeit und
              Persönlichkeit - inkl. Auswertungsgespräch
            </p>
          </div>
          <div className={s.rightPart}>
            <button type="button" className="btn btn-secondary btn-wide">
              <a
                href="https://ooe.arbeiterkammer.at/potenzialanalyse"
                target="_blank"
                rel="noreferrer"
              >
                Mehr Info
              </a>
            </button>
          </div>
        </div>
        <div className={s.listItem}>
          <div className={s.leftPart}>
            <h3>Kompetenz+ Beratung</h3>
            <p>Stärkenworkshops und Einzelcoaching</p>
          </div>
          <div className={s.rightPart}>
            <button type="button" className="btn btn-secondary">
              <a
                href="https://ooe.arbeiterkammer.at/kompetenzberatung"
                target="_blank"
                rel="noreferrer"
              >
                Mehr Info
              </a>
            </button>
          </div>
        </div>
        <div className={s.listItem}>
          <div className={s.leftPart}>
            <h3>Beratungsabend „WEITER mit BILDUNG!“</h3>
            <p>AKOÖ Onlineberatungsabende und AK Weiterbildungsmessen in OÖ</p>
          </div>
          <div className={s.rightPart}>
            <button type="button" className="btn btn-secondary">
              <a
                href="https://ooe.arbeiterkammer.at/weitermitbildung"
                target="_blank"
                rel="noreferrer"
              >
                Mehr Info
              </a>
            </button>
          </div>
        </div>
        <div className={s.info}>
          <p>
            Nutzen Sie auch folgende Onlineangebote der AK Oberösterreich im
            Bereich Bildung:
          </p>
          <a
            href="https://ooe.arbeiterkammer.at/bildung"
            target="_blank"
            rel="noreferrer"
          >
            ooe.arbeiterkammer.at/bildung
          </a>
          <a
            href="https://www.stipendienrechner.at"
            target="_blank"
            rel="noreferrer"
          >
            stipendienrechner.at
          </a>
          <a
            href="http://schulbeihilfenrechner.at"
            target="_blank"
            rel="noreferrer"
          >
            schulbeihilfenrechner.at
          </a>
          <a
            href="http://www.lehrberufsabc.at/startseite.html"
            target="_blank"
            rel="noreferrer"
          >
            lehrberufsabc.at
          </a>
          <a
            href="http://www.heimdatenbank.at"
            target="_blank"
            rel="noreferrer"
          >
            heimdatenbank.at
          </a>
          <a
            href="https://www.berufsinteressentest.at"
            target="_blank"
            rel="noreferrer"
          >
            berufsinteressentest.at
          </a>
        </div>
      </div>
    );
  }

  render() {
    const {
      loggedMode,
      meData,
      userItestResults,
      refetchSelectedBundesland,
      setSelectedBundesland,
    } = this.props;

    const { bundesland } = this.props.params;

    const testResultsInvalid = checkIfTestResultsAreInvalid(userItestResults);
    const finishedTest = !testResultsInvalid;

    let content = null;

    if (bundesland === 'wien') {
      content = this.renderWienOffers();
    } else if (bundesland === 'oberösterreich') {
      content = this.renderAkooeOffers();
    } else if (bundesland === 'salzburg') {
      content = this.renderBasicOffers([
        {
          hyperlinkUrl:
            'https://sbg.arbeiterkammer.at/online_bildungsberatung?msclkid=31e21e5cb64c11ec862ba757b353c43b',
          hyperlinkTitle: 'Online-Bildungs- und Berufsberatung mit Testung',
        },
        {
          hyperlinkUrl:
            'https://sbg.arbeiterkammer.at/beratung/bildungundjugend/bildungs-undberufswahl/Bildungsangebote_und_services.html#heading_Bildungs__und_Berufsberatung',
          hyperlinkTitle: 'Bildungsangebote und -services',
        },
        {
          hyperlinkUrl:
            'https://sbg.arbeiterkammer.at/beratung/bildungundjugend/bildungs-undberufswahl/Bildungschancen_nutzen.html',
          hyperlinkTitle: 'BiBer-Bildungsberatung',
        },
        {
          hyperlinkUrl:
            'https://sbg.arbeiterkammer.at/beratung/bildungundjugend/bildungs-undberufswahl/Beratungsservice_Gesundheitsberufe.html',
          hyperlinkTitle: 'Beratungsservice Gesundheitsberufe',
        },
      ]);
    } else if (bundesland === 'vorarlberg') {
      content = this.renderBasicOffers([
        {
          hyperlinkUrl:
            'https://vbg.arbeiterkammer.at/beratung/bildung/index.html',
          hyperlinkTitle: 'Bildung | AK Vorarlberg (arbeiterkammer.at)',
        },
      ]);
    } else if (bundesland === 'steiermark') {
      content = this.renderBasicOffers([
        {
          hyperlinkUrl:
            'https://stmk.arbeiterkammer.at/beratung/bildung/bildungsberatung/AK-Bildungsberatung.html',
          hyperlinkTitle: 'AK-Bildungsberatung',
        },
      ]);
    } else if (bundesland === 'burgenland') {
      content = this.renderBasicOffers([
        {
          hyperlinkUrl:
            'https://bgld.arbeiterkammer.at/beratung/Bildung/bildungsnavi/AK-Bildungsnavi.html',
          hyperlinkTitle: 'AK-Bildungsnavi',
        },
      ]);
    } else {
      content = this.renderBasicOffers([]);
    }

    return [
      <div key="offersContainer" className={s.offersContainer}>
        <OffersHeader
          bundesland={bundesland}
          setSelectedBundesland={setSelectedBundesland}
          refetchSelectedBundesland={refetchSelectedBundesland}
        />
        <ContentBody>{content}</ContentBody>
        <Footer
          loggedMode={loggedMode}
          meData={meData}
          finishedTest={finishedTest}
        />
      </div>,
      this.state.loading ? <Loading key="loading" /> : null,
    ];
  }
}

export default compose(
  withAuthentication,
  withInterestTestResults,
  withBundesland,
  withRouter,
)(ReortientationOffers);
