/* eslint-disable react/sort-comp */
import React from 'react';
import { flowRight as compose } from 'lodash';
import { FiSquare, FiCheckSquare } from 'react-icons/fi';

import s from './FavoriteStrengths.module.scss';
import { ROUTE_PREFIX } from '../../../../constants/router';
import withAuthentication, {
  LOGGED_MODE_USER,
  LOGGED_MODE_GUEST,
} from '../../../Auth/AuthenticationWrapper';
import withInterestTestResults, {
  itestResultsType,
} from '../../../HomeScreen/DataWrappers/InterestTestResultsWrapper';
import { checkIfTestResultsAreInvalid } from '../../../HomeScreen/DataWrappers/TestCompletionRequiredWrapper';
import withBundesland from '../../../HomeScreen/DataWrappers/BundeslandWrapper';
import withAkooeData, {
  akooeDataType,
} from '../../../HomeScreen/DataWrappers/AkooeDataWrapper';
import ContentBody from '../../../ContentBody';
import Footer from '../../../Footer';
import OffersHeader from '../../../OffersScreen/OffersHeader';
import StrengthCards from '../StrengthCards/StrengthCards';
import StrengthCard from '../StrengthCards/StrengthCard';
import StrengthCardsList from '../StrengthCardsList/StrengthCardsList';
import Loading from '../../../Loading';
import {
  parseModuleData,
  deleteCustomStrengthCard,
  resizeFile,
} from '../index';
import FavoriteLimitReachedModal from '../Modals/FavoriteLimitReachedModal';
import ConfirmDeleteStrengthCardModal from '../Modals/ConfirmDeleteStrengthCardModal';
import NoImageUploadAsGuestModal from '../Modals/NoImageUploadAsGuestModal';
import ModifyStrengthCardImageModal from '../Modals/ModifyStrengthCardImageModal';
import ImageExplanationModal from '../Modals/ImageExplanationModal';

const STRENGTH_MODAL_IMAGE_EXPLANATION_MODAL_ID = 'strengthCardPhotoDialog';

interface FavoriteStrengthsProps {
  loggedMode: string;
  meData?: any;
  userItestResults?: itestResultsType;
  refetchSelectedBundesland: () => Promise<void>;
  selectedBundesland: string;
  // eslint-disable-next-line no-unused-vars
  setSelectedBundesland: (bundesland: string) => Promise<void>;
  userAkooeData: akooeDataType;
  refetchAkooeData: () => Promise<void>;
  // eslint-disable-next-line no-unused-vars
  setStrengthsModuleData: (data: any) => void;
  // eslint-disable-next-line no-unused-vars
  setStrengthCardImage: (id: string, imageBase64: any) => Promise<void>;
  refetchStrengthCardImages: () => Promise<void>;
  // eslint-disable-next-line no-unused-vars
  deleteStrengthCardImage: (id: string) => Promise<void>;
  allStrengthCardImages: any;
}

class FavoriteStrengths extends React.Component<FavoriteStrengthsProps> {
  // eslint-disable-next-line react/state-in-constructor
  state: any;

  static defaultProps = {
    meData: null,
    userItestResults: null,
  };

  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
      cardWidth: 0,
      moduleData: null,
      filterFavorites: false,
      showFavoriteLimitReachedModal: false,
      showConfirmDeleteStrengthCardModal: false,
      cardToDeleteId: null,
      showNoImageUploadAsGuestModal: false,
      showModifyStrengthCardImageModal: false,
      cardToModifyImage: null,
      showImageExplanationModal: false,
    };

    this.toggleFavorite = this.toggleFavorite.bind(this);
    this.onImageUploadClicked = this.onImageUploadClicked.bind(this);
    this.uploadImage = this.uploadImage.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
    this.updateDoNotShowAgainModalStatus = this.updateDoNotShowAgainModalStatus.bind(this); // eslint-disable-line prettier/prettier
  }

  componentDidMount() {
    const { userAkooeData } = this.props;

    const moduleData = parseModuleData(userAkooeData);

    this.setState({
      moduleData,
      // eslint-disable-next-line prettier/prettier
      showImageExplanationModal: moduleData.doNotShowAgainModals[STRENGTH_MODAL_IMAGE_EXPLANATION_MODAL_ID] !== true,
    });
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.userAkooeData !== this.props.userAkooeData) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        moduleData: parseModuleData(this.props.userAkooeData),
      });
    }
  }

  async toggleFavorite(id: string) {
    const { setStrengthsModuleData } = this.props;
    const { moduleData } = this.state;

    if (moduleData.strengthCardsFavorites[id] === true) {
      delete moduleData.strengthCardsFavorites[id];
    } else if (Object.keys(moduleData.strengthCardsFavorites).length >= 5) {
      this.setState({ showFavoriteLimitReachedModal: true });
      return;
    } else {
      moduleData.strengthCardsFavorites[id] = true;
    }

    this.setState({ moduleData });

    setStrengthsModuleData(JSON.stringify(moduleData));
  }

  async onImageUploadClicked(id: string) {
    const { loggedMode } = this.props;

    if (loggedMode === LOGGED_MODE_GUEST) {
      this.setState({ showNoImageUploadAsGuestModal: true });
    } else if (loggedMode === LOGGED_MODE_USER) {
      this.setState({
        showModifyStrengthCardImageModal: true,
        cardToModifyImage: id,
      });
    }
  }

  async uploadImage(imageFile: any) {
    const { setStrengthCardImage, refetchStrengthCardImages } = this.props;
    const { cardToModifyImage } = this.state;

    this.setState({ loading: true });

    const imageBase64: any = await resizeFile(imageFile);
    await setStrengthCardImage(cardToModifyImage, imageBase64);
    await refetchStrengthCardImages();

    this.setState({
      showModifyStrengthCardImageModal: false,
      loading: false,
    });
  }

  async deleteImage(id?: string) {
    const {
      deleteStrengthCardImage,
      refetchAkooeData,
      refetchStrengthCardImages,
    } = this.props;
    const { cardToModifyImage } = this.state;

    this.setState({ loading: true });

    await deleteStrengthCardImage(id != null ? id : cardToModifyImage);
    await refetchAkooeData();
    await refetchStrengthCardImages();

    this.setState({
      showModifyStrengthCardImageModal: false,
      loading: false,
    });
  }

  async updateDoNotShowAgainModalStatus() {
    const { setStrengthsModuleData } = this.props;
    const { moduleData } = this.state;

    // eslint-disable-next-line prettier/prettier
    moduleData.doNotShowAgainModals[STRENGTH_MODAL_IMAGE_EXPLANATION_MODAL_ID] = true;

    setStrengthsModuleData(JSON.stringify(moduleData));
  }

  render() {
    const {
      loggedMode,
      meData,
      userItestResults,
      refetchSelectedBundesland,
      selectedBundesland,
      setSelectedBundesland,
      allStrengthCardImages,
    } = this.props;

    const {
      loading,
      cardWidth,
      moduleData,
      filterFavorites,
      showFavoriteLimitReachedModal,
      showConfirmDeleteStrengthCardModal,
      cardToDeleteId,
      showNoImageUploadAsGuestModal,
      showModifyStrengthCardImageModal,
      showImageExplanationModal,
    } = this.state;

    if (!moduleData) return <Loading />;

    const testResultsInvalid = checkIfTestResultsAreInvalid(userItestResults);
    const finishedTest = !testResultsInvalid;

    let selectedStrengthCards = moduleData.strengthCards.categorization.matching
      .map((id: string) => {
        let card = StrengthCards.find((sc) => sc.id === id);
        if (!card) {
          card = moduleData.strengthCards.custom.customCards.find(
            (sc: any) => sc.id === id,
          );
        }
        return card;
      })
      .filter((sc: any) => sc != null);

    if (filterFavorites) {
      selectedStrengthCards = selectedStrengthCards.filter(
        (sc: any) => moduleData.strengthCardsFavorites[sc.id] === true,
      );
    }

    return (
      <div className={s.favoriteStrengthsScreenContainer}>
        <OffersHeader
          bundesland={selectedBundesland}
          setSelectedBundesland={setSelectedBundesland}
          refetchSelectedBundesland={refetchSelectedBundesland}
          backUrl={`${ROUTE_PREFIX}/orientation/offers/${selectedBundesland}/modules/strengths`}
        />
        <ContentBody>
          <div className={s.content}>
            <div className={s.headerSection}>
              <h1>MEINE STÄRKEN</h1>
              <div>
                {`(Favoriten: ${
                  Object.keys(moduleData.strengthCardsFavorites).length
                } / 5)`}
              </div>
              <div className={s.selectWrapper}>
                <input
                  type="checkbox"
                  id="filterFavoritesCheckbox"
                  checked={this.state.filterFavorites}
                  onChange={() => {
                    this.setState({ filterFavorites: !filterFavorites });
                  }}
                  hidden
                />
                <label htmlFor="filterFavoritesCheckbox">
                  {this.state.filterFavorites ? (
                    <FiCheckSquare />
                  ) : (
                    <FiSquare />
                  )}
                  <span>Nur Favoriten anzeigen</span>
                </label>
              </div>
            </div>
            <StrengthCardsList
              childIds={selectedStrengthCards.map((sc: any) => sc.id)}
              cardWidth={cardWidth}
              withDrag={false}
            >
              {selectedStrengthCards.map((sc: any, index: number) => (
                <div key={sc.id} className={s.listCardWrapper}>
                  <StrengthCard
                    strengthCardData={selectedStrengthCards[index]}
                    customImage={allStrengthCardImages[sc.id] || null}
                    onResize={
                      index === 0
                        ? (width: any) => {
                            this.setState({ cardWidth: width });
                          }
                        : () => {}
                    }
                    isFavorite={
                      moduleData.strengthCardsFavorites[sc.id] === true
                    }
                    showHeart
                    onToggleFavorite={this.toggleFavorite}
                    onDelete={(id: string) => {
                      this.setState({
                        showConfirmDeleteStrengthCardModal: true,
                        cardToDeleteId: id,
                      });
                    }}
                    onImageUploadClicked={this.onImageUploadClicked}
                  />
                </div>
              ))}
            </StrengthCardsList>
          </div>
        </ContentBody>
        <Footer
          loggedMode={loggedMode}
          meData={meData}
          finishedTest={finishedTest}
          forceShowExport
          exportUrl={`${ROUTE_PREFIX}/orientation/offers/${selectedBundesland}/export`}
          withEPortfolio
          refetchAkooeData={this.props.refetchAkooeData}
        />
        <FavoriteLimitReachedModal
          showModal={showFavoriteLimitReachedModal}
          closeModal={() => {
            this.setState({ showFavoriteLimitReachedModal: false });
          }}
        />
        <ConfirmDeleteStrengthCardModal
          showModal={showConfirmDeleteStrengthCardModal}
          closeModal={() => {
            this.setState({ showConfirmDeleteStrengthCardModal: false });
          }}
          onSubmit={() => {
            deleteCustomStrengthCard(this, cardToDeleteId);
            this.setState({ showConfirmDeleteStrengthCardModal: false });
          }}
        />
        <NoImageUploadAsGuestModal
          showModal={showNoImageUploadAsGuestModal}
          closeModal={() => {
            this.setState({ showNoImageUploadAsGuestModal: false });
          }}
        />
        <ModifyStrengthCardImageModal
          showModal={showModifyStrengthCardImageModal}
          closeModal={() => {
            this.setState({ showModifyStrengthCardImageModal: false });
          }}
          onSelectImage={this.uploadImage}
          onResetImage={this.deleteImage}
        />
        <ImageExplanationModal
          showModal={showImageExplanationModal}
          closeModal={(checked: boolean) => {
            if (checked) this.updateDoNotShowAgainModalStatus();
            this.setState({ showImageExplanationModal: false });
          }}
        />
        {loading && <Loading />}
      </div>
    );
  }
}

export default compose(
  withAuthentication,
  withInterestTestResults,
  withBundesland,
  (comp) => withAkooeData(comp, true),
)(FavoriteStrengths);
