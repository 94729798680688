/* eslint-disable no-case-declarations */
/* eslint-disable default-param-last */
import { SET_USER_INTEREST_ANSWER } from '../constants/variables';

const initialState = {
  interests: {},
};

export default function userTestData(state = { ...initialState }, action: any) {
  switch (action.type) {
    case SET_USER_INTEREST_ANSWER:
      const newState: any = { ...state };
      newState[action.id] = action.value;
      return newState;
    default:
      return state;
  }
}
