import React from 'react';

import s from './Loading.module.scss';
import Spinner from '../Spinner';

class Loading extends React.Component {
  render() {
    return (
      <div className={s.loading}>
        <div className={s.spinnerWrapper}>
          <Spinner show />
        </div>
        <span className={s.loadingText}>Lädt ...</span>
      </div>
    );
  }
}

export default Loading;
