/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Modal } from 'react-bootstrap';
import { FiSquare, FiCheckSquare } from 'react-icons/fi';

import s from './StrengthCardsModal.module.scss';

interface StrengthModuleExplanationModalProps {
  showModal: boolean;
  // eslint-disable-next-line no-unused-vars
  closeModal: (doNotShowAgainChecked: boolean) => void;
}

class StrengthModuleExplanationModal extends React.Component<StrengthModuleExplanationModalProps> {
  // eslint-disable-next-line react/state-in-constructor
  state: any;

  constructor(props: any) {
    super(props);

    this.state = {
      doNotShowAgainChecked: false,
    };
  }

  render() {
    const { doNotShowAgainChecked } = this.state;

    return (
      <div>
        <Modal
          centered
          show={this.props.showModal}
          backdrop="static"
          dialogClassName={s.modal}
        >
          <Modal.Header className={s.header}>
            <Modal.Title>
              <h3>Deine Stärken</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className={s.body}>
            <div className={s.info}>
              Ziehe Stärkekarten, die zu dir passen, von der oberen in die
              untere Liste. Du kannst auch eigene Stärkenkarten erstellen. Im
              Anschluss kannst du auf 'MEINE STÄRKEN' klicken, um deine
              Favoriten auszuwählen.
            </div>
            <div className={s.buttonContainerTwoButtons}>
              <div className={s.selectWrapper}>
                <input
                  type="checkbox"
                  id="doNotShowAgain"
                  checked={this.state.doNotShowAgainChecked}
                  onChange={() => {
                    this.setState({
                      doNotShowAgainChecked: !doNotShowAgainChecked,
                    });
                  }}
                  hidden
                />
                <label htmlFor="doNotShowAgain">
                  {doNotShowAgainChecked ? <FiCheckSquare /> : <FiSquare />}
                  <span>Nicht mehr anzeigen</span>
                </label>
              </div>
              <button
                className="btn btn-primary"
                type="button"
                onClick={() => {
                  this.props.closeModal(doNotShowAgainChecked);
                }}
              >
                OK
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default StrengthModuleExplanationModal;
