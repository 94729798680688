import React from 'react';
import { NavigateFunction } from 'react-router-dom';

import { ROUTE_PREFIX } from '../../../constants/router';
import { withRouter } from '../../../util/withRouter';
import Loading from '../../Loading';
import { itestResultsType } from './InterestTestResultsWrapper';
import { checkIfTestResultsAreInvalid } from './TestCompletionRequiredWrapper';

interface TestIncompletionRequiredProps {
  userItestResults?: itestResultsType;
  navigate: NavigateFunction;
}

const TestCompletionRequiredWrapper = (WrappedComponent: any) => {
  class TestIncompletionRequired extends React.Component<TestIncompletionRequiredProps> {
    static defaultProps = {
      userItestResults: null,
    };

    render() {
      const { userItestResults, navigate } = this.props;

      const testResultsInvalid = checkIfTestResultsAreInvalid(userItestResults);

      if (!testResultsInvalid) {
        navigate(`${ROUTE_PREFIX}/home`);
        return <Loading />;
      }

      return <WrappedComponent {...this.props} />;
    }
  }

  return withRouter(TestIncompletionRequired);
};

export default TestCompletionRequiredWrapper;
