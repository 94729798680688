import React from 'react';
import { Modal } from 'react-bootstrap';

import s from './StrengthCardsModal.module.scss';

interface NoImageUploadAsGuestModalProps {
  showModal: boolean;
  closeModal: () => void;
}

class NoImageUploadAsGuestModal extends React.Component<NoImageUploadAsGuestModalProps> {
  render() {
    return (
      <div>
        <Modal
          centered
          show={this.props.showModal}
          backdrop="static"
          dialogClassName={s.modal}
        >
          <Modal.Header className={s.header}>
            <Modal.Title>
              <h3>Bild hinzufügen nicht möglich</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className={s.body}>
            <div className={s.info}>
              Du kannst als Gast keine eigenen Bilder hinzufügen. Bitte
              registriere dich im Hauptmenü, um deine bisherigen Daten zu
              übernehemen. Danach kannst du deine Stärkenkarten mit Bildern
              personalisieren.
            </div>
            <div className={s.buttonContainer}>
              <button
                className="btn btn-primary"
                type="button"
                onClick={this.props.closeModal}
              >
                OK
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default NoImageUploadAsGuestModal;
