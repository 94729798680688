import React from 'react';
import { Modal, Button } from 'react-bootstrap';

interface ShowOnceModalProps {
  headerText: string;
  infoText: string;
  infoImageSrc?: any;
  onShow: () => void;
  wasShown: boolean;
}

class ShowOnceModal extends React.Component<ShowOnceModalProps> {
  // eslint-disable-next-line react/state-in-constructor
  state: any;

  static defaultProps = {
    infoImageSrc: null,
  };

  constructor(props: any) {
    super(props);

    this.state = {
      showModal: true,
    };

    this.closeModal = this.closeModal.bind(this);
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  render() {
    const { headerText, infoText, infoImageSrc, onShow, wasShown } = this.props;
    const { showModal } = this.state;

    const show = !wasShown && showModal;

    return (
      <Modal show={show} onHide={this.closeModal}>
        <Modal.Header>
          <Modal.Title>
            <h3>
              <strong>{headerText}</strong>
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ display: 'flex', flexDirection: 'column' }}>
          <div>
            {infoText.split('\n').map((t) => (
              <span key={t}>
                {t}
                <br />
              </span>
            ))}
          </div>
          {infoImageSrc && (
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              <img
                style={{ height: '50px', justifySelf: 'center' }}
                src={infoImageSrc}
                alt=""
              />
            </div>
          )}
          <div
            style={{
              flexDirection: 'row',
              marginTop: '50px',
              textAlign: 'end',
            }}
          >
            <Button
              onClick={() => {
                onShow();
                this.closeModal();
              }}
            >
              OK
            </Button>
          </div>
        </Modal.Body>
        <Modal.Footer />
      </Modal>
    );
  }
}

export default ShowOnceModal;
