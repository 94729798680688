import _, { isObject } from 'lodash';
import {
  COMPLETED,
  getJobStatus,
  getSchoolStatus,
} from '../DesiredJobOrSchoolScreen/statusGetters';

export const activityIsCompleted = (moduleData: any) => {
  if (!moduleData) return false;
  const data = JSON.parse(moduleData);
  if (!data || !data.activities) return false;
  const favorite = data.activities.find((d: any) => d.favorite);
  return isObject(favorite);
};

export const strengthsAreCompleted = (moduleData: any) => {
  if (!moduleData) return false;
  const data = JSON.parse(moduleData);
  const strengths = _.get(data, 'strengthCardsFavorites');
  if (!strengths) return false;
  const likedStrengths = Object.keys(strengths).reduce(
    (liked, strength) => (strengths[strength] ? liked + 1 : liked),
    0,
  );
  return likedStrengths >= 5;
};

export const jobExpAreCompleted = (moduleData: any) => {
  if (!moduleData) return false;
  const data = JSON.parse(moduleData);
  const strengths = _.get(data, 'favorites');
  if (!strengths) return false;
  return Object.keys(strengths).length >= 5;
};

export const jobOrSchoolIsCompleted = (moduleData: any) => {
  if (!moduleData) return false;
  const data = JSON.parse(moduleData);
  if (!data || (!data.job && !data.school)) return false;
  const jobComplete = getJobStatus(data);
  const schoolComplete = getSchoolStatus(data);
  return jobComplete === COMPLETED || schoolComplete === COMPLETED;
};

export const nextStepsAreCompleted = (moduleData: any) => {
  if (!moduleData) return false;
  const data = JSON.parse(moduleData);
  if (!data || !data.standard || !data.custom) return false;
  const allTodos = [...data.standard, ...data.custom];
  const unfinishedTodos = allTodos.filter((todo) => !todo.value);
  return unfinishedTodos.length === 0;
};
