import React from 'react';

import s from './InterestsTestScreen.module.scss';

interface SubmitCardProps {
  render?: boolean;
  finishTest: () => void;
  cancelTest: () => void;
  isPilot?: boolean;
  isVerification?: boolean;
  uploadStatus?: string;
  canSubmit?: boolean;
  canRestart?: boolean;
}

class SubmitCard extends React.Component<SubmitCardProps> {
  static defaultProps = {
    render: false,
    isPilot: false,
    isVerification: false,
    uploadStatus: null,
    canSubmit: true,
    canRestart: false,
  };

  render() {
    const {
      render,
      finishTest,
      cancelTest,
      isPilot,
      isVerification,
      uploadStatus,
      canSubmit,
      canRestart,
    } = this.props;

    if (!render) return <div className={`${s.interestCard} ${s.subitCard}`} />;

    let code = '';

    if (isPilot) {
      const normalizationData: any = JSON.parse(
        sessionStorage.getItem('normalization_data') || '{}',
      );

      code = normalizationData.code;
    }

    return (
      <div className={`${s.interestCard} ${s.subitCard}`}>
        {uploadStatus && (
          <div className={s.statusTextWrapper}>{uploadStatus}</div>
        )}
        {canSubmit && (
          <button
            type="button"
            className="btn btn-secondary"
            onClick={finishTest}
          >
            {isPilot || isVerification ? 'Abschließen' : 'ERGEBNIS ANZEIGEN'}
          </button>
        )}
        {isPilot && canRestart && (
          <a
            type="button"
            className="btn btn-secondary"
            href={`https://docs.google.com/forms/d/e/1FAIpQLSc9gMPaY_uW4cs_XILvuYo3neOL6rbLlY1fHc37sqFOwAtFkw/viewform?usp=pp_url&entry.1071063146=${code}`}
            target="_blank"
            rel="noreferrer"
          >
            Weiter zum Fragebogen
          </a>
        )}
        {(isPilot || isVerification) && canRestart && (
          <button
            type="button"
            className={`${s.cancelTestButton} btn btn-secondary`}
            onClick={cancelTest}
          >
            Neuen Test starten
          </button>
        )}
      </div>
    );
  }
}

export default SubmitCard;
