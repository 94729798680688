import React from 'react';

import { graphql } from '@apollo/client/react/hoc';
import { flowRight as compose } from 'lodash';

import AppContext from '../../context';
import {
  withLogin,
  meQuery,
  getGuestLocalStorageData,
  getUserTokenCookie,
} from './withLogin';
import Loading from '../Loading';
import { withRouter } from '../../util/withRouter';

export const LOGGED_MODE_USER = 'LOGGED_MODE_USER';
export const LOGGED_MODE_GUEST = 'LOGGED_MODE_GUEST';

interface WithAuthenticationProps {
  guestLogin: () => void;
  meData?: {
    loading: boolean;
    me: {
      id: string;
      name: string;
    };
  };
}

const AuthenticationWrapper = (WrappedComponent: any) => {
  class WithAuthentication extends React.Component<WithAuthenticationProps> {
    static contextType = AppContext;

    // eslint-disable-next-line react/state-in-constructor
    state: any;

    static defaultProps = {
      meData: null,
    };

    constructor(props: any) {
      super(props);

      this.state = {
        guestData: {
          loading: true,
          isActive: false,
        },
      };
    }

    componentDidMount() {
      // check for guest
      if (getGuestLocalStorageData() != null) {
        this.setState({
          guestData: {
            loading: false,
            isActive: true,
          },
        });
      } else {
        this.setState({
          guestData: {
            loading: false,
            isActive: false,
          },
        });
      }
    }

    render() {
      const { guestLogin, meData } = this.props;
      const { guestData } = this.state;

      if ((meData && meData.loading) || guestData.loading) return <Loading />;

      let loggedMode = null;

      if (meData && meData.me) {
        loggedMode = LOGGED_MODE_USER;
      } else if (guestData.isActive) {
        loggedMode = LOGGED_MODE_GUEST;
      }

      // not logged in as guest or user --> login as guest
      if (loggedMode !== LOGGED_MODE_USER && loggedMode !== LOGGED_MODE_GUEST) {
        guestLogin();
        loggedMode = LOGGED_MODE_GUEST;
      }

      return <WrappedComponent {...this.props} loggedMode={loggedMode} />;
    }
  }

  return compose(
    graphql(meQuery, {
      name: 'meData',
      skip: () => getUserTokenCookie() == null,
    }),
    withLogin,
    withRouter,
  )(WithAuthentication);
};

export default AuthenticationWrapper;
