import getAssetLink from '../util/getAssetLink';

const DieMacher = getAssetLink('DieMacher.png');
const DieDenker = getAssetLink('DieDenker.png');
const DieGestalter = getAssetLink('DieGestalter.png');
const DieHelfer = getAssetLink('DieHelfer.png');
const DieUeberzeuger = getAssetLink('DieUeberzeuger.png');
const DieDatenverrueckten = getAssetLink('DieDatenverrueckten.png');
const Sport = getAssetLink('Sport.png');
const Sprachen = getAssetLink('Sprachen.png');

export default [
  {
    title: 'UMSETZEN',
    content: [
      'Upcycling - mach aus einem alten Gegenstand durch deine Kreativität etwas Neues (z.B. Deko, Kleidung, Möbel).',
      'Baue eine elektrische Schaltung für einen ferngesteuerten Roboter.',
    ],
    color: '#8CA068',
    icon: DieMacher,
  },
  {
    title: 'FORSCHEN',
    content: [
      'Führe Experimente durch und beobachte was passiert.',
      'Erforsche eine bestimmte Tierart und führe Aufzeichnungen.',
      'Programmiere dein eigenes Computerspiel (z.B. mit Hilfe von Jugendhackt).',
    ],
    color: '#9471A5',
    icon: DieDenker,
  },
  {
    title: 'GESTALTEN',
    content: [
      'Veröffentliche deine eigene Musik auf Youtube.',
      'Studiere einen neuen Dance Move ein und mache eine Vorführung.',
      'Backe und gestalte bunte Cupcakes für die nächste Party.',
      'Designe deine eigene Website.',
    ],
    color: '#D67C31',
    icon: DieGestalter,
  },
  {
    title: 'HELFEN',
    content: [
      'Gib jemandem gratis Nachhilfe.',
      'Geh mit Hunden aus dem Tierheim spazieren.',
    ],
    color: '#EAB934',
    icon: DieHelfer,
  },
  {
    title: 'ÜBERZEUGEN',
    content: [
      'Verkaufe alte Dinge am Flohmarkt/ über eine online Plattform.',
      'Organisiere ein Fest mit deinen Freunden.',
      'Gestalte eine Kampagne zu einem Thema, das dir am Herzen liegt und überzeuge andere damit.',
    ],
    color: '#7DAEAC',
    icon: DieUeberzeuger,
  },
  {
    title: 'DATEN',
    content: [
      'Gestalte deinen persönlichen Aktivitätenplan und notiere alle wichtigen Termine.',
      'Überlege dir ein System für die Sortierung deiner Sammelkarten.',
    ],
    color: '#6986AF',
    icon: DieDatenverrueckten,
  },
  {
    title: 'SPORT',
    content: [
      'Absolviere einen Waldparcour und messe deine Zeit.',
      'Mach ein Schnuppertraining in einer neuen Sportart.',
    ],
    color: '#95B2DE',
    icon: Sport,
  },
  {
    title: 'SPRACHEN',
    content: [
      'Wohin geht dein nächster Urlaub? Bereite dich mit einer Fremdsprachen-App vor.',
      'Denk dir deinen eigenen Rap-Text zu einem coolen Hip Hop Beat aus.',
    ],
    color: '#A87E44',
    icon: Sprachen,
  },
];
