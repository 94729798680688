import React from 'react';
import { Link, NavigateFunction } from 'react-router-dom';
import { flowRight as compose } from 'lodash';

import { ROUTE_PREFIX } from '../../../constants/router';
import s from './OverviewScreen.module.scss';
import withAuthentication, {
  LOGGED_MODE_GUEST,
} from '../../Auth/AuthenticationWrapper';
import withInterestTestResults, {
  itestResultsType,
} from '../../HomeScreen/DataWrappers/InterestTestResultsWrapper';
import { checkIfTestResultsAreInvalid } from '../../HomeScreen/DataWrappers/TestCompletionRequiredWrapper';
import withBundesland from '../../HomeScreen/DataWrappers/BundeslandWrapper';
import withAkooeData, {
  akooeDataType,
} from '../../HomeScreen/DataWrappers/AkooeDataWrapper';
import ShowOnceModal from './ShowOnceModal';
import ContentBody from '../../ContentBody/index';
import Footer from '../../Footer';
import OffersHeader from '../../OffersScreen/OffersHeader';
import {
  activityIsCompleted,
  strengthsAreCompleted,
  jobExpAreCompleted,
  jobOrSchoolIsCompleted,
  nextStepsAreCompleted,
} from './completionCheckers';
import RegisterModal from '../../HomeScreen/RegisterModal';
import JobExpectations from '../JobExpectationsScreen/JobExpectations';
import getAssetLink from '../../../util/getAssetLink';
import Loading from '../../Loading';

const NOT_EDITED = 'not edited';
const EDITED = 'edited';
const COMPLETED = 'completed';

const getIconByStatus = (status: string) => {
  switch (status) {
    case NOT_EDITED:
      return getAssetLink('edit_light.png');
    case EDITED:
      return getAssetLink('edit.png');
    case COMPLETED:
      return getAssetLink('heart_selected.png');
    default:
      return getAssetLink('edit_light.png');
  }
};

const getStatus = (isSet: any, isComplete: boolean) => {
  if (isSet) {
    if (isComplete) {
      return COMPLETED;
    }
    return EDITED;
  }
  return NOT_EDITED;
};

interface OverviewScreenProps {
  loggedMode: string;
  meData?: any;
  userItestResults?: itestResultsType;
  refetchSelectedBundesland: () => Promise<void>;
  selectedBundesland: string;
  // eslint-disable-next-line no-unused-vars
  setSelectedBundesland: (bundesland: string) => Promise<void>;
  userAkooeData: akooeDataType;
  refetchAkooeData: () => Promise<void>;
  // eslint-disable-next-line no-unused-vars
  setSeenMotivationModal: (seen: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  setSeenReportModal: (seen: boolean) => void;
  navigate: NavigateFunction;
}

class OverviewScreen extends React.Component<OverviewScreenProps> {
  // eslint-disable-next-line react/state-in-constructor
  state: any;

  static defaultProps = {
    meData: null,
    userItestResults: null,
  };

  constructor(props: any) {
    super(props);

    this.state = {
      showRegisterModal: false,
    };
  }

  render() {
    const {
      loggedMode,
      meData,
      userItestResults,
      refetchSelectedBundesland,
      selectedBundesland,
      setSelectedBundesland,
      userAkooeData,
      navigate,
    } = this.props;

    if (
      selectedBundesland === 'oberösterreich' &&
      !userAkooeData.watchedIntro
    ) {
      navigate(`${ROUTE_PREFIX}/orientation/offers/oberösterreich/intro-video`);
      return <Loading />;
    }

    const { showRegisterModal } = this.state;

    const testResultsInvalid = checkIfTestResultsAreInvalid(userItestResults);
    const finishedTest = !testResultsInvalid;

    // get status
    // intro
    const intro = userAkooeData.watchedIntro;
    const introStatus = getStatus(intro, true);

    // activity
    const activity = userAkooeData.activityModuleData;
    const activityComplete = activityIsCompleted(activity);
    const activityStatus = getStatus(
      activity && activity !== '{}',
      activityComplete,
    );

    // strength
    const strengths = userAkooeData.strengthsModuleData;
    const strengthComplete = strengthsAreCompleted(strengths);
    const strengthStatus = getStatus(
      strengths && strengths !== '{}',
      strengthComplete,
    );
    const strengthsModuleLink =
      strengths &&
      Object.keys(JSON.parse(strengths).strengthCardsFavorites || {}).length > 0
        ? `${ROUTE_PREFIX}/orientation/offers/${selectedBundesland}/modules/strengths/favorites`
        : `${ROUTE_PREFIX}/orientation/offers/${selectedBundesland}/modules/strengths`;

    // job expectations
    const jobExp = userAkooeData.jobExpectationsModuleData;
    const jobExpCompleted = jobExpAreCompleted(jobExp);
    const jobExpectationStatus = getStatus(
      jobExp && jobExp !== '{}',
      jobExpCompleted,
    );
    const jobExpModuleLink =
      jobExp &&
      // eslint-disable-next-line prettier/prettier
        Object.keys(JSON.parse(jobExp).jobExpectations || {}).length >= JobExpectations.length
        ? `${ROUTE_PREFIX}/orientation/offers/${selectedBundesland}/modules/job-expectations/favorites`
        : `${ROUTE_PREFIX}/orientation/offers/${selectedBundesland}/modules/job-expectations`;

    // desired school or job
    const jobOrSchool = userAkooeData.desiredJobOrSchoolModuleData;
    const jobOrSchoolCompleted = jobOrSchoolIsCompleted(jobOrSchool);
    const jobOrSchoolStatus = getStatus(
      jobOrSchool && jobOrSchool !== '{}',
      jobOrSchoolCompleted,
    );

    // next steps
    const nextSteps = userAkooeData.nextStepsModuleData;
    const nextStepsCompleted = nextStepsAreCompleted(nextSteps);
    const nextStepStatus = getStatus(
      nextSteps && nextSteps !== '{}',
      nextStepsCompleted,
    );

    let completedModules = 0;
    if (activityStatus !== NOT_EDITED) completedModules += 1;
    if (strengthStatus !== NOT_EDITED) completedModules += 1;
    if (jobExpectationStatus !== NOT_EDITED) completedModules += 1;
    if (jobExpectationStatus !== NOT_EDITED) completedModules += 1;
    if (nextStepStatus !== NOT_EDITED) completedModules += 1;

    const showMotivationModalAfter1stModule = completedModules >= 1;
    const showReportTip = completedModules >= 4;

    return (
      <div className={s.overviewContainer}>
        <OffersHeader
          bundesland={selectedBundesland}
          setSelectedBundesland={setSelectedBundesland}
          refetchSelectedBundesland={refetchSelectedBundesland}
        />
        <ContentBody>
          <div key="overviewContent">
            {loggedMode === LOGGED_MODE_GUEST && (
              <div className={s.guestBanner}>
                Du bist als Gast angemeldet. Registriere dich, um deine
                bisherigen Daten zu übernehmen.
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    this.setState({ showRegisterModal: true });
                  }}
                >
                  REGISTRIEREN
                </button>
              </div>
            )}
            <div className={s.headerSection}>
              <div className={s.headerWrapper}>
                <div className={s.headerLine}>
                  <h1>SO GEHT ES WEITER</h1>
                </div>
                <div className={s.headerLine}>Erstelle dir dein Portfolio</div>
              </div>
            </div>
            {selectedBundesland === 'oberösterreich' && (
              <Link
                className={s.link}
                to={`${ROUTE_PREFIX}/orientation/offers/oberösterreich/intro-video`}
              >
                <div className={s.listItem}>
                  <div className={s.leftPart}>ERKLÄRVIDEO</div>
                  <div className={s.rightPart}>
                    <img
                      className={s.icon}
                      src={getIconByStatus(introStatus)}
                      alt=""
                    />
                  </div>
                </div>
              </Link>
            )}
            <Link
              className={s.link}
              to={`${ROUTE_PREFIX}/orientation/offers/${selectedBundesland}/modules/activities`}
            >
              <div className={s.listItem}>
                <div className={s.leftPart}>MEINE INTERESSEN</div>
                <div className={s.rightPart}>
                  <img
                    className={s.icon}
                    src={getIconByStatus(activityStatus)}
                    alt=""
                  />
                </div>
              </div>
            </Link>
            <Link className={s.link} to={strengthsModuleLink}>
              <div className={s.listItem}>
                <div className={s.leftPart}>MEINE STÄRKEN</div>
                <div className={s.rightPart}>
                  <img
                    className={s.icon}
                    src={getIconByStatus(strengthStatus)}
                    alt=""
                  />
                </div>
              </div>
            </Link>
            <Link className={s.link} to={jobExpModuleLink}>
              <div className={s.listItem}>
                <div className={s.leftPart}>
                  MEINE
                  <br />
                  BERUFSERWARTUNGEN
                </div>
                <div className={s.rightPart}>
                  <img
                    className={s.icon}
                    src={getIconByStatus(jobExpectationStatus)}
                    alt=""
                  />
                </div>
              </div>
            </Link>
            <Link
              className={s.link}
              to={`${ROUTE_PREFIX}/orientation/offers/${selectedBundesland}/modules/desired-job-or-school`}
            >
              <div className={s.listItem}>
                <div className={s.leftPart}>
                  MEIN WUNSCHBERUF
                  <br />
                  MEINE WUNSCHSCHULE
                </div>
                <div className={s.rightPart}>
                  <img
                    className={s.icon}
                    src={getIconByStatus(jobOrSchoolStatus)}
                    alt=""
                  />
                </div>
              </div>
            </Link>
            <Link
              className={s.link}
              to={`${ROUTE_PREFIX}/orientation/offers/${selectedBundesland}/modules/next-steps`}
            >
              <div className={s.listItem}>
                <div className={s.leftPart}>
                  MEINE NÄCHSTEN
                  <br />
                  SCHRITTE
                </div>
                <div className={s.rightPart}>
                  <img
                    className={s.icon}
                    src={getIconByStatus(nextStepStatus)}
                    alt=""
                  />
                </div>
              </div>
            </Link>
            {showMotivationModalAfter1stModule && showReportTip && (
              <Link
                className={s.link}
                to={`${ROUTE_PREFIX}/orientation/offers/${selectedBundesland}/export`}
              >
                <div className={s.listItem}>
                  <div className={s.leftPart}>ZUSAMMENFASSUNG</div>
                  <div className={s.rightPart}>
                    <img
                      className={s.icon}
                      src={getAssetLink('download_button.png')}
                      alt=""
                    />
                  </div>
                </div>
              </Link>
            )}
          </div>
          {selectedBundesland === 'oberösterreich' && (
            <a href="http://www.fragdieak.at" target="_blank" rel="noreferrer">
              <img
                className={s.fragdieak}
                src={getAssetLink('frag-die-ak.png')}
                alt=""
              />
            </a>
          )}
          {selectedBundesland === 'burgenland' && (
            <div className={s.headerSection}>
              <div className={s.headerWrapperLong}>
                <div className={s.headerLine} style={{ marginTop: '20px' }}>
                  <h1>Du hast noch Fragen?</h1>
                </div>
                <div className={s.headerLine}>
                  Mehr Information und Unterstützung bei der
                  Bildungswegentscheidung findest du unter:
                </div>
                <div
                  className={s.headerLine}
                  style={{ marginTop: '3px', marginBottom: '15px' }}
                >
                  <a
                    href="https://bgld.arbeiterkammer.at/bildungsnavi"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://bgld.arbeiterkammer.at/bildungsnavi
                  </a>
                </div>
              </div>
            </div>
          )}
          {selectedBundesland === 'steiermark' && (
            <div className={s.headerSection}>
              <div className={s.headerWrapperLong}>
                <div className={s.headerLine} style={{ marginTop: '20px' }}>
                  <h1>Du hast noch Fragen?</h1>
                </div>
                <div className={s.headerLine}>
                  {/* eslint-disable react/jsx-one-expression-per-line */}
                  Das Bildungssystem wird komplizierter. Egal, ob die Wahl der
                  Schule oder des Studiums, es gibt viele Möglichkeiten. Auch
                  bei der Lehre oder einer berufsbegleitenden Aus- und
                  Weiterbildung. Unterstützung gibt die AK-Bildungsberatung:{' '}
                  <b>05-7799-2355</b> oder <b>bildung@akstmk.at</b> in einem
                  persönlichen Gespräch oder direkt bei Workshops in der AK oder
                  in den Schulen.
                  {/* eslint-enable react/jsx-one-expression-per-line */}
                </div>
                <div
                  className={s.headerLine}
                  style={{ marginTop: '3px', marginBottom: '15px' }}
                >
                  <a
                    href="https://stmk.arbeiterkammer.at/beratung/bildung/bildungsberatung/AK-Bildungsberatung.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    AK-Bildungsberatung | Arbeiterkammer Steiermark
                  </a>
                </div>
              </div>
            </div>
          )}
        </ContentBody>
        <Footer
          loggedMode={loggedMode}
          meData={meData}
          finishedTest={finishedTest}
          forceShowExport
          exportUrl={`${ROUTE_PREFIX}/orientation/offers/${selectedBundesland}/export`}
          withEPortfolio
          refetchAkooeData={this.props.refetchAkooeData}
        />
        <RegisterModal
          showModal={showRegisterModal}
          closeModal={() => {
            this.setState({ showRegisterModal: false });
          }}
        />
        {showMotivationModalAfter1stModule && (
          <ShowOnceModal
            headerText="Dein Portfolio"
            infoText="Das Portfolio hilft dir, dich besser kennenzulernen. Die nächsten Schritte bringen dich deiner Entscheidung noch näher."
            onShow={async () => {
              await this.props.setSeenMotivationModal(true);
              await this.props.refetchAkooeData();
            }}
            wasShown={userAkooeData.seenMotivationModal as boolean}
          />
        )}
        {showReportTip && (
          <ShowOnceModal
            headerText="Report"
            infoText={`Im Report bekommst du einen Überblick über deine wichtigsten Ergebnisse. Besprich diese mit deinen Eltern, Freunden/-innen und Personen, die dir wichtig sind!\n\nDu findest deinen Report unter "Zusammenfassung" auf dieser Seite oder mit dem Teilen-Button in der Fußzeile.`}
            infoImageSrc={getAssetLink('share_red.png')}
            onShow={async () => {
              await this.props.setSeenReportModal(true);
              await this.props.refetchAkooeData();
            }}
            wasShown={userAkooeData.seenReportModal as boolean}
          />
        )}
      </div>
    );
  }
}

export default compose(
  withAuthentication,
  withInterestTestResults,
  withBundesland,
  withAkooeData,
)(OverviewScreen);
