import React from 'react';
import { Modal } from 'react-bootstrap';
import { FiSquare, FiCheckSquare } from 'react-icons/fi';

import s from './AkooeRestartModal.module.scss';

interface AkooeRestartModalProps {
  showModal: boolean;
  closeModal: () => void;
  // eslint-disable-next-line no-unused-vars
  onSubmit: (data: any) => void;
}

class AkooeRestartModal extends React.Component<AkooeRestartModalProps> {
  // eslint-disable-next-line react/state-in-constructor
  state: any;

  constructor(props: any) {
    super(props);

    this.state = {
      resetEPortfolioChecked: true,
      resetItestChecked: false,
    };
  }

  render() {
    const { showModal, closeModal, onSubmit } = this.props;
    const { resetEPortfolioChecked, resetItestChecked } = this.state;

    return (
      <div>
        <Modal
          centered
          show={showModal}
          backdrop="static"
          dialogClassName={s.modal}
        >
          <Modal.Header className={s.header}>
            <Modal.Title>
              <h3>Zurücksetzen</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className={s.body}>
            <div className={s.info}>
              <div className={s.resetText}>
                Welche Daten willst du zurücksetzen?
              </div>
              <div className={s.selectWrapper}>
                <input
                  type="checkbox"
                  id="resetEPortfolio"
                  checked={this.state.resetEPortfolioChecked}
                  onChange={() => {
                    this.setState({
                      resetEPortfolioChecked: !resetEPortfolioChecked,
                    });
                  }}
                  hidden
                />
                <label htmlFor="resetEPortfolio">
                  {resetEPortfolioChecked ? <FiCheckSquare /> : <FiSquare />}
                  <span>E-Portfolio zurücksetzen</span>
                </label>
              </div>
              <div className={s.selectWrapper}>
                <input
                  type="checkbox"
                  id="resetItest"
                  checked={this.state.resetItestChecked}
                  onChange={() => {
                    this.setState({
                      resetItestChecked: !resetItestChecked,
                    });
                  }}
                  hidden
                />
                <label htmlFor="resetItest">
                  {resetItestChecked ? <FiCheckSquare /> : <FiSquare />}
                  <span>Jopsy-Test zurücksetzen</span>
                </label>
              </div>
            </div>
            <div className={s.buttonContainerTwoButtons}>
              <button
                className="btn btn-secondary"
                type="button"
                onClick={() => {
                  closeModal();
                }}
              >
                ABBRUCH
              </button>
              <button
                className="btn btn-primary"
                type="button"
                onClick={() => {
                  onSubmit({
                    resetItest: resetItestChecked,
                    resetEPortfolio: resetEPortfolioChecked,
                  });
                }}
              >
                ZURÜCKSETZEN
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default AkooeRestartModal;
