/* eslint-disable default-param-last */
import { SET_PROFILE_ACCURACY_MODAL_STATE } from '../constants/variables';

const initialState = { profileAccuracyModal: true };

export default function setProfileAccuracyModalState(
  state = initialState,
  action: any,
) {
  switch (action.type) {
    case SET_PROFILE_ACCURACY_MODAL_STATE:
      return { ...state, profileAccuracyModal: action.show };
    default:
      return state;
  }
}
