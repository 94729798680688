/* eslint-disable react/prop-types, max-len, jsx-a11y/label-has-for */

import React from 'react';
import Textarea from 'react-textarea-autosize';

import Spinner from '../Spinner';

// workaround for https://www.chromestatus.com/features/5093566007214080
const onTouchStart = (event: any) => {
  event.target.focus();
};

const wrapHorizontal = (
  id: string,
  component: any,
  label: string,
  required = false,
) => {
  if (!label) {
    return (
      <div key={`horizontal_component_${id}`} className="col-sm-8">
        {component}
      </div>
    );
  }
  return [
    <label key={`horizontal_label_${id}`} htmlFor={id} className="col-sm-4">
      {label}
      {required && (
        <span key={`horizontal_required_${id}`} className="required">
          *
        </span>
      )}
    </label>,
    <div key={`horizontal_component_${id}`} className="col-sm-8">
      {component}
    </div>,
  ];
};

const renderInput = ({
  input,
  label,
  type,
  meta: { asyncValidating, touched, error, warning },
  id,
  placeholder,
  disabled,
  horizontal = false,
  required = false,
  ...rest
}: any) => {
  const inputField = (
    <input
      {...input}
      {...rest}
      id={id}
      type={type}
      key={id}
      className="form-control"
      placeholder={placeholder}
      onTouchStart={onTouchStart}
      disabled={disabled}
      // ref={el => autoFocus && el && el.focus()}
    />
  );

  const component = horizontal
    ? wrapHorizontal(id, inputField, label, required)
    : [
        label && (
          <label htmlFor={id} key={`label_${id}`}>
            {label}
            {required && <span className="required">*</span>}
          </label>
        ),
        inputField,
      ];

  const errorClass = horizontal
    ? 'validationErrorHorizontal'
    : 'validationError';
  return (
    <div className="form-group">
      <Spinner show={asyncValidating} />
      {component}
      {touched &&
        ((error && <p className={errorClass}>{error}</p>) ||
          (warning && <p className="bg-warning">{warning}</p>))}
    </div>
  );
};

const renderFileInput = ({
  label,
  meta: { asyncValidating, touched, error, warning },
  id,
  onFileChange,
}: any) => (
  <div className="form-group">
    <Spinner show={asyncValidating} />
    {label && (
      <label
        htmlFor={id}
        className="btn btn-default btn-block btn-file"
        style={{ minHeight: '38px' }}
      >
        {label}
      </label>
    )}
    <input
      id={id}
      type="file"
      className="form-control"
      style={label ? { display: 'none' } : {}}
      onTouchStart={onTouchStart}
      onChange={onFileChange}
    />
    {touched &&
      ((error && <p className="bg-danger">{error}</p>) ||
        (warning && <p className="bg-warning">{warning}</p>))}
  </div>
);

const renderCheckbox = ({
  input,
  label,
  type,
  meta: { touched, error, warning },
  id,
  style,
  disabled,
  showErrors = true,
}: any) => (
  <div className="form-group">
    <div className="checkbox" style={style}>
      <label htmlFor={id}>
        <input {...input} id={id} type={type} disabled={disabled} />
        {label}
      </label>
      {showErrors &&
        touched &&
        ((error && <p className="validationError">{error}</p>) ||
          (warning && <p className="bg-warning">{warning}</p>))}
    </div>
  </div>
);

const renderRadio = ({
  input,
  label,
  type,
  meta: { touched, error, warning },
  id,
}: any) => (
  <div className="radio">
    <label htmlFor={id}>
      <input {...input} id={id} type={type} />
      {label}
      <span className="customRadioButton" />
    </label>
    {touched &&
      ((error && <p className="bg-danger">{error}</p>) ||
        (warning && <p className="bg-warning">{warning}</p>))}
  </div>
);

const renderSelect = ({
  input,
  label,
  meta: { touched, error, warning },
  id,
  children,
  disabled,
  horizontal = false,
  required = false,
}: any) => {
  const selectField = (
    <select {...input} id={id} className="form-control" disabled={disabled}>
      {children}
    </select>
  );

  const component = horizontal
    ? wrapHorizontal(id, selectField, label, required)
    : [label && <label htmlFor={id}>{label}</label>, selectField];

  return (
    <div className="form-group">
      {component}
      {touched &&
        ((error && <p className="validationError">{error}</p>) ||
          (warning && <p className="bg-warning">{warning}</p>))}
    </div>
  );
};

const renderTextarea = ({
  input,
  label,
  meta: { touched, error, warning },
  id,
  placeholder,
  horizontal = false,
  style,
}: any) => {
  const text = (
    <Textarea
      {...input}
      id={id}
      className="form-control"
      placeholder={placeholder}
      style={style}
      key={id}
    >
      {input.value}
    </Textarea>
  );

  const component = horizontal
    ? wrapHorizontal(id, text, label)
    : [label && <label htmlFor={id}>{label}</label>, text];

  return (
    <div className="form-group">
      {component}
      {touched &&
        ((error && <p className="bg-danger">{error}</p>) ||
          (warning && <p className="bg-warning">{warning}</p>))}
    </div>
  );
};

export default {
  renderInput,
  renderFileInput,
  renderCheckbox,
  renderRadio,
  renderSelect,
  renderTextarea,
};
