import React from 'react';
import { flowRight as compose } from 'lodash';

import s from './ImprintScreen.module.scss';
import withAuthentication from '../Auth/AuthenticationWrapper';
import withInterestTestResults, {
  itestResultsType,
} from '../HomeScreen/DataWrappers/InterestTestResultsWrapper';
import { checkIfTestResultsAreInvalid } from '../HomeScreen/DataWrappers/TestCompletionRequiredWrapper';
import ContentBody from '../ContentBody/index';
import Header from '../Header/index';
import Footer from '../Footer/index';
import { withSEO } from '../SEO/withSEO';

interface ImprintScreenProps {
  loggedMode: string;
  meData?: any;
  userItestResults?: itestResultsType;
}

class ImprintScreen extends React.Component<ImprintScreenProps> {
  static defaultProps = {
    meData: null,
    userItestResults: null,
  };

  render() {
    const { loggedMode, meData, userItestResults } = this.props;

    const testResultsInvalid = checkIfTestResultsAreInvalid(userItestResults);
    const finishedTest = !testResultsInvalid;

    return (
      <div className={s.imprintScreenContainer}>
        <Header withBackButton />
        <ContentBody>
          <h1>IMPRESSUM</h1>
          <div className={s.container}>
            <h3>AK Wien</h3>
            Prinz-Eugen-Straße 20-22
            <br />
            1040 Wien
          </div>
          <div className={s.container}>
            <h3>AK Oberösterreich</h3>
            Volksgartenstraße 40
            <br />
            4020 Linz
          </div>
        </ContentBody>
        <Footer
          loggedMode={loggedMode}
          meData={meData}
          finishedTest={finishedTest}
        />
      </div>
    );
  }
}

export default compose(
  withSEO({
    title: 'Jopsy | Impressum',
    description:
      'AK Wien, Prinz-Eugen-Straße 20-22, 1040 Wien | AK Oberösterreich, Volksgartenstraße 40, 4020 Linz',
  }),
  withAuthentication,
  withInterestTestResults,
)(ImprintScreen);
