import React from 'react';
import gql from 'graphql-tag';
import moment from 'moment';
import { Line, Pie } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FiRefreshCw } from 'react-icons/fi';
import { flowRight as compose } from 'lodash';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

import { FaDownload, FaTrash } from 'react-icons/fa';
import s from './AdminDashboard.module.scss';
import AppContext, { AppContextType } from '../../context';
import Loading from '../Loading';
import withAdminAuthentication from '../Auth/AdminWrapper';

Chart.register(...registerables);

const colors = [
  '#d44141',
  '#ca78ca',
  '#8CA068',
  '#EAB934',
  '#9471A5',
  '#6986AF',
  '#7DAEAC',
  '#D67C31',
  '#b6be72',
];

const getTrackingDataOverTimeQuery = gql`
  query getTrackingDataOverTime($from: String!, $to: String!) {
    trackingDataOverTime(from: $from, to: $to) {
      totalVisits
      totalVisitsDuration
      offersClicks
      offers18PlusClicks
      totalTestStarts
      totalTestFinishes
      total18PlusTestStarts
      total18PlusTestFinishes
      locations
      deviceTypes
      date
    }
  }
`;

const getAccessLogsOverTimeQuery = gql`
  query getAccessLogsOverTime($from: String!, $to: String!) {
    accessLogsOverTime(from: $from, to: $to) {
      trackKey
      date
      accessCount
    }
  }
`;

const getPilotDataQuery = gql`
  query getPilotData {
    pilotData {
      id
      json
      createdAt
      updatedAt
    }
  }
`;

const deletePilotDataMutation = gql`
  mutation deletePilotData {
    deletePilotData
  }
`;

const TRACKING_DATE_FORMAT = 'YYYY-MM-DD';

class AdminDashboard extends React.Component {
  static contextType = AppContext;

  // eslint-disable-next-line react/state-in-constructor
  state: any;

  constructor(props: any) {
    super(props);

    this.state = {
      from: moment().startOf('month').set({ millisecond: 0 }).toDate(),
      to: moment().set({ millisecond: 0 }).toDate(),
      trackingData: null,
      accessLogs: null,
      loading: true,
    };

    this.loadTrackingData = this.loadTrackingData.bind(this);
    this.downloadPilotData = this.downloadPilotData.bind(this);
    this.createDateAxis = this.createDateAxis.bind(this);
    this.handleDateSelect = this.handleDateSelect.bind(this);
    this.mapPieChartData = this.mapPieChartData.bind(this);
    this.renderPieCharts = this.renderPieCharts.bind(this);
  }

  componentDidMount() {
    this.loadTrackingData();
  }

  // eslint-disable-next-line react/sort-comp
  async loadTrackingData() {
    let result1;
    let result2;

    try {
      result1 = await (this.context as AppContextType).client.query({
        query: getTrackingDataOverTimeQuery,
        variables: {
          from: moment(this.state.from).format(TRACKING_DATE_FORMAT),
          to: moment(this.state.to).format(TRACKING_DATE_FORMAT),
        },
        fetchPolicy: 'network-only',
      });
    } catch (e) {
      // do nothing
    }

    try {
      result2 = await (this.context as AppContextType).client.query({
        query: getAccessLogsOverTimeQuery,
        variables: {
          from: moment(this.state.from).format(TRACKING_DATE_FORMAT),
          to: moment(this.state.to).format(TRACKING_DATE_FORMAT),
        },
        fetchPolicy: 'network-only',
      });
    } catch (e) {
      // do nothing
    }

    let trackingData = [];
    let accessLogs = {};

    if (result1 && result1.data && result1.data.trackingDataOverTime) {
      trackingData = result1.data.trackingDataOverTime;
    }

    if (result2 && result2.data && result2.data.accessLogsOverTime) {
      accessLogs = result2.data.accessLogsOverTime.reduce(
        (map: any, accessLog: any) => {
          if (map[accessLog.trackKey] == null) {
            // eslint-disable-next-line no-param-reassign
            map[accessLog.trackKey] = [];
          }
          map[accessLog.trackKey].push(accessLog);
          return map;
        },
        {},
      );
    }

    this.setState({
      trackingData,
      accessLogs,
      loading: false,
    });
  }

  async downloadPilotData() {
    try {
      const result = await (this.context as AppContextType).client.query({
        query: getPilotDataQuery,
        fetchPolicy: 'network-only',
      });

      if (result.data.pilotData) {
        const data = result.data.pilotData.map((d: any) => {
          const jsonData = JSON.parse(d.json);
          const row: any = {};
          row.personCode = jsonData.personCode;
          row.testMode = jsonData.testMode;
          row.createdAt = new Date(d.createdAt);
          Object.keys(jsonData.interests).forEach((key) => {
            if (jsonData.interests[key].interest >= 0) {
              row[`interest[${key}]`] = jsonData.interests[key].interest;
              row[`time[${key}]`] = jsonData.interests[key].timeSpent;
            }
          });
          return row;
        });

        const ws = XLSX.utils.book_new();
        XLSX.utils.sheet_add_json(ws, data);
        const wb = { Sheets: { Results: ws }, SheetNames: ['Results'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const fileData = new Blob([excelBuffer], {
          type: 'application/octet-stream',
        });
        saveAs(fileData, 'Results.xlsx');
      } else {
        // eslint-disable-next-line no-alert
        alert(`Fehler beim Laden der Daten: ${result?.error}`);
      }
    } catch (e: any) {
      // eslint-disable-next-line no-alert
      alert(`Fehler beim Laden der Daten: ${e.message}`);
    }
  }

  async deletePilotData() {
    try {
      const response = await (this.context as AppContextType).client.mutate({
        mutation: deletePilotDataMutation,
      });
      if (response.data.deletePilotData === true) {
        // eslint-disable-next-line no-alert
        alert('Daten wurden gelöscht.');
      } else {
        // eslint-disable-next-line no-alert
        alert('Fehler beim Löschen der Daten.');
      }
    } catch (e: any) {
      // eslint-disable-next-line no-alert
      alert(`Fehler beim Löschen der Daten: ${e.message}`);
    }
  }

  createDateAxis() {
    const { from, to } = this.state;

    const fromDate = moment(from, TRACKING_DATE_FORMAT);
    const toDate = moment(to, TRACKING_DATE_FORMAT);
    let currentDate = fromDate.clone();

    const dateAxis = [];

    while (!currentDate.isAfter(toDate)) {
      dateAxis.push(currentDate.format(TRACKING_DATE_FORMAT));
      currentDate = currentDate.add(1, 'day');
    }

    return dateAxis;
  }

  handleDateSelect(date: Date | null, type: string) {
    this.setState({ [type]: date }, this.loadTrackingData);
  }

  // eslint-disable-next-line class-methods-use-this
  mapPieChartData(dateAxis: Array<any>, trackingData: Array<any>) {
    const defaultValues = {
      locations: {
        wien: { value: 0, color: '#d44141' },
        niederösterreich: { value: 0, color: '#ca78ca' },
        oberösterreich: { value: 0, color: '#8CA068' },
        tirol: { value: 0, color: '#EAB934' },
        vorarlberg: { value: 0, color: '#9471A5' },
        kärnten: { value: 0, color: '#6986AF' },
        burgenland: { value: 0, color: '#7DAEAC' },
        steiermark: { value: 0, color: '#D67C31' },
        salzburg: { value: 0, color: '#b6be72' },
      },
      offersClicks: {
        wien: { value: 0, color: '#d44141' },
        oberösterreich: { value: 0, color: '#8CA068' },
        kärnten: { value: 0, color: '#6986AF' },
        steiermark: { value: 0, color: '#D67C31' },
        burgenland: { value: 0, color: '#7DAEAC' },
      },
      offers18PlusClicks: {
        wien: { value: 0, color: '#d44141' },
        oberösterreich: { value: 0, color: '#8CA068' },
        salzburg: { value: 0, color: '#b6be72' },
        vorarlberg: { value: 0, color: '#9471A5' },
        steiermark: { value: 0, color: '#D67C31' },
        burgenland: { value: 0, color: '#7DAEAC' },
      },
      testStarts: {
        'Jünger als 18': { value: 0, color: '#d44141' },
        'Älter als 18': { value: 0, color: '#6986AF' },
      },
      testFinishes: {
        'Jünger als 18': { value: 0, color: '#d44141' },
        'Älter als 18': { value: 0, color: '#6986AF' },
      },
      deviceTypes: {
        desktop: { value: 0, color: '#d44141' },
        mobile: { value: 0, color: '#8CA068' },
        tablet: { value: 0, color: '#6986AF' },
        others: { value: 0, color: '#bdbdbd' },
      },
      browsers: {
        chrome: { value: 0, color: '#7DAEAC' },
        firefox: { value: 0, color: '#8CA068' },
        safari: { value: 0, color: '#6986AF' },
        opera: { value: 0, color: '#d15cd1' },
        ie: { value: 0, color: '#EAB934' },
        edge: { value: 0, color: '#d44141' },
        others: { value: 0, color: '#bdbdbd' },
      },
      desktopOS: {
        windows: { value: 0, color: '#d44141' },
        mac: { value: 0, color: '#6986AF' },
        others: { value: 0, color: '#bdbdbd' },
      },
      mobileOS: {
        android: { value: 0, color: '#8CA068' },
        ios: { value: 0, color: '#6986AF' },
        winPhone: { value: 0, color: '#d44141' },
        others: { value: 0, color: '#bdbdbd' },
      },
      tabletOS: {
        android: { value: 0, color: '#8CA068' },
        ios: { value: 0, color: '#6986AF' },
        winPhone: { value: 0, color: '#d44141' },
        others: { value: 0, color: '#bdbdbd' },
      },
    };

    const pieChartData = dateAxis.reduce((map, date) => {
      const {
        testStarts,
        testFinishes,
        locations,
        offersClicks,
        offers18PlusClicks,
        deviceTypes,
        browsers,
        desktopOS,
        mobileOS,
        tabletOS,
      } = map;

      const trackingDataEntry = trackingData.find((td) => td.date === date);

      if (trackingDataEntry) {
        if (trackingDataEntry.totalTestStarts != null) {
          testStarts['Jünger als 18'].value +=
            trackingDataEntry.totalTestStarts;
        }
        if (trackingDataEntry.totalTestFinishes != null) {
          testFinishes['Jünger als 18'].value +=
            trackingDataEntry.totalTestFinishes;
        }

        if (trackingDataEntry.total18PlusTestStarts != null) {
          testStarts['Älter als 18'].value +=
            trackingDataEntry.total18PlusTestStarts;
        }
        if (trackingDataEntry.total18PlusTestFinishes != null) {
          testFinishes['Älter als 18'].value +=
            trackingDataEntry.total18PlusTestFinishes;
        }

        if (trackingDataEntry.locations != null) {
          const newLocations = JSON.parse(trackingDataEntry.locations);
          Object.keys(newLocations).forEach((key) => {
            locations[key].value += newLocations[key];
          });
        }

        if (
          trackingDataEntry.offersClicks != null &&
          trackingDataEntry.offersClicks.trim() !== ''
        ) {
          const newOffersClicks = JSON.parse(trackingDataEntry.offersClicks);
          Object.keys(newOffersClicks).forEach((key) => {
            offersClicks[key].value += newOffersClicks[key];
          });
        }

        if (
          trackingDataEntry.offers18PlusClicks != null &&
          trackingDataEntry.offers18PlusClicks.trim() !== ''
        ) {
          const newOffers18PlusClicks = JSON.parse(
            trackingDataEntry.offers18PlusClicks,
          );
          Object.keys(newOffers18PlusClicks).forEach((key) => {
            offers18PlusClicks[key].value += newOffers18PlusClicks[key];
          });
        }

        if (trackingDataEntry.deviceTypes != null) {
          const newDeviceTypes = JSON.parse(trackingDataEntry.deviceTypes);
          Object.keys(newDeviceTypes).forEach((deviceType) => {
            let sum = 0;
            Object.keys(newDeviceTypes[deviceType]).forEach((os) => {
              let OSSum = 0;
              Object.keys(newDeviceTypes[deviceType][os]).forEach((browser) => {
                OSSum += newDeviceTypes[deviceType][os][browser];
                browsers[browser].value +=
                  newDeviceTypes[deviceType][os][browser];
              });
              switch (deviceType) {
                case 'desktop': {
                  desktopOS[os].value += OSSum;
                  break;
                }
                case 'mobile': {
                  mobileOS[os].value += OSSum;
                  break;
                }
                case 'tablet': {
                  tabletOS[os].value += OSSum;
                  break;
                }
                default:
                  break;
              }
              sum += OSSum;
            });
            deviceTypes[deviceType].value += sum;
          });
        }
      }

      return map;
    }, defaultValues);

    const sortedData: any = {
      testStarts: { data: [], label: 'Gestartete Tests nach Alter' },
      testFinishes: { data: [], label: 'Abgeschlossene Tests nach Alter' }, // eslint-disable-line prettier/prettier
      offersClicks: { data: [], label: 'Angebote angeklickt (Jünger als 18)*' }, // eslint-disable-line prettier/prettier
      offers18PlusClicks: { data: [], label: 'Angebote angeklickt (Älter als 18)*' }, // eslint-disable-line prettier/prettier
      locations: { data: [], label: 'Aurufe nach Bundesland*' },
      deviceTypes: { data: [], label: 'Aufrufe nach Gerätetyp*' },
      browsers: { data: [], label: 'Aufrufe nach Webbrowser*' },
      desktopOS: { data: [], label: 'Aufrufe Desktops nach Betriebssystem*' },
      mobileOS: { data: [], label: 'Aufrufe Mobilgeräte nach Betriebssystem*' }, // eslint-disable-line prettier/prettier
      tabletOS: { data: [], label: 'Aufrufe Tablets nach Betriebssystem*' },
    };

    Object.keys(pieChartData).forEach((keyLvl1) => {
      Object.keys(pieChartData[keyLvl1])
        .sort(
          (a, b) =>
            pieChartData[keyLvl1][b].value - pieChartData[keyLvl1][a].value,
        )
        .forEach((keyLvl2) => {
          sortedData[keyLvl1].data.push({
            key: keyLvl2,
            ...pieChartData[keyLvl1][keyLvl2],
          });
        });
    });

    return sortedData;
  }

  // eslint-disable-next-line class-methods-use-this
  renderPieCharts(pieChartData: any) {
    const result = Object.keys(pieChartData).map((key) => {
      const chartData = {
        labels: pieChartData[key].data.map(
          (item: any) => `${item.key}: ${item.value}`,
        ),
        datasets: [
          {
            data: pieChartData[key].data.map((item: any) => item.value),
            backgroundColor: pieChartData[key].data.map(
              (item: any) => item.color,
            ),
          },
        ],
      };

      return (
        <div
          className={s.pieChartRowContainer}
          key={`${pieChartData[key].label}_pieChart`}
        >
          <div>{pieChartData[key].label}</div>
          <div className={s.chartContainer}>
            <Pie
              data={chartData}
              options={{ maintainAspectRatio: false }}
              width={250}
              height={250}
            />
          </div>
        </div>
      );
    });
    return <div className={s.pieChartContainer}>{result}</div>;
  }

  render() {
    const { from, to, loading, trackingData, accessLogs } = this.state;

    if (loading) return <Loading />;

    const dateAxis = this.createDateAxis();
    const dateAxisForDisplay = dateAxis.map((date) =>
      moment(date, TRACKING_DATE_FORMAT).format('DD.MM.YYYY'),
    );

    const visitDataOverTime = dateAxis.map((date) => {
      const trackingDataEntry = trackingData.find(
        (td: any) => td.date === date,
      );
      return trackingDataEntry
        ? {
            totalVisits: trackingDataEntry.totalVisits || 0,
            totalDuration: trackingDataEntry.totalVisitsDuration || 0,
            averageDuration:
              trackingDataEntry.totalVisits != null &&
              trackingDataEntry.totalVisits > 0 &&
              trackingDataEntry.totalVisitsDuration != null
                ? trackingDataEntry.totalVisitsDuration /
                  trackingDataEntry.totalVisits
                : 0,
            totalTestStarts: trackingDataEntry.totalTestStarts || 0,
            total18PlusTestStarts: trackingDataEntry.total18PlusTestStarts || 0,
            totalTestFinishes: trackingDataEntry.totalTestFinishes || 0,
            total18PlusTestFinishes: trackingDataEntry.total18PlusTestFinishes || 0, // eslint-disable-line prettier/prettier
          }
        : {
            totalVisits: 0,
            totalDuration: 0,
            averageDuration: 0,
            totalTestStarts: 0,
            total18PlusTestStarts: 0,
            totalTestFinishes: 0,
            total18PlusTestFinishes: 0,
          };
    });

    const globalTotalVisits = visitDataOverTime.reduce(
      (sum, item) => sum + item.totalVisits,
      0,
    );

    const globalTotalDuration = visitDataOverTime.reduce(
      (sum, item) => sum + item.totalDuration,
      0,
    );

    const globalAverageDuration =
      globalTotalVisits > 0 ? globalTotalDuration / globalTotalVisits : 0;

    const globalTotalTestStarts = visitDataOverTime.reduce(
      (sum, item) => sum + item.totalTestStarts + item.total18PlusTestStarts,
      0,
    );

    const globalTotalTestFinishes = visitDataOverTime.reduce(
      (sum, item) =>
        sum + item.totalTestFinishes + item.total18PlusTestFinishes,
      0,
    );

    const totalVisitCountChartData = {
      labels: dateAxisForDisplay,
      datasets: [
        {
          label: 'Aufrufe',
          data: visitDataOverTime.map((v) => v.totalVisits),
          fill: false,
          borderColor: '#3f80ac',
        },
      ],
    };

    const avgVisitDurationChartData = {
      labels: dateAxisForDisplay,
      datasets: [
        {
          label: 'Durchschnittliche Verweildauer (in Minuten)',
          data: visitDataOverTime.map((v) => v.averageDuration / 60),
          fill: false,
          borderColor: '#d18144',
        },
      ],
    };

    const totalTestStartsChartData = {
      labels: dateAxisForDisplay,
      datasets: [
        {
          label: 'Jünger als 18',
          data: visitDataOverTime.map((v) => v.totalTestStarts),
          fill: false,
          borderColor: '#d44141',
        },
        {
          label: 'Älter als 18',
          data: visitDataOverTime.map((v) => v.total18PlusTestStarts),
          fill: false,
          borderColor: '#6986AF',
        },
      ],
    };

    const totalTestFinishesChartData = {
      labels: dateAxisForDisplay,
      datasets: [
        {
          label: 'Jünger als 18',
          data: visitDataOverTime.map((v) => v.totalTestFinishes),
          fill: false,
          borderColor: '#d44141',
        },
        {
          label: 'Älter als 18',
          data: visitDataOverTime.map((v) => v.total18PlusTestFinishes),
          fill: false,
          borderColor: '#6986AF',
        },
      ],
    };

    const accessLogsOverTime = Object.keys(accessLogs).reduce(
      (map: any, trackKey: string) => {
        // eslint-disable-next-line no-param-reassign
        map[trackKey] = dateAxis.map((date) => {
          const accessLogEntry = accessLogs[trackKey].find(
            (al: any) => al.date === date,
          );
          return accessLogEntry
            ? { accessCount: accessLogEntry.accessCount || 0 }
            : { accessCount: 0 };
        });
        return map;
      },
      {},
    );

    const accessCountsChartData = {
      labels: dateAxisForDisplay,
      datasets: Object.keys(accessLogsOverTime).map((trackKey, index) => ({
        label: trackKey,
        data: accessLogsOverTime[trackKey].map((a: any) => a.accessCount),
        fill: false,
        borderColor: colors[index % colors.length],
      })),
    };

    const globalAccessCounts = Object.keys(accessLogsOverTime).reduce(
      (map: any, trackKey: string) => {
        // eslint-disable-next-line no-param-reassign
        map[trackKey] = accessLogsOverTime[trackKey].reduce(
          (sum: number, item: any) => sum + item.accessCount,
          0,
        );
        return map;
      },
      {},
    );

    const pieChartData = this.mapPieChartData(dateAxis, trackingData);

    return (
      <div style={{ height: '100%', overflow: 'auto' }}>
        <div className={s.rowContainer}>
          <h1>PILOT / VERIFICATION</h1>
        </div>
        <div className={s.rowContainer}>
          <span className={s.buttonText}>
            Pilot-/Verification-Daten herunterladen
          </span>
          <button
            type="button"
            className={`btn btn-primary ${s.refreshButton}`}
            onClick={() => {
              this.downloadPilotData();
            }}
          >
            <FaDownload />
          </button>
        </div>
        <div className={s.rowContainer}>
          <span className={s.buttonText}>
            Pilot-/Verification-Daten löschen
          </span>
          <button
            type="button"
            className={`btn btn-primary ${s.refreshButton}`}
            onClick={() => {
              // eslint-disable-next-line no-alert
              if (window.confirm('Bist du sicher?')) {
                this.deletePilotData();
              }
            }}
          >
            <FaTrash />
          </button>
        </div>
        <div className={s.rowContainer}>
          <h1>TRACKING STATISTIKEN</h1>
        </div>
        <div className={s.rowContainer}>
          <div className={s.datePickerContainer}>
            <span>Zeitraum von: </span>
            <DatePicker
              selected={from}
              dateFormat="dd.MM.yyyy"
              onChange={(date) => {
                this.handleDateSelect(date, 'from');
              }}
              className={s.datePicker}
            />
          </div>
          <div className={s.datePickerContainer}>
            <span>bis: </span>
            <DatePicker
              selected={to}
              dateFormat="dd.MM.yyyy"
              onChange={(date) => {
                this.handleDateSelect(date, 'to');
              }}
              className={s.datePicker}
            />
          </div>
          <button
            type="button"
            className={`btn btn-primary ${s.refreshButton}`}
            onClick={() => {
              this.loadTrackingData();
            }}
          >
            <FiRefreshCw />
          </button>
        </div>
        <div className={s.statsRowContainer}>
          <div>
            <div className={s.leftStatisticsContainer}>Sessions gesamt:</div>
            <div className={s.rightStatisticsContainer}>
              {globalTotalVisits}
            </div>
          </div>
          <div>
            <div className={s.leftStatisticsContainer}>
              Durchschnittliche Verweildauer:
            </div>
            <div className={s.rightStatisticsContainer}>
              {`${
                Math.round((globalAverageDuration / 60) * 100) / 100
              } Minuten`}
            </div>
          </div>
        </div>
        <div className={s.lineChartRowContainer}>
          Aufrufe nach Tag:
          <div className={s.lineChartContainer}>
            <Line
              data={totalVisitCountChartData}
              options={{
                scales: {
                  y: {
                    ticks: {
                      stepSize: 1,
                    },
                  },
                },
              }}
            />
          </div>
          Durchschnittliche Verweildauer nach Tag:
          <div className={s.lineChartContainer}>
            <Line
              data={avgVisitDurationChartData}
              options={{
                scales: {
                  y: {
                    ticks: {
                      stepSize: 1,
                    },
                  },
                },
              }}
            />
          </div>
        </div>
        <div className={s.statsRowContainer}>
          {Object.keys(globalAccessCounts).map((trackKey) => (
            <div>
              <div className={s.leftStatisticsContainer}>
                {`"${trackKey}" Zugriffe:`}
              </div>
              <div className={s.rightStatisticsContainer}>
                {globalAccessCounts[trackKey]}
              </div>
            </div>
          ))}
        </div>
        <div className={s.lineChartRowContainer}>
          Zugriffszahlen auf statische Seiten:
          <div className={s.lineChartContainer}>
            <Line
              data={accessCountsChartData}
              options={{
                scales: {
                  y: {
                    ticks: {
                      stepSize: 1,
                    },
                  },
                },
              }}
            />
          </div>
        </div>
        <div className={s.statsRowContainer}>
          <div>
            <div className={s.leftStatisticsContainer}>
              Gestartete Tests gesamt:
            </div>
            <div className={s.rightStatisticsContainer}>
              {globalTotalTestStarts}
            </div>
          </div>
          <div>
            <div className={s.leftStatisticsContainer}>
              Abgeschlossene Tests gesamt:
            </div>
            <div className={s.rightStatisticsContainer}>
              {globalTotalTestFinishes}
            </div>
          </div>
        </div>
        <div className={s.lineChartRowContainer}>
          Gestartete Tests nach Alter:
          <div className={s.lineChartContainer}>
            <Line
              data={totalTestStartsChartData}
              options={{
                scales: {
                  y: {
                    ticks: {
                      stepSize: 1,
                    },
                  },
                },
              }}
            />
          </div>
          Abgeschlossene Tests nach Alter:
          <div className={s.lineChartContainer}>
            <Line
              data={totalTestFinishesChartData}
              options={{
                scales: {
                  yAxes: {
                    ticks: {
                      stepSize: 1,
                    },
                  },
                },
              }}
            />
          </div>
        </div>
        {this.renderPieCharts(pieChartData)}
        <div className={s.noteContainer}>
          * Daten wurden nur von Benutzern erfasst, die lokales Tracking in
          ihrem Browser erlaubt haben. Die tatsächlichen Zahlen sind höher als
          die hier abgebildeten, die relativen Verhältnisse zwischen den
          gemessenen Gruppierungen werden sich zur Gesamtmenge aber nur wenig
          unterscheiden.
        </div>
      </div>
    );
  }
}

export default compose(withAdminAuthentication)(AdminDashboard);
