/* eslint-disable no-nested-ternary */
import React from 'react';
import { Link, Params } from 'react-router-dom';
import { flowRight as compose } from 'lodash';
import { FaPlayCircle } from 'react-icons/fa';

import { ROUTE_PREFIX } from '../../constants/router';
import s from './Offers.module.scss';
import withAuthentication from '../Auth/AuthenticationWrapper';
import withInterestTestResults, {
  itestResultsType,
} from '../HomeScreen/DataWrappers/InterestTestResultsWrapper';
import { checkIfTestResultsAreInvalid } from '../HomeScreen/DataWrappers/TestCompletionRequiredWrapper';
import ContentBody from '../ContentBody/index';
import OffersHeader from './OffersHeader';
import withBundesland from '../HomeScreen/DataWrappers/BundeslandWrapper';
import Footer from '../Footer';
import Loading from '../Loading';
import { I_TYPE_NAME } from '../../constants/interests';
import getAssetLink from '../../util/getAssetLink';
import { withRouter } from '../../util/withRouter';

interface OffersProps {
  loggedMode: string;
  meData?: any;
  userItestResults?: itestResultsType;
  refetchSelectedBundesland: () => Promise<void>;
  // eslint-disable-next-line no-unused-vars
  setSelectedBundesland: (bundesland: string | null) => Promise<void>;
  params: Readonly<Params<string>>;
}

class Offers extends React.Component<OffersProps> {
  // eslint-disable-next-line react/state-in-constructor
  state: any;

  static defaultProps = {
    meData: null,
    userItestResults: null,
  };

  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
    };

    this.renderBasicOffers = this.renderBasicOffers.bind(this);
    this.renderWienOffers = this.renderWienOffers.bind(this);
    this.renderKaerntenOffers = this.renderKaerntenOffers.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  renderBasicOffers({ hyperlinkUrl, hyperlinkTitle, additionalContent }: any) {
    return (
      <div key="offersContent">
        <div className={s.headerSection}>
          <h1>ANGEBOTE DER AK</h1>
        </div>
        <div className={s.info}>
          {hyperlinkUrl && hyperlinkTitle ? (
            <p>
              Mehr Information und Unterstützung bei der Bildungswegentscheidung
              findest du unter:
              <br />
              <a href={hyperlinkUrl} target="_blank" rel="noreferrer">
                {hyperlinkTitle}
              </a>
            </p>
          ) : (
            <p>
              Die Angebote der Arbeiterkammer stehen momentan noch nicht zur
              Verfügung.
            </p>
          )}
        </div>
        {additionalContent}
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderWienOffers() {
    return (
      <div key="offersContent">
        <div className={s.headerSection}>
          <h1>ANGEBOTE DER AK</h1>
        </div>
        <div className={s.info}>
          <p>
            Mehr Information und Unterstützung bei der Bildungswegentscheidung
            findest du unter:
            <br />
            <a
              href="https://wien.arbeiterkammer.at/bildungsundberufswahl"
              target="_blank"
              rel="noreferrer"
            >
              Bildungs- und Berufswahl | Arbeiterkammer Wien
            </a>
          </p>
        </div>
        <div className={s.afterInfo}>
          <div className={s.test}>
            <div className={s.buttonInfo}>
              Diese Videos geben dir mehr Informationen zu den sechs
              Interessenstypen:
            </div>
            <div className={s.buttonBox}>
              <div className={s.buttonContainer}>
                <Link
                  className={s.buttonLink}
                  to={`${ROUTE_PREFIX}/orientation/interest-video/R`}
                >
                  <button type="button" className="btn R-button">
                    {I_TYPE_NAME.R}
                    <span>
                      <FaPlayCircle />
                    </span>
                  </button>
                </Link>
              </div>
              <div className={s.buttonContainer}>
                <Link
                  className={s.buttonLink}
                  to={`${ROUTE_PREFIX}/orientation/interest-video/I`}
                >
                  <button type="button" className="btn I-button">
                    {I_TYPE_NAME.I}
                    <span>
                      <FaPlayCircle />
                    </span>
                  </button>
                </Link>
              </div>
              <div className={s.buttonContainer}>
                <Link
                  className={s.buttonLink}
                  to={`${ROUTE_PREFIX}/orientation/interest-video/A`}
                >
                  <button type="button" className="btn A-button">
                    {I_TYPE_NAME.A}
                    <span>
                      <FaPlayCircle />
                    </span>
                  </button>
                </Link>
              </div>
              <div className={s.buttonContainer}>
                <Link
                  className={s.buttonLink}
                  to={`${ROUTE_PREFIX}/orientation/interest-video/S`}
                >
                  <button type="button" className="btn S-button">
                    {I_TYPE_NAME.S}
                    <span>
                      <FaPlayCircle />
                    </span>
                  </button>
                </Link>
              </div>
              <div className={s.buttonContainer}>
                <Link
                  className={s.buttonLink}
                  to={`${ROUTE_PREFIX}/orientation/interest-video/E`}
                >
                  <button type="button" className="btn E-button">
                    {I_TYPE_NAME.E}
                    <span>
                      <FaPlayCircle />
                    </span>
                  </button>
                </Link>
              </div>
              <div className={s.buttonContainer}>
                <Link
                  className={s.buttonLink}
                  to={`${ROUTE_PREFIX}/orientation/interest-video/C`}
                >
                  <button type="button" className="btn C-button">
                    {I_TYPE_NAME.C}
                    <span>
                      <FaPlayCircle />
                    </span>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderKaerntenOffers() {
    return this.renderBasicOffers({
      hyperlinkUrl: 'https://kaernten.arbeiterkammer.at/beratung/bildung',
      hyperlinkTitle: 'kaernten.arbeiterkammer.at/beratung/bildung',
      additionalContent: (
        <div className={s.afterInfo}>
          <div className={s.kaerntenLogo}>
            <a href="http://www.akyoung.at/" target="_blank" rel="noreferrer">
              <img src={getAssetLink('ak_kaernten_logo.png')} alt="" />
            </a>
          </div>
        </div>
      ),
    });
  }

  render() {
    const {
      loggedMode,
      meData,
      userItestResults,
      refetchSelectedBundesland,
      setSelectedBundesland,
    } = this.props;

    const { bundesland } = this.props.params;

    const testResultsInvalid = checkIfTestResultsAreInvalid(userItestResults);
    const finishedTest = !testResultsInvalid;

    let content = null;

    if (bundesland === 'wien') {
      content = this.renderWienOffers();
    } else if (bundesland === 'kärnten') {
      content = this.renderKaerntenOffers();
    } else {
      content = this.renderBasicOffers({});
    }

    return [
      <div key="offersContainer" className={s.offersContainer}>
        <OffersHeader
          bundesland={bundesland}
          setSelectedBundesland={setSelectedBundesland}
          refetchSelectedBundesland={refetchSelectedBundesland}
        />
        <ContentBody>{content}</ContentBody>
        <Footer
          loggedMode={loggedMode}
          meData={meData}
          finishedTest={finishedTest}
        />
      </div>,
      this.state.loading ? <Loading key="loading" /> : null,
    ];
  }
}

export default compose(
  withAuthentication,
  withInterestTestResults,
  withBundesland,
  withRouter,
)(Offers);
