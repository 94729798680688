/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Modal, Button } from 'react-bootstrap';

import s from './ConfirmCancelModal.module.scss';

interface ConfirmCancelModalProps {
  onCancel: () => void;
  closeModal: () => void;
  showModal: boolean;
}

class ConfirmCancelModal extends React.Component<ConfirmCancelModalProps> {
  render() {
    const { onCancel, closeModal, showModal } = this.props;

    return (
      <div>
        <Modal
          centered
          show={showModal}
          backdrop="static"
          dialogClassName={s.confirmationModal}
        >
          <Modal.Header className={s.header}>
            <Modal.Title>
              <h3>
                <strong>Test abbrechen?</strong>
              </h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className={s.body}>
            Ein Abbruch löscht deinen Testfortschritt. Willst du wirklich
            abbrechen?
          </Modal.Body>
          <Modal.Footer className={s.footer}>
            <Button variant="btn OK-button" onClick={closeModal}>
              NEIN
            </Button>
            <Button variant="btn OK-button" onClick={onCancel}>
              JA
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default ConfirmCancelModal;
