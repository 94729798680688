import React from 'react';

class NotFound extends React.Component {
  render() {
    return (
      <div
        style={{
          display: 'flex',
          height: '100%',
          width: '100%',
          padding: '15px',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        404
      </div>
    );
  }
}

export default NotFound;
