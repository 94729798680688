import React from 'react';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import { Document, Page } from 'react-pdf';

import s from './ExportScreen.module.scss';
import { getGuestLocalStorageData } from '../Auth/withLogin';
import withInterestTestResults from '../HomeScreen/DataWrappers/InterestTestResultsWrapper';
import testCompletionRequired from '../HomeScreen/DataWrappers/TestCompletionRequiredWrapper';
import withSuggestedProfessions from '../HomeScreen/DataWrappers/SuggestedProfessionsWrapper';
import withFavoriteProfessions from '../HomeScreen/DataWrappers/FavoriteProfessionsWrapper';
import withAuthentication, {
  LOGGED_MODE_USER,
  LOGGED_MODE_GUEST,
} from '../Auth/AuthenticationWrapper';
import ContentBody from '../ContentBody/index';
import Header from '../Header/index';
import Footer from '../Footer/index';
import Loading from '../Loading';
import { TEST_MODE_YOUNG } from '../../constants/testModes';
import './pdfWorkerFix';

export const b64toBlob = (b64Data: any, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const itestPdfQuery = gql`
  query createUserItestPdf {
    createUserItestPdf
  }
`;

export const itestPdfForGuestQuery = gql`
  query createUserItestPdfForGuest(
    $itestResults: String!
    $favoriteProfessions: String!
    $testMode: String!
    $professionsFilter: String!
  ) {
    createUserItestPdfForGuest(
      itestResults: $itestResults
      favoriteProfessions: $favoriteProfessions
      testMode: $testMode
      professionsFilter: $professionsFilter
    )
  }
`;

interface ExportScreenProps {
  loggedMode: string;
  meData?: any;
  itestPdfData?: {
    loading: boolean;
    createUserItestPdf?: string;
  };
  itestPdfForGuestData?: {
    loading: boolean;
    createUserItestPdfForGuest?: string;
  };
}

class ExportScreen extends React.Component<ExportScreenProps> {
  // eslint-disable-next-line react/state-in-constructor
  state: any;

  static defaultProps = {
    meData: null,
    itestPdfData: null,
    itestPdfForGuestData: null,
  };

  constructor(props: any) {
    super(props);

    this.state = {
      numPages: null,
      blobUrl: null,
    };
  }

  componentDidUpdate(prevProps: any) {
    let data = null;

    if (
      this.props.loggedMode === LOGGED_MODE_USER &&
      this.props.itestPdfData &&
      !this.props.itestPdfData.loading &&
      this.props.itestPdfData !== prevProps.itestPdfData
    ) {
      data = this.props.itestPdfData.createUserItestPdf;
    } else if (
      this.props.loggedMode === LOGGED_MODE_GUEST &&
      this.props.itestPdfForGuestData &&
      !this.props.itestPdfForGuestData.loading &&
      this.props.itestPdfForGuestData !== prevProps.itestPdfForGuestData
    ) {
      data = this.props.itestPdfForGuestData.createUserItestPdfForGuest;
    }

    if (data) {
      const blob = b64toBlob(data, 'pdf');
      const blobUrl = URL.createObjectURL(blob);

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ blobUrl });
    }
  }

  onDocumentLoadSuccess = ({ numPages }: any) => {
    this.setState({ numPages });
  };

  itestPdfViewer = (pdf: string) => (
    <Document
      key="itestPdfViewer"
      file={`data:application/pdf;base64,${pdf}`}
      onLoadSuccess={this.onDocumentLoadSuccess}
      className={s.pdfContainer}
    >
      {Array.from(new Array(this.state.numPages), (el, index) => (
        <Page
          key={`page_${index + 1}`}
          pageNumber={index + 1}
          className={s.pageContainer}
          scale={1.4}
          renderTextLayer={false}
          renderAnnotationLayer={false}
        />
      ))}
    </Document>
  );

  render() {
    const { loggedMode, meData, itestPdfData, itestPdfForGuestData } =
      this.props;

    if (
      (loggedMode === LOGGED_MODE_USER && itestPdfData && itestPdfData.loading) || // eslint-disable-line prettier/prettier
      (loggedMode === LOGGED_MODE_GUEST && itestPdfForGuestData && itestPdfForGuestData.loading) // eslint-disable-line prettier/prettier
    ) {
      return <Loading />;
    }

    let data = null;

    if (LOGGED_MODE_USER && itestPdfData) {
      data = itestPdfData.createUserItestPdf;
    } else if (LOGGED_MODE_USER && itestPdfForGuestData) {
      data = itestPdfForGuestData.createUserItestPdfForGuest;
    }

    return (
      <div className={s.exportScreenContainer}>
        <Header withBackButton />
        <ContentBody>
          {data && [
            this.itestPdfViewer(data),
            <div
              key="downloadButtonWrapper"
              className={s.downloadButtonWrapper}
            >
              <a href={this.state.blobUrl} download="Jopsy Ergebnisse.pdf">
                <button type="button" className={`btn btn-primary ${s.button}`}>
                  Download PDF
                </button>
              </a>
            </div>,
          ]}
        </ContentBody>
        <Footer loggedMode={loggedMode} meData={meData} />
      </div>
    );
  }
}

export default compose(
  withAuthentication,
  withInterestTestResults,
  testCompletionRequired,
  withSuggestedProfessions,
  (comp) => withFavoriteProfessions(comp, true),
  graphql(itestPdfQuery, {
    name: 'itestPdfData',
    options: {
      fetchPolicy: 'no-cache',
    },
    skip: (props: any) => props.loggedMode !== LOGGED_MODE_USER,
  }),
  graphql(itestPdfForGuestQuery, {
    name: 'itestPdfForGuestData',
    options: () => {
      const guestData = getGuestLocalStorageData();
      return {
        variables: {
          itestResults: guestData ? guestData.userItestResults || null : null,
          favoriteProfessions: guestData
            ? guestData.favoriteProfessions || '[]'
            : '[]',
          testMode: guestData
            ? guestData.userItestMode || TEST_MODE_YOUNG
            : TEST_MODE_YOUNG,
          professionsFilter: guestData
            ? guestData.favoriteProfessionsFilter || 'E0'
            : 'E0',
        },
        fetchPolicy: 'no-cache',
      };
    },
    skip: (props: any) => props.loggedMode !== LOGGED_MODE_GUEST,
  }),
)(ExportScreen);
