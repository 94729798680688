/* eslint-disable react/sort-comp */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import Draggable from 'react-draggable';

import s from './DraggableCard.module.scss';

const HORIZONTAL_THRESHOLD = 5;
const VERTICAL_THRESHOLD = 5;

interface DraggableCardProps {
  children: React.ReactNode;
  cardWidth: number;
  dragDirection: 'up' | 'down';
  onDragStart?: () => void;
  onDragEnd?: () => void;
  onDragSuccess: () => void;
}

class DraggableCard extends React.Component<DraggableCardProps> {
  // eslint-disable-next-line react/state-in-constructor
  state: any;

  dragThresholdReached: boolean = false;

  deltaX: number = 0;

  deltaY: number = 0;

  dragCanceled: boolean = false;

  static defaultProps = {
    onDragStart: () => {},
    onDragEnd: () => {},
  };

  constructor(props: any) {
    super(props);

    this.state = {
      position: { x: 0, y: 0 },
      dragCanceled: false,
    };

    this.handleStart = this.handleStart.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
    this.handleStop = this.handleStop.bind(this);
  }

  handleStart() {
    const { onDragStart } = this.props;
    this.dragThresholdReached = false;
    this.deltaX = 0;
    this.deltaY = 0;
    this.dragCanceled = false;
    this.setState({ dragCanceled: false });
    if (onDragStart) {
      onDragStart();
    }
  }

  // eslint-disable-next-line consistent-return
  handleDrag(e: any, ui: any): void | false {
    if (this.dragCanceled) return false;

    this.deltaX += ui.deltaX;
    this.deltaY += ui.deltaY;

    if (
      !this.dragThresholdReached &&
      !this.dragCanceled &&
      Math.abs(this.deltaX) > HORIZONTAL_THRESHOLD &&
      Math.abs(this.deltaY) < Math.abs(this.deltaX)
    ) {
      this.dragCanceled = true;
      this.setState({ dragCanceled: true });
      return false;
    }

    if (Math.abs(this.deltaY) > VERTICAL_THRESHOLD) {
      this.dragThresholdReached = true;
    }
  }

  handleStop() {
    const { cardWidth, onDragSuccess, onDragEnd } = this.props;
    if (Math.abs(this.deltaY) > cardWidth * 0.5) {
      onDragSuccess();
    }
    if (onDragEnd) {
      onDragEnd();
    }
  }

  render() {
    const { children, cardWidth, dragDirection } = this.props;
    const { position, dragCanceled } = this.state;

    const bounds: any = {};

    if (dragDirection === 'up') {
      bounds.top = cardWidth * -0.75;
      bounds.bottom = cardWidth * 0;
    } else if (dragDirection === 'down') {
      bounds.top = cardWidth * 0;
      bounds.bottom = cardWidth * 0.75;
    }

    return (
      <Draggable
        position={position}
        defaultClassName={`${s.draggable} ${dragCanceled ? s.canceled : ''}`}
        defaultClassNameDragging={s.dragging}
        bounds={bounds}
        onStart={this.handleStart}
        onDrag={this.handleDrag}
        onStop={this.handleStop}
        cancel="button"
      >
        {children}
      </Draggable>
    );
  }
}

export default DraggableCard;
