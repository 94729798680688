/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { flowRight as compose } from 'lodash';

import { Params } from 'react-router-dom';
import s from './ProfessionDetailScreen.module.scss';
import { ROUTE_PREFIX } from '../../constants/router';
import AppContext from '../../context';
import { JOBS_VERSION } from '../../constants/professions';
import withAuthentication from '../Auth/AuthenticationWrapper';
import withInterestTestResults from '../HomeScreen/DataWrappers/InterestTestResultsWrapper';
import testCompletionRequired from '../HomeScreen/DataWrappers/TestCompletionRequiredWrapper';
import withSuggestedProfessions, {
  recommendedProfessionType,
} from '../HomeScreen/DataWrappers/SuggestedProfessionsWrapper';
import withFavoriteProfessions from '../HomeScreen/DataWrappers/FavoriteProfessionsWrapper';
import ContentBody from '../ContentBody';
import Header from '../Header';
import Footer from '../Footer';
import getAssetLink from '../../util/getAssetLink';

interface ProfessionDetailScreenProps {
  loggedMode: string;
  meData?: any;
  recommendedProfessions: Array<recommendedProfessionType>;
  favoriteProfessions: Array<string>;
  // eslint-disable-next-line no-unused-vars
  setFavoriteProfession: (professionId: string, favorite: boolean) => void;
  params: Readonly<Params<string>>;
}

class ProfessionDetailScreen extends React.Component<ProfessionDetailScreenProps> {
  static contextType = AppContext;

  // eslint-disable-next-line react/state-in-constructor
  state: any;

  static defaultProps = {
    meData: null,
  };

  constructor(props: any) {
    super(props);

    const professionData = props.recommendedProfessions.find(
      (profession: recommendedProfessionType) =>
        profession.key.split('-')[1] === this.props.params.id,
    );

    const profession = {
      id: professionData.key,
      title: professionData.title,
      description: professionData.description,
      educationLevels: professionData.education,
      isFavorite: this.props.favoriteProfessions.includes(professionData.key),
      image: `https://www.jopsy.at/appimages/jobs/${JOBS_VERSION}/${professionData.imageName}`,
    };

    this.state = { profession };

    this.renderContent = this.renderContent.bind(this);
    this.getFavoriteIcon = this.getFavoriteIcon.bind(this);
    this.toggleFavorite = this.toggleFavorite.bind(this);
  }

  getFavoriteIcon() {
    return this.state.profession.isFavorite
      ? getAssetLink('heart_selected.png')
      : getAssetLink('heart.png');
  }

  async toggleFavorite() {
    const { setFavoriteProfession } = this.props;
    const { profession } = this.state;

    setFavoriteProfession(profession.id, !profession.isFavorite);

    this.setState({
      profession: {
        ...profession,
        isFavorite: !profession.isFavorite,
      },
    });
  }

  renderContent() {
    const { profession } = this.state;

    return (
      <div className={s.contentContainer}>
        <div className={s.titleContainer}>
          <div className={s.heading}>
            <h2 className={s.title}>{profession.title}</h2>
            <h2 className={s.education}>
              {profession.educationLevels.map((key: string, index: number) => (
                // eslint-disable-next-line react/no-array-index-key
                <div className={s.educationItem} key={`${key} ${index}`}>
                  {key}
                </div>
              ))}
            </h2>
          </div>
          <div className={s.favoriteIcon}>
            <img
              src={this.getFavoriteIcon()}
              alt=""
              onClick={this.toggleFavorite}
            />
          </div>
        </div>
        <div className={s.professionImage}>
          <img src={profession.image} alt={profession.title} />
        </div>
        <div className={s.description}>{profession.description}</div>
      </div>
    );
  }

  render() {
    const { loggedMode, meData } = this.props;

    return (
      <div className={s.professionDetailScreenContainer}>
        <Header backUrl={`${ROUTE_PREFIX}/professions`} />
        <ContentBody>{this.renderContent()}</ContentBody>
        <Footer loggedMode={loggedMode} meData={meData} />
      </div>
    );
  }
}

export default compose(
  withAuthentication,
  withInterestTestResults,
  testCompletionRequired,
  withSuggestedProfessions,
  (comp) => withFavoriteProfessions(comp, true),
)(ProfessionDetailScreen);
