import React from 'react';

import AppContext, { AppContextType } from '../../context';
import { ROUTE_PREFIX } from '../../constants/router';
import { triggerNewVisit } from '../Layout';
import { withRouter } from '../../util/withRouter';

function withLogout(WrappedComponent: any) {
  class LogoutWrapper extends React.Component {
    static contextType = AppContext;

    constructor(props: any) {
      super(props);
      this.logout = this.logout.bind(this);
    }

    // eslint-disable-next-line class-methods-use-this
    logout(redirectUrl = `${ROUTE_PREFIX}/`, shouldTriggerNewVisit = true) {
      sessionStorage.removeItem('id_token');
      sessionStorage.removeItem('guest_data'); // delete guest data

      if (shouldTriggerNewVisit) {
        triggerNewVisit(this.context as AppContextType); // trigger new visit for tracking purposes
      }

      // eslint-disable-next-line no-restricted-globals
      location.href = redirectUrl;
    }

    render() {
      return <WrappedComponent logout={this.logout} {...this.props} />;
    }
  }

  return withRouter(LogoutWrapper);
}

// eslint-disable-next-line import/prefer-default-export
export { withLogout };
