export default {
  desiredSchoolQuestions: [
    {
      id: 'desired-school',
      question: 'Meine Wunschschule',
    },
    {
      id: 'question-1',
      question: 'Welche Schwerpunkte bietet die Schule an?',
    },
    {
      id: 'question-2',
      question: 'Aufnahme-\nvoraussetzungen',
    },
    {
      id: 'question-3',
      question: 'Anmeldefrist',
    },
    {
      id: 'question-4',
      question: 'Tag der offenen Tür',
    },
    {
      id: 'question-5',
      question: 'Ausbildungsdauer',
    },
    {
      id: 'question-6',
      question: 'Möglichkeiten nach Schulabschluss',
    },
    {
      id: 'question-7',
      question: 'Anfahrtszeiten und öffentliche Verkehrsmittel',
    },
    {
      id: 'question-8',
      question: 'Welche Stärken sind in dieser Schule gefragt?',
    },
    {
      id: 'question-9',
      question: 'Warum gerade diese Schule?',
    },
    {
      id: 'question-10',
      question: 'Wer kann dir mehr über diese Schule erzählen?',
    },
    {
      id: 'question-11',
      question: 'Plan B',
    },
  ],
  desiredJobQuestions: [
    {
      id: 'desired-job',
      question: 'Mein Wunschberuf',
    },
    {
      id: 'question-1',
      question: 'Wichtige Tätigkeiten',
    },
    {
      id: 'question-2',
      question: 'Voraussetzungen für diesen Beruf',
    },
    {
      id: 'question-3',
      question: 'Ausbildungsdauer',
    },
    {
      id: 'question-4',
      question: 'Durchschnitts-\nverdienst',
    },
    {
      id: 'question-5',
      question: 'Betriebe, in denen du diesen Beruf erlernen kannst',
    },
    {
      id: 'question-6',
      question: 'Schnuppertermin',
    },
    {
      id: 'question-7',
      question: 'Welche Stärken sind in diesem Beruf gefragt?',
    },
    {
      id: 'question-8',
      question: 'Warum gerade dieser Beruf?',
    },
    {
      id: 'question-9',
      question: 'Wer kann dir mehr über diesen Beruf erzählen?',
    },
    {
      id: 'question-10',
      question: 'Plan B',
    },
  ],
};
