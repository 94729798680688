import React from 'react';
import { Link, NavigateFunction } from 'react-router-dom';
import { flowRight as compose } from 'lodash';
import Cookies from 'js-cookie';
import { FaPlayCircle } from 'react-icons/fa';
import gql from 'graphql-tag';

import { ROUTE_PREFIX } from '../../constants/router';
import { withLogout } from '../Auth/withLogout';
import withAuthentication, {
  LOGGED_MODE_USER,
  LOGGED_MODE_GUEST,
} from '../Auth/AuthenticationWrapper';
import withInterestTestResults from '../HomeScreen/DataWrappers/InterestTestResultsWrapper';
import testIncompletionRequired from '../HomeScreen/DataWrappers/TestIncompletionRequiredWrapper';
import s from './StartScreen.module.scss';
import Loading from '../Loading';
import getAssetLink from '../../util/getAssetLink';
import { withRouter } from '../../util/withRouter';
import { setGuestLocalStorageData } from '../Auth/withLogin';
import {
  TEST_MODE_PILOT,
  TEST_MODE_VERIFICATION,
} from '../../constants/testModes';
import { withSEO } from '../SEO/withSEO';

export const createItestDataMutation = gql`
  mutation createUserItestData($itestMode: String!, $override: Boolean!) {
    createUserItestData(itestMode: $itestMode, override: $override)
  }
`;

interface StartScreenProps {
  location: Location;
  loggedMode: string;
  logout: () => void;
  navigate: NavigateFunction;
  refetchItestResults: () => Promise<void>;
}

class StartScreen extends React.Component<StartScreenProps> {
  // eslint-disable-next-line react/state-in-constructor
  state: any;

  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    const { location, loggedMode } = this.props;

    const isPilot = location.pathname.includes('normalization');
    const isVerification = location.pathname.includes('verification');

    sessionStorage.removeItem('normalization_data');

    if (isPilot || isVerification) {
      sessionStorage.removeItem('id_token');
      sessionStorage.removeItem('guest_data');

      const newGuestData: any = {
        userItestMode: isPilot ? TEST_MODE_PILOT : TEST_MODE_VERIFICATION,
      };
      setGuestLocalStorageData(newGuestData);

      // if logged mode is still cahced as user, need to reload page
      if (loggedMode === LOGGED_MODE_USER) {
        window.location.reload();
      }
    }
  }

  render() {
    const { location, loggedMode, logout, navigate } = this.props;

    const consentCookie = Cookies.get('cookie-consent-settings');

    const isPilot = location.pathname.includes('normalization');
    const isVerification = location.pathname.includes('verification');

    let watchedExplanationVideo: any = Cookies.get('watchedExplanationVideo');
    watchedExplanationVideo =
      watchedExplanationVideo == null
        ? null
        : JSON.parse(watchedExplanationVideo);

    if (
      !isPilot &&
      !isVerification &&
      (watchedExplanationVideo == null || !watchedExplanationVideo.watched) &&
      consentCookie
    ) {
      navigate(`${ROUTE_PREFIX}/intro-video`);
      return <Loading />;
    }

    let startUrl: string;
    if (isPilot) {
      startUrl = `${ROUTE_PREFIX}/normalization/identification`;
    } else if (isVerification) {
      startUrl = `${ROUTE_PREFIX}/verification/infographic`;
    } else {
      startUrl = `${ROUTE_PREFIX}/mode-select/interests-test`;
    }

    return [
      <div
        className={s.startScreenContainer}
        key="startScreenContainer"
        data-nosnippet
      >
        <img className={s.akLogo} src={getAssetLink('AKLogo.png')} alt="" />
        <div className={s.textContainer}>
          Deine Interessen.
          <br />
          Deine Entscheidung.
          <br />
          Dein Weg.
        </div>
        <div className={s.buttonContainer}>
          <div>
            <button
              type="button"
              className="btn btn-primary btn-wide"
              onClick={async () => {
                await this.props.refetchItestResults();
                this.props.navigate(startUrl);
              }}
            >
              START
            </button>
          </div>
          {!isPilot && !isVerification && (
            <>
              <div>
                <Link to={`${ROUTE_PREFIX}/mode-select/offers`}>
                  <button type="button" className="btn btn-primary btn-wide">
                    ZU DEN ANGEBOTEN
                  </button>
                </Link>
              </div>
              <div>
                {loggedMode === LOGGED_MODE_USER && (
                  <button
                    type="button"
                    className="btn btn-primary btn-wide"
                    onClick={() => {
                      this.setState({ loading: true });
                      logout();
                    }}
                  >
                    LOGOUT
                  </button>
                )}
                {loggedMode === LOGGED_MODE_GUEST && (
                  <Link to={`${ROUTE_PREFIX}/login`}>
                    <button type="button" className="btn btn-primary btn-wide">
                      LOGIN
                    </button>
                  </Link>
                )}
              </div>
              <div className={s.explanationVideoButtonContainer}>
                <Link to={`${ROUTE_PREFIX}/intro-video`}>
                  <button type="button" className="btn btn-secondary btn-wide">
                    ERKLÄRVIDEO
                    <span>
                      <FaPlayCircle />
                    </span>
                  </button>
                </Link>
              </div>
            </>
          )}
        </div>
      </div>,
      this.state.loading && <Loading key="loading" />,
    ];
  }
}

export default compose(
  withSEO({
    title: 'Jopsy',
    description: 'Deine Interessen. Deine Entscheidung. Dein Weg.',
  }),
  withAuthentication,
  withInterestTestResults,
  testIncompletionRequired,
  withLogout,
  withRouter,
)(StartScreen);
