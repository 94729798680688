/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';
import { NavigateFunction } from 'react-router-dom';

import AppContext, { AppContextType } from '../../context';
import { ROUTE_PREFIX } from '../../constants/router';
import withAuthentication, {
  LOGGED_MODE_USER,
  LOGGED_MODE_GUEST,
} from '../Auth/AuthenticationWrapper';
import withInterestTestResults from '../HomeScreen/DataWrappers/InterestTestResultsWrapper';
import testIncompletionRequired from '../HomeScreen/DataWrappers/TestIncompletionRequiredWrapper';
import { resetTestMutation } from '../InterestsTestScreen/index';
import {
  getGuestLocalStorageData,
  setGuestLocalStorageData,
} from '../Auth/withLogin';
import ModeSelection from '../ModeSelection';
import Loading from '../Loading';
import { TEST_MODE_YOUNG, TEST_MODE_OLD } from '../../constants/testModes';
import { createItestDataMutation } from '../StartScreen';
import { withRouter } from '../../util/withRouter';
import { withSEO } from '../SEO/withSEO';

const logTestStartMutation = gql`
  mutation logTestStart($mode: String!) {
    logTestStart(mode: $mode) {
      success
      message
    }
  }
`;

interface ModeSelectionForTestProps {
  loggedMode: string;
  userItestMode: string;
  refetchItestResults: () => Promise<void>;
  navigate: NavigateFunction;
}

class ModeSelectionForTest extends React.Component<ModeSelectionForTestProps> {
  static contextType = AppContext;

  // eslint-disable-next-line react/state-in-constructor
  state: any;

  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
    };

    this.onStartTest = this.onStartTest.bind(this);
    this.logTestStart = this.logTestStart.bind(this);
    this.startTestForUser = this.startTestForUser.bind(this);
    this.startTestForGuest = this.startTestForGuest.bind(this);
    this.resetTest = this.resetTest.bind(this);
  }

  async onStartTest(testMode: string) {
    const { loggedMode, userItestMode } = this.props;

    if (userItestMode !== testMode) {
      await this.resetTest();
    }

    if (loggedMode === LOGGED_MODE_USER) {
      await this.startTestForUser(testMode);
    } else if (loggedMode === LOGGED_MODE_GUEST) {
      await this.startTestForGuest(testMode);
    }
  }

  async logTestStart(testMode: string) {
    await (this.context as AppContextType).client.mutate({
      mutation: logTestStartMutation,
      variables: {
        mode: testMode,
      },
    });
  }

  async startTestForUser(testMode: string) {
    this.setState({
      loading: true,
    });

    const response = await (this.context as AppContextType).client.mutate({
      mutation: createItestDataMutation,
      variables: {
        itestMode: testMode,
        override: false,
      },
    });

    if (
      response &&
      response.data &&
      response.data.createUserItestData === true
    ) {
      await this.props.refetchItestResults();
      await this.logTestStart(testMode);

      this.props.navigate(`${ROUTE_PREFIX}/infographic`);
    }
  }

  async startTestForGuest(testMode: string) {
    const guestData = getGuestLocalStorageData();
    const newGuestData = {
      ...guestData,
      userItestMode: testMode,
    };
    setGuestLocalStorageData(newGuestData);

    await this.props.refetchItestResults();
    await this.logTestStart(testMode);

    this.props.navigate(`${ROUTE_PREFIX}/infographic`);
  }

  async resetTest() {
    const { loggedMode, refetchItestResults } = this.props;

    if (loggedMode === LOGGED_MODE_USER) {
      await (this.context as AppContextType).client.mutate({
        mutation: resetTestMutation,
      });
    } else if (loggedMode === LOGGED_MODE_GUEST) {
      const guestData = getGuestLocalStorageData();
      const newGuestData: any = {};

      // keep offers selection
      newGuestData.selectedBundesland = guestData.selectedBundesland;
      newGuestData.userAkooeData = guestData.userAkooeData;

      setGuestLocalStorageData(newGuestData);
    }

    // refetch test results for other screens
    await refetchItestResults();
  }

  render() {
    return [
      <ModeSelection
        key="modeSelection"
        onYoungClicked={() => {
          this.onStartTest(TEST_MODE_YOUNG);
        }}
        onOldClicked={() => {
          this.onStartTest(TEST_MODE_OLD);
        }}
      />,
      this.state.loading ? <Loading key="loading" /> : null,
    ];
  }
}

export default compose(
  withSEO({
    title: 'Jopsy | Interessenstest',
    description: 'Interessenstest für Jugendliche und Erwachsene starten',
  }),
  withAuthentication,
  withInterestTestResults,
  testIncompletionRequired,
  withRouter,
)(ModeSelectionForTest);
