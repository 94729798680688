import React from 'react';
import { Link } from 'react-router-dom';
import { FaUser } from 'react-icons/fa';

import { ROUTE_PREFIX } from '../../constants/router';

import s from './SettingsMenu.module.scss';

interface SettingsMenuProps {
  meData?: any;
  finishedTest?: boolean;
  onLogout: () => void;
  showShareOption?: boolean;
  withEPortfolio?: boolean;
  // eslint-disable-next-line no-unused-vars
  onShare?: (data: any) => void;
}

class SettingsMenu extends React.Component<SettingsMenuProps> {
  static defaultProps = {
    finishedTest: true,
    meData: null,
    showShareOption: false,
    withEPortfolio: false,
    onShare: () => {},
  };

  constructor(props: any) {
    super(props);
    this.renderLoginLogoutButton = this.renderLoginLogoutButton.bind(this);
  }

  renderLoginLogoutButton() {
    const { meData } = this.props;
    const loggedInUser = meData && meData.me;
    const username = loggedInUser ? loggedInUser.name : null;
    if (username) {
      return (
        <button
          type="button"
          className={`${s.menuItem} ${s.logoutItem}`}
          onClick={this.props.onLogout}
        >
          {/* eslint-disable-next-line */}
          Logout (<FaUser style={{ padding: '1px 0 4px' }} /> {username})
        </button>
      );
    }
    return (
      <Link
        to={`${ROUTE_PREFIX}/login`}
        className={`${s.menuItem} ${s.logoutItem}`}
        onClick={this.props.onLogout}
      >
        <p style={{ paddingTop: '10px' }}>
          Anmelden
          <FaUser style={{ marginLeft: '5px', padding: '1px 0 4px' }} />
        </p>
      </Link>
    );
  }

  render() {
    const { finishedTest, showShareOption, onShare, withEPortfolio } =
      this.props;

    const logoutLoginButton = this.renderLoginLogoutButton();
    return (
      <div className={s.menuContainer}>
        <div className={s.menuBody}>
          {finishedTest && (
            <Link to={`${ROUTE_PREFIX}/results-explanation`}>
              <p className={s.menuItem}>Erklärung der Ergebnisse</p>
            </Link>
          )}
          <Link to={`${ROUTE_PREFIX}/faq`}>
            <p className={s.menuItem}>FAQ</p>
          </Link>
          {showShareOption && (
            <button
              type="button"
              className={`${s.menuItem} ${s.logoutItem}`}
              onClick={() => {
                if (onShare) {
                  onShare({ withEPortfolio });
                }
              }}
            >
              {withEPortfolio ? 'Portfolio teilen' : 'Ergebnisse teilen'}
            </button>
          )}
          <Link to={`${ROUTE_PREFIX}/imprint`}>
            <p className={s.menuItem}>Impressum</p>
          </Link>
          <Link to={`${ROUTE_PREFIX}/datenschutz`}>
            <p className={s.menuItem}>Datenschutzinfo</p>
          </Link>
          {logoutLoginButton}
        </div>
        <div>
          <div className={s.triangle} />
        </div>
      </div>
    );
  }
}

export default SettingsMenu;
