import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ScrollToTop from '../ScrollToTop';
import { RouteType } from '../../routes';
import NotFound from '../../routes/notFound';

interface RouteBundlerProps {
  routes: Array<RouteType>;
}

class RouteBundler extends React.Component<RouteBundlerProps> {
  render() {
    const { routes } = this.props;

    return (
      <ScrollToTop>
        <Routes>
          {routes.map((route: any) => (
            <Route
              key={route.path}
              path={route.path}
              element={<route.component {...(route.props || {})} />}
            />
          ))}
          <Route key="not-found" path="*" element={<NotFound />} />
        </Routes>
      </ScrollToTop>
    );
  }
}

export default RouteBundler;
