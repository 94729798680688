import React from 'react';
import { Link } from 'react-router-dom';
import { flowRight as compose } from 'lodash';
import { FaPlay } from 'react-icons/fa';

import { ROUTE_PREFIX } from '../../constants/router';
import withAuthentication from '../Auth/AuthenticationWrapper';
import withInterestTestResults, {
  itestResultsType,
} from '../HomeScreen/DataWrappers/InterestTestResultsWrapper';
import testCompletionRequired from '../HomeScreen/DataWrappers/TestCompletionRequiredWrapper';
import s from './InterestsOverviewScreen.module.scss';
import ContentBody from '../ContentBody/index';
import Header from '../Header/index';
import Footer from '../Footer/index';
import {
  I_TYPE_NAME,
  I_TYPE_NAME_18_PLUS,
  I_TYPE_COLOR,
} from '../../constants/interests';
import { TEST_MODE_OLD } from '../../constants/testModes';

const blurFactor = 0.25;

interface InterestsOverviewScreenProps {
  loggedMode: string;
  meData?: any;
  userItestResults: itestResultsType;
  userItestMode: string;
}

class InterestsOverviewScreen extends React.Component<InterestsOverviewScreenProps> {
  static defaultProps = {
    meData: null,
  };

  constructor(props: any) {
    super(props);
    this.deriveColorGradient = this.deriveColorGradient.bind(this);
  }

  deriveColorGradient() {
    const { userItestResults } = this.props;

    const top3Interests = Object.keys(userItestResults.interestPercentages)
      .map((key) => ({
        interest: key,
        percentage: (userItestResults.interestPercentages as any)[key],
      }))
      .sort((i1, i2) => (i1.percentage < i2.percentage ? 1 : -1))
      .slice(0, 3);

    const colors = [];
    const locations = [];

    // derive colors
    for (let i = 0; i < 3; i += 1) {
      // push color 2 times
      colors.push(I_TYPE_COLOR[top3Interests[i].interest]);
      colors.push(I_TYPE_COLOR[top3Interests[i].interest]);
    }

    // substract half first offset (to make first color slice not beeing perceived too large)
    let sum = -(blurFactor * 0.5) * top3Interests[0].percentage;

    for (let i = 0; i < 3; i += 1) {
      const { percentage } = top3Interests[i];

      locations[2 * i] = sum + percentage * blurFactor;
      locations[2 * i + 1] = sum + percentage * (1 - blurFactor);
      sum += percentage;
    }

    // substract half last offset (to make last color slice not beeing perceived too large)
    sum -= blurFactor * 0.5 * top3Interests[2].percentage;

    // normalize locations
    for (let i = 0; i < locations.length; i += 1) {
      locations[i] /= sum;
    }

    // build color gradient
    let colorGradient = '';

    for (let i = 0; i < 6; i += 1) {
      const color = colors[i];
      const location = locations[i];
      if (i !== 0) colorGradient += ', ';
      colorGradient += `${color} ${location * 100}%`;
    }

    return colorGradient;
  }

  render() {
    const { loggedMode, meData, userItestResults, userItestMode } = this.props;

    const sortedInterests = Object.keys(userItestResults.interestPercentages)
      .map((key) => ({
        interest: key,
        percentage: (userItestResults.interestPercentages as any)[key],
      }))
      .sort((i1, i2) => (i1.percentage < i2.percentage ? 1 : -1));

    const colorGradient = this.deriveColorGradient();

    let nameMap = I_TYPE_NAME;

    if (userItestMode === TEST_MODE_OLD) {
      nameMap = I_TYPE_NAME_18_PLUS;
    }

    return (
      <div className={s.interestsOverviewScreenContainer}>
        <Header />
        <ContentBody>
          <div className={s.headerSection}>
            <h1>DEINE INTERESSEN</h1>
          </div>
          <div className={s.gradientBarWrapper}>
            <div
              className={s.gradientBar}
              style={{ background: `linear-gradient(${colorGradient})` }}
            />
          </div>
          <div className={s.interestSection}>
            {sortedInterests.map((i) => (
              <div key={`interest-${i.interest}`} className={s.interestLine}>
                <div
                  className={s.percentageLabel}
                  style={{ backgroundColor: I_TYPE_COLOR[i.interest] }}
                >
                  {`${i.percentage}%`}
                </div>
                <div className={s.buttonLinks}>
                  <Link
                    className={s.buttonLink}
                    to={`${ROUTE_PREFIX}/interest/${i.interest}`}
                  >
                    <button
                      type="button"
                      className={`btn ${i.interest}-button`}
                    >
                      {nameMap[i.interest]}
                    </button>
                  </Link>
                  <Link
                    className={s.playButtonLink}
                    to={`${ROUTE_PREFIX}/interest-video/${i.interest}?return=overview`}
                  >
                    <button
                      type="button"
                      className={`btn ${i.interest}-button`}
                    >
                      <FaPlay />
                    </button>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </ContentBody>
        <Footer loggedMode={loggedMode} meData={meData} />
      </div>
    );
  }
}

export default compose(
  withAuthentication,
  withInterestTestResults,
  testCompletionRequired,
)(InterestsOverviewScreen);
