/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/sort-comp */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Modal } from 'react-bootstrap';
import getAssetLink from '../../../../util/getAssetLink';

import s from './ExperienceTileMenuModal.module.scss';

interface ExperienceTileMenuModalProps {
  showModal: boolean;
  closeModal: () => void;
  // eslint-disable-next-line no-unused-vars
  addItem: (item: any) => Promise<void>;
  experience: {
    title: string;
    content: Array<string>;
    color: string;
  };
}

class ExperienceTileMenuModal extends React.Component<ExperienceTileMenuModalProps> {
  constructor(props: any) {
    super(props);

    this.addItem = this.addItem.bind(this);
    this.renderInfoText = this.renderInfoText.bind(this);
    this.renderTiles = this.renderTiles.bind(this);
  }

  async addItem(item: any) {
    await this.props.addItem(item);
  }

  renderInfoText() {
    return this.props.experience.content &&
      this.props.experience.content.length > 0
      ? 'Berühre eine Karte, um diese zu deiner To-do-Liste hinzuzufügen.'
      : 'Alle Karten dieser Kategorie befinden sich bereits in deiner To-do-Liste.';
  }

  renderTiles() {
    const { color, content } = this.props.experience;
    return (
      <div className={s.menuTileContainer}>
        {content.map((item, index) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={`item-${index}`}
            style={{ backgroundColor: color }}
            className={s.menuTile}
            onClick={async () => {
              await this.props.addItem(item);
              this.props.closeModal();
            }}
          >
            <div className={s.tileContentContainer}>{item}</div>
          </div>
        ))}
      </div>
    );
  }

  render() {
    const { title, color } = this.props.experience;
    return (
      <div className={s.modal}>
        <Modal
          centered
          show={this.props.showModal}
          backdrop="static"
          dialogClassName={s.modal}
        >
          <Modal.Header>
            <Modal.Title className={s.header}>
              <img
                src={getAssetLink('CrossIcon.png')}
                alt=""
                className={s.closeButton}
                onClick={this.props.closeModal}
              />
              <h3 style={{ color }}>{title}</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{this.renderInfoText()}</div>
            <div className={s.tileContainer}>{this.renderTiles()}</div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default ExperienceTileMenuModal;
