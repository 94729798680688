import React from 'react';
import gql from 'graphql-tag';

import AppContext, { AppContextType } from '../../context';

const loginQuery = gql`
  query login($name: String!, $password: String!) {
    login(name: $name, password: $password) {
      success
      token
    }
  }
`;

const meQuery = gql`
  query me {
    me {
      id
      name
    }
  }
`;

function setUserTokenCookie(token: string) {
  sessionStorage.setItem('id_token', token);
}

function getUserTokenCookie() {
  return sessionStorage.getItem('id_token') || null;
}

function setGuestLocalStorageData(data: any) {
  sessionStorage.setItem('guest_data', JSON.stringify(data));
}

function getGuestLocalStorageData() {
  const data = sessionStorage.getItem('guest_data') || null;
  if (data != null) return JSON.parse(data);
  return null;
}

function withLogin(WrappedComponent: any) {
  class LoginWrapper extends React.Component {
    static contextType = AppContext;

    constructor(props: any) {
      super(props);
      this.login = this.login.bind(this);
    }

    async login(name: string, password: string) {
      const response = await (this.context as AppContextType).client.query({
        query: loginQuery,
        variables: {
          name,
          password,
        },
        fetchPolicy: 'network-only',
      });
      if (response.data.login.success) {
        setUserTokenCookie(response.data.login.token); // set user cookie
        sessionStorage.removeItem('guest_data'); // delete guest data
        return true;
      }
      return false;
    }

    // eslint-disable-next-line class-methods-use-this
    guestLogin() {
      setGuestLocalStorageData({}); // set guest cookie
      // for testing:
      /*
      setGuestLocalStorageData({
        userItestAnswers: JSON.stringify({
          '0': 100,
          '1': 48,
          '2': 100,
          '3': 68,
          '4': 100,
          '5': 43,
          '6': 68,
          '7': 1,
          '8': 67,
          '9': 68,
          '10': 69,
          '11': 72,
          '12': 1,
          '13': 97,
          '14': 33,
          '15': 69,
          '16': 5,
          '17': 100,
          '18': 36,
          '19': 100,
          '20': 100,
          '21': 1,
          '22': 1,
          '23': 70,
          '24': 78,
          '25': 100,
          '26': 100,
          '27': 21,
          '28': 1,
          '29': 30,
          '30': 100,
          '31': 100,
          '32': 7,
          '33': 19,
          '34': 19,
          '35': 1,
          '36': 100,
          '37': 100,
          '38': 1,
          '39': 1,
          '40': 1,
          '41': 1,
          '42': 1,
          '43': 100,
          '44': 100,
          '45': 1,
          '46': 1,
          '47': 1,
          '48': 100,
          '49': 100,
          '50': 100,
          '51': 1,
          '52': 1,
          '53': 1,
          '54': 100,
          '55': 54,
          '56': 81,
          '57': 20,
          '58': 96,
          '59': 100,
        }),
      });
      */
      sessionStorage.removeItem('id_token'); // delete user token
    }

    render() {
      return (
        <WrappedComponent
          login={this.login}
          guestLogin={this.guestLogin}
          {...this.props}
        />
      );
    }
  }

  return LoginWrapper;
}

export {
  withLogin,
  setUserTokenCookie,
  getUserTokenCookie,
  setGuestLocalStorageData,
  getGuestLocalStorageData,
  loginQuery,
  meQuery,
};
