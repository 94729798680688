/* eslint-disable no-nested-ternary */
import React from 'react';
import { flowRight as compose } from 'lodash';
import SwipeableViews from 'react-swipeable-views';
import { Link } from 'react-router-dom';

import s from './JobExpectations.module.scss';
import { ROUTE_PREFIX } from '../../../constants/router';
import JobExpectationCard from './JobExpectationCard';
import withAuthentication from '../../Auth/AuthenticationWrapper';
import withInterestTestResults, {
  itestResultsType,
} from '../../HomeScreen/DataWrappers/InterestTestResultsWrapper';
import { checkIfTestResultsAreInvalid } from '../../HomeScreen/DataWrappers/TestCompletionRequiredWrapper';
import withBundesland from '../../HomeScreen/DataWrappers/BundeslandWrapper';
import withAkooeData, {
  akooeDataType,
} from '../../HomeScreen/DataWrappers/AkooeDataWrapper';
import OffersHeader from '../../OffersScreen/OffersHeader';
import ContentBody from '../../ContentBody/index';
import Footer from '../../Footer/index';
// import arrowLeft from '../../../../assets/arrow_left.png';
// import arrowRight from '../../../../assets/arrow_right.png';
import JobExpectations from './JobExpectations';
import Loading from '../../Loading';
import getAssetLink from '../../../util/getAssetLink';

interface JobExpectationsScreenProps {
  loggedMode: string;
  meData?: any;
  userItestResults?: itestResultsType;
  refetchSelectedBundesland: () => Promise<void>;
  selectedBundesland: string;
  // eslint-disable-next-line no-unused-vars
  setSelectedBundesland: (bundesland: string) => Promise<void>;
  userAkooeData: akooeDataType;
  // eslint-disable-next-line no-unused-vars
  setJobExpectationsModuleData: (data: any) => void;
  refetchAkooeData: () => Promise<void>;
}

class JobExpectationsScreen extends React.Component<JobExpectationsScreenProps> {
  // eslint-disable-next-line react/state-in-constructor
  state: any;

  nextSlideTimeOut: any = null;

  static defaultProps = {
    meData: null,
    userItestResults: null,
  };

  constructor(props: any) {
    super(props);

    const moduleData =
      props.userAkooeData && props.userAkooeData.jobExpectationsModuleData
        ? JSON.parse(props.userAkooeData.jobExpectationsModuleData)
        : {};

    this.state = {
      currentIndex: 0,
      loading: false,
      moduleData,
    };

    this.prevSlide = this.prevSlide.bind(this);
    this.nextSlide = this.nextSlide.bind(this);
    this.indexChanged = this.indexChanged.bind(this);
    this.onJobExpectationChange = this.onJobExpectationChange.bind(this);
  }

  componentWillUnmount() {
    if (this.nextSlideTimeOut) clearTimeout(this.nextSlideTimeOut);
  }

  onJobExpectationChange(id: string, selection: number) {
    const { setJobExpectationsModuleData } = this.props;

    const { moduleData } = this.state;

    if (!moduleData.jobExpectations) {
      moduleData.jobExpectations = {};
    }

    moduleData.jobExpectations[id] = selection;

    setJobExpectationsModuleData(JSON.stringify(moduleData));
    this.setState({ moduleData });
    if (this.nextSlideTimeOut) clearTimeout(this.nextSlideTimeOut);

    this.nextSlideTimeOut = setTimeout(() => {
      this.nextSlide();
    }, 400);

    // this.setState({ loading: false });
  }

  indexChanged(newIndex: number) {
    this.setState({
      currentIndex: newIndex,
    });
  }

  prevSlide() {
    const { currentIndex } = this.state;
    this.indexChanged(Math.max(0, currentIndex - 1));
  }

  nextSlide() {
    const { currentIndex } = this.state;
    this.indexChanged(Math.min(JobExpectations.length - 1, currentIndex + 1));
  }

  render() {
    const {
      loggedMode,
      meData,
      userItestResults,
      selectedBundesland,
      setSelectedBundesland,
      refetchSelectedBundesland,
    } = this.props;

    const { currentIndex, loading, moduleData } = this.state;

    if (!moduleData.jobExpectations) {
      moduleData.jobExpectations = {};
    }

    const testResultsInvalid = checkIfTestResultsAreInvalid(userItestResults);
    const finishedTest = !testResultsInvalid;

    let answeredAllQuestsions = true;
    for (let i = 0; i < JobExpectations.length; i += 1) {
      if (moduleData.jobExpectations[JobExpectations[i].id] == null) {
        answeredAllQuestsions = false;
      }
    }

    return (
      <div
        key="jobExpectationsContainer"
        className={s.jobExpectationsContainer}
      >
        <OffersHeader
          backUrl={`${ROUTE_PREFIX}/orientation/offers/${selectedBundesland}`}
          bundesland={selectedBundesland}
          setSelectedBundesland={setSelectedBundesland}
          refetchSelectedBundesland={refetchSelectedBundesland}
        />
        <ContentBody>
          <div
            className={s.jobExpectationsContent}
            key="jobExpectationsContent"
          >
            <div className={s.headerSection}>
              <h1>MEINE BERUFSERWARTUNGEN</h1>
              <h2>Wie wichtig ist dir ...?</h2>
            </div>
            <div className={s.jobExpectationsList}>
              <SwipeableViews
                index={currentIndex}
                onChangeIndex={this.indexChanged}
              >
                {JobExpectations.map((jobExpectation) => (
                  <JobExpectationCard
                    key={jobExpectation.id}
                    id={jobExpectation.id}
                    value={moduleData.jobExpectations[jobExpectation.id]}
                    title={jobExpectation.text}
                    onChange={this.onJobExpectationChange}
                  />
                ))}
              </SwipeableViews>
              <button
                className={s.previousSlideButton}
                type="button"
                onClick={this.prevSlide}
                disabled={currentIndex <= 0}
              >
                <img src={getAssetLink('arrow_left.png')} alt="" />
              </button>
              <button
                className={s.nextSlideButton}
                type="button"
                onClick={this.nextSlide}
                disabled={currentIndex >= JobExpectations.length - 1}
              >
                <img src={getAssetLink('arrow_right.png')} alt="" />
              </button>
            </div>
            {answeredAllQuestsions ? (
              <div className={s.bottomInfo}>
                <Link
                  to={`${ROUTE_PREFIX}/orientation/offers/${selectedBundesland}/modules/job-expectations/favorites`}
                >
                  <button type="button" className="btn btn-primary">
                    ZUSAMMENFASSUNG
                  </button>
                </Link>
              </div>
            ) : (
              <div className={s.bottomInfo}>
                Beantworte alle Fragen, um zur Zusammenfassung zu gelangen und
                deine Favoriten auszuwählen!
              </div>
            )}
          </div>
        </ContentBody>
        <Footer
          loggedMode={loggedMode}
          meData={meData}
          finishedTest={finishedTest}
          forceShowExport
          exportUrl={`${ROUTE_PREFIX}/orientation/offers/${selectedBundesland}/export`}
          withEPortfolio
          refetchAkooeData={this.props.refetchAkooeData}
        />
        {loading && <Loading />}
      </div>
    );
  }
}

export default compose(
  withAuthentication,
  withInterestTestResults,
  withBundesland,
  withAkooeData,
)(JobExpectationsScreen);
