import React from 'react';
import { NavigateFunction } from 'react-router-dom';

import s from './Header.module.scss';
import { ROUTE_PREFIX } from '../../constants/router';
import getAssetLink from '../../util/getAssetLink';
import { withRouter } from '../../util/withRouter';

interface HeaderProps {
  withBackButton?: boolean;
  backUrl?: string;
  children?: React.ReactNode;
  navigate: NavigateFunction;
}

class Header extends React.Component<HeaderProps> {
  static defaultProps = {
    withBackButton: true,
    backUrl: `${ROUTE_PREFIX}/home`,
    children: null,
  };

  constructor(props: any) {
    super(props);
    this.renderBackButton = this.renderBackButton.bind(this);
  }

  renderBackButton() {
    return (
      <button
        type="button"
        className="btn-no-style"
        onClick={() => {
          this.props.navigate(this.props.backUrl as string);
        }}
      >
        <img
          className={s.backButton}
          src={getAssetLink('back_button.png')}
          alt="back"
        />
      </button>
    );
  }

  render() {
    return (
      <div className={s.headerContainer}>
        <div className={s.backButtonWrapper}>
          {this.props.withBackButton && this.renderBackButton()}
        </div>
        <div className={s.middle}>{this.props.children}</div>
        <div className={s.logoWrapper}>
          <img className={s.logo} src={getAssetLink('AKLogo.png')} alt="logo" />
        </div>
      </div>
    );
  }
}

export default withRouter(Header);
