/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Link } from 'react-router-dom';
import { Modal, Accordion, Card, Button } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { FaAngleRight, FaAngleDown } from 'react-icons/fa';
import Cookies from 'js-cookie';

import s from './CookieConsentModal.module.scss';
import { ROUTE_PREFIX } from '../../constants/router';
import { COOKIE_CONSENT_SETTINGS } from '../../constants/cookies';

function AccordionToggle({ children, eventKey, onClick }: any) {
  const decoratedOnClick = useAccordionButton(eventKey);

  return (
    <Button
      variant="link"
      onClick={(event) => {
        decoratedOnClick(event);
        onClick();
      }}
    >
      {children}
    </Button>
  );
}

class CookieConsentModal extends React.Component {
  // eslint-disable-next-line react/state-in-constructor
  state: any;

  constructor(props: any) {
    super(props);

    this.state = {
      cookiesOpened: false,
      trackingOpened: false,
      showModal: true,
    };

    this.setCookie = this.setCookie.bind(this);
    this.acceptTracking = this.acceptTracking.bind(this);
    this.blockTracking = this.blockTracking.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  setCookie(trackingDataAccepted: boolean) {
    Cookies.set(
      COOKIE_CONSENT_SETTINGS,
      JSON.stringify({ trackingAccepted: trackingDataAccepted }),
      { expires: 365 },
    );
  }

  async acceptTracking() {
    this.setCookie(true);
    window.location.reload(); // Reload to start intro video
  }

  async blockTracking() {
    this.setCookie(false);
    window.location.reload(); // Reload to start intro video
  }

  render() {
    const { cookiesOpened, trackingOpened, showModal } = this.state;

    return (
      <div>
        <Modal
          centered
          show={showModal}
          backdrop="static"
          dialogClassName={s.modal}
        >
          <Modal.Header className={s.header}>
            <Modal.Title>
              <div data-nosnippet>
                <h3>Wir brauchen deine Zustimmung</h3>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className={s.body}>
            <div className={s.info} data-nosnippet>
              <div className={s.buttonContainerTop}>
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={this.acceptTracking}
                >
                  Lokale Session und Tracking Daten erfassen
                </button>
              </div>
              <div>
                <p style={{ fontSize: 'smaller' }}>
                  Wenn du diese Webseite nutzt, werden wir Cookies auf deinem
                  Endgerät speichern und ähnliche Technologien nutzen mit deren
                  Hilfe wir dich bzw. verwendete Endgerät wiedererkennen können.
                  Wir verwenden diese Technologien, um dir Komforteinstellungen
                  zu ermöglichen und das Nutzerverhalten auf der Webseite
                  statistisch zu analysieren.
                </p>
                <p style={{ fontWeight: 'bold', fontSize: 'smaller' }}>
                  Es werden keine Werbeprofile erstellt und keine
                  personalisierte Werbung ausgespielt. Eine personenbezogene
                  Auswertung der Daten findet nicht statt.
                </p>
                <p>
                  Nähere Information hierzu findest du in der{' '}
                  <Link
                    to={`${ROUTE_PREFIX}/datenschutz`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    Datenschutzerklärung
                  </Link>
                  .
                </p>
              </div>
              <Accordion>
                <Card className={s.accordionCard}>
                  <div className={s.line}>
                    <AccordionToggle
                      eventKey="0"
                      onClick={() =>
                        this.setState({ cookiesOpened: !cookiesOpened })
                      }
                    >
                      <div>
                        Lokale Session Daten/Cookies (notwendig)
                        {cookiesOpened ? <FaAngleDown /> : <FaAngleRight />}
                      </div>
                    </AccordionToggle>
                  </div>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body className={s.infotext}>
                      Damit diese Seite richtig funktioniert, müssen sowohl als
                      eingeloggter User als auch als Gast Session Daten lokal
                      gespeichert werden. Deine Anmeldedaten oder Gastdaten sind
                      nur im aktuellen Browserfenster gespeichert. Schließt du
                      das Browserfenster, wirst du automatisch abgemeldet oder
                      deine Gastdaten werden gelöscht. Es werden keine deiner
                      Daten an Dritte weitergegeben. Jopsy ist absolut anonym
                      und du entscheidest, wer deine Ergebnisse sehen darf.
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card className={s.accordionCard}>
                  <div className={s.line}>
                    <AccordionToggle
                      eventKey="1"
                      onClick={() =>
                        this.setState({ trackingOpened: !trackingOpened })
                      }
                    >
                      <div>
                        Tracking Daten
                        {trackingOpened ? <FaAngleDown /> : <FaAngleRight />}
                      </div>
                    </AccordionToggle>
                  </div>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body className={s.infotext}>
                      {' '}
                      Um unsere Services zu verbessern, speichern wir folgende
                      Tracking Daten:
                      <ul>
                        <li>Dauer deiner Session</li>
                        <li>Welche Arbeiterkammer du ausgewählt hast</li>
                        <li>
                          Geocode Location (wir speichern nur das Bundesland) -
                          Anmerkung: Nur dann, wenn du auch im Browser Location
                          Tracking aktiviert hast.
                        </li>
                        <li>
                          Device Informationen über Gerätetyp (PC, Mobile oder
                          Tablet), Betriebssystem und Browser{' '}
                        </li>
                      </ul>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
              <div className={s.buttonContainerBottom}>
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={this.blockTracking}
                >
                  Nur lokale Session Daten erfassen
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default CookieConsentModal;
