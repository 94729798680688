import React from 'react';

interface PieChartLabelProps {
  labelProps: any;
}

class PieChartLabel extends React.Component<PieChartLabelProps> {
  render() {
    const { labelProps } = this.props;
    const width =
      labelProps.dataEntry.percentage < 8
        ? labelProps.dataEntry.percentage
        : 10;
    const x = labelProps.x + labelProps.dx - width / 2;
    const y = labelProps.y + labelProps.dy - width / 2;
    return (
      <image
        x={x}
        y={y}
        xlinkHref={labelProps.dataEntry.image}
        width={width}
        height={width}
      />
    );
  }
}

export default PieChartLabel;
