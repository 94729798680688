import React from 'react';
import gql from 'graphql-tag';

import s from './InterestsTestScreen.module.scss';
import AppContext from '../../context';
import ConfirmCancelModal from './ConfirmCancelModal';
import Loading from '../Loading';
import getAssetLink from '../../util/getAssetLink';

export const resetTestMutation = gql`
  mutation resetTest {
    resetTest
  }
`;

interface ProgressTopBarProps {
  index: number;
  length: number;
  cancelTest: () => Promise<void>;
}

class ProgressTopBar extends React.Component<ProgressTopBarProps> {
  static contextType = AppContext;

  // eslint-disable-next-line react/state-in-constructor
  state: any;

  constructor(props: any) {
    super(props);

    this.state = {
      cancelingTest: false,
      showModal: false,
    };

    this.onCancel = this.onCancel.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  async onCancel() {
    const { cancelTest } = this.props;

    await cancelTest();

    this.setState({ showModal: false });
  }

  openModal() {
    this.setState({ showModal: true });
  }

  async closeModal() {
    this.setState({ showModal: false });
  }

  render() {
    const { index, length } = this.props;
    const { cancelingTest } = this.state;

    return [
      <div className={s.progressTopBar} key="progressTopBar">
        <ConfirmCancelModal
          onCancel={this.onCancel}
          closeModal={this.closeModal}
          showModal={this.state.showModal}
        />
        <div className={s.progressContainer}>
          <div className={s.progressBar}>
            <img
              className={s.progressFill}
              src={getAssetLink('progressbar.png')}
              alt=""
            />
            <div
              className={s.progressBackground}
              style={{ left: `${((index + 1) / length) * 100}%` }}
            />
          </div>
          <div className={s.progressLabel}>
            {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
            <span style={{ fontWeight: 'bold' }}>{index + 1}</span> von {length}
          </div>
        </div>
        <button
          type="button"
          className={`btn-no-style ${s.iconContainer}`}
          onClick={this.openModal}
        >
          <img
            className={s.backButton}
            src={getAssetLink('CrossIcon_red.png')}
            alt="restart"
          />
        </button>
      </div>,
      cancelingTest ? <Loading key="loading" /> : null,
    ];
  }
}

export default ProgressTopBar;
