import React from 'react';
import gql from 'graphql-tag';
import { flowRight as compose } from 'lodash';

import { NavigateFunction } from 'react-router-dom';
import { ROUTE_PREFIX } from '../../constants/router';
import AppContext, { AppContextType } from '../../context';
import s from './Footer.module.scss';
import {
  LOGGED_MODE_USER,
  LOGGED_MODE_GUEST,
} from '../Auth/AuthenticationWrapper';
import {
  getGuestLocalStorageData,
  setGuestLocalStorageData,
} from '../Auth/withLogin';
import { withLogout } from '../Auth/withLogout';
import SettingsMenu from '../SettingsMenu';
import Loading from '../Loading';
import ConfirmRestartModal from './ConfirmRestartModal';
import AkooeRestartModal from './AkooeRestartModal';
import ConfirmLogoutModal from './ConfirmLogoutModal';
import ShareLinkModal from './ShareLinkModal';
import getAssetLink from '../../util/getAssetLink';
import { withRouter } from '../../util/withRouter';

export const resetTestMutation = gql`
  mutation resetTest {
    resetTest
  }
`;

export const resetUserAkooeDataMutation = gql`
  mutation resetUserAkooeData {
    resetUserAkooeData
  }
`;

export const generateShareLinkForUserMutation = gql`
  mutation generateShareLinkForUser($type: String!) {
    generateShareLinkForUser(type: $type)
  }
`;

interface FooterProps {
  loggedMode: string;
  meData?: any;
  finishedTest?: boolean;
  forceShowExport?: boolean;
  exportUrl?: string;
  withEPortfolio?: boolean;
  navigate: NavigateFunction;
  logout: () => void;
}

class Footer extends React.Component<FooterProps> {
  static contextType = AppContext;

  // eslint-disable-next-line react/state-in-constructor
  state: any;

  static defaultProps = {
    meData: null,
    finishedTest: true,
    forceShowExport: false,
    exportUrl: `${ROUTE_PREFIX}/export`,
    withEPortfolio: false,
  };

  constructor(props: any) {
    super(props);

    this.state = {
      showOptionsMenu: false,
      restartingTest: false,
      showRestartModal: false,
      showLogoutModal: false,
      loadingShareLink: false,
      showShareLinkModal: false,
      shareId: '',
    };

    this.onHome = this.onHome.bind(this);
    this.onShare = this.onShare.bind(this);
    this.openRestartModal = this.openRestartModal.bind(this);
    this.closeRestartModal = this.closeRestartModal.bind(this);
    this.openLogoutModal = this.openLogoutModal.bind(this);
    this.closeLogoutModal = this.closeLogoutModal.bind(this);
    this.displayOptions = this.displayOptions.bind(this);
    this.restartTest = this.restartTest.bind(this);
    this.generateShareLink = this.generateShareLink.bind(this);
  }

  onHome() {
    this.props.navigate(`${ROUTE_PREFIX}/home`);
  }

  onShare() {
    this.props.navigate(this.props.exportUrl as string);
  }

  openRestartModal() {
    this.setState({ showRestartModal: true });
  }

  closeRestartModal() {
    this.setState({ showRestartModal: false });
  }

  openLogoutModal() {
    if (this.props.loggedMode === LOGGED_MODE_GUEST) {
      this.setState({ showLogoutModal: true });
    } else {
      this.props.logout();
    }
  }

  closeLogoutModal() {
    this.setState({ showLogoutModal: false });
  }

  async restartTest({ resetItest, resetEPortfolio }: any) {
    if (!resetItest && !resetEPortfolio) {
      this.setState({ showRestartModal: false });
      return;
    }

    const { loggedMode } = this.props;

    this.setState({ restartingTest: true, showRestartModal: false });

    if (loggedMode === LOGGED_MODE_USER) {
      if (resetItest) {
        await (this.context as AppContextType).client.mutate({
          mutation: resetTestMutation,
        });
      }
      if (resetEPortfolio) {
        await (this.context as AppContextType).client.mutate({
          mutation: resetUserAkooeDataMutation,
        });
      }
    } else if (loggedMode === LOGGED_MODE_GUEST) {
      const guestData = getGuestLocalStorageData();
      let newGuestData: any = {};

      if (!resetItest) {
        newGuestData = { ...guestData };
        delete newGuestData.selectedBundesland;
        delete newGuestData.userAkooeData;
      }

      if (!resetEPortfolio) {
        newGuestData.selectedBundesland = guestData.selectedBundesland;
        newGuestData.userAkooeData = guestData.userAkooeData;
      }

      setGuestLocalStorageData(newGuestData);
    }

    if (resetItest) {
      // eslint-disable-next-line no-restricted-globals
      location.href = `${ROUTE_PREFIX}/`;
    } else if (resetEPortfolio) {
      // eslint-disable-next-line no-restricted-globals
      location.href = `${ROUTE_PREFIX}/`;
    }
  }

  async generateShareLink({ withEPortfolio }: any) {
    const { loggedMode } = this.props;

    this.setState({ loadingShareLink: true });
    let shareId;
    if (loggedMode === LOGGED_MODE_USER) {
      const shareLinkData = await (
        this.context as AppContextType
      ).client.mutate({
        mutation: generateShareLinkForUserMutation,
        variables: { type: withEPortfolio ? 'portfolio' : 'iTest' },
      });
      shareId =
        shareLinkData && shareLinkData.data
          ? shareLinkData.data.generateShareLinkForUser
          : null;
    } else if (loggedMode === LOGGED_MODE_GUEST) {
      // nothing to do atm
    }

    this.setState({
      loadingShareLink: false,
      shareId,
      showShareLinkModal: shareId,
    });
  }

  displayOptions() {
    return this.state.showOptionsMenu ? (
      <SettingsMenu
        meData={this.props.meData}
        finishedTest={this.props.finishedTest}
        onLogout={this.openLogoutModal}
        showShareOption={this.props.loggedMode === LOGGED_MODE_USER}
        withEPortfolio={this.props.withEPortfolio}
        onShare={this.generateShareLink}
      />
    ) : (
      <div />
    );
  }

  render() {
    const { withEPortfolio } = this.props;
    const { showOptionsMenu, restartingTest, loadingShareLink } = this.state;

    return [
      <div key="footerContainer" className={s.footerContainer}>
        <ConfirmRestartModal
          showModal={!withEPortfolio && this.state.showRestartModal}
          restartTest={() => {
            this.restartTest({ resetItest: true, resetEPortfolio: false });
          }}
          closeModal={this.closeRestartModal}
        />
        <AkooeRestartModal
          showModal={withEPortfolio && this.state.showRestartModal}
          closeModal={this.closeRestartModal}
          onSubmit={({ resetItest, resetEPortfolio }) => {
            this.restartTest({ resetItest, resetEPortfolio });
          }}
        />
        <ConfirmLogoutModal
          showModal={this.state.showLogoutModal}
          onLogout={this.props.logout}
          closeModal={this.closeLogoutModal}
        />
        <ShareLinkModal
          showModal={this.state.showShareLinkModal}
          shareId={this.state.shareId}
          suffix={this.props.withEPortfolio ? 'portfolio' : 'results'}
          closeModal={() => this.setState({ showShareLinkModal: false })}
        />
        <div className={s.buttonBar}>
          <div className={`${s.sideButtonsWrapper} ${s.left}`}>
            <button
              type="button"
              className={`btn-no-style ${s.homeButton}`}
              onClick={this.onHome}
            >
              <img
                className={s.homeIcon}
                src={getAssetLink('HomeIcon.png')}
                alt="home"
              />
            </button>
          </div>
          <button
            type="button"
            className={`btn-no-style ${s.restartButton}`}
            onClick={this.openRestartModal}
          >
            <img
              className={s.restartIcon}
              src={getAssetLink('ResetIcon.png')}
              alt="restart"
            />
          </button>
          <div className={`${s.sideButtonsWrapper} ${s.right}`}>
            {(this.props.finishedTest || this.props.forceShowExport) && (
              <button
                type="button"
                className={`btn-no-style ${s.shareButton}`}
                onClick={this.onShare}
              >
                <img
                  className={s.shareIcon}
                  src={getAssetLink('share.png')}
                  alt="options"
                />
              </button>
            )}
            <button
              type="button"
              className={`btn-no-style ${s.optionsButton} ${
                this.props.finishedTest || this.props.forceShowExport
                  ? ''
                  : s.fullWidth
              }`}
              onClick={() => {
                this.setState({ showOptionsMenu: !showOptionsMenu });
              }}
            >
              <img
                className={s.optionsIcon}
                src={getAssetLink('MenuIcon.png')}
                alt="options"
              />
            </button>
          </div>
          <div className={s.settingsMenuContainer}>{this.displayOptions()}</div>
        </div>
      </div>,
      restartingTest || loadingShareLink ? <Loading key="loading" /> : null,
    ];
  }
}

export default compose(withRouter, withLogout)(Footer);
