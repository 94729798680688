/* eslint-disable max-classes-per-file */
import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';

import s from './DesiredJobOrSchoolComponent.module.scss';
import SuggestionsModal from './Modals/SuggestionsModal';
import getAssetLink from '../../../util/getAssetLink';

const getSmallBubbleStyles = (size: Array<number>, bgColor: string) => ({
  smallBubble: {
    width: '100%',
    height: '100%',
    backgroundColor: bgColor,
    borderRadius: `calc(${size[0]}px + ${size[1]}%)`,
    alignItems: 'center',
    justifyContent: 'center',
  },
  smallBubbleContent: {
    display: 'flex',
    width: '100%',
    height: '100%',
    fontFamily: 'open sans',
    fontSize: `calc((${size[0] / 5}px + ${size[1]}px`,
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 600,
  },
  simpleBubbleContent: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    fontFamily: 'open sans',
    fontSize: `calc(8px + 0.3vw)`,
    textAlign: 'center',
    fontWeight: 600,
    paddingTop: 'calc(30px + 1vw)',
  },
  smallBubbleImage: {
    height: '30%',
  },
});

interface SmallBubbleProps_ {
  size?: Array<number>;
  onClick?: () => void;
  questionState?: 'answered' | 'viewed' | 'not_viewed';
}

class SmallBubble_ extends React.Component<SmallBubbleProps_> {
  static defaultProps = {
    size: [200, 5],
    onClick: () => {},
    questionState: 'not_viewed',
  };

  render() {
    const { size, onClick, questionState } = this.props;

    let bgColor = '#FFFFFF';
    if (questionState === 'answered') bgColor = '#BFC0C1';
    else if (questionState === 'viewed') bgColor = '#e4e4e7';
    else if (questionState === 'not_viewed') bgColor = '#FFFFFF';
    const styles = getSmallBubbleStyles(size as Array<number>, bgColor);
    let icon = null;
    if (questionState === 'answered') {
      icon = (
        <div style={styles.smallBubbleContent}>
          <img
            style={styles.smallBubbleImage}
            src={getAssetLink('check.png')}
            alt="beantwortet"
          />
        </div>
      );
    } else if (questionState === 'viewed') {
      icon = (
        <div style={styles.smallBubbleContent}>
          <img
            style={styles.smallBubbleImage}
            src={getAssetLink('edit.png')}
            alt="editiert"
          />
        </div>
      );
    } else if (questionState === 'not_viewed') {
      icon = (
        <div style={styles.smallBubbleContent}>
          <div>?</div>
        </div>
      );
    }

    return (
      <button
        type="button"
        className={s.smallBubble}
        style={styles.smallBubble}
        onClick={onClick}
      >
        {icon}
      </button>
    );
  }
}

interface SimpleBubbleProps_ {
  questionData: {
    id: string;
    question: string;
    answer: string;
  };
  size?: Array<number>;
  onClick?: () => void;
  currentAnswer?: string;
}

class SimpleBubble_ extends React.Component<SimpleBubbleProps_> {
  static defaultProps = {
    size: [200, 5],
    onClick: () => {},
    currentAnswer: '',
  };

  render() {
    const { size, questionData, onClick, currentAnswer } = this.props;
    const bgColor = '#FFFFFF';
    const styles = getSmallBubbleStyles(size as Array<number>, bgColor);

    return (
      <button
        type="button"
        className={s.smallBubble}
        style={styles.smallBubble}
        onClick={onClick}
      >
        <div style={styles.simpleBubbleContent as any}>
          <div style={{ fontSize: '13px' }}>{questionData.question}</div>
          <div className={s.currentAnswer}>{currentAnswer}</div>
        </div>
      </button>
    );
  }
}

interface QuestionBubbleProps_ {
  schoolOrJob: '' | 'job' | 'school';
  questionData: {
    id: string;
    question: string;
    answer: string;
  };
  close?: () => void;
  suggestions?: Array<string>;
  currentAnswer?: string;
  // eslint-disable-next-line no-unused-vars
  updateBubbleData: (id: string, value: string) => void;
}

class QuestionBubble_ extends React.Component<QuestionBubbleProps_> {
  // eslint-disable-next-line react/state-in-constructor
  state: any;

  static defaultProps = {
    close: () => {},
    suggestions: [],
    currentAnswer: '',
  };

  constructor(props: any) {
    super(props);

    this.state = {
      edit: false,
      showSuggestionsModal: false,
    };
  }

  render() {
    const {
      schoolOrJob,
      questionData,
      close,
      suggestions,
      updateBubbleData,
      currentAnswer,
    } = this.props;

    const { edit, showSuggestionsModal } = this.state;

    return (
      <div className={s.bubble}>
        <div className={s.questionContainer}>
          <div className={s.questionText}>{questionData.question}</div>
          <div className={s.suggestionButtonContainer}>
            {edit && suggestions && suggestions.length > 0 && (
              <Button
                onClick={() => {
                  this.setState({ showSuggestionsModal: true });
                }}
              >
                Aus Favoriten
              </Button>
            )}
          </div>
        </div>
        <div className={s.answerContainer}>
          {edit && (
            <textarea
              className={s.answerInputText}
              rows={5}
              onChange={(event) => {
                if (schoolOrJob === 'job') {
                  updateBubbleData(questionData.id, event.target.value);
                } else if (schoolOrJob === 'school') {
                  updateBubbleData(questionData.id, event.target.value);
                }
              }}
              defaultValue={questionData.answer}
              value={currentAnswer || ''}
              placeholder="Leer"
            />
          )}
          {!edit && <div className={s.answerText}>{currentAnswer}</div>}
        </div>
        <div className={s.iconsBar}>
          <Button
            className={s.editIconContainer}
            onClick={() => {
              if (this.state.edit) {
                this.setState({ edit: false });
                if (close) {
                  close();
                }
              } else {
                this.setState({ edit: true });
              }
            }}
          >
            <img
              src={getAssetLink(this.state.edit ? 'check.png' : 'edit.png')}
              className={s.editIcon}
              alt="edit"
            />
          </Button>
        </div>
        {suggestions && suggestions.length > 0 && (
          <SuggestionsModal
            showModal={showSuggestionsModal}
            suggestions={suggestions}
            onClose={() => {
              this.setState({ showSuggestionsModal: false });
            }}
            onSelectSuggestion={(suggestion) => {
              updateBubbleData('desired-job', suggestion);
              this.setState({ showSuggestionsModal: false });
            }}
          />
        )}
      </div>
    );
  }
}

export const SimpleBubble = connect()(SimpleBubble_);
export const SmallBubble = SmallBubble_;
export const QuestionBubble = connect()(QuestionBubble_);
