import React from 'react';
import { Modal, Button } from 'react-bootstrap';

import s from './ConfirmRestartModal.module.scss';

interface ConfirmLogoutModalProps {
  showModal: boolean;
  onLogout: () => void;
  closeModal: () => void;
}

class ConfirmLogoutModal extends React.Component<ConfirmLogoutModalProps> {
  render() {
    return (
      <div>
        <Modal
          centered
          show={this.props.showModal}
          backdrop="static"
          dialogClassName={s.restartModal}
        >
          <Modal.Header className={s.header}>
            <Modal.Title>
              <h3>
                <strong>Logout?</strong>
              </h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className={s.body}>
            Du bist aktuell als Gast angemeldet. Wenn du dich abmeldest, gehen
            alle bisherigen Daten verloren.
            <br />
            <br />
            Willst du dich wirklich abmelden?
            <br />
            <br />
            Du kannst dich im Hauptmenü auch registrieren und deine bisherigen
            Daten übernehemen.
          </Modal.Body>
          <Modal.Footer className={s.footer}>
            <Button variant="btn btn-secondary" onClick={this.props.closeModal}>
              ABBRECHEN
            </Button>
            <Button variant="btn btn-primary" onClick={this.props.onLogout}>
              LOGOUT
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default ConfirmLogoutModal;
