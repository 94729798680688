import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';

import { ROUTE_PREFIX } from '../../../constants/router';
import s from '../login/LoginForm.module.scss';
import render from '../../ReduxForm/renderField';

const validate = () => {
  const errors = {};
  return errors;
};

interface RegistrationType {
  username: string;
  password: string;
  confirmPassword: string;
}

interface RegistrationFormProps {
  // eslint-disable-next-line no-unused-vars
  onSubmit: (data: any) => void;
  errors?: Array<string>;
  withHeader?: boolean;
  withLoginLink?: boolean;
  withRegisterButton?: boolean;
}

class RegistrationForm extends React.Component<
  InjectedFormProps<RegistrationType, RegistrationFormProps> &
    RegistrationFormProps
> {
  // eslint-disable-next-line react/state-in-constructor
  state: any;

  static defaultProps = {
    errors: [],
    withHeader: true,
    withLoginLink: true,
    withRegisterButton: true,
  };

  constructor(props: any) {
    super(props);

    this.state = {
      dataProtectionChecked: false,
    };
  }

  render() {
    const {
      handleSubmit,
      errors,
      withHeader,
      withLoginLink,
      withRegisterButton,
    } = this.props;
    const { dataProtectionChecked } = this.state;
    const dataProtectionCheckText = (
      <span className={s.span} style={{ margin: '0' }}>
        {' '}
        Ich stimme der Speicherung meines Passworts und meiner Abfrageergebnisse
        auf dem Server des Websitebetreibers zum Zweck der Wartung meines
        Accounts zu. Nähere Informationen zu meinen Datenschutzrechten und zu
        den Pflichten des Websitebetreibers finde ich{' '}
        <a href="/datenschutz" target="_blank" rel="noopener noreferrer">
          hier
        </a>
        .
      </span>
    );
    return (
      <div className={s.loginForm}>
        {withHeader && <h1>REGISTRIEREN</h1>}
        <form className="default-form" onSubmit={handleSubmit}>
          <fieldset className={s.fieldset}>
            <Field
              id="username"
              name="username"
              type="text"
              placeholder="Benutzername"
              component={render.renderInput}
            />
            <Field
              id="password"
              name="password"
              type="password"
              placeholder="Passwort"
              component={render.renderInput}
            />
            <Field
              id="confirmPassword"
              name="confirmPassword"
              type="password"
              placeholder="Passwort wiederholen"
              component={render.renderInput}
            />
            <div className={s.checkBoxBox}>
              <Field
                type="checkbox"
                id="dataProtectionCheck"
                name="dataProtectionCheck"
                component={render.renderCheckbox}
                style={{
                  minHeight: '20px',
                  paddingTop: '3px',
                }}
                checked={this.state.dataProtectionChecked}
                onChange={() =>
                  // eslint-disable-next-line prettier/prettier
                  this.setState({ dataProtectionChecked: !dataProtectionChecked })}
                label={dataProtectionCheckText}
              />
            </div>
            {errors != null && errors.length === 0 ? null : (
              <ul style={{ padding: 0, margin: 0, listStyleType: 'none' }}>
                {errors != null &&
                  errors.map((err) => (
                    <li key={err} className="validationError">
                      {err}
                    </li>
                  ))}
              </ul>
            )}
            {withRegisterButton && (
              <button
                type="submit"
                className="btn btn-secondary"
                disabled={!this.state.dataProtectionChecked}
              >
                REGISTRIEREN
              </button>
            )}
          </fieldset>
          {withLoginLink && (
            <div className={s.links}>
              <Link to={`${ROUTE_PREFIX}/login`} className="link">
                ANMELDEN
              </Link>
            </div>
          )}
        </form>
      </div>
    );
  }
}

export default reduxForm<RegistrationType, RegistrationFormProps>({
  form: 'registration',
  validate,
  pure: false,
})(RegistrationForm);
