/* eslint-disable no-nested-ternary */
import React from 'react';
import { flowRight as compose } from 'lodash';
import { NavigateFunction } from 'react-router-dom';

import s from './FavoriteJobExpectations.module.scss';
import { ROUTE_PREFIX } from '../../../constants/router';
import withAuthentication from '../../Auth/AuthenticationWrapper';
import withInterestTestResults, {
  itestResultsType,
} from '../../HomeScreen/DataWrappers/InterestTestResultsWrapper';
import { checkIfTestResultsAreInvalid } from '../../HomeScreen/DataWrappers/TestCompletionRequiredWrapper';
import withBundesland from '../../HomeScreen/DataWrappers/BundeslandWrapper';
import withAkooeData, {
  akooeDataType,
} from '../../HomeScreen/DataWrappers/AkooeDataWrapper';
import OffersHeader from '../../OffersScreen/OffersHeader';
import ContentBody from '../../ContentBody/index';
import Footer from '../../Footer/index';
import JobExpectations from './JobExpectations';
import FavoriteJobExpectationLine from './FavoriteJobExpectationLine';
import MaximumFavoriteCountModal from './MaximumFavoriteCountModal';
import Loading from '../../Loading';
import { withRouter } from '../../../util/withRouter';

interface FavoriteJobExpectationsScreenProps {
  loggedMode: string;
  meData?: any;
  userItestResults?: itestResultsType;
  refetchSelectedBundesland: () => Promise<void>;
  selectedBundesland: string;
  // eslint-disable-next-line no-unused-vars
  setSelectedBundesland: (bundesland: string) => Promise<void>;
  userAkooeData: akooeDataType;
  // eslint-disable-next-line no-unused-vars
  setJobExpectationsModuleData: (data: any) => void;
  refetchAkooeData: () => Promise<void>;
  navigate: NavigateFunction;
}

class FavoriteJobExpectationsScreen extends React.Component<FavoriteJobExpectationsScreenProps> {
  // eslint-disable-next-line react/state-in-constructor
  state: any;

  static defaultProps = {
    meData: null,
    userItestResults: null,
  };

  constructor(props: any) {
    super(props);

    const moduleData =
      props.userAkooeData && props.userAkooeData.jobExpectationsModuleData
        ? JSON.parse(props.userAkooeData.jobExpectationsModuleData)
        : {};

    this.state = {
      loading: false,
      showMaximumFavoriteCountModal: false,
      moduleData,
    };

    this.onSetFavorite = this.onSetFavorite.bind(this);
  }

  async onSetFavorite(id: string, favorite: boolean) {
    const { setJobExpectationsModuleData } = this.props;

    const { moduleData } = this.state;

    if (!moduleData.favorites) {
      moduleData.favorites = {};
    }

    if (favorite === true && Object.keys(moduleData.favorites).length >= 5) {
      this.setState({ showMaximumFavoriteCountModal: true });
      return;
    }

    if (favorite === true) {
      moduleData.favorites[id] = true;
    } else {
      delete moduleData.favorites[id];
    }
    setJobExpectationsModuleData(JSON.stringify(moduleData));
    this.setState({ moduleData });
  }

  render() {
    const {
      loggedMode,
      meData,
      userItestResults,
      selectedBundesland,
      setSelectedBundesland,
      refetchSelectedBundesland,
      navigate,
    } = this.props;

    const { moduleData } = this.state;

    if (!moduleData.jobExpectations) {
      moduleData.jobExpectations = {};
    }

    if (!moduleData.favorites) {
      moduleData.favorites = {};
    }

    let answeredAllQuestsions = true;
    for (let i = 0; i < JobExpectations.length; i += 1) {
      if (moduleData.jobExpectations[JobExpectations[i].id] == null) {
        answeredAllQuestsions = false;
      }
    }

    if (!answeredAllQuestsions) {
      navigate(
        `${ROUTE_PREFIX}/orientation/offers/${selectedBundesland}/modules/job-expectations`,
      );
      return <Loading />;
    }

    const sortedJobExpectations = Object.keys(moduleData.jobExpectations)
      .map((j) => ({ id: j, value: moduleData.jobExpectations[j] }))
      .sort((j1, j2) => {
        if (j1.value > j2.value) return -1;
        if (j1.value < j2.value) return 1;
        return 0;
      });

    const testResultsInvalid = checkIfTestResultsAreInvalid(userItestResults);
    const finishedTest = !testResultsInvalid;

    const JobExpectationsMap: any = JobExpectations.reduce((map, j) => {
      const newMap: any = map;
      newMap[j.id] = j.text;
      return newMap;
    }, {});

    return [
      <div
        key="favoriteJobExpectationsContainer"
        className={s.favoriteJobExpectationsContainer}
      >
        <OffersHeader
          backUrl={`${ROUTE_PREFIX}/orientation/offers/${selectedBundesland}/modules/job-expectations`}
          bundesland={selectedBundesland}
          setSelectedBundesland={setSelectedBundesland}
          refetchSelectedBundesland={refetchSelectedBundesland}
        />
        <ContentBody>
          <div
            className={s.favoriteJobExpectationsContent}
            key="favoriteJobExpectationsContent"
          >
            <div className={s.headerSection}>
              <h1>MEINE BERUFSERWARTUNGEN</h1>
              <h2>Markiere deine 5 wichtigsten Berufserwartungen.</h2>
            </div>
            <div className={s.favoriteJobExpectationLines}>
              {sortedJobExpectations.map((j) => (
                <FavoriteJobExpectationLine
                  key={j.id}
                  id={j.id}
                  text={JobExpectationsMap[j.id]}
                  value={j.value}
                  isFavorite={moduleData.favorites[j.id] === true}
                  onSetFavorite={this.onSetFavorite}
                />
              ))}
            </div>
          </div>
        </ContentBody>
        <Footer
          loggedMode={loggedMode}
          meData={meData}
          finishedTest={finishedTest}
          forceShowExport
          exportUrl={`${ROUTE_PREFIX}/orientation/offers/${selectedBundesland}/export`}
          withEPortfolio
          refetchAkooeData={this.props.refetchAkooeData}
        />
      </div>,
      <MaximumFavoriteCountModal
        key="maximumFavoriteCountModal"
        showModal={this.state.showMaximumFavoriteCountModal}
        hideModal={() => {
          this.setState({ showMaximumFavoriteCountModal: false });
        }}
      />,
      this.state.loading && <Loading key="loading" />,
    ];
  }
}

export default compose(
  withAuthentication,
  withInterestTestResults,
  withBundesland,
  withAkooeData,
  withRouter,
)(FavoriteJobExpectationsScreen);
