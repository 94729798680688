/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import Clipboard from 'clipboard';
import { FaClipboard } from 'react-icons/fa';

import s from './ConfirmRestartModal.module.scss';
import { ROUTE_PREFIX } from '../../constants/router';

interface ShareLinkModalProps {
  showModal: boolean;
  shareId: string;
  suffix: string;
  closeModal: () => void;
}

class ShareLinkModal extends React.Component<ShareLinkModalProps> {
  // eslint-disable-next-line react/state-in-constructor
  state: any;

  shareLink: any = null;

  copyButton: any = null;

  clipboard: any = null;

  constructor(props: any) {
    super(props);

    this.state = {
      copied: false,
    };

    this.shareLink = React.createRef();
    this.copyButton = React.createRef();
  }

  componentDidUpdate(prevProps: any) {
    const { shareLink, copyButton } = this;

    if (!prevProps.showModal && shareLink.current && copyButton.current) {
      this.clipboard = new Clipboard(copyButton.current, {
        target: () => shareLink.current,
      });
      this.clipboard.on('success', () => {
        this.setState({ copied: true });
      });
    } else if (this.clipboard) {
      this.clipboard.destroy();
    }
  }

  render() {
    const { shareId, suffix } = this.props;
    const { copied } = this.state;
    const { location } = window;

    return (
      <div>
        <Modal
          centered
          show={this.props.showModal}
          backdrop="static"
          dialogClassName={s.restartModal}
        >
          <Modal.Header className={s.header}>
            <Modal.Title>
              <h3>
                <strong>Share-Link</strong>
              </h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className={s.body}>
            <input
              className={`form-control ${s.shareLink}`}
              ref={this.shareLink}
              value={`${location.protocol}//${location.hostname}${ROUTE_PREFIX}/share/${shareId}/${suffix}`}
            />
            <button
              type="button"
              ref={this.copyButton}
              className={`btn btn-primary ${s.copyButton}`}
            >
              <FaClipboard />
            </button>
            <div className={s.shareInfo}>
              {copied && 'Dein Share-Link wurde in die Zwischenablage kopiert.'}
            </div>
          </Modal.Body>
          <Modal.Footer className={s.samePaddingFooter}>
            <Button
              variant="btn btn-secondary"
              onClick={() => {
                this.setState({ copied: false });
                this.props.closeModal();
              }}
            >
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default ShareLinkModal;
