import React from 'react';
import { NavigateFunction } from 'react-router-dom';
import { flowRight as compose } from 'lodash';

import s from './Login.module.scss';
import startScreenStyle from '../../StartScreen/StartScreen.module.scss';
import AppContext from '../../../context';
import { ROUTE_PREFIX } from '../../../constants/router';
import LoginForm from './LoginForm';
import { withLogin } from '../withLogin';
import withAuthentication, { LOGGED_MODE_USER } from '../AuthenticationWrapper';
import Loading from '../../Loading';
import { withRouter } from '../../../util/withRouter';
import getAssetLink from '../../../util/getAssetLink';
import { withSEO } from '../../SEO/withSEO';

export const submitLoginForm = async ({
  context,
  loginFunction,
  username,
  password,
}: any) => {
  if (!username || username === '' || !password || password === '') {
    context.setState({
      loading: false,
      errors: ['Bitte alle Felder ausfüllen.'],
    });
    return false;
  }

  context.setState({
    loading: true,
    errors: [],
  });

  const result = await loginFunction(username, password);

  if (!result) {
    context.setState({
      loading: false,
      errors: [
        'Kein Benutzer unter dem angegebenen Namen und Passwort gefunden.',
      ],
    });
    return false;
  }

  return true;
};

interface LoginProps {
  loggedMode?: string;
  // eslint-disable-next-line no-unused-vars
  login: (name: string, password: string) => Promise<boolean>;
  navigate: NavigateFunction;
}

class Login extends React.Component<LoginProps> {
  static contextType = AppContext;

  // eslint-disable-next-line react/state-in-constructor
  state: any;

  static defaultProps = {
    loggedMode: null,
  };

  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
      errors: [],
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onCancel() {
    const { navigate } = this.props;

    this.setState({ errors: [] }, () => {
      navigate({ pathname: `${ROUTE_PREFIX}/` });
    });
  }

  async onSubmit({ username, password }: any) {
    const { navigate, login } = this.props;
    const success = await submitLoginForm({
      context: this,
      loginFunction: login,
      username,
      password,
    });

    if (success) {
      this.setState(
        {
          errors: [],
        },
        () => {
          navigate({ pathname: `${ROUTE_PREFIX}/` });
        },
      );
    }
  }

  render() {
    const { loggedMode, navigate } = this.props;

    if (loggedMode === LOGGED_MODE_USER) {
      navigate({ pathname: `${ROUTE_PREFIX}/` });
      return <Loading />;
    }

    return [
      <div
        className={startScreenStyle.startScreenContainer}
        key="loginScreenContainer"
        data-nosnippet
      >
        <img
          className={startScreenStyle.akLogo}
          src={getAssetLink('AKLogo.png')}
          alt=""
        />
        <div className={startScreenStyle.textContainer}>
          Deine Interessen.
          <br />
          Deine Entscheidung.
          <br />
          Dein Weg.
        </div>
        <div className={s.loginContainer}>
          <LoginForm
            onSubmit={this.onSubmit}
            onCancel={this.onCancel}
            errors={this.state.errors}
          />
        </div>
      </div>,
      this.state.loading && <Loading key="loading" />,
    ];
  }
}

export default compose(
  withSEO({
    title: 'Jopsy | Login',
    description: 'Mit Account anmelden',
  }),
  withAuthentication,
  withLogin,
  withRouter,
)(Login);
