/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { flowRight as compose } from 'lodash';
import { NavigateFunction } from 'react-router-dom';

import { ROUTE_PREFIX } from '../../constants/router';
import withAuthentication from '../Auth/AuthenticationWrapper';
import withInterestTestResults from '../HomeScreen/DataWrappers/InterestTestResultsWrapper';
import testIncompletionRequired from '../HomeScreen/DataWrappers/TestIncompletionRequiredWrapper';
import withBundesland from '../HomeScreen/DataWrappers/BundeslandWrapper';
import ModeSelection from '../ModeSelection';
import { withRouter } from '../../util/withRouter';
import { withSEO } from '../SEO/withSEO';

interface ModeSelectionForOffersProps {
  // eslint-disable-next-line no-unused-vars
  setSelectedBundesland: (bundesland: string | null) => Promise<void>;
  navigate: NavigateFunction;
}

class ModeSelectionForOffers extends React.Component<ModeSelectionForOffersProps> {
  render() {
    return (
      <ModeSelection
        onYoungClicked={async () => {
          await this.props.setSelectedBundesland(null);
          this.props.navigate(
            `${ROUTE_PREFIX}/orientation/bundesland-selection`,
          );
        }}
        onOldClicked={async () => {
          await this.props.setSelectedBundesland(null);
          this.props.navigate(
            `${ROUTE_PREFIX}/reorientation/bundesland-selection`,
          );
        }}
      />
    );
  }
}

export default compose(
  withSEO({
    title: 'Jopsy | Angebote',
    description: 'Angebote für Jugendliche und Erwachsene',
  }),
  withAuthentication,
  withInterestTestResults,
  testIncompletionRequired,
  withBundesland,
  withRouter,
)(ModeSelectionForOffers);
