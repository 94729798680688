import React from 'react';
import { flowRight as compose } from 'lodash';

import s from './Faq.module.scss';
import withAuthentication from '../Auth/AuthenticationWrapper';
import withInterestTestResults, {
  itestResultsType,
} from '../HomeScreen/DataWrappers/InterestTestResultsWrapper';
import { checkIfTestResultsAreInvalid } from '../HomeScreen/DataWrappers/TestCompletionRequiredWrapper';
import ContentBody from '../ContentBody';
import Header from '../Header';
import Footer from '../Footer';
// import ResetIconRed from '../../../assets/ResetIcon_red.png';
import withAccessTracker from '../AccessTracker';
import getAssetLink from '../../util/getAssetLink';
import { withSEO } from '../SEO/withSEO';

interface FaqProps {
  loggedMode: string;
  meData?: any;
  userItestResults?: itestResultsType;
}

class Faq extends React.Component<FaqProps> {
  static defaultProps = {
    meData: null,
    userItestResults: null,
  };

  constructor(props: any) {
    super(props);
    this.renderTexts = this.renderTexts.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  renderTexts() {
    return (
      <div>
        <div className={s.section}>
          <h3>Sind meine Angaben anonym?</h3>
          <p>
            Wenn du dich bei Jopsy registrierst, werden deine Daten auf dem
            Server gespeichert. Dies umfasst deine Bewertungen der
            Interessensbilder, das Ergebnis der Testauswertung, sowie alle Daten
            von weiterführenden Angeboten nach dem Test. Wenn du dich nicht
            registrierst und den Test als Gast abschließt, werden auch keine
            Daten am Server gespeichert. Deine Anmeldedaten oder Gastdaten sind
            nur im aktuellen Browserfenster gespeichert. Schließt du das
            Browserfenster, wirst du automatisch abgemeldet oder deine Gastdaten
            werden gelöscht. Es werden keine deiner Daten an Dritte
            weitergegeben. Jopsy ist absolut anonym und du entscheidest, wer
            deine Ergebnisse sehen darf.
          </p>
        </div>
        <div className={s.section}>
          <h3>Bleiben meine Ergebnisse gespeichert?</h3>
          <p>
            Wenn du alle Bilder von Jopsy bewertet hast und du dein Ergebnis
            bekommen hast, so bleibt dieses Ergebnis am Server gespeichert,
            falls du dich vorher als Benutzer registriert hast. Wenn du den Test
            stattdessen als Gast gemacht hast, bleiben deine Ergebnisse nur
            lokal im aktuellen Browserfenster gespeichert. Du kannst deine
            Gastdaten auch übernehmen und dich mit diesen als neuer Benutzer
            registieren. Wenn du dich bei Jopsy erneut anmeldest, wird dir
            sofort dein Jopsy-Ergebnis (deine Top 3 Interessen und die Favoriten
            deiner Berufe) angezeigt.
          </p>
        </div>
        <div className={s.section}>
          <h3>Kann ich den Test wiederholen?</h3>
          <p>
            Wenn du den Button „Neu starten“ (&nbsp;
            <img
              style={{ width: '17px', marginBottom: '-1.5px' }}
              src={getAssetLink('ResetIcon_red.png')}
              alt=""
            />
            &nbsp;) drückst, kannst du Jopsy noch einmal neu machen. Es werden
            aber deine vorherigen Ergebnisse gelöscht.
          </p>
        </div>
        <div className={s.section}>
          <h3>
            So viele Berufsvorschläge! Was tun, wenn ich mich nicht entscheiden
            kann?
          </h3>
          <p>
            Versuche noch mehr über deine Interessen, Stärken und Werte
            herauszufinden. Probiere viel aus, mach neue Erfahrungen, so lernst
            du dich noch besser kennen. Recherchiere im Internet und sprich mit
            Personen, die den Beruf ausüben. Hole dir auch noch eine
            Fremdeinschätzung von außen ein, vielleicht fällt es dir dann
            leichter deine Favoriten in der Berufsliste bestimmen zu können.
          </p>
        </div>
        <div className={s.section}>
          <h3>Keiner der vorgeschlagenen Berufe interessiert mich. Was nun?</h3>
          <p>
            Die vorgeschlagenen Berufe sind Anregungen und es bedeutet nicht,
            dass nur diese Berufe für dich in Frage kommen. Es sind jene Berufe,
            bei denen die Tätigkeiten deinem Interessensprofil am ehesten
            entsprechen. Versuche aus der Jopsy-Berufsliste ein paar wenige
            Berufe als Favoriten zu bestimmen und mehr über diese Berufe
            herauszufinden. Vielleicht weckt das Ausprobieren von
            berufstypischen Tätigkeiten oder Gesprächen mit Personen, die den
            Beruf ausüben, doch dein Interesse für einen Beruf bzw. eine
            Ausbildung.
          </p>
        </div>
        <div className={s.section}>
          <h3>
            Die Profilgenauigkeit ist sehr gering. Was bedeutet das und was kann
            ich tun?
          </h3>
          <p>
            Wenn die Profilgenauigkeit sehr gering ist, so können keine
            zuverlässigen Berufsvorschläge gemacht werden. Falls dieser Fall
            zutrifft, wird das beim Ergebnis angezeigt. Um deine Interessen
            genauer bestimmen zu können, ist es gut vielfältige Erfahrungen
            (Schnuppern, Praktika, Freizeiterfahrungen, etc.) zu sammeln. Wenn
            du etwas tatsächlich ausprobierst, kannst du besser erkennen, ob ein
            Bereich für dich interessant ist oder nicht.
          </p>
        </div>
        <div className={s.section}>
          <h3>
            Ich möchte mehr über den Beruf erfahren. Wo bekomme ich nähere
            Infos?
          </h3>
          <p>
            Nutze am besten die Berufsdatenbank des Arbeitsmarktservice (AMS),
            du findest sie online unter&nbsp;
            <a href="http://www.berufslexikon.at">www.berufslexikon.at</a>.
          </p>
        </div>
        <div className={s.section}>
          <h3>
            Ich habe Jopsy noch einmal gemacht und nun ganz andere Ergebnisse,
            was bedeutet das?
          </h3>
          <p>
            Dies kann passieren, wenn deine Interessen noch nicht eindeutig
            ausgeprägt sind oder wenn du das Tool das letzte Mal vor längerer
            Zeit gemacht hast. Dann kann es auch sein, dass sich deine
            Interessen geändert haben, denn unsere alltäglichen Erfahrungen
            können natürlich auch unsere Interessen beeinflussen und verändern.
          </p>
        </div>
        <div className={s.section}>
          <h3>
            Kann ich auch einen Beruf wählen/anstreben, der nicht meinen
            Interessen entspricht?
          </h3>
          <p>
            Natürlich kannst du auch einen Beruf wählen, der nicht deinen
            momentanen Interessen entspricht. Es ist sehr wahrscheinlich, dass
            sich deine Interessen im Laufe der Zeit noch ändern werden. Es hat
            sich jedoch gezeigt, dass Menschen eine höhere Motivation haben,
            Dinge zu lernen, die den eigenen Interessen entsprechen. Wenn du ein
            Studium oder eine Berufsausbildung wählst, die deinen Interessen
            entsprechen, wird es leichter für dich sein, dabei erfolgreich zu
            sein. Zur Berufswahl gehören nicht nur die Interessen, sondern auch
            deine Talente, Stärken und Begabungen. Zusätzlich spielen auch
            andere Aspekte wie die zukünftige Bezahlung, Nähe der
            Ausbildungsstätte und die Arbeitszeiten eine Rolle. Manche
            Interessen kannst du wahrscheinlich nicht in deinem Berufsleben
            verwirklichen. Diese kannst du dann aber natürlich in deiner
            Freizeit ausüben.
          </p>
        </div>
        <div className={s.section}>
          <h3>
            Ich finde, dass die Ergebnisse nicht zu mir passen. Was kann ich
            tun?
          </h3>
          <p>
            Das Interessenstool von Jopsy und die angezeigten Ergebnisse
            basieren auf einem seit Jahrzehnten wissenschaftlich erprobtem
            Modell für die Berufs- und Laufbahnberatung (RIASEC). Dennoch kann
            es sein, dass du dich nicht mit dem Ergebnis identifizieren kannst.
            Dies kann unterschiedliche Gründe haben. Besprich auf jeden Fall die
            Ergebnisse mit verschiedenen Personen und hole dir Rückmeldung von
            außen ein. Eine Fremdeinschätzung vervollständigt das Bild.
          </p>
        </div>
      </div>
    );
  }

  render() {
    const { loggedMode, meData, userItestResults } = this.props;

    const testResultsInvalid = checkIfTestResultsAreInvalid(userItestResults);
    const finishedTest = !testResultsInvalid;

    return (
      <div className={s.faqContainer}>
        <Header />
        <ContentBody>
          <div className={s.faqHeader}>
            <h1>FAQ</h1>
          </div>
          {this.renderTexts()}
        </ContentBody>
        <Footer
          loggedMode={loggedMode}
          meData={meData}
          finishedTest={finishedTest}
        />
      </div>
    );
  }
}

export default compose(
  withSEO({
    title: 'Jopsy | FAQ',
    description: 'Oft gestellte Fragen',
  }),
  withAccessTracker('FAQ'),
  withAuthentication,
  withInterestTestResults,
)(Faq);
