import React from 'react';
import ReactPlayer from 'react-player';
import { flowRight as compose } from 'lodash';
import Cookies from 'js-cookie';
import { FaVolumeUp, FaVolumeMute } from 'react-icons/fa';
import { NavigateFunction } from 'react-router-dom';

import { ROUTE_PREFIX } from '../../constants/router';
import s from '../AKOOE/IntroVideoScreen/IntroVideoScreen.module.scss';
import withAuthentication from '../Auth/AuthenticationWrapper';
import { userInteractionData } from '../Layout';
import { withSEO } from '../SEO/withSEO';

const EXPLANATION_VIDEO_SKIP_BUTTON_TIMER_DURATION = 0;

interface ExplanationVideoScreenProps {
  navigate: NavigateFunction;
}

class ExplanationVideoScreen extends React.Component<ExplanationVideoScreenProps> {
  // eslint-disable-next-line react/state-in-constructor
  state: any;

  skipButtonTimeout: any = null;

  constructor(props: any) {
    super(props);

    this.state = {
      muted: !userInteractionData.siteInteraction,
      showSkipPlayer: false,
    };

    this.onToggleMute = this.onToggleMute.bind(this);
    this.onEnded = this.onEnded.bind(this);
  }

  componentDidMount() {
    this.skipButtonTimeout = setTimeout(() => {
      this.setState({ showSkipPlayer: true });
    }, EXPLANATION_VIDEO_SKIP_BUTTON_TIMER_DURATION);
  }

  componentWillUnmount() {
    if (this.skipButtonTimeout) {
      clearTimeout(this.skipButtonTimeout);
    }
  }

  onToggleMute() {
    const { muted } = this.state;

    this.setState({
      muted: !muted,
    });
  }

  onEnded() {
    const { navigate } = this.props;

    Cookies.set('watchedExplanationVideo', JSON.stringify({ watched: true }), {
      expires: 365,
    });
    navigate(`${ROUTE_PREFIX}/`);
  }

  render() {
    return (
      <div className={s.introVideoContainer} data-nosnippet>
        <div className={s.player}>
          <ReactPlayer
            playing
            muted={this.state.muted}
            url="/appimages/explanation-videos/jopsy_erklaervideo_final_v3.mp4"
            width="100%"
            height="100%"
            onEnded={this.onEnded}
          />
        </div>
        {this.state.showSkipPlayer === true ? (
          <div className={s.buttonsContainer}>
            <button
              type="button"
              className={s.buttonMute}
              onClick={this.onToggleMute}
            >
              {this.state.muted ? <FaVolumeMute /> : <FaVolumeUp />}
            </button>
            <button type="button" onClick={this.onEnded}>
              Überspringen
            </button>
          </div>
        ) : null}
      </div>
    );
  }
}

export default compose(
  withSEO({
    title: 'Jopsy | Erklärvideo',
    description: 'Video zur Verwendung von Jopsy',
  }),
  withAuthentication,
)(ExplanationVideoScreen);
