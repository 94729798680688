import React from 'react';
import { Modal, Button } from 'react-bootstrap';

interface SelectJobOrSchoolModalProps {
  schoolOrJob: '' | 'job' | 'school';
  showModal: boolean;
  onClose: () => void;
}

export default class SelectJobOrSchoolModal extends React.Component<SelectJobOrSchoolModalProps> {
  render() {
    const { schoolOrJob, showModal, onClose } = this.props;

    let headerText = '';
    let contentText = '';

    if (schoolOrJob === 'job') {
      headerText = 'Beruf auswählen';
      contentText =
        'Bitte gib zuerst einen Beruf an, bevor du dich den Fragen widmest.';
    } else if (schoolOrJob === 'school') {
      headerText = 'Schule auswählen';
      contentText =
        'Bitte gib zuerst eine Schule an, bevor du dich den Fragen widmest.';
    }
    return (
      <Modal show={showModal} onHide={onClose}>
        <Modal.Header>
          <Modal.Title>
            <h3>
              <strong>{headerText}</strong>
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ display: 'flex' }}>
          <div>{contentText}</div>
          <div
            style={{
              marginTop: '50px',
              alignItems: 'flex-end',
            }}
          >
            <Button onClick={onClose}>OK</Button>
          </div>
        </Modal.Body>
        <Modal.Footer />
      </Modal>
    );
  }
}
