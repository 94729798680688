import React from 'react';
import getAssetLink from '../../../../util/getAssetLink';

import s from './StrengthCard.module.scss';

interface StrengthCardProps {
  strengthCardData: any;
  customImage?: string;
  // eslint-disable-next-line no-unused-vars
  onResize?: (width: any) => void;
  isFavorite?: boolean;
  showHeart?: boolean;
  // eslint-disable-next-line no-unused-vars
  onToggleFavorite?: (id: string) => void;
  // eslint-disable-next-line no-unused-vars
  onDelete?: (id: string) => void;
  // eslint-disable-next-line no-unused-vars
  onImageUploadClicked?: (id: string) => void;
}

class StrengthCard extends React.Component<StrengthCardProps> {
  // eslint-disable-next-line react/state-in-constructor
  state: any;

  cardRef: any;

  static defaultProps = {
    customImage: null,
    onResize: () => {},
    isFavorite: false,
    showHeart: false,
    onToggleFavorite: () => {},
    onDelete: () => {},
    onImageUploadClicked: () => {},
  };

  constructor(props: any) {
    super(props);

    this.state = {
      headerFontSize: 0,
      textFontSize: 0,
    };

    this.cardRef = React.createRef();
    this.onResize = this.onResize.bind(this);
  }

  componentDidMount() {
    this.onResize();
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize() {
    if (this.cardRef && this.cardRef.current) {
      const width = this.cardRef.current.clientWidth;
      this.setState(
        {
          headerFontSize: width * 0.07,
          textFontSize: width * 0.057,
        },
        () => {
          if (this.props.onResize) {
            this.props.onResize(width);
          }
        },
      );
    }
  }

  render() {
    const {
      strengthCardData,
      customImage,
      isFavorite,
      showHeart,
      onToggleFavorite,
      onDelete,
      onImageUploadClicked,
    } = this.props;
    const { headerFontSize, textFontSize } = this.state;

    const isCustom = strengthCardData.id.startsWith('custom');

    let headerClassName;

    if (isCustom && showHeart) {
      headerClassName = s.smallerHeader;
    } else if (isCustom || showHeart) {
      headerClassName = s.smallHeader;
    } else {
      headerClassName = s.header;
    }

    return (
      <div ref={this.cardRef} className={s.card}>
        <div className={s.cardContent}>
          <div
            className={s.headerContainer}
            style={{ fontSize: `${headerFontSize}px` }}
          >
            <div className={headerClassName}>{strengthCardData.title}</div>
            {isCustom && (
              <div
                className={`${s.crossContainer} ${
                  !showHeart ? s.rightMostIcon : ''
                }`}
              >
                <button
                  type="button"
                  onClick={() => {
                    if (onDelete) {
                      onDelete(strengthCardData.id);
                    }
                  }}
                >
                  <img src={getAssetLink('CrossIcon_white.png')} alt="" />
                </button>
              </div>
            )}
            {showHeart && (
              <div className={`${s.heartContainer} ${s.rightMostIcon}`}>
                <button
                  type="button"
                  onClick={() => {
                    if (onToggleFavorite) {
                      onToggleFavorite(strengthCardData.id);
                    }
                  }}
                >
                  <img
                    src={
                      isFavorite
                        ? getAssetLink('heart_selected_white.png')
                        : getAssetLink('heart_white.png')
                    }
                    alt=""
                  />
                </button>
              </div>
            )}
          </div>
          <div className={s.imageContainer}>
            <img
              src={customImage || strengthCardData.image}
              alt=""
              draggable="false"
            />
            <div className={s.imageOverlay} />
            <button
              type="button"
              className={s.cameraButton}
              onClick={() => {
                if (onImageUploadClicked) {
                  onImageUploadClicked(strengthCardData.id);
                }
              }}
            >
              <img src={getAssetLink('Camera.png')} alt="" />
            </button>
          </div>
          <div
            className={s.descriptionContainer}
            style={{ fontSize: `${textFontSize}px` }}
          >
            {strengthCardData.description}
          </div>
        </div>
      </div>
    );
  }
}

export default StrengthCard;
