import React from 'react';
import ReactPlayer from 'react-player';
import { flowRight as compose } from 'lodash';
import { NavigateFunction } from 'react-router-dom';

import { ROUTE_PREFIX } from '../../../constants/router';
import s from './IntroVideoScreen.module.scss';
import withAuthentication from '../../Auth/AuthenticationWrapper';
import withBundesland from '../../HomeScreen/DataWrappers/BundeslandWrapper';
import withAkooeData, {
  akooeDataType,
} from '../../HomeScreen/DataWrappers/AkooeDataWrapper';

const SKIP_BUTTON_TIMER_DURATION = 15000;

interface IntroVideoScreenProps {
  userAkooeData: akooeDataType;
  // eslint-disable-next-line no-unused-vars
  setWatchedIntro: (watched: boolean) => Promise<void>;
  refetchAkooeData: () => Promise<void>;
  navigate: NavigateFunction;
}

class IntroVideoScreen extends React.Component<IntroVideoScreenProps> {
  // eslint-disable-next-line react/state-in-constructor
  state: any;

  skipButtonTimeout: any = null;

  constructor(props: any) {
    super(props);

    this.state = {
      showSkipPlayer: false,
    };

    this.onEnded = this.onEnded.bind(this);
  }

  componentDidMount() {
    const { userAkooeData } = this.props;

    if (userAkooeData.watchedIntro) {
      this.setState({
        showSkipPlayer: true,
      });
    } else {
      this.skipButtonTimeout = setTimeout(() => {
        this.setState({ showSkipPlayer: true });
      }, SKIP_BUTTON_TIMER_DURATION);
    }
  }

  componentWillUnmount() {
    if (this.skipButtonTimeout) {
      clearTimeout(this.skipButtonTimeout);
    }
  }

  async onEnded() {
    const { userAkooeData, setWatchedIntro, refetchAkooeData, navigate } =
      this.props;

    if (!userAkooeData.watchedIntro) {
      await setWatchedIntro(true);
      await refetchAkooeData();
    }

    navigate(`${ROUTE_PREFIX}/orientation/offers/oberösterreich`);
  }

  render() {
    return (
      <div className={s.introVideoContainer}>
        <div className={s.player}>
          <ReactPlayer
            playing
            muted
            url="/appimages/ak-oberoesterreich/v-1.1/intro_1080p.mp4"
            width="100%"
            height="100%"
            onEnded={this.onEnded}
          />
        </div>
        {this.state.showSkipPlayer === true ? (
          <div className={s.buttonsContainer}>
            <button type="button" onClick={this.onEnded}>
              Überspringen
            </button>
          </div>
        ) : null}
      </div>
    );
  }
}

export default compose(
  withAuthentication,
  withBundesland,
  withAkooeData,
)(IntroVideoScreen);
