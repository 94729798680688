/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { E_TYPE } from '../../constants/professions';

import s from './FilterMenu.module.scss';

interface FilterMenuProps {
  // eslint-disable-next-line no-unused-vars
  applyFilter: (data: any) => void;
  filter?: string;
}

class FilterMenu extends React.Component<FilterMenuProps> {
  // eslint-disable-next-line react/state-in-constructor
  state: any;

  static defaultProps = {
    filter: 'ALLE',
  };

  constructor(props: any) {
    super(props);
    this.state = { activeFilter: this.props.filter || 'ALLE' };
    this.renderMenuItems = this.renderMenuItems.bind(this);
  }

  renderMenuItems() {
    return Object.values(E_TYPE).map((key: any) => (
      <p
        key={`menuItem ${key}`}
        className={
          this.state.activeFilter === key
            ? `${s.menuItem} ${s.active}`
            : s.menuItem
        }
        onClick={() => {
          this.setState({ activeFilter: key });
          this.props.applyFilter({ filter: key });
        }}
      >
        {key}
      </p>
    ));
  }

  render() {
    return (
      <div className={s.menuContainer}>
        <div className={s.menuBody}>{this.renderMenuItems()}</div>
        <div>
          <div className={s.triangle} />
        </div>
      </div>
    );
  }
}

export default FilterMenu;
