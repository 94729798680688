import React from 'react';

import s from './ContentBody.module.scss';

interface ContentBodyProps {
  children?: React.ReactNode;
}

class ContentBody extends React.Component<ContentBodyProps> {
  static defaultProps = {
    children: null,
  };

  render() {
    return <div className={s.contentBodyContainer}>{this.props.children}</div>;
  }
}

export default ContentBody;
