import React from 'react';
import { NavigateFunction } from 'react-router-dom';

import { ROUTE_PREFIX } from '../../../constants/router';
import { withRouter } from '../../../util/withRouter';
import Loading from '../../Loading';
import { itestResultsType } from './InterestTestResultsWrapper';

export const checkIfTestResultsAreInvalid = (
  userItestResults?: itestResultsType,
): boolean =>
  !userItestResults ||
  !userItestResults.interestPercentages ||
  userItestResults.interestPercentages.R == null ||
  userItestResults.interestPercentages.I == null ||
  userItestResults.interestPercentages.A == null ||
  userItestResults.interestPercentages.S == null ||
  userItestResults.interestPercentages.E == null ||
  userItestResults.interestPercentages.C == null ||
  !userItestResults.interestStandardValues ||
  userItestResults.interestStandardValues.R == null ||
  userItestResults.interestStandardValues.I == null ||
  userItestResults.interestStandardValues.A == null ||
  userItestResults.interestStandardValues.S == null ||
  userItestResults.interestStandardValues.E == null ||
  userItestResults.interestStandardValues.C == null ||
  !userItestResults.diffScore ||
  userItestResults.diffScore.percentage == null ||
  userItestResults.diffScore.raw == null ||
  userItestResults.diffScore.standard == null;

interface TestCompletionRequiredProps {
  userItestResults?: itestResultsType;
  navigate: NavigateFunction;
}

const TestCompletionRequiredWrapper = (WrappedComponent: any) => {
  class TestCompletionRequired extends React.Component<TestCompletionRequiredProps> {
    static defaultProps = {
      userItestResults: null,
    };

    render() {
      const { userItestResults, navigate } = this.props;

      const testResultsInvalid = checkIfTestResultsAreInvalid(userItestResults);

      if (testResultsInvalid) {
        navigate(`${ROUTE_PREFIX}/`);
        return <Loading />;
      }

      return <WrappedComponent {...this.props} />;
    }
  }

  return withRouter(TestCompletionRequired);
};

export default TestCompletionRequiredWrapper;
