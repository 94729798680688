import React from 'react';
import { Link } from 'react-router-dom';
import { flowRight as compose } from 'lodash';

import s from './DesiredJobOrSchoolScreen.module.scss';
import { checkIfTestResultsAreInvalid } from '../../HomeScreen/DataWrappers/TestCompletionRequiredWrapper';
import { ROUTE_PREFIX } from '../../../constants/router';
import withInterestTestResults, {
  itestResultsType,
} from '../../HomeScreen/DataWrappers/InterestTestResultsWrapper';
import withAuthentication from '../../Auth/AuthenticationWrapper';
import withBundesland from '../../HomeScreen/DataWrappers/BundeslandWrapper';
import withAkooeData, {
  akooeDataType,
} from '../../HomeScreen/DataWrappers/AkooeDataWrapper';
import ContentBody from '../../ContentBody/index';
import Footer from '../../Footer';
import OffersHeader from '../../OffersScreen/OffersHeader';

import {
  NOT_EDITED,
  EDITED,
  COMPLETED,
  getJobStatus,
  getSchoolStatus,
} from './statusGetters';
import getAssetLink from '../../../util/getAssetLink';

interface DesiredJobOrSchoolScreenProps {
  loggedMode: string;
  meData?: any;
  userItestResults?: itestResultsType;
  userAkooeData: akooeDataType;
  refetchSelectedBundesland: () => Promise<void>;
  selectedBundesland: string;
  // eslint-disable-next-line no-unused-vars
  setSelectedBundesland: (bundesland: string) => Promise<void>;
  refetchAkooeData: () => Promise<void>;
}

class DesiredJobOrSchoolScreen extends React.Component<DesiredJobOrSchoolScreenProps> {
  static defaultProps = {
    meData: null,
    userItestResults: null,
  };

  static getIconByStatus(status: string) {
    switch (status) {
      case NOT_EDITED:
        return getAssetLink('edit_light.png');
      case EDITED:
        return getAssetLink('edit.png');
      case COMPLETED:
        return getAssetLink('heart_selected.png');
      default:
        return getAssetLink('edit_light.png');
    }
  }

  render() {
    const {
      loggedMode,
      meData,
      refetchSelectedBundesland,
      selectedBundesland,
      setSelectedBundesland,
      userItestResults,
      userAkooeData,
    } = this.props;
    const moduleData = userAkooeData.desiredJobOrSchoolModuleData
      ? JSON.parse(userAkooeData.desiredJobOrSchoolModuleData)
      : {};
    const schoolStatus = getSchoolStatus(moduleData);
    const jobStatus = getJobStatus(moduleData);
    const testResultsInvalid = checkIfTestResultsAreInvalid(userItestResults);
    const finishedTest = !testResultsInvalid;
    return (
      <div className={s.overviewContainer}>
        <OffersHeader
          bundesland={selectedBundesland}
          setSelectedBundesland={setSelectedBundesland}
          refetchSelectedBundesland={refetchSelectedBundesland}
          backUrl={`${ROUTE_PREFIX}/orientation/offers/${selectedBundesland}`}
        />
        <ContentBody>
          <div className={s.headerSection}>Wähle aus:</div>
          <div key="overviewContent" className={s.content}>
            <Link
              className={s.link}
              to={`${ROUTE_PREFIX}/orientation/offers/${selectedBundesland}/modules/desired-job-or-school/school`}
            >
              <div className={s.listItem}>
                <div className={s.leftPart}>MEINE WUNSCHSCHULE</div>
                <div className={s.rightPart}>
                  <img
                    className={s.icon}
                    src={DesiredJobOrSchoolScreen.getIconByStatus(schoolStatus)}
                    alt=""
                  />
                </div>
              </div>
            </Link>
            <Link
              className={s.link}
              to={`${ROUTE_PREFIX}/orientation/offers/${selectedBundesland}/modules/desired-job-or-school/job`}
            >
              <div className={s.listItem}>
                <div className={s.leftPart}>MEIN WUNSCHBERUF</div>
                <div className={s.rightPart}>
                  <img
                    className={s.icon}
                    src={DesiredJobOrSchoolScreen.getIconByStatus(jobStatus)}
                    alt=""
                  />
                </div>
              </div>
            </Link>
          </div>
        </ContentBody>
        <Footer
          loggedMode={loggedMode}
          meData={meData}
          finishedTest={finishedTest}
          forceShowExport
          exportUrl={`${ROUTE_PREFIX}/orientation/offers/${selectedBundesland}/export`}
          withEPortfolio
          refetchAkooeData={this.props.refetchAkooeData}
        />
      </div>
    );
  }
}

export default compose(
  withAuthentication,
  withInterestTestResults,
  withBundesland,
  withAkooeData,
)(DesiredJobOrSchoolScreen);
