import React from 'react';
import ReactDOM from 'react-dom/client';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import gql from 'graphql-tag';
import { Provider as ReduxProvider } from 'react-redux';

import AppContext from './context';
import { App } from './components/App';
import createApolloClient from './core/createApolloClient';
import configureStore from './store/configureStore';
import { ROUTE_PREFIX } from './constants/router';
import ScrollToTop from './components/ScrollToTop';

(window as any).App = { state: {} };

const apolloClient = createApolloClient();

const store = configureStore((window as any).App.state, {
  apolloClient,
  // history,
});

const context = {
  client: apolloClient,
  store,
  allowItestResultPosts: false,
};

const allowPostItestResultsQuery = gql`
  query allowPostItestResults($token: String!) {
    allowPostItestResults(token: $token)
  }
`;

// listen for messages that verify message posting
const onMessage = async (event: any) => {
  const eventData = event.data;

  if (eventData.type === 'logout') {
    sessionStorage.removeItem('id_token');
    sessionStorage.removeItem('guest_data');
    // eslint-disable-next-line no-restricted-globals
    location.href = `${ROUTE_PREFIX}/`;
  }

  if (eventData.type === 'verifyPostItestResultsToken') {
    const response = await apolloClient.query({
      query: allowPostItestResultsQuery,
      variables: {
        token: eventData.token,
      },
      fetchPolicy: 'network-only',
    });

    if (
      response &&
      response.data &&
      response.data.allowPostItestResults === true
    ) {
      context.allowItestResultPosts = true;
    }
  }
};

if (window.addEventListener) {
  window.addEventListener('message', onMessage, false);
} else if ((window as any).attachEvent) {
  (window as any).attachEvent('onmessage', onMessage, false);
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <HelmetProvider>
        <Helmet>
          <title>Jopsy</title>
          <meta
            name="description"
            content="Deine Interessen. Deine Entscheidung. Dein Weg."
          />
        </Helmet>
        <ScrollToTop>
          <ReduxProvider store={store}>
            <AppContext.Provider value={context}>
              <App />
            </AppContext.Provider>
          </ReduxProvider>
        </ScrollToTop>
      </HelmetProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
