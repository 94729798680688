/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import getAssetLink from '../../util/getAssetLink';

import s from './ModeSelection.module.scss';

interface ModeSelectionProps {
  onYoungClicked: () => void;
  onOldClicked: () => void;
}

class ModeSelection extends React.Component<ModeSelectionProps> {
  render() {
    const { onYoungClicked, onOldClicked } = this.props;

    return (
      <div className={s.modeSelectionContainer} data-nosnippet>
        <div className={s.youngModeContainer}>
          <img src={getAssetLink('below18Mode.jpg')} alt="" />
          <div className={s.textContainer}>
            Bist du
            <br />
            unter 18?
          </div>
          <div className={s.buttonContainer}>
            <button type="button" onClick={onYoungClicked}>
              Los geht&apos;s
            </button>
          </div>
        </div>
        <div className={s.spacer} />
        <div className={s.oldModeContainer}>
          <img src={getAssetLink('18plusMode.jpg')} alt="" />
          <div className={s.textContainer}>
            Bist du
            <br />
            über 18?
          </div>
          <div className={s.buttonContainer}>
            <button type="button" onClick={onOldClicked}>
              Los geht&apos;s
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ModeSelection;
