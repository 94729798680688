import React from 'react';
import gql from 'graphql-tag';

import AppContext, { AppContextType } from '../../../context';
import {
  LOGGED_MODE_USER,
  LOGGED_MODE_GUEST,
} from '../../Auth/AuthenticationWrapper';
import { getGuestLocalStorageData } from '../../Auth/withLogin';
import Loading from '../../Loading';
import { TEST_MODE_YOUNG } from '../../../constants/testModes';

export interface recommendedProfessionType {
  key: string;
  title: string;
  description: string;
  education: Array<string>;
  university: Array<string>;
  college: Array<string>;
  interests: Array<string>;
  codes: Array<string>;
  imageName: string;
}

const recommendedProfessionsQuery = gql`
  query getRecommendedProfessions {
    recommendedProfessions
  }
`;

const recommendedProfessionsGuestQuery = gql`
  query getRecommendedProfessionsForGuest(
    $itestResults: String!
    $testMode: String!
  ) {
    recommendedProfessionsForGuest(
      itestResults: $itestResults
      testMode: $testMode
    )
  }
`;

interface WithSuggestedProfessionsProps {
  loggedMode: string;
}

const SuggestedProfessionsWrapper = (WrappedComponent: any) => {
  class WithSuggestedProfessions extends React.Component<WithSuggestedProfessionsProps> {
    static contextType = AppContext;

    // eslint-disable-next-line react/state-in-constructor
    state: any;

    constructor(props: any) {
      super(props);

      this.state = {
        loading: true,
        recommendedProfessions: null,
      };

      this.fetchRecommendedProfessions = this.fetchRecommendedProfessions.bind(this); // eslint-disable-line prettier/prettier
      this.getDataForUser = this.getDataForUser.bind(this);
      this.getDataForGuest = this.getDataForGuest.bind(this);
    }

    componentDidMount() {
      this.fetchRecommendedProfessions(false);
    }

    // eslint-disable-next-line react/sort-comp
    async fetchRecommendedProfessions(forceNetwork = true) {
      if (this.props.loggedMode === LOGGED_MODE_USER) {
        await this.getDataForUser(forceNetwork);
      } else if (this.props.loggedMode === LOGGED_MODE_GUEST) {
        await this.getDataForGuest(forceNetwork);
      }
    }

    async getDataForUser(forceNetwork: boolean) {
      const response = await (this.context as AppContextType).client.query({
        query: recommendedProfessionsQuery,
        fetchPolicy: forceNetwork ? 'network-only' : 'cache-first',
      });

      if (
        response &&
        response.data &&
        'recommendedProfessions' in response.data
      ) {
        this.setState({
          loading: false,
          recommendedProfessions: response.data.recommendedProfessions,
        });
      }
    }

    async getDataForGuest(forceNetwork: boolean) {
      const guestData = getGuestLocalStorageData();

      const response = await (this.context as AppContextType).client.query({
        query: recommendedProfessionsGuestQuery,
        variables: {
          itestResults: guestData.userItestResults || '{}',
          testMode: guestData.userItestMode || TEST_MODE_YOUNG,
        },
        fetchPolicy: forceNetwork ? 'network-only' : 'cache-first',
      });

      if (
        response &&
        response.data &&
        'recommendedProfessionsForGuest' in response.data
      ) {
        this.setState({
          loading: false,
          recommendedProfessions: response.data.recommendedProfessionsForGuest,
        });
      }
    }

    render() {
      const { loading, recommendedProfessions } = this.state;

      if (loading) return <Loading />;

      return (
        <WrappedComponent
          {...this.props}
          recommendedProfessions={
            recommendedProfessions && recommendedProfessions !== ''
              ? JSON.parse(recommendedProfessions)
              : []
          }
          refetchRecommendedProfessions={this.fetchRecommendedProfessions}
        />
      );
    }
  }

  return WithSuggestedProfessions;
};

export default SuggestedProfessionsWrapper;
