import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';

import { ROUTE_PREFIX } from '../../../constants/router';
import s from './LoginForm.module.scss';
import render from '../../ReduxForm/renderField';

const validate = () => {
  const errors = {};
  return errors;
};

interface LoginType {
  username: string;
  password: string;
}

interface LoginFormProps {
  // eslint-disable-next-line no-unused-vars
  onSubmit: (data: any) => void;
  onCancel: () => void;
  isAdminLogin?: boolean;
  errors?: Array<string>;
}

class LoginForm extends React.Component<
  InjectedFormProps<LoginType, LoginFormProps> & LoginFormProps
> {
  static defaultProps = {
    isAdminLogin: false,
    errors: [],
  };

  render() {
    const { handleSubmit, onCancel, isAdminLogin, errors } = this.props;

    return (
      <div className={s.loginForm}>
        <h1>{isAdminLogin ? 'ADMIN LOGIN' : 'ANMELDEN'}</h1>
        <form className="default-form" onSubmit={handleSubmit}>
          <fieldset>
            <Field
              id="username"
              name="username"
              type="text"
              placeholder="Benutzername"
              component={render.renderInput}
            />
            <Field
              id="password"
              name="password"
              type="password"
              placeholder="Passwort"
              component={render.renderInput}
            />
            {errors != null && errors.length === 0 ? null : (
              <ul style={{ padding: 0, margin: 0, listStyleType: 'none' }}>
                {errors != null &&
                  errors.map((err: any) => (
                    <li key={err} className="validationError">
                      {err}
                    </li>
                  ))}
              </ul>
            )}
            <div>
              <button type="submit" className="btn btn-secondary">
                ANMELDEN
              </button>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={onCancel}
              >
                ABBRECHEN
              </button>
            </div>
          </fieldset>
          {!isAdminLogin && (
            <div className={s.links}>
              <Link to={`${ROUTE_PREFIX}/registration`} className="link">
                REGISTRIEREN
              </Link>
            </div>
          )}
        </form>
      </div>
    );
  }
}

export default reduxForm<LoginType, LoginFormProps>({
  form: 'login',
  validate,
  pure: false,
})(LoginForm);
