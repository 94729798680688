/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { FaCheckSquare, FaSquare } from 'react-icons/fa';
import { flowRight as compose } from 'lodash';
import { NavigateFunction } from 'react-router-dom';

import s from './BundeslandSelection.module.scss';
import { ROUTE_PREFIX } from '../../constants/router';
import withAuthentication from '../Auth/AuthenticationWrapper';
import withBundesland from '../HomeScreen/DataWrappers/BundeslandWrapper';
import FullScreenBubble from '../FullScreenBubble';
import Loading from '../Loading';

interface BundeslandSelectionProps {
  selectedBundesland?: string;
  navigate: NavigateFunction;
  refetchSelectedBundesland?: () => Promise<void>;
  // eslint-disable-next-line no-unused-vars
  setSelectedBundesland?: (bundesland: string) => Promise<void>;
  mode: string;
}

class BundeslandSelection extends React.Component<BundeslandSelectionProps> {
  // eslint-disable-next-line react/state-in-constructor
  state: any;

  static defaultProps = {
    selectedBundesland: null, // use 'wien' or null for testing around the component behaviour
    refetchSelectedBundesland: () => {},
    setSelectedBundesland: () => {},
  };

  constructor(props: any) {
    super(props);

    this.state = {
      bundesland: this.props.selectedBundesland,
      loading: false,
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(event: any) {
    const { value } = event.target;
    this.setState({ bundesland: value.toLowerCase() });
  }

  async setBundeslandAndRefetch() {
    const { mode } = this.props;
    this.setState({ loading: true });

    if (this.props.setSelectedBundesland) {
      await this.props.setSelectedBundesland(this.state.bundesland);
    }
    if (this.props.refetchSelectedBundesland) {
      await this.props.refetchSelectedBundesland();
    }

    this.props.navigate(
      `${ROUTE_PREFIX}/${mode}/offers/${this.state.bundesland}`,
      { replace: true },
    );
  }

  renderSelection(bundesland: string) {
    return (
      <div className={s.selectWrapper}>
        <input
          type="radio"
          name="country"
          id={bundesland}
          value={bundesland}
          checked={this.state.bundesland === bundesland.toLowerCase()}
          onChange={this.onChange}
          hidden
        />
        <label htmlFor={bundesland}>
          {this.state.bundesland === bundesland.toLowerCase() ? (
            <FaCheckSquare />
          ) : (
            <FaSquare />
          )}
          <span>{`Arbeiterkammer ${bundesland}`}</span>
        </label>
      </div>
    );
  }

  render() {
    const { mode } = this.props;

    if (this.props.selectedBundesland != null) {
      this.props.navigate(
        `${ROUTE_PREFIX}/${mode}/offers/${this.props.selectedBundesland}`,
      );
      return <Loading />;
    }
    return [
      <FullScreenBubble key="bubble">
        <div className={s.contentContainer}>
          <h1>
            Um dir Angebote in deiner Umgebung anzuzeigen, wähle bitte dein
            Bundesland aus.
          </h1>
          <div className={s.selectionWrapper}>
            <div className={s.selections}>
              {mode === 'orientation' ? (
                <>
                  {this.renderSelection('Wien')}
                  {this.renderSelection('Oberösterreich')}
                  {this.renderSelection('Kärnten')}
                  {this.renderSelection('Steiermark')}
                  {this.renderSelection('Burgenland')}
                  {/*
                  {this.renderSelection('Salzburg')}
                  {this.renderSelection('Vorarlberg')}
                  {this.renderSelection('Tirol')}
                  */}
                </>
              ) : null}
              {mode === 'reorientation' ? (
                <>
                  {this.renderSelection('Wien')}
                  {this.renderSelection('Oberösterreich')}
                  {this.renderSelection('Salzburg')}
                  {this.renderSelection('Vorarlberg')}
                  {this.renderSelection('Steiermark')}
                  {this.renderSelection('Burgenland')}
                  {/*
                  {this.renderSelection('Kärnten')}
                  {this.renderSelection('Tirol')}
                  */}
                </>
              ) : null}
            </div>
          </div>
          {this.state.bundesland != null ? (
            <div className={s.offers}>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  this.setBundeslandAndRefetch();
                }}
              >
                WEITER
              </button>
            </div>
          ) : null}
        </div>
      </FullScreenBubble>,
      this.state.loading ? <Loading key="loading" /> : null,
    ];
  }
}

export default compose(withAuthentication, withBundesland)(BundeslandSelection);
