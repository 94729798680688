import React from 'react';

import s from './InterestBar.module.scss';

interface InterestBarProps {
  percentage: number;
  maxPercentage: number;
  color: string;
}

class InterestBar extends React.Component<InterestBarProps> {
  render() {
    return (
      <div className={s.interestBarContainer}>
        <div
          style={{
            width: '100%',
            height: `calc((100% - 45px) * ${
              1 - this.props.percentage / this.props.maxPercentage
            })`,
          }}
        />
        <div
          className={s.percentage}
          style={{
            width: '100%',
            height: '45px',
            color: `${this.props.color}`,
          }}
        >
          {`${this.props.percentage}%`}
        </div>
        <div
          style={{
            width: '100%',
            height: `calc((100% - 45px) * ${
              this.props.percentage / this.props.maxPercentage
            })`,
            backgroundColor: `${this.props.color}`,
          }}
        />
      </div>
    );
  }
}

export default InterestBar;
