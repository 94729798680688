// eslint-disable-next-line import/prefer-default-export
export const todoEntries = [
  {
    key: 'todo:get-feedback',
    entry:
      'Ich versende mein Ergebnis aus Jopsy und hole mir zu meinen Ergebnissen Feedback von außen (Eltern, Freunde, Bekannte).',
    link: () => '/export',
  },
  {
    key: 'todo:discuss-feedback',
    entry: 'Ich bespreche das Feedback mit den jeweiligen Personen.',
  },
  {
    key: 'todo:interests-module',
    entry: 'Ich bin offen für neue Interessen/Erfahrungen.',
    link: (bundesland: string) =>
      `orientation/offers/${bundesland}/modules/activities`,
  },
  {
    key: 'todo:strengths-module',
    entry: 'Ich benenne meine Stärken und suche Beispiele für den Einsatz.',
    link: (bundesland: string) =>
      `orientation/offers/${bundesland}/modules/strengths`,
  },
  {
    key: 'todo:expectations-module',
    entry: 'Ich überlege was ich vom Berufsleben erwarte.',
    link: (bundesland: string) =>
      `orientation/offers/${bundesland}/modules/job-expectations`,
  },
  {
    key: 'todo:desiredJobOrSchool-module',
    entry: 'Ich hole Infos zum Wunschberuf/zur Wunschschule ein.',
    link: (bundesland: string) =>
      `orientation/offers/${bundesland}/modules/desired-job-or-school`,
  },
  {
    key: 'todo:interviews',
    entry:
      'Ich führe Interviews mit Personen, die den Beruf ausüben bzw. diese Schule besuchen.',
  },
  {
    key: 'todo:compare',
    entry:
      'Ich vergleiche die Anforderungen des Berufs/der Schule  mit meinen Vorstellungen und Stärken.',
  },
  {
    key: 'todo:goal',
    entry: 'Ich setze mir konkrete Ziele.',
  },
  {
    key: 'todo:todoList-module',
    entry:
      'Ich erstelle eine To-do-Liste mit Zeitplan für meine nächsten Schritte.',
  },
  {
    key: 'todo:planB',
    entry: 'Ich überlege mir einen Plan B (Alternativen).',
  },
];
