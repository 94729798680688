export default [
  {
    id: 'job-expectation-0',
    text: 'Der Beruf muss Spaß machen',
  },
  {
    id: 'job-expectation-1',
    text: 'Etwas tun, das ich sinnvoll ﬁnde',
  },
  {
    id: 'job-expectation-2',
    text: 'Das Gefühl, etwas zu leisten',
  },
  {
    id: 'job-expectation-3',
    text: 'Gute Aufstiegsmöglichkeiten',
  },
  {
    id: 'job-expectation-4',
    text: 'Überdurchschnittlich hohe Bezahlung',
  },
  {
    id: 'job-expectation-5',
    text: 'Ein angenehmes Arbeitsklima',
  },
  {
    id: 'job-expectation-6',
    text: 'Eine anspruchsvolle Tätigkeit, die mich herausfordert',
  },
  {
    id: 'job-expectation-7',
    text: 'Die Möglichkeit, mich fachlich weiterentwickeln zu können',
  },
  {
    id: 'job-expectation-8',
    text: 'In meiner Arbeit eigene Ideen verwirklichen können',
  },
  {
    id: 'job-expectation-9',
    text: 'Viele verschiedene und abwechslungsreiche Tätigkeiten',
  },
  {
    id: 'job-expectation-10',
    text: 'Routinearbeit, bei der ich nicht viel nachdenken muss',
  },
  {
    id: 'job-expectation-11',
    text: 'Arbeitsplatz, der so nahe wie möglich am Wohnort liegt',
  },
  {
    id: 'job-expectation-12',
    text: 'Beruflich viel unterwegs sein und im Ausland arbeiten',
  },
  {
    id: 'job-expectation-13',
    text: 'Einen Beruf, der gesellschaftlich hohes Ansehen genießt',
  },
  {
    id: 'job-expectation-14',
    text: 'Regelmäßige Arbeitszeiten',
  },
  {
    id: 'job-expectation-15',
    text: 'Arbeitszeiten selbst einteilen können (z.B. Gleitzeit)',
  },
  {
    id: 'job-expectation-16',
    text: 'Genügend Freizeit neben der Berufstätigkeit',
  },
  {
    id: 'job-expectation-17',
    text: 'Selbstständiges und eigenverantwortliches Arbeiten',
  },
  {
    id: 'job-expectation-18',
    text: 'Beruflich mit vielen Menschen in Kontakt sein',
  },
  {
    id: 'job-expectation-19',
    text: 'Die Sicherheit, jahrelang im gleichen Betrieb arbeiten zu können',
  },
  {
    id: 'job-expectation-20',
    text: 'Berufs- und Familienleben vereinbaren können',
  },
];
