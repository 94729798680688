import React from 'react';
import Cookies from 'js-cookie';
import gql from 'graphql-tag';
import {
  isDesktop,
  isChrome,
  isFirefox,
  isSafari,
  isOpera,
  isIE,
  isEdge,
  isMobileOnly,
  isTablet,
  isWindows,
  isMacOs,
  isAndroid,
  isWinPhone,
  isIOS,
} from 'react-device-detect';
import moment from 'moment';

import AppContext, { AppContextType } from '../../context';
import s from './Layout.module.scss';
import { COOKIE_CONSENT_SETTINGS } from '../../constants/cookies';

const logTrackingDataMutation = gql`
  mutation logTrackingData(
    $isNewVisit: Boolean!
    $duration: Int!
    $deviceInformation: String!
  ) {
    logTrackingData(
      isNewVisit: $isNewVisit
      duration: $duration
      deviceInformation: $deviceInformation
    ) {
      success
      message
    }
  }
`;

const logOffersClickMutation = gql`
  mutation logOffersClick($bundesland: String!, $mode: String!) {
    logOffersClick(bundesland: $bundesland, mode: $mode) {
      success
      message
    }
  }
`;

const logLocationMutation = gql`
  mutation logLocation($latitude: Float!, $longitude: Float!) {
    logLocation(latitude: $latitude, longitude: $longitude) {
      success
      message
    }
  }
`;

const VISIT_TIMEOUT_MS = 30 * 60; // 30 minutes in secods
const MIN_UPDATE_TRACKING_INTERVAL = 10 * 1000; // 10 seconds in ms
const TRACKING_TIME_FORMAT = 'DD.MM.YYYY HH:mm:ss';

export const userInteractionData = {
  siteInteraction: false,
};

export const trackingVariables = {
  lastUrl: null,
  lastUpdate: 0,
};

export const trackingAllowed = () => {
  let consentCookie: any = Cookies.get(COOKIE_CONSENT_SETTINGS);
  if (consentCookie == null) return false;
  consentCookie = JSON.parse(consentCookie);
  if (!consentCookie.trackingAccepted) {
    sessionStorage.removeItem('analyticsData');
  }
  return consentCookie.trackingAccepted;
};

export const getTrackingCookie = () => {
  if (trackingAllowed()) {
    const analyticsData: any = sessionStorage.getItem('analyticsData');

    if (analyticsData == null) {
      return {
        lastAccess: null,
        clickedOffers: [],
        clicked18PlusOffers: [],
        locationSent: false,
      };
    }

    if (analyticsData.lastAccess != null) {
      const now = moment();
      const then = moment(analyticsData.lastAccess, TRACKING_TIME_FORMAT);
      const diffSeconds = now.diff(then, 'seconds');

      if (diffSeconds > VISIT_TIMEOUT_MS) {
        return {
          lastAccess: null,
          clickedOffers: [],
          clicked18PlusOffers: [],
          locationSent: false,
        };
      }
    }
    return JSON.parse(analyticsData);
  }

  return null;
};

export const setTrackingCookie = (analyticsData: any) => {
  if (trackingAllowed()) {
    sessionStorage.setItem('analyticsData', JSON.stringify(analyticsData));
  }
};

export const getDeviceInformation = () => {
  if (trackingAllowed()) {
    let deviceType = 'others';
    if (isDesktop) deviceType = 'desktop';
    else if (isMobileOnly) deviceType = 'mobile';
    else if (isTablet) deviceType = 'tablet';

    let os = 'others';
    if (isDesktop && isWindows) os = 'windows';
    else if (isDesktop && isMacOs) os = 'mac';
    else if ((isMobileOnly || isTablet) && isAndroid) os = 'android';
    else if ((isMobileOnly || isTablet) && isIOS) os = 'ios';
    else if ((isMobileOnly || isTablet) && isWinPhone) os = 'winPhone';

    let browser = 'others';
    if (isChrome) browser = 'chrome';
    if (isFirefox) browser = 'firefox';
    if (isSafari) browser = 'safari';
    if (isOpera) browser = 'opera';
    if (isIE) browser = 'ie';
    if (isEdge) browser = 'edge';

    return {
      deviceType,
      os,
      browser,
    };
  }

  return null;
};

export const logTrackingData = async (
  context: AppContextType,
  force = false,
) => {
  if (trackingAllowed()) {
    const thisUpdate = new Date().getTime();

    if (
      !force &&
      thisUpdate - trackingVariables.lastUpdate < MIN_UPDATE_TRACKING_INTERVAL
    ) {
      return;
    }

    trackingVariables.lastUpdate = thisUpdate;

    const analyticsData = getTrackingCookie();
    const now = moment();

    if (analyticsData.lastAccess == null) {
      await context.client.mutate({
        mutation: logTrackingDataMutation,
        variables: {
          isNewVisit: true,
          duration: 0,
          deviceInformation: JSON.stringify(getDeviceInformation()),
        },
      });
    } else {
      const then = moment(analyticsData.lastAccess, TRACKING_TIME_FORMAT);
      const diffSeconds = now.diff(then, 'seconds');

      await context.client.mutate({
        mutation: logTrackingDataMutation,
        variables: {
          isNewVisit: false,
          duration: diffSeconds,
          locationEnabled: false, // already set on visit start
          latitude: 0, // already set on visit start
          longitude: 0, // already set on visit start
          deviceInformation: '', // already set on visit start
        },
      });
    }

    analyticsData.lastAccess = now.format(TRACKING_TIME_FORMAT);
    setTrackingCookie(analyticsData);
  }
};

export const logOffersClick = async (context: AppContextType, url: string) => {
  if (trackingAllowed()) {
    const href = decodeURIComponent(url);

    if (!href.includes('/orientation/offers/')) return;

    const analyticsData = getTrackingCookie();
    if (!analyticsData.clickedOffers) {
      analyticsData.clickedOffers = [];
    }

    /* eslint-disable prettier/prettier */
    let bundesland = '';
    if (href.includes('/orientation/offers/wien')) bundesland = 'wien';
    else if (href.includes('/orientation/offers/oberösterreich')) bundesland = 'oberösterreich';
    else if (href.includes('/orientation/offers/kärnten')) bundesland = 'kärnten';
    else if (href.includes('/orientation/offers/steiermark')) bundesland = 'steiermark';
    else if (href.includes('/orientation/offers/burgenland')) bundesland = 'burgenland';
    /* eslint-enable prettier/prettier */

    if (
      bundesland !== '' &&
      !analyticsData.clickedOffers.includes(bundesland)
    ) {
      analyticsData.clickedOffers.push(bundesland);
      setTrackingCookie(analyticsData);

      await context.client.mutate({
        mutation: logOffersClickMutation,
        variables: {
          bundesland,
          mode: 'young',
        },
      });
    }
  }
};

export const log18PlusOffersClick = async (
  context: AppContextType,
  url: string,
) => {
  if (trackingAllowed()) {
    const href = decodeURIComponent(url);
    if (!href.includes('/reorientation/offers/')) return;

    const analyticsData = getTrackingCookie();
    if (!analyticsData.clicked18PlusOffers) {
      analyticsData.clicked18PlusOffers = [];
    }

    /* eslint-disable prettier/prettier */
    let bundesland = '';
    if (href.includes('/reorientation/offers/wien')) bundesland = 'wien';
    else if (href.includes('/reorientation/offers/oberösterreich')) bundesland = 'oberösterreich';
    else if (href.includes('/reorientation/offers/salzburg')) bundesland = 'salzburg';
    else if (href.includes('/reorientation/offers/vorarlberg')) bundesland = 'vorarlberg';
    else if (href.includes('/reorientation/offers/steiermark')) bundesland = 'steiermark';
    else if (href.includes('/reorientation/offers/burgenland')) bundesland = 'burgenland';
    /* eslint-enable prettier/prettier */

    if (
      bundesland !== '' &&
      !analyticsData.clicked18PlusOffers.includes(bundesland)
    ) {
      analyticsData.clicked18PlusOffers.push(bundesland);
      setTrackingCookie(analyticsData);

      await context.client.mutate({
        mutation: logOffersClickMutation,
        variables: {
          bundesland,
          mode: '18+',
        },
      });
    }
  }
};

export const logLocation = async (context: AppContextType) => {
  if (trackingAllowed()) {
    let analyticsData = getTrackingCookie();
    if (analyticsData.locationSent) return;

    if (navigator && navigator.geolocation) {
      // resolves once user accepts location service
      navigator.geolocation.getCurrentPosition(async (pos) => {
        if (
          pos.coords &&
          pos.coords.latitude != null &&
          pos.coords.longitude != null
        ) {
          // fetch again, if already changed
          analyticsData = getTrackingCookie();
          if (analyticsData.locationSent) return;

          analyticsData.locationSent = true;
          setTrackingCookie(analyticsData);

          await context.client.mutate({
            mutation: logLocationMutation,
            variables: {
              latitude: pos.coords.latitude,
              longitude: pos.coords.longitude,
            },
          });
        }
      });
    }

    /*
    // for testing
    analyticsData.locationSent = true;
    setTrackingCookie(analyticsData);

    await context.client.mutate({
      mutation: logLocationMutation,
      variables: {
        latitude: 50.0656392920582,
        longitude: 14.437840821138268,
        // latitude: 48.250443,
        // longitude: 16.4468171,
      },
    });
    */
  }
};

export const triggerNewVisit = (context: AppContextType) => {
  sessionStorage.removeItem('analyticsData');
  logTrackingData(context, true);
};

interface LayoutProps {
  children: React.ReactNode;
}

class Layout extends React.Component<LayoutProps> {
  static contextType = AppContext;

  lastUrl: string | null = null;

  constructor(props: any) {
    super(props);

    this.registerInteraction = this.registerInteraction.bind(this);
  }

  componentDidMount() {
    const currentUrl = window.location.href;

    // log statistics but ignore admin pages
    if (!currentUrl.includes('/admin')) {
      const logData = async () => {
        await logTrackingData(this.context as AppContextType, true);
        await logOffersClick(this.context as AppContextType, currentUrl);
        await log18PlusOffersClick(this.context as AppContextType, currentUrl);
        await logLocation(this.context as AppContextType);
      };
      logData();
    }

    document.addEventListener('mousedown', this.registerInteraction);
    document.addEventListener('touchstart', this.registerInteraction);
  }

  componentDidUpdate() {
    // make updates on subpage changes
    const currentUrl = window.location.href;

    // log statistics but ignore admin pages
    if (!currentUrl.includes('/admin')) {
      if (currentUrl != null && currentUrl !== this.lastUrl) {
        const logData = async () => {
          await logTrackingData(this.context as AppContextType);
          await logOffersClick(this.context as AppContextType, currentUrl);
          await log18PlusOffersClick(this.context as AppContextType, currentUrl); // eslint-disable-line prettier/prettier
          await logLocation(this.context as AppContextType);
        };
        logData();
      }
      this.lastUrl = window.location.href;
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.registerInteraction);
    document.removeEventListener('touchstart', this.registerInteraction);
  }

  // eslint-disable-next-line class-methods-use-this
  registerInteraction() {
    userInteractionData.siteInteraction = true;
  }

  render() {
    return (
      <div className={s.appContainer}>
        <div className={s.appContent}>{this.props.children}</div>
      </div>
    );
  }
}

export default Layout;
