import React from 'react';
import { Modal, Button } from 'react-bootstrap';

import s from './MaximumFavoriteCountModal.module.scss';

interface MaximumFavoriteCountModalProps {
  showModal: boolean;
  hideModal: () => void;
}

class MaximumFavoriteCountModal extends React.Component<MaximumFavoriteCountModalProps> {
  render() {
    return (
      <Modal
        centered
        show={this.props.showModal}
        backdrop="static"
        dialogClassName={s.maximumFavoriteCountModal}
      >
        <Modal.Header className={s.header}>
          <Modal.Title>
            <h3>
              <strong>Limit erreicht</strong>
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={s.body}>Maximal 5 Favoriten möglich.</Modal.Body>
        <Modal.Footer className={s.footer}>
          <Button
            variant="btn btn-primary"
            onClick={() => {
              this.props.hideModal();
            }}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default MaximumFavoriteCountModal;
