import React from 'react';
import { Modal } from 'react-bootstrap';

import s from './StrengthCardsModal.module.scss';

interface ConfirmDeleteStrengthCardModalProps {
  showModal: boolean;
  closeModal: () => void;
  onSubmit: () => void;
}

class ConfirmDeleteStrengthCardModal extends React.Component<ConfirmDeleteStrengthCardModalProps> {
  render() {
    const { closeModal, onSubmit } = this.props;

    return (
      <div>
        <Modal
          centered
          show={this.props.showModal}
          backdrop="static"
          dialogClassName={s.modal}
        >
          <Modal.Header className={s.header}>
            <Modal.Title>
              <h3>Stärkenkarte löschen</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className={s.body}>
            <div className={s.info}>
              Willst du diese Stärkenkarte wirklich löschen?
            </div>
            <div className={s.buttonContainerTwoButtons}>
              <button
                className="btn btn-secondary"
                type="button"
                onClick={closeModal}
              >
                ABBRUCH
              </button>
              <button
                className="btn btn-primary"
                type="button"
                onClick={onSubmit}
              >
                LÖSCHEN
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default ConfirmDeleteStrengthCardModal;
