import React from 'react';
import gql from 'graphql-tag';

import AppContext, { AppContextType } from '../../../context';
import {
  LOGGED_MODE_USER,
  LOGGED_MODE_GUEST,
} from '../../Auth/AuthenticationWrapper';
import {
  getGuestLocalStorageData,
  setGuestLocalStorageData,
} from '../../Auth/withLogin';
import Loading from '../../Loading';
import { todoEntries } from '../../../data/todoEntries';
import {
  activityIsCompleted,
  strengthsAreCompleted,
  jobExpAreCompleted,
  jobOrSchoolIsCompleted,
} from '../../AKOOE/OverviewScreen/completionCheckers';

export interface akooeDataType {
  seenMotivationModal?: boolean;
  seenReportModal?: boolean;
  watchedIntro?: boolean;
  activityModuleData?: string;
  strengthsModuleData?: string;
  jobExpectationsModuleData?: string;
  desiredJobOrSchoolModuleData?: string;
  nextStepsModuleData?: string;
}

const userAkooeDataQuery = gql`
  query getUserAkooeData {
    userAkooeData {
      seenMotivationModal
      seenReportModal
      watchedIntro
      activityModuleData
      strengthsModuleData
      jobExpectationsModuleData
      desiredJobOrSchoolModuleData
      nextStepsModuleData
    }
  }
`;

const setWatchedIntroMutation = gql`
  mutation setWatchedIntro($watched: Boolean!) {
    setWatchedIntro(watched: $watched)
  }
`;

const setSeenMotivationModalMutation = gql`
  mutation setSeenMotivationModal($seen: Boolean!) {
    setSeenMotivationModal(seen: $seen)
  }
`;

const setSeenReportModalMutation = gql`
  mutation setSeenReportModal($seen: Boolean!) {
    setSeenReportModal(seen: $seen)
  }
`;

const setActivityModuleDataMutation = gql`
  mutation setActivityModuleData($moduleData: String!) {
    setActivityModuleData(moduleData: $moduleData)
  }
`;

const setStrengthsModuleDataMutation = gql`
  mutation setStrengthsModuleData($moduleData: String!) {
    setStrengthsModuleData(moduleData: $moduleData)
  }
`;

const setJobExpectationsModuleDataMutation = gql`
  mutation setJobExpectationsModuleData($moduleData: String!) {
    setJobExpectationsModuleData(moduleData: $moduleData)
  }
`;

const setDesiredJobOrSchoolModuleDataMutation = gql`
  mutation setDesiredJobOrSchoolModuleData($moduleData: String!) {
    setDesiredJobOrSchoolModuleData(moduleData: $moduleData)
  }
`;

const setNextStepsModuleDataMutation = gql`
  mutation setNextStepsModuleData($moduleData: String!) {
    setNextStepsModuleData(moduleData: $moduleData)
  }
`;

const setStrengthCardImageMutation = gql`
  mutation setStrengthCardImage($cardId: String!, $imageBase64: String!) {
    setStrengthCardImage(cardId: $cardId, imageBase64: $imageBase64)
  }
`;

const deleteStrengthCardImageMutation = gql`
  mutation deleteStrengthCardImage($cardId: String!) {
    deleteStrengthCardImage(cardId: $cardId)
  }
`;

const getAllStrengthCardImagesQuery = gql`
  query getAllStrengthCardImages {
    allStrengthCardImages {
      cardId
      imageBase64
    }
  }
`;

interface WithAkooeDataProps {
  loggedMode: string;
}

const AkooeDataWrapper = (
  WrappedComponent: any,
  withStrengthCardImagesInitial = false,
) => {
  class WithAkooeData extends React.Component<WithAkooeDataProps> {
    static contextType = AppContext;

    // eslint-disable-next-line react/state-in-constructor
    state: any;

    constructor(props: any) {
      super(props);

      this.state = {
        loading: true,
        userAkooeData: null,
      };

      /* eslint-disable prettier/prettier */
      this.fetchAkooeData = this.fetchAkooeData.bind(this);
      this.getAkooeDataForUser = this.getAkooeDataForUser.bind(this);
      this.getCustomStrengthImages = this.getCustomStrengthImages.bind(this);
      this.getAkooeDataForGuest = this.getAkooeDataForGuest.bind(this);
      this.setUserData = this.setUserData.bind(this);
      this.setGuestData = this.setGuestData.bind(this);
      this.setModuleTodoCheck = this.setModuleTodoCheck.bind(this);
      this.setWatchedIntro = this.setWatchedIntro.bind(this);
      this.setSeenMotivationModal = this.setSeenMotivationModal.bind(this);
      this.setSeenReportModal = this.setSeenReportModal.bind(this);
      this.setActivityModuleData = this.setActivityModuleData.bind(this);
      this.setStrengthsModuleData = this.setStrengthsModuleData.bind(this);
      this.setJobExpectationsModuleData = this.setJobExpectationsModuleData.bind(this);
      this.setDesiredJobOrSchoolModuleData = this.setDesiredJobOrSchoolModuleData.bind(this);
      this.setNextStepsModuleData = this.setNextStepsModuleData.bind(this);
      this.setStrengthCardImage = this.setStrengthCardImage.bind(this);
      this.deleteStrengthCardImage = this.deleteStrengthCardImage.bind(this);
      /* eslint-enable prettier/prettier */
    }

    componentDidMount() {
      this.fetchAkooeData(true, withStrengthCardImagesInitial);
    }

    // eslint-disable-next-line react/sort-comp
    async fetchAkooeData(forceNetwork = true, withStrengthCardImages = false) {
      if (this.props.loggedMode === LOGGED_MODE_USER) {
        await this.getAkooeDataForUser(forceNetwork, withStrengthCardImages);
      } else if (this.props.loggedMode === LOGGED_MODE_GUEST) {
        this.getAkooeDataForGuest();
      }
    }

    async getAkooeDataForUser(
      forceNetwork: boolean,
      withStrengthCardImages = false,
    ) {
      const response = await (this.context as AppContextType).client.query({
        query: userAkooeDataQuery,
        fetchPolicy: forceNetwork ? 'network-only' : 'cache-first',
      });

      if (withStrengthCardImages) {
        await this.getCustomStrengthImages(forceNetwork);
      }

      if (response && response.data && 'userAkooeData' in response.data) {
        this.setState({
          loading: false,
          userAkooeData: response.data.userAkooeData,
        });
      }
    }

    async getCustomStrengthImages(forceNetwork = true) {
      const response = await (this.context as AppContextType).client.query({
        query: getAllStrengthCardImagesQuery,
        fetchPolicy: forceNetwork ? 'network-only' : 'cache-first',
      });

      if (
        response &&
        response.data &&
        'allStrengthCardImages' in response.data
      ) {
        const imagesMap = response.data.allStrengthCardImages.reduce(
          (map: any, scImage: any) => {
            // eslint-disable-next-line no-param-reassign
            map[scImage.cardId] = scImage.imageBase64;
            return map;
          },
          {},
        );

        this.setState({
          allStrengthCardImages: imagesMap,
        });
      }
    }

    getAkooeDataForGuest() {
      const guestData = getGuestLocalStorageData();

      if (guestData) {
        this.setState({
          loading: false,
          userAkooeData: guestData.userAkooeData,
          allStrengthCardImages: {},
        });
      }
    }

    async setUserData(
      mutation: any,
      mutationName: string,
      variables: any,
      variableKey?: string,
    ) {
      let success = false;

      const response = await (this.context as AppContextType).client.mutate({
        mutation,
        variables,
      });
      success = response.data && response.data[mutationName] === true;
      const { userAkooeData } = this.state;
      if (variableKey) {
        this.setState({
          userAkooeData: {
            ...userAkooeData,
            [variableKey]: variables.moduleData
              ? variables.moduleData
              : variables,
          },
        });
      }
      return success;
    }

    // eslint-disable-next-line class-methods-use-this
    async setGuestData(propName: string, propValue: any) {
      const guestData = getGuestLocalStorageData();

      const newUserAkooeData = guestData.userAkooeData || {};
      newUserAkooeData[propName] = propValue;

      const newGuestData = {
        ...guestData,
        userAkooeData: newUserAkooeData,
      };

      setGuestLocalStorageData(newGuestData);

      return true;
    }

    async setModuleTodoCheck(key: string) {
      const { userAkooeData } = this.state;

      const nextStepsModuleData =
        userAkooeData && userAkooeData.nextStepsModuleData
          ? JSON.parse(userAkooeData.nextStepsModuleData)
          : {};

      if (!nextStepsModuleData.standard) {
        nextStepsModuleData.standard = todoEntries.map((entry) => ({
          key: entry.key,
          value: false,
          touched: false,
        }));
      }

      if (!nextStepsModuleData.custom) {
        nextStepsModuleData.custom = [];
      }

      for (let i = 0; i < nextStepsModuleData.standard.length; i += 1) {
        const todo = nextStepsModuleData.standard[i];

        if (todo.key === key && !todo.value && !todo.touched) {
          todo.value = true;
        }
      }

      await this.setNextStepsModuleData(JSON.stringify(nextStepsModuleData));
    }

    async setWatchedIntro(watched: boolean) {
      let success = false;

      // handle logged user
      if (this.props.loggedMode === LOGGED_MODE_USER) {
        success = await this.setUserData(
          setWatchedIntroMutation,
          'setWatchedIntro',
          {
            watched,
          },
          'watchedIntro',
        );
      }
      // handle guest user
      if (this.props.loggedMode === LOGGED_MODE_GUEST) {
        success = await this.setGuestData('watchedIntro', watched);
      }

      return success;
    }

    async setSeenMotivationModal(seen: boolean) {
      let success = false;

      // handle logged user
      if (this.props.loggedMode === LOGGED_MODE_USER) {
        success = await this.setUserData(
          setSeenMotivationModalMutation,
          'setSeenMotivationModal',
          {
            seen,
          },
          'seenMotivationModal',
        );
      }
      // handle guest user
      if (this.props.loggedMode === LOGGED_MODE_GUEST) {
        success = await this.setGuestData('seenMotivationModal', seen);
      }

      return success;
    }

    async setSeenReportModal(seen: boolean) {
      let success = false;

      // handle logged user
      if (this.props.loggedMode === LOGGED_MODE_USER) {
        success = await this.setUserData(
          setSeenReportModalMutation,
          'setSeenReportModal',
          {
            seen,
          },
          'seenReportModal',
        );
      }
      // handle guest user
      if (this.props.loggedMode === LOGGED_MODE_GUEST) {
        success = await this.setGuestData('seenReportModal', seen);
      }

      return success;
    }

    async setActivityModuleData(moduleData: any) {
      let success = false;

      const { userAkooeData } = this.state;

      const experiences = moduleData
        ? JSON.parse(moduleData).experiences || []
        : [];

      const oldExperiences =
        userAkooeData && userAkooeData.activityModuleData
          ? JSON.parse(userAkooeData.activityModuleData).experiences || []
          : [];

      const oldNextStepsData =
        userAkooeData && userAkooeData.nextStepsModuleData
          ? JSON.parse(userAkooeData.nextStepsModuleData)
          : {};

      const newNextStepsData = {
        standard: oldNextStepsData.standard
          ? [...oldNextStepsData.standard]
          : todoEntries,
        custom: oldNextStepsData.custom ? [...oldNextStepsData.custom] : [],
      };

      // check if experiences have been added
      const todosToAdd = [];
      for (let i = 0; i < experiences.length; i += 1) {
        let present = false;
        for (let j = 0; j < oldExperiences.length; j += 1) {
          if (experiences[i] === oldExperiences[j]) {
            present = true;
          }
        }
        if (!present) {
          todosToAdd.push(experiences[i]);
        }
      }

      // check if experiences have been removed
      const todosToRemove = [];
      for (let i = 0; i < oldExperiences.length; i += 1) {
        let present = false;
        for (let j = 0; j < experiences.length; j += 1) {
          if (oldExperiences[i] === experiences[j]) {
            present = true;
          }
        }
        if (!present) {
          todosToRemove.push(oldExperiences[i]);
        }
      }

      // add new experiences to todos
      for (let i = 0; i < todosToAdd.length; i += 1) {
        let present = false;
        for (let j = 0; j < newNextStepsData.custom.length; j += 1) {
          if (todosToAdd[i] === newNextStepsData.custom[j].label) {
            present = true;
          }
        }
        if (!present) {
          newNextStepsData.custom.push({ label: todosToAdd[i], value: false });
        }
      }

      // remove deleted experiences from todos
      for (let i = newNextStepsData.custom.length - 1; i >= 0; i -= 1) {
        let present = false;
        for (let j = 0; j < todosToRemove.length; j += 1) {
          if (newNextStepsData.custom[i].label === todosToRemove[j]) {
            present = true;
          }
        }
        if (present && !newNextStepsData.custom[i].value) {
          newNextStepsData.custom.splice(i, 1);
        }
      }

      // handle logged user
      if (this.props.loggedMode === LOGGED_MODE_USER) {
        success = await this.setUserData(
          setActivityModuleDataMutation,
          'setActivityModuleData',
          {
            moduleData,
          },
          'activityModuleData',
        );
      }
      // handle guest user
      if (this.props.loggedMode === LOGGED_MODE_GUEST) {
        success = await this.setGuestData('activityModuleData', moduleData);
      }

      await this.setNextStepsModuleData(JSON.stringify(newNextStepsData));

      if (activityIsCompleted(moduleData)) {
        await this.setModuleTodoCheck('todo:interests-module');
      }

      return success;
    }

    async setStrengthsModuleData(moduleData: any) {
      let success = false;

      // handle logged user
      if (this.props.loggedMode === LOGGED_MODE_USER) {
        success = await this.setUserData(
          setStrengthsModuleDataMutation,
          'setStrengthsModuleData',
          {
            moduleData,
          },
          'strengthsModuleData',
        );
      }
      // handle guest user
      if (this.props.loggedMode === LOGGED_MODE_GUEST) {
        success = await this.setGuestData('strengthsModuleData', moduleData);
      }

      if (strengthsAreCompleted(moduleData)) {
        await this.setModuleTodoCheck('todo:strengths-module');
      }

      return success;
    }

    async setJobExpectationsModuleData(moduleData: any) {
      let success = false;

      // handle logged user
      if (this.props.loggedMode === LOGGED_MODE_USER) {
        success = await this.setUserData(
          setJobExpectationsModuleDataMutation,
          'setJobExpectationsModuleData',
          {
            moduleData,
          },
          'jobExpectationsModuleData',
        );
      }
      // handle guest user
      if (this.props.loggedMode === LOGGED_MODE_GUEST) {
        success = await this.setGuestData(
          'jobExpectationsModuleData',
          moduleData,
        );
      }

      if (jobExpAreCompleted(moduleData)) {
        await this.setModuleTodoCheck('todo:expectations-module');
      }

      return success;
    }

    async setDesiredJobOrSchoolModuleData(moduleData: any) {
      let success = false;

      // handle logged user
      if (this.props.loggedMode === LOGGED_MODE_USER) {
        success = await this.setUserData(
          setDesiredJobOrSchoolModuleDataMutation,
          'setDesiredJobOrSchoolModuleData',
          {
            moduleData,
          },
          'desiredJobOrSchoolModuleData',
        );
      }
      // handle guest user
      if (this.props.loggedMode === LOGGED_MODE_GUEST) {
        success = await this.setGuestData(
          'desiredJobOrSchoolModuleData',
          moduleData,
        );
      }

      if (jobOrSchoolIsCompleted(moduleData)) {
        await this.setModuleTodoCheck('todo:desiredJobOrSchool-module');
      }

      return success;
    }

    async setNextStepsModuleData(moduleData: any) {
      let success = false;

      // handle logged user
      if (this.props.loggedMode === LOGGED_MODE_USER) {
        success = await this.setUserData(
          setNextStepsModuleDataMutation,
          'setNextStepsModuleData',
          {
            moduleData,
          },
          'nextStepsModuleData',
        );
      }
      // handle guest user
      if (this.props.loggedMode === LOGGED_MODE_GUEST) {
        success = await this.setGuestData('nextStepsModuleData', moduleData);
      }

      return success;
    }

    async setStrengthCardImage(cardId: string, imageBase64: any) {
      let success = false;

      // handle logged user
      if (this.props.loggedMode === LOGGED_MODE_USER) {
        success = await this.setUserData(
          setStrengthCardImageMutation,
          'setStrengthCardImage',
          {
            cardId,
            imageBase64,
          },
        );
      }

      // not available for guest user

      return success;
    }

    async deleteStrengthCardImage(cardId: string) {
      let success = false;

      // handle logged user
      if (this.props.loggedMode === LOGGED_MODE_USER) {
        success = await this.setUserData(
          deleteStrengthCardImageMutation,
          'deleteStrengthCardImage',
          {
            cardId,
          },
        );
      }

      // not available for guest user

      return success;
    }

    render() {
      const { loading, userAkooeData, allStrengthCardImages } = this.state;

      if (loading) return <Loading />;
      return (
        <WrappedComponent
          {...this.props}
          userAkooeData={userAkooeData || {}}
          setWatchedIntro={this.setWatchedIntro}
          setActivityModuleData={this.setActivityModuleData}
          setStrengthsModuleData={this.setStrengthsModuleData}
          setStrengthCardImage={this.setStrengthCardImage}
          refetchStrengthCardImages={this.getCustomStrengthImages}
          allStrengthCardImages={allStrengthCardImages || {}}
          deleteStrengthCardImage={this.deleteStrengthCardImage}
          setJobExpectationsModuleData={this.setJobExpectationsModuleData}
          setDesiredJobOrSchoolModuleData={this.setDesiredJobOrSchoolModuleData}
          setNextStepsModuleData={this.setNextStepsModuleData}
          setSeenMotivationModal={this.setSeenMotivationModal}
          setSeenReportModal={this.setSeenReportModal}
          refetchAkooeData={this.fetchAkooeData}
        />
      );
    }
  }

  return WithAkooeData;
};

export default AkooeDataWrapper;
