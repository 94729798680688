import React from 'react';

import s from './StatusBar.module.scss';

interface StatusBarProps {
  percentage: number;
}

class StatusBar extends React.Component<StatusBarProps> {
  render() {
    return (
      <div className={s.barContainer}>
        <div
          className={s.fill}
          style={{
            width: `calc(100% * ${this.props.percentage / 100})`,
          }}
        />
        <div
          className={s.track}
          style={{
            width: `calc(100% * ${1 - this.props.percentage / 100})`,
          }}
        />
      </div>
    );
  }
}

export default StatusBar;
