/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { NavigateFunction } from 'react-router-dom';

import { ROUTE_PREFIX } from '../../constants/router';
import { JOBS_VERSION } from '../../constants/professions';
import s from './FavoriteProfessionsList.module.scss';
import getAssetLink from '../../util/getAssetLink';
import { withRouter } from '../../util/withRouter';

interface FavoriteProfessionsListProps {
  professions: Array<{ key: string; imageName: string }>;
  navigate: NavigateFunction;
}

class FavoriteProfessionsList extends React.Component<FavoriteProfessionsListProps> {
  // eslint-disable-next-line react/state-in-constructor
  state: any;

  constructor(props: any) {
    super(props);

    this.state = {
      currentIndex: 0,
    };

    this.prevSlide = this.prevSlide.bind(this);
    this.nextSlide = this.nextSlide.bind(this);
    this.indexChanged = this.indexChanged.bind(this);
  }

  prevSlide() {
    const { currentIndex } = this.state;

    this.indexChanged(Math.max(0, currentIndex - 1));
  }

  nextSlide() {
    const { professions } = this.props;
    const { currentIndex } = this.state;

    this.indexChanged(Math.min(professions.length - 1, currentIndex + 1));
  }

  indexChanged(newIndex: number) {
    this.setState({
      currentIndex: newIndex,
    });
  }

  render() {
    const { professions, navigate } = this.props;
    const { currentIndex } = this.state;

    if (professions.length === 0) {
      return (
        <div className={s.favoriteProfessionsList}>
          <div className={s.noFavoritesContainer}>
            Du hast noch keine der Berufe zu deinen Favoriten hinzugefügt. Bitte
            wähle deine Lieblingsberufe, indem du auf das Herz drückst.
          </div>
        </div>
      );
    }

    return (
      <div className={s.favoriteProfessionsList}>
        <SwipeableViews
          className={s.listRoot}
          index={currentIndex}
          onChangeIndex={this.indexChanged}
        >
          {professions.map((profession) => (
            <div key={profession.key} className={s.professionTile}>
              <img
                src={`https://www.jopsy.at/appimages/jobs/${JOBS_VERSION}/${profession.imageName}`}
                alt=""
                onClick={() => {
                  navigate(
                    `${ROUTE_PREFIX}/profession/${
                      profession.key.split('-')[1]
                    }`,
                  );
                }}
              />
            </div>
          ))}
        </SwipeableViews>
        <button
          className={s.previousSlideButton}
          type="button"
          onClick={this.prevSlide}
          disabled={currentIndex <= 0}
        >
          <img src={getAssetLink('arrow_left.png')} alt="" />
        </button>
        <button
          className={s.nextSlideButton}
          type="button"
          onClick={this.nextSlide}
          disabled={currentIndex >= professions.length - 1}
        >
          <img src={getAssetLink('arrow_right.png')} alt="" />
        </button>
      </div>
    );
  }
}

export default withRouter(FavoriteProfessionsList);
