import React from 'react';
import { Modal, Button } from 'react-bootstrap';

import s from './SuggestionsModal.module.scss';

interface SuggestionsModalProps {
  showModal: boolean;
  suggestions: Array<string>;
  onClose: () => void;
  // eslint-disable-next-line no-unused-vars
  onSelectSuggestion: (suggestion: string) => void;
}

class SuggestionsModal extends React.Component<SuggestionsModalProps> {
  render() {
    const { showModal, suggestions, onClose, onSelectSuggestion } = this.props;
    return (
      <Modal show={showModal} onHide={onClose} className={s.modal}>
        <div className={s.modalContent}>
          <div className={s.headerText}>Beruf aus Favoriten wählen</div>
          <div className={s.suggestionWrapper}>
            <Button
              className={`btn-secondary ${s.abortButton}`}
              onClick={onClose}
            >
              Abbruch
            </Button>
          </div>
          <div className={s.suggestionsContainer}>
            {suggestions.map((suggestion) => (
              <Button
                key={suggestion}
                className={s.button}
                onClick={() => {
                  onSelectSuggestion(suggestion);
                }}
              >
                {suggestion}
              </Button>
            ))}
          </div>
        </div>
      </Modal>
    );
  }
}

export default SuggestionsModal;
