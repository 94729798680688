import React from 'react';
import DesiredJobOrSchoolScreen from '../DesiredJobOrSchoolComponent';

const name = 'MEIN WUNSCHBERUF';
const link = 'www.lehrberufsabc.at';
class DesiredJobScreen extends React.Component {
  render() {
    return (
      <DesiredJobOrSchoolScreen schoolOrJob="job" name={name} link={link} />
    );
  }
}

export default DesiredJobScreen;
