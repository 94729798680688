import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import s from './StrengthCardsModal.module.scss';
import render from '../../../ReduxForm/renderField';

const validate = () => {
  const errors = {};
  return errors;
};

interface CreateCustomStrengthCardType {
  title: string;
  description: string;
}

interface CreateCustomStrengthCardFormProps {
  // eslint-disable-next-line no-unused-vars
  onSubmit: (data: any) => void;
  onCancel: () => void;
  errors?: Array<string>;
}
class CreateCustomStrengthCardForm extends React.Component<
  InjectedFormProps<
    CreateCustomStrengthCardType,
    CreateCustomStrengthCardFormProps
  > &
    CreateCustomStrengthCardFormProps
> {
  static defaultProps = {
    errors: [],
  };

  render() {
    const { handleSubmit, onCancel, errors } = this.props;

    return (
      <div className={s.createCustomStrengthCardForm}>
        <form className="default-form" onSubmit={handleSubmit}>
          <fieldset>
            <h3>Titel:</h3>
            <Field
              id="title"
              name="title"
              type="text"
              component={render.renderInput}
              maxLength={30}
            />
            <h3>Beschreibung:</h3>
            <Field
              id="description"
              name="description"
              type="text"
              component={render.renderInput}
              maxLength={80}
            />
            {errors && errors.length === 0 ? null : (
              <ul style={{ padding: 0, margin: 0, listStyleType: 'none' }}>
                {errors &&
                  errors.map((err) => (
                    <li key={err} className="validationError">
                      {err}
                    </li>
                  ))}
              </ul>
            )}
            <div className={s.buttonContainerTwoButtons}>
              <button
                className="btn btn-secondary"
                type="button"
                onClick={onCancel}
              >
                ABBRUCH
              </button>
              <button className="btn btn-primary" type="submit">
                OK
              </button>
            </div>
          </fieldset>
        </form>
      </div>
    );
  }
}

export default reduxForm<
  CreateCustomStrengthCardType,
  CreateCustomStrengthCardFormProps
>({
  form: 'createCustomStrengthCardForm',
  validate,
  pure: false,
})(CreateCustomStrengthCardForm);
