/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
  Field,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';

import s from './AddTodoModal.module.scss';
import render from '../../../ReduxForm/renderField';

const validate = () => {
  const errors = {};
  return errors;
};

interface AddTodoType {
  label: string;
  value: string;
}

interface AddTodoModalProps {
  showModal: boolean;
  closeModal: () => void;
  // eslint-disable-next-line no-unused-vars
  onSubmit: (data: any) => void;
}

class AddTodoModal extends React.Component<
  InjectedFormProps<AddTodoType, AddTodoModalProps> & AddTodoModalProps
> {
  render() {
    return (
      <div>
        <Modal
          centered
          show={this.props.showModal}
          backdrop="static"
          dialogClassName={s.modal}
        >
          <Modal.Header className={s.header}>
            <Modal.Title>
              <h3>Neuer To-do-Eintrag</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className={s.body}>
            <form onSubmit={this.props.handleSubmit}>
              <fieldset>
                <Field
                  className={s.formFieldContainer}
                  name="label"
                  placeholder=""
                  component={render.renderTextarea}
                  id="label"
                  style={{
                    borderRadius: '3px',
                    padding: '7px',
                    width: '100%',
                    marginTop: '10px',
                    resize: 'none',
                  }}
                />
                <div className={s.buttonContainer}>
                  <Button
                    variant="btn btn-secondary"
                    onClick={this.props.closeModal}
                  >
                    ABBRUCH
                  </Button>
                  <Button type="submit" variant="btn btn-primary">
                    OK
                  </Button>
                </div>
              </fieldset>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const addTodoForm = reduxForm<AddTodoType, AddTodoModalProps>({
  form: 'addTodo',
  enableReinitialize: true,
  validate,
  pure: false,
})(AddTodoModal);

export default connect((state: any) => {
  const selector = formValueSelector('addTodo');
  return {
    value: selector(state, 'value'),
  };
})(addTodoForm);
