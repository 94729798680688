/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/sort-comp */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, FieldArray, InjectedFormProps } from 'redux-form';
import { Link } from 'react-router-dom';
import { flowRight as compose } from 'lodash';

import s from './NextStepsScreen.module.scss';
import { ROUTE_PREFIX } from '../../../constants/router';
import withInterestTestResults, {
  itestResultsType,
} from '../../HomeScreen/DataWrappers/InterestTestResultsWrapper';
import withAuthentication from '../../Auth/AuthenticationWrapper';
import { checkIfTestResultsAreInvalid } from '../../HomeScreen/DataWrappers/TestCompletionRequiredWrapper';
import withBundesland from '../../HomeScreen/DataWrappers/BundeslandWrapper';
import withAkooeData, {
  akooeDataType,
} from '../../HomeScreen/DataWrappers/AkooeDataWrapper';
import ContentBody from '../../ContentBody';
import OffersHeader from '../../OffersScreen/OffersHeader';
import Footer from '../../Footer';
import Loading from '../../Loading';
import AddTodoModal from './AddTodoModal';
import { todoEntries } from '../../../data/todoEntries';
import render from '../../ReduxForm/renderField';
import getAssetLink from '../../../util/getAssetLink';

const validate = () => {
  const errors = {};
  return errors;
};

interface NextStepsScreenProps {
  loggedMode: string;
  meData?: any;
  userItestResults?: itestResultsType;
  refetchSelectedBundesland: () => Promise<void>;
  selectedBundesland: string;
  // eslint-disable-next-line no-unused-vars
  setSelectedBundesland: (bundesland: string) => Promise<void>;
  userAkooeData: akooeDataType;
  refetchAkooeData: () => Promise<void>;
  // eslint-disable-next-line no-unused-vars
  setNextStepsModuleData: (data: any) => void;
}

class NextStepsScreen extends React.Component<
  InjectedFormProps<any, NextStepsScreenProps> & NextStepsScreenProps
> {
  // eslint-disable-next-line react/state-in-constructor
  state: any;

  static defaultProps = {
    meData: undefined,
    userItestResults: undefined,
  };

  constructor(props: any) {
    super(props);

    const moduleData =
      props.userAkooeData && props.userAkooeData.nextStepsModuleData
        ? JSON.parse(props.userAkooeData.nextStepsModuleData)
        : {};

    this.state = {
      loading: false,
      showModal: false,
      moduleData,
    };

    this.renderTodoList = this.renderTodoList.bind(this);
    this.handleTodoCheck = this.handleTodoCheck.bind(this);
    this.renderCustomTodosList = this.renderCustomTodosList.bind(this);
    this.removeTodo = this.removeTodo.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.handleCustomTodoCheck = this.handleCustomTodoCheck.bind(this);
    this.addTodo = this.addTodo.bind(this);
  }

  renderTodoList(moduleData: any) {
    const { selectedBundesland } = this.props;

    return (
      <fieldset>
        {todoEntries.map((todo) => (
          <div key={todo.key} className={s.entryContainer}>
            <Field
              type="checkbox"
              id={todo.key}
              name={todo.key}
              component={render.renderCheckbox}
              style={{ height: '20px', paddingTop: '3px' }}
              onChange={() => this.handleTodoCheck(todo.key, moduleData)}
            />
            <label htmlFor={todo.key}>
              <span className={s.entryInfo}>{todo.entry}</span>
            </label>
            {todo.link ? (
              <Link
                to={`${ROUTE_PREFIX}/${todo.link(selectedBundesland)}`}
                className={s.iconContainer}
              >
                <img
                  src={getAssetLink('link_black.png')}
                  alt=""
                  className={s.icon}
                />
              </Link>
            ) : (
              <div />
            )}
          </div>
        ))}
      </fieldset>
    );
  }

  handleTodoCheck(key: string, moduleData: any) {
    const { setNextStepsModuleData } = this.props;

    const data = moduleData.standard;
    let index = -1;
    for (let i = 0; i < data.length; i += 1) {
      if (data[i].key === key) {
        index = i;
      }
    }
    if (index > -1) {
      // eslint-disable-next-line no-param-reassign
      moduleData.standard[index].touched = true;
      // eslint-disable-next-line no-param-reassign
      moduleData.standard[index].value = !moduleData.standard[index].value;

      setNextStepsModuleData(JSON.stringify(moduleData));
    }

    this.setState({ moduleData });
  }

  renderCustomTodosList({ fields, moduleData }: any) {
    return (
      <div>
        {fields.map((listItem: any, index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index} className={s.entryContainer}>
            <Field
              type="checkbox"
              name={`${listItem}.value`}
              component={render.renderCheckbox}
              style={{ height: '20px', paddingTop: '3px' }}
              onChange={() => {
                this.handleCustomTodoCheck(fields.get(index).label, moduleData);
              }}
            />
            <label htmlFor={`interest ${index}`}>
              <span className={s.entryInfo}>{fields.get(index).label}</span>
            </label>
            <div className={s.iconContainer}>
              <img
                src={getAssetLink('CrossIcon.png')}
                alt=""
                className={s.icon}
                onClick={() => {
                  this.removeTodo(fields, index, moduleData);
                }}
              />
            </div>
          </div>
        ))}
        <div className={s.plusIconContainer}>
          <img
            src={getAssetLink('PlusIcon.png')}
            alt=""
            className={s.plusIcon}
            onClick={() => this.openModal()}
          />
        </div>
        <AddTodoModal
          closeModal={this.closeModal}
          showModal={this.state.showModal}
          onSubmit={(todo: any) => {
            this.addTodo(todo, fields, moduleData);
          }}
        />
      </div>
    );
  }

  addTodo(todo: any, fields: any, moduleData: any) {
    const { setNextStepsModuleData } = this.props;

    fields.push(todo);
    moduleData.custom.push(todo);
    setNextStepsModuleData(JSON.stringify(moduleData));

    this.closeModal();
    this.setState({ moduleData });
  }

  handleCustomTodoCheck(key: string, moduleData: any) {
    const { setNextStepsModuleData } = this.props;
    const data = moduleData.custom;

    let index = -1;
    for (let i = 0; i < data.length; i += 1) {
      if (data[i].label === key) {
        index = i;
      }
    }
    if (index > -1) {
      // eslint-disable-next-line no-param-reassign
      moduleData.custom[index].value = !moduleData.custom[index].value;
      setNextStepsModuleData(JSON.stringify(moduleData));
      this.setState({ moduleData });
    }
  }

  removeTodo(fields: any, index: number, moduleData: any) {
    const { setNextStepsModuleData } = this.props;

    fields.remove(index);
    moduleData.custom.splice(index, 1);
    setNextStepsModuleData(JSON.stringify(moduleData));

    this.setState({ moduleData });
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  openModal() {
    this.setState({ showModal: true });
  }

  render() {
    const {
      loggedMode,
      meData,
      userItestResults,
      selectedBundesland,
      setSelectedBundesland,
      refetchSelectedBundesland,
    } = this.props;

    const { moduleData } = this.state;
    const testResultsInvalid = checkIfTestResultsAreInvalid(userItestResults);
    const finishedTest = !testResultsInvalid;

    if (!moduleData.standard) {
      moduleData.standard = todoEntries.map((entry) => ({
        key: entry.key,
        value: false,
        touched: false,
      }));
    }

    if (!moduleData.custom) {
      moduleData.custom = [];
    }

    return [
      <div key="nextStepsContainer" className={s.nextStepsContainer}>
        <OffersHeader
          backUrl={`${ROUTE_PREFIX}/orientation/offers/${selectedBundesland}`}
          bundesland={selectedBundesland}
          setSelectedBundesland={setSelectedBundesland}
          refetchSelectedBundesland={refetchSelectedBundesland}
        />
        <ContentBody>
          <div className={s.contentHeader}>
            <h3>Meine nächsten Schritte</h3>
          </div>
          {this.renderTodoList(moduleData)}
          <div className={s.contentHeader}>
            <h3>Weitere wichtige Schritte für mich</h3>
          </div>
          <FieldArray
            name="customTodosList"
            component={this.renderCustomTodosList}
            moduleData={moduleData}
            showModal={this.state.showModal}
          />
          {selectedBundesland === 'oberösterreich' && (
            <a
              href="https://www.fragdieak.at/bildung/index.html"
              target="_blank"
              rel="noreferrer"
              className={s.fragDieAKContainer}
            >
              <img
                src={getAssetLink('frag-die-ak.png')}
                alt=""
                className={s.fragDieAKLogo}
              />
            </a>
          )}
        </ContentBody>
        <Footer
          loggedMode={loggedMode}
          meData={meData}
          finishedTest={finishedTest}
          forceShowExport
          exportUrl={`${ROUTE_PREFIX}/orientation/offers/${selectedBundesland}/export`}
          withEPortfolio
          refetchAkooeData={this.props.refetchAkooeData}
        />
      </div>,
      this.state.loading ? <Loading key="loading" /> : null,
    ];
  }
}

const NextStepsForm = reduxForm<any, NextStepsScreenProps>({
  form: 'nextSteps',
  enableReinitialize: true,
  validate,
  pure: false,
  destroyOnUnmount: false,
})(NextStepsScreen);

export default compose(
  withAuthentication,
  withInterestTestResults,
  withBundesland,
  withAkooeData,
  connect((state: any, ownProps: any) => {
    const nextStepsData =
      ownProps.userAkooeData && ownProps.userAkooeData.nextStepsModuleData
        ? JSON.parse(ownProps.userAkooeData.nextStepsModuleData)
        : {};

    let defaultStandardData =
      nextStepsData && nextStepsData.standard ? nextStepsData.standard : [];
    defaultStandardData = defaultStandardData.reduce(
      (initialValues: any, entry: any) => ({
        ...initialValues,
        [entry.key]: entry.value,
      }),
      {},
    );

    let defaultCustomData =
      nextStepsData && nextStepsData.custom ? nextStepsData.custom : [];
    defaultCustomData = defaultCustomData.map(({ label, value }: any) => ({
      label,
      value,
    }));

    return {
      initialValues: {
        customTodosList: defaultCustomData,
        ...defaultStandardData,
      },
    };
  }),
)(NextStepsForm);
