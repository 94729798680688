/* eslint-disable react/sort-comp */
import React from 'react';
import { flowRight as compose } from 'lodash';
import { Link } from 'react-router-dom';
import Resizer from 'react-image-file-resizer';

import s from './StrengthsScreen.module.scss';
import { ROUTE_PREFIX } from '../../../constants/router';
import withAuthentication, {
  LOGGED_MODE_USER,
  LOGGED_MODE_GUEST,
} from '../../Auth/AuthenticationWrapper';
import withInterestTestResults, {
  itestResultsType,
} from '../../HomeScreen/DataWrappers/InterestTestResultsWrapper';
import { checkIfTestResultsAreInvalid } from '../../HomeScreen/DataWrappers/TestCompletionRequiredWrapper';
import withBundesland from '../../HomeScreen/DataWrappers/BundeslandWrapper';
import withAkooeData, {
  akooeDataType,
} from '../../HomeScreen/DataWrappers/AkooeDataWrapper';
import ContentBody from '../../ContentBody/index';
import Footer from '../../Footer';
import OffersHeader from '../../OffersScreen/OffersHeader';
import StrengthCards from './StrengthCards/StrengthCards';
import StrengthCard from './StrengthCards/StrengthCard';
import CreateCustomStrengthCard from './StrengthCards/CreateCustomStrengthCard';
import StrengthCardsList from './StrengthCardsList/StrengthCardsList';
import Loading from '../../Loading';
import CreateCustomStrengthCardModal from './Modals/CreateCustomStrengthCardModal';
import ConfirmDeleteStrengthCardModal from './Modals/ConfirmDeleteStrengthCardModal';
import NoImageUploadAsGuestModal from './Modals/NoImageUploadAsGuestModal';
import ModifyStrengthCardImageModal from './Modals/ModifyStrengthCardImageModal';
import StrengthModuleExplanationModal from './Modals/StrengthModuleExplanationModal';

const STRENGTH_MODAL_EXPLANATION_MODAL_ID = 'strengthModuleExplanationDialog';

export const resizeFile = (file: any) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      'JPEG',
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      'base64',
    );
  });

export const parseModuleData = (userAkooeData: any) => {
  const moduleData =
    userAkooeData && userAkooeData.strengthsModuleData
      ? JSON.parse(userAkooeData.strengthsModuleData)
      : {};

  if (!moduleData.strengthCards) {
    moduleData.strengthCards = {};
  }

  if (!moduleData.strengthCards.categorization) {
    moduleData.strengthCards.categorization = {};
  }

  if (!moduleData.strengthCards.categorization.notCategorized) {
    moduleData.strengthCards.categorization.notCategorized = [];
  }

  if (!moduleData.strengthCards.categorization.matching) {
    moduleData.strengthCards.categorization.matching = [];
  }

  if (!moduleData.strengthCards.custom) {
    moduleData.strengthCards.custom = {};
  }

  if (!moduleData.strengthCards.custom.currentId) {
    moduleData.strengthCards.custom.currentId = 0;
  }

  if (!moduleData.strengthCards.custom.customCards) {
    moduleData.strengthCards.custom.customCards = [];
  }

  StrengthCards.forEach((sc) => {
    if (
      !moduleData.strengthCards.categorization.notCategorized.includes(sc.id) &&
      !moduleData.strengthCards.categorization.matching.includes(sc.id)
    ) {
      moduleData.strengthCards.categorization.notCategorized.push(sc.id);
    }
  });

  if (!moduleData.strengthCardImages) {
    moduleData.strengthCardImages = {};
  }

  if (!moduleData.strengthCardsFavorites) {
    moduleData.strengthCardsFavorites = {};
  }

  if (!moduleData.doNotShowAgainModals) {
    moduleData.doNotShowAgainModals = {};
  }

  // some cleanup
  moduleData.strengthCards.categorization.notCategorized =
    moduleData.strengthCards.categorization.notCategorized.filter(
      (id: string) => id != null,
    );
  moduleData.strengthCards.categorization.matching =
    moduleData.strengthCards.categorization.matching.filter(
      (id: string) => id != null,
    );
  moduleData.strengthCards.custom.customCards =
    moduleData.strengthCards.custom.customCards.filter(
      (id: string) => id != null,
    );
  Object.keys(moduleData.strengthCardsFavorites).forEach((id) => {
    if (
      !moduleData.strengthCards.categorization.notCategorized.includes(id) &&
      !moduleData.strengthCards.categorization.matching.includes(id)
    ) {
      delete moduleData.strengthCardsFavorites[id];
    }
  });

  return moduleData;
};

export const deleteCustomStrengthCard = async (thisRef: any, id: string) => {
  const { setStrengthsModuleData, refetchAkooeData } = thisRef.props;
  const { moduleData } = thisRef.state;

  const customIndex = moduleData.strengthCards.custom.customCards.findIndex(
    (sc: any) => sc.id === id,
  );
  if (customIndex >= 0) {
    moduleData.strengthCards.custom.customCards.splice(customIndex, 1);
  } else {
    return;
  }

  const notCategorizedIndex =
    moduleData.strengthCards.categorization.notCategorized.indexOf(id);
  if (notCategorizedIndex >= 0) {
    moduleData.strengthCards.categorization.notCategorized.splice(
      notCategorizedIndex,
      1,
    );
  }

  const matchingIndex =
    moduleData.strengthCards.categorization.matching.indexOf(id);
  if (matchingIndex >= 0) {
    moduleData.strengthCards.categorization.matching.splice(matchingIndex, 1);
  }

  if (moduleData.strengthCardsFavorites[id] === true) {
    delete moduleData.strengthCardsFavorites[id];
  }

  thisRef.setState({ moduleData });

  const success = await setStrengthsModuleData(JSON.stringify(moduleData));
  await thisRef.deleteImage(id);

  if (success) {
    await refetchAkooeData();
  }
};

interface StrengthsScreenProps {
  loggedMode: string;
  meData?: any;
  userItestResults?: itestResultsType;
  refetchSelectedBundesland: () => Promise<void>;
  selectedBundesland: string;
  // eslint-disable-next-line no-unused-vars
  setSelectedBundesland: (bundesland: string) => Promise<void>;
  userAkooeData: akooeDataType;
  refetchAkooeData: () => Promise<void>;
  // eslint-disable-next-line no-unused-vars
  setStrengthsModuleData: (data: any) => void;
  // eslint-disable-next-line no-unused-vars
  setStrengthCardImage: (id: string, imageBase64: any) => Promise<void>;
  refetchStrengthCardImages: () => Promise<void>;
  // eslint-disable-next-line no-unused-vars
  deleteStrengthCardImage: (id: string) => Promise<void>;
  allStrengthCardImages: any;
}

class StrengthsScreen extends React.Component<StrengthsScreenProps> {
  // eslint-disable-next-line react/state-in-constructor
  state: any;

  static defaultProps = {
    meData: null,
    userItestResults: null,
  };

  constructor(props: any) {
    super(props);

    const { userAkooeData } = props;
    const moduleData = parseModuleData(userAkooeData);

    this.state = {
      loading: false,
      upperListCardWidth: 0,
      lowerListCardWidth: 0,
      upperListIndex: 0,
      lowerListIndex: 0,
      moduleData,
      showCreateCustomStrengthCardModal: false,
      showConfirmDeleteStrengthCardModal: false,
      cardToDeleteId: null,
      showNoImageUploadAsGuestModal: false,
      showModifyStrengthCardImageModal: false,
      cardToModifyImage: null,
      showStrengthModuleExplanationModal:
        moduleData.doNotShowAgainModals[STRENGTH_MODAL_EXPLANATION_MODAL_ID] !==
        true,
    };

    this.selectCard = this.selectCard.bind(this);
    this.unSelectCard = this.unSelectCard.bind(this);
    this.onCreateCustomStrengthCard = this.onCreateCustomStrengthCard.bind(this); // eslint-disable-line prettier/prettier
    this.onImageUploadClicked = this.onImageUploadClicked.bind(this);
    this.uploadImage = this.uploadImage.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
    this.updateDoNotShowAgainModalStatus = this.updateDoNotShowAgainModalStatus.bind(this); // eslint-disable-line prettier/prettier
  }

  selectCard(id: string) {
    const { setStrengthsModuleData } = this.props;
    const { moduleData, lowerListIndex } = this.state;

    // eslint-disable-next-line prettier/prettier
    const index = moduleData.strengthCards.categorization.notCategorized.indexOf(id);
    if (index >= 0) {
      moduleData.strengthCards.categorization.notCategorized.splice(index, 1);
    }

    if (!moduleData.strengthCards.categorization.matching.includes(id)) {
      moduleData.strengthCards.categorization.matching.splice(
        Math.max(0, lowerListIndex - 1),
        0,
        id,
      );
    }

    this.setState({ moduleData });

    setStrengthsModuleData(JSON.stringify(moduleData));
  }

  unSelectCard(id: string) {
    const { setStrengthsModuleData } = this.props;
    const { moduleData, upperListIndex } = this.state;

    const index = moduleData.strengthCards.categorization.matching.indexOf(id);
    if (index >= 0) {
      moduleData.strengthCards.categorization.matching.splice(index, 1);
    }

    if (!moduleData.strengthCards.categorization.notCategorized.includes(id)) {
      moduleData.strengthCards.categorization.notCategorized.splice(
        upperListIndex,
        0,
        id,
      );
    }

    if (moduleData.strengthCardsFavorites[id] === true) {
      delete moduleData.strengthCardsFavorites[id];
    }

    this.setState({ moduleData });
    setStrengthsModuleData(JSON.stringify(moduleData));
  }

  onCreateCustomStrengthCard(formValues: any) {
    const { setStrengthsModuleData } = this.props;
    const { moduleData } = this.state;

    const customCard = {
      id: `custom-strength-card-${moduleData.strengthCards.custom.currentId}`,
      title: formValues.title,
      description: formValues.description,
    };

    moduleData.strengthCards.custom.customCards.push(customCard);
    moduleData.strengthCards.custom.currentId += 1;

    moduleData.strengthCards.categorization.matching.splice(
      0,
      0,
      customCard.id,
    );

    this.setState({
      moduleData,
      showCreateCustomStrengthCardModal: false,
    });

    setStrengthsModuleData(JSON.stringify(moduleData));
  }

  async onImageUploadClicked(id: string) {
    const { loggedMode } = this.props;

    if (loggedMode === LOGGED_MODE_GUEST) {
      this.setState({ showNoImageUploadAsGuestModal: true });
    } else if (loggedMode === LOGGED_MODE_USER) {
      this.setState({
        showModifyStrengthCardImageModal: true,
        cardToModifyImage: id,
      });
    }
  }

  async uploadImage(imageFile: any) {
    const { setStrengthCardImage, refetchStrengthCardImages } = this.props;
    const { cardToModifyImage } = this.state;

    this.setState({ loading: true });

    const imageBase64 = await resizeFile(imageFile);
    await setStrengthCardImage(cardToModifyImage, imageBase64);
    await refetchStrengthCardImages();

    this.setState({
      showModifyStrengthCardImageModal: false,
      loading: false,
    });
  }

  async deleteImage(id?: string) {
    const { deleteStrengthCardImage, refetchStrengthCardImages } = this.props;
    const { cardToModifyImage } = this.state;

    this.setState({ loading: true });

    await deleteStrengthCardImage(id != null ? id : cardToModifyImage);
    await refetchStrengthCardImages();

    this.setState({
      showModifyStrengthCardImageModal: false,
      loading: false,
    });
  }

  updateDoNotShowAgainModalStatus() {
    const { setStrengthsModuleData } = this.props;
    const { moduleData } = this.state;

    moduleData.doNotShowAgainModals[STRENGTH_MODAL_EXPLANATION_MODAL_ID] = true;

    setStrengthsModuleData(JSON.stringify(moduleData));
  }

  render() {
    const {
      loggedMode,
      meData,
      userItestResults,
      refetchSelectedBundesland,
      selectedBundesland,
      setSelectedBundesland,
      allStrengthCardImages,
    } = this.props;

    const {
      loading,
      upperListCardWidth,
      lowerListCardWidth,
      moduleData,
      showCreateCustomStrengthCardModal,
      showConfirmDeleteStrengthCardModal,
      cardToDeleteId,
      showNoImageUploadAsGuestModal,
      showModifyStrengthCardImageModal,
      showStrengthModuleExplanationModal,
    } = this.state;

    if (!moduleData) return <Loading />;

    const testResultsInvalid = checkIfTestResultsAreInvalid(userItestResults);
    const finishedTest = !testResultsInvalid;

    const nonSelectedStrengthCards =
      moduleData.strengthCards.categorization.notCategorized
        .map((id: string) => {
          let card = StrengthCards.find((sc) => sc.id === id);
          if (!card) {
            card = moduleData.strengthCards.custom.customCards.find(
              (sc: any) => sc.id === id,
            );
          }
          return card;
        })
        .filter((sc: any) => sc != null);

    const selectedStrengthCards =
      moduleData.strengthCards.categorization.matching
        .map((id: string) => {
          let card = StrengthCards.find((sc) => sc.id === id);
          if (!card) {
            card = moduleData.strengthCards.custom.customCards.find(
              (sc: any) => sc.id === id,
            );
          }
          return card;
        })
        .filter((sc: any) => sc != null);

    return (
      <div className={s.strengthsScreenContainer}>
        <OffersHeader
          bundesland={selectedBundesland}
          setSelectedBundesland={setSelectedBundesland}
          refetchSelectedBundesland={refetchSelectedBundesland}
          backUrl={`${ROUTE_PREFIX}/orientation/offers/${selectedBundesland}`}
        />
        <ContentBody>
          <div className={s.content}>
            <StrengthCardsList
              childIds={nonSelectedStrengthCards.map((sc: any) => sc.id)}
              cardWidth={upperListCardWidth}
              dragDirection="down"
              onDragSuccess={(index) => {
                this.selectCard(nonSelectedStrengthCards[index].id);
              }}
              onIndexChanged={(index) => {
                this.setState({ upperListIndex: index });
              }}
            >
              {nonSelectedStrengthCards.map((sc: any, index: number) => (
                <div key={sc.id} className={s.upperListCardWrapper}>
                  <StrengthCard
                    strengthCardData={nonSelectedStrengthCards[index]}
                    customImage={allStrengthCardImages[sc.id] || null}
                    onResize={
                      index === 0
                        ? (width) => {
                            this.setState({ upperListCardWidth: width });
                          }
                        : () => {}
                    }
                    onDelete={(id) => {
                      this.setState({
                        showConfirmDeleteStrengthCardModal: true,
                        cardToDeleteId: id,
                      });
                    }}
                    onImageUploadClicked={this.onImageUploadClicked}
                  />
                </div>
              ))}
            </StrengthCardsList>
            <div className={s.buttonContainer}>
              <Link
                to={`${ROUTE_PREFIX}/orientation/offers/${selectedBundesland}/modules/strengths/favorites`}
              >
                <button className="btn btn-secondary" type="button">
                  MEINE STÄRKEN
                </button>
              </Link>
            </div>
            <StrengthCardsList
              childIds={selectedStrengthCards.map((sc: any) => sc.id)}
              cardWidth={lowerListCardWidth}
              withCreateCustomCard
              dragDirection="up"
              onDragSuccess={(index) => {
                this.unSelectCard(selectedStrengthCards[index].id);
              }}
              onIndexChanged={(index) => {
                this.setState({ lowerListIndex: index });
              }}
            >
              {[
                <div
                  key="createCustomStrengthCard"
                  className={s.lowerListCardWrapper}
                >
                  <CreateCustomStrengthCard
                    onCreate={() => {
                      this.setState({
                        showCreateCustomStrengthCardModal: true,
                      });
                    }}
                    onResize={(width) => {
                      this.setState({ lowerListCardWidth: width });
                    }}
                  />
                </div>,
                ...selectedStrengthCards.map((sc: any, index: number) => (
                  <div key={sc.id} className={s.lowerListCardWrapper}>
                    <StrengthCard
                      strengthCardData={selectedStrengthCards[index]}
                      customImage={allStrengthCardImages[sc.id] || null}
                      onDelete={(id) => {
                        this.setState({
                          showConfirmDeleteStrengthCardModal: true,
                          cardToDeleteId: id,
                        });
                      }}
                      onImageUploadClicked={this.onImageUploadClicked}
                    />
                  </div>
                )),
              ]}
            </StrengthCardsList>
          </div>
        </ContentBody>
        <Footer
          loggedMode={loggedMode}
          meData={meData}
          finishedTest={finishedTest}
          forceShowExport
          exportUrl={`${ROUTE_PREFIX}/orientation/offers/${selectedBundesland}/export`}
          withEPortfolio
          refetchAkooeData={this.props.refetchAkooeData}
        />
        <CreateCustomStrengthCardModal
          showModal={showCreateCustomStrengthCardModal}
          closeModal={() => {
            this.setState({ showCreateCustomStrengthCardModal: false });
          }}
          onSubmit={this.onCreateCustomStrengthCard}
        />
        <ConfirmDeleteStrengthCardModal
          showModal={showConfirmDeleteStrengthCardModal}
          closeModal={() => {
            this.setState({ showConfirmDeleteStrengthCardModal: false });
          }}
          onSubmit={() => {
            deleteCustomStrengthCard(this, cardToDeleteId);
            this.setState({ showConfirmDeleteStrengthCardModal: false });
          }}
        />
        <NoImageUploadAsGuestModal
          showModal={showNoImageUploadAsGuestModal}
          closeModal={() => {
            this.setState({ showNoImageUploadAsGuestModal: false });
          }}
        />
        <ModifyStrengthCardImageModal
          showModal={showModifyStrengthCardImageModal}
          closeModal={() => {
            this.setState({ showModifyStrengthCardImageModal: false });
          }}
          onSelectImage={this.uploadImage}
          onResetImage={this.deleteImage}
        />
        <StrengthModuleExplanationModal
          showModal={showStrengthModuleExplanationModal}
          closeModal={(checked) => {
            if (checked) this.updateDoNotShowAgainModalStatus();
            this.setState({ showStrengthModuleExplanationModal: false });
          }}
        />
        {loading && <Loading />}
      </div>
    );
  }
}

export default compose(
  withAuthentication,
  withInterestTestResults,
  withBundesland,
  (comp) => withAkooeData(comp, true),
)(StrengthsScreen);
