/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Modal } from 'react-bootstrap';

import s from './StrengthCardsModal.module.scss';

interface ModifyStrengthCardImageModalProps {
  showModal: boolean;
  closeModal: () => void;
  // eslint-disable-next-line no-unused-vars
  onSelectImage: (imageFile: any) => void;
  onResetImage: () => void;
}

class ModifyStrengthCardImageModal extends React.Component<ModifyStrengthCardImageModalProps> {
  render() {
    const { closeModal, onSelectImage, onResetImage } = this.props;

    return (
      <div>
        <Modal
          centered
          show={this.props.showModal}
          backdrop="static"
          dialogClassName={s.modal}
        >
          <Modal.Header className={s.header}>
            <Modal.Title>
              <h3>Bild hinzufügen</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className={s.body}>
            <div className={s.info}>
              Füge ein eigenes Bild hinzu, das deine Stärken zum Ausdruck
              bringt. Du kannst auch auf das Standardbild zurücksetzen.
            </div>
            <div className={s.verticalbuttonContainer}>
              <button
                className="btn btn-secondary"
                type="button"
                onClick={closeModal}
              >
                ABBRUCH
              </button>
              <button
                className="btn btn-primary"
                type="button"
                onClick={() => {
                  onResetImage();
                }}
              >
                ZURÜCKSETZEN
              </button>
              <button
                className="btn btn-primary"
                type="button"
                // onClick={onSelectImage}
              >
                <label htmlFor="strengthCardImage">BILD AUSWÄHLEN</label>
              </button>
              <input
                name="strengthCardImage"
                id="strengthCardImage"
                type="file"
                accept=".jpg, .jpeg, .png"
                onChange={(event) => {
                  const imageFile =
                    event &&
                    event.target &&
                    event.target.files &&
                    event.target.files.length > 0
                      ? event.target.files[0]
                      : null;
                  onSelectImage(imageFile);
                }}
                hidden
              />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default ModifyStrengthCardImageModal;
