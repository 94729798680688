/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

import s from './FullScreenBubble.module.scss';

interface FullScreenBubbleProps {
  children: React.ReactNode;
}

class FullScreenBubble extends React.Component<FullScreenBubbleProps> {
  render() {
    return (
      <div className={s.bubbleContainer}>
        <div className={s.bubble}>{this.props.children}</div>
      </div>
    );
  }
}

export default FullScreenBubble;
