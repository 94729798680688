export const TEST_MODE_YOUNG = 'young';
export const TEST_MODE_OLD = '18+';
export const TEST_MODE_PILOT = TEST_MODE_YOUNG; // the current pilot mode
export const TEST_MODE_VERIFICATION = TEST_MODE_YOUNG; // the current verification mode

export const TEST_VERSION = 'v-2.0';
export const TEST_VERSION_OLD = 'v-1.0';
export const TEST_VERSION_PILOT = 'v-pilot-2';
export const TEST_VERSION_PILOT_OLD = 'v-pilot-2';
export const TEST_VERSION_VERIFICATION = 'v-verification-2';
export const TEST_VERSION_VERIFICATION_OLD = 'v-verification-2';
