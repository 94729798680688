/**
 * Scrolls to the top of a page once its rendered.
 * This is useful for navigation purposes, where you generally want
 * to start on the top of the page you're navigating though.
 *
 * Doesn't jump to the top when hitting the browser's back button,
 * which is desired behaviour since you usually want to end up
 * exactly where you were (including scroll position) if you
 * click back.
 */

import React from 'react';
import { Location } from 'react-router-dom';

import { withRouter } from '../../util/withRouter';

interface ScrollToTopProps {
  location: Location;
  children: React.ReactNode;
}

class ScrollToTop extends React.Component<ScrollToTopProps> {
  componentDidUpdate(prevProps: ScrollToTopProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
