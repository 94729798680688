/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/sort-comp */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { flowRight as compose } from 'lodash';
import { PieChart } from 'react-minimal-pie-chart';
import { connect } from 'react-redux';
import { reset } from 'redux-form';

import s from './ActivityModule.module.scss';
import { ROUTE_PREFIX } from '../../../constants/router';
import withAuthentication from '../../Auth/AuthenticationWrapper';
import withInterestTestResults, {
  itestResultsType,
} from '../../HomeScreen/DataWrappers/InterestTestResultsWrapper';
import { checkIfTestResultsAreInvalid } from '../../HomeScreen/DataWrappers/TestCompletionRequiredWrapper';
import withBundesland from '../../HomeScreen/DataWrappers/BundeslandWrapper';
import withAkooeData, {
  akooeDataType,
} from '../../HomeScreen/DataWrappers/AkooeDataWrapper';
import ContentBody from '../../ContentBody';
import OffersHeader from '../../OffersScreen/OffersHeader';
import Footer from '../../Footer';
// import zeitfresser from '../../../../assets/Zeitfresser_alt_white.png';
// import heart from '../../../../assets/heart_white.png';
// import wave from '../../../../assets/Welle_white.png';
// import plusIcon from '../../../../assets/PlusIcon.png';
// import plusIconNoBorder from '../../../../assets/PlusIconWithoutBorder2.png';
// import minusIconNoBorder from '../../../../assets/MinusIconWithoutBorder.png';
import PieChartLabel from './PieChartLabel';
import EditActivityModal from './EditActivityModal';
import ActivityListModal from './ActivityListModal';
import exp from '../../../constants/experiences';
import ExperienceTileMenuModal from './ExperienceTileMenuModal';
import Loading from '../../Loading';
import getAssetLink from '../../../util/getAssetLink';

interface ActivitiesScreenProps {
  loggedMode: string;
  meData?: any;
  userItestResults?: itestResultsType;
  refetchSelectedBundesland: () => Promise<void>;
  selectedBundesland: string;
  // eslint-disable-next-line no-unused-vars
  setSelectedBundesland: (bundesland: string) => Promise<void>;
  userAkooeData: akooeDataType;
  // eslint-disable-next-line no-unused-vars
  setActivityModuleData: (data: any) => void;
  clearEditForm: () => void;
}

class ActivitiesScreen extends React.Component<ActivitiesScreenProps> {
  // eslint-disable-next-line react/state-in-constructor
  state: any;

  static defaultProps = {
    meData: null,
    userItestResults: null,
  };

  constructor(props: any) {
    super(props);

    const moduleData =
      props.userAkooeData && props.userAkooeData.activityModuleData
        ? JSON.parse(props.userAkooeData.activityModuleData)
        : {};

    this.state = {
      moduleData,
      showEditModal: false,
      showListModal: false,
      defaultValues: {},
      createNew: true,
      showExperiencesModal: false,
      experience: {
        title: 'dummy',
        content: [],
        color: '#000000',
      },
    };
    this.calculateSlices = this.calculateSlices.bind(this);
    this.openEditModal = this.openEditModal.bind(this);
    this.closeEditModal = this.closeEditModal.bind(this);
    this.openListModal = this.openListModal.bind(this);
    this.closeListModal = this.closeListModal.bind(this);
    this.editActivity = this.editActivity.bind(this);
    this.deleteActivity = this.deleteActivity.bind(this);
    this.renderExperienceTiles = this.renderExperienceTiles.bind(this);
    this.renderExperienceTile = this.renderExperienceTile.bind(this);
    this.addExperience = this.addExperience.bind(this);
    this.renderExperienceMenuTiles = this.renderExperienceMenuTiles.bind(this);
    this.openExperiencesModal = this.openExperiencesModal.bind(this);
    this.closeExperiencesModal = this.closeExperiencesModal.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  calculateSlices(activities: Array<any>) {
    const size = {
      favorite: 0,
      zeitfresser: 0,
      misc: 0,
    };

    for (let i = 0; i < activities.length; i += 1) {
      (size as any)[activities[i].type] += activities[i].hours;
    }

    return size.favorite === 0 && size.zeitfresser === 0 && size.misc === 0
      ? [
          {
            title: 'zeitfresser',
            value: 1,
            color: '#333333',
            image: getAssetLink('Zeitfresser_alt_white.png'),
          },
          {
            title: 'misc',
            value: 1,
            color: '#fc9ba8',
            image: getAssetLink('Welle_white.png'),
          },
          {
            title: 'favorite',
            value: 1,
            color: '#be0021',
            image: getAssetLink('heart_white.png'),
          },
        ]
      : [
          {
            title: 'zeitfresser',
            value: size.zeitfresser,
            color: '#333333',
            image: getAssetLink('Zeitfresser_alt_white.png'),
          },
          {
            title: 'misc',
            value: size.misc,
            color: '#fc9ba8',
            image: getAssetLink('Welle_white.png'),
          },
          {
            title: 'favorite',
            value: size.favorite,
            color: '#be0021',
            image: getAssetLink('heart_white.png'),
          },
        ];
  }

  openEditModal(activity: any) {
    const { createNew, defaultValues } = activity
      ? {
          createNew: false,
          defaultValues: {
            name: activity.name,
            type: activity.type,
            hours: activity.hours,
          },
        }
      : {
          createNew: true,
          defaultValues: {
            name: '',
            type: '',
            hours: 0,
          },
        };
    if (createNew) this.props.clearEditForm();
    this.setState({ showEditModal: true, createNew, defaultValues });
  }

  closeEditModal() {
    this.setState(
      {
        showEditModal: false,
      },
      () => {
        this.props.clearEditForm();
      },
    );
  }

  openListModal() {
    this.setState({ showListModal: true });
  }

  closeListModal() {
    this.setState({ showListModal: false });
  }

  editActivity(activity: any, moduleData: any) {
    const { setActivityModuleData } = this.props;
    const { createNew, defaultValues } = this.state;

    if (createNew) {
      moduleData.activities.push(activity);
    } else {
      const index = moduleData.activities
        .map((a: any) => a.name)
        .indexOf(defaultValues.name);
      // eslint-disable-next-line no-param-reassign
      moduleData.activities[index] = activity;
    }
    setActivityModuleData(JSON.stringify(moduleData));

    this.setState({
      moduleData,
      showEditModal: false,
      loading: false,
    });
  }

  deleteActivity(activity: any, moduleData: any) {
    const { setActivityModuleData } = this.props;
    const index = moduleData.activities
      .map((a: any) => a.name)
      .indexOf(activity);

    if (index > -1) {
      moduleData.activities.splice(index, 1);
      setActivityModuleData(JSON.stringify(moduleData));
      this.setState({ moduleData });
    }
  }

  addExperience(experience: any, moduleData: any) {
    const { setActivityModuleData } = this.props;

    if (experience && !moduleData.experiences.includes(experience)) {
      // eslint-disable-next-line no-param-reassign
      moduleData.experiences = [...moduleData.experiences, experience];
      setActivityModuleData(JSON.stringify(moduleData));
      this.setState({ moduleData, loading: false });
    }
  }

  renderExperienceTiles(moduleData: any) {
    let experienceTiles: Array<any> = [];
    const assignedTiles: Array<any> = [];

    exp.forEach((experienceData) => {
      moduleData.experiences.forEach((experience: any) => {
        if (experienceData.content.includes(experience)) {
          experienceTiles.push(
            this.renderExperienceTile(
              experience,
              experienceData.color,
              moduleData,
            ),
          );
          assignedTiles.push(experience);
        }
      });
    });

    const customExperiences = moduleData.experiences.filter(
      (experience: any) => !assignedTiles.includes(experience),
    );

    const customtExperienceTiles: Array<any> = [];
    customExperiences.forEach((experience: any) => {
      customtExperienceTiles.push(
        this.renderExperienceTile(experience, '#C9C560', moduleData),
      );
    });

    experienceTiles = [...customtExperienceTiles, ...experienceTiles];

    return <div className={s.experienceTilesContainer}>{experienceTiles}</div>;
  }

  renderExperienceTile(content: any, color: string, moduleData: any) {
    return (
      <div className={s.tile} key={content} style={{ backgroundColor: color }}>
        <img
          src={getAssetLink('MinusIconWithoutBorder.png')}
          alt=""
          className={s.removeTileButton}
          onClick={() => this.removeTile(content, moduleData)}
        />
        <div className={s.tileContentContainer}>{content}</div>
      </div>
    );
  }

  removeTile(content: any, moduleData: any) {
    const { setActivityModuleData } = this.props;
    const index = moduleData.experiences.indexOf(content);

    if (index > -1) {
      moduleData.experiences.splice(index, 1);
      setActivityModuleData(JSON.stringify(moduleData));
      this.setState({ moduleData });
    }
  }

  renderExperienceMenuTiles(moduleData: any) {
    return (
      <div className={s.experienceMenuTilesContainer}>
        {exp.map((experience) => (
          <div
            className={s.experienceMenuTile}
            key={experience.title}
            onClick={() => this.openExperiencesModal(experience, moduleData)}
          >
            <img
              className={s.experienceMenuIcon}
              src={experience.icon}
              alt=""
            />
            <h3 style={{ color: `${experience.color}` }}>{experience.title}</h3>
          </div>
        ))}
      </div>
    );
  }

  openExperiencesModal(experience: any, moduleData: any) {
    const { title, color, icon } = experience;
    let { content } = experience;
    content = content.filter(
      (item: any) => !moduleData.experiences.includes(item),
    );
    this.setState({
      showExperiencesModal: true,
      experience: { title, content, color, icon },
    });
  }

  closeExperiencesModal() {
    this.setState({ showExperiencesModal: false });
  }

  render() {
    const {
      loggedMode,
      meData,
      userItestResults,
      selectedBundesland,
      setSelectedBundesland,
      refetchSelectedBundesland,
    } = this.props;

    const { moduleData } = this.state;
    if (!moduleData.activities) {
      moduleData.activities = [];
    }

    if (!moduleData.experiences) {
      moduleData.experiences = [];
    }

    const testResultsInvalid = checkIfTestResultsAreInvalid(userItestResults);
    const finishedTest = !testResultsInvalid;

    return [
      <div key="activitiesContainer" className={s.activitiesContainer}>
        <OffersHeader
          backUrl={`${ROUTE_PREFIX}/orientation/offers/${selectedBundesland}`}
          bundesland={selectedBundesland}
          setSelectedBundesland={setSelectedBundesland}
          refetchSelectedBundesland={refetchSelectedBundesland}
        />
        <ContentBody>
          <EditActivityModal
            closeModal={this.closeEditModal}
            showModal={this.state.showEditModal}
            onSubmit={(activity: any) => {
              this.editActivity(activity, moduleData);
            }}
            defaultValues={this.state.defaultValues}
            createNew={this.state.createNew}
          />
          <ActivityListModal
            closeModal={this.closeListModal}
            showModal={this.state.showListModal}
            delete={async (activity) => {
              this.deleteActivity(activity, moduleData);
            }}
            edit={this.openEditModal}
            activities={moduleData.activities}
          />
          <ExperienceTileMenuModal
            closeModal={this.closeExperiencesModal}
            showModal={this.state.showExperiencesModal}
            addItem={async (item) => {
              this.addExperience(item, moduleData);
            }}
            experience={this.state.experience}
          />
          <div className={s.headerSection}>
            <h1>MEINE AKTIVITÄTEN</h1>
            <h2>
              Mach dir einen Wochenüberblick zu deinen Aufgaben, Pflichten und
              Freizeitaktivitäten.
            </h2>
          </div>
          <div className={s.pieChartContainer}>
            <PieChart
              data={this.calculateSlices(moduleData.activities)}
              // eslint-disable-next-line react/no-unstable-nested-components
              label={(data) => (
                <PieChartLabel labelProps={data} key={data.dataIndex} />
              )}
              startAngle={270}
              onClick={() => this.openListModal()}
              style={{ cursor: 'pointer' }}
            />
            <img
              className={s.plusIcon}
              src={getAssetLink('PlusIcon.png')}
              alt=""
              onClick={() => this.openEditModal('')}
            />
          </div>
          <div className={s.headerSection}>
            <h1>NEUE ERFAHRUNGEN</h1>
            <h2>
              Durch vielfältige Erfahrungen eröffnen sich neue Ziele. Was
              möchtest du ausprobieren?
            </h2>
          </div>
          {this.renderExperienceTiles(moduleData)}
          <div className={s.experienceInputContainer}>
            <form
              id="add-experience-form"
              onSubmit={async (e) => {
                e.preventDefault();
                this.addExperience(
                  (e.target as any).elements.experience.value,
                  moduleData,
                );
                (document.getElementById('add-experience-form') as any).reset();
              }}
            >
              <input
                id="experience"
                type="text"
                name="experience"
                placeholder="..."
                className={s.experienceInputField}
              />
              <input
                type="image"
                className={s.plusIconNoBorder}
                src={getAssetLink('PlusIconWithoutBorder2.png')}
                alt=""
              />
            </form>
          </div>
          {this.renderExperienceMenuTiles(moduleData)}
        </ContentBody>
        <Footer
          loggedMode={loggedMode}
          meData={meData}
          finishedTest={finishedTest}
          forceShowExport
          exportUrl={`${ROUTE_PREFIX}/orientation/offers/${selectedBundesland}/export`}
          withEPortfolio
        />
      </div>,
      this.state.loading ? <Loading key="loading" /> : null,
    ];
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  clearEditForm: () => dispatch(reset('editActivity')),
});

export default compose(
  withAuthentication,
  withInterestTestResults,
  withBundesland,
  withAkooeData,
  connect(null, mapDispatchToProps),
)(ActivitiesScreen);
