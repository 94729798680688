import React from 'react';
import { Link, Params } from 'react-router-dom';
import { flowRight as compose } from 'lodash';
import { FaPlay } from 'react-icons/fa';

import s from './InterestDetailsScreen.module.scss';
import { ROUTE_PREFIX } from '../../constants/router';
import withAuthentication from '../Auth/AuthenticationWrapper';
import withInterestTestResults, {
  itestResultsType,
} from '../HomeScreen/DataWrappers/InterestTestResultsWrapper';
import testCompletionRequired from '../HomeScreen/DataWrappers/TestCompletionRequiredWrapper';
import Header from '../Header';
import Footer from '../Footer';
import ContentBody from '../ContentBody';
import {
  I_TYPE_NAME,
  I_TYPE_NAME_18_PLUS,
  I_TYPE_COLOR,
  I_TEXTS,
} from '../../constants/interests';
import RANK_TEXTS from '../../constants/rankTexts';
import InterestBar from '../InterestBar';
import { TEST_MODE_OLD, TEST_MODE_YOUNG } from '../../constants/testModes';

interface InterestDetailsScreenProps {
  loggedMode: string;
  meData?: any;
  userItestResults: itestResultsType;
  userItestMode: string;
  params: Readonly<Params<string>>;
}

class InterestDetailsScreen extends React.Component<InterestDetailsScreenProps> {
  static defaultProps = {
    meData: null,
  };

  constructor(props: any) {
    super(props);
    this.getInterestDetails = this.getInterestDetails.bind(this);
  }

  getInterestDetails(interests: Array<any>) {
    const { userItestMode } = this.props;

    const rank = interests.findIndex(
      (item) => item.interest === this.props.params.id,
    );

    let nameMap = I_TYPE_NAME;

    if (userItestMode === TEST_MODE_OLD) {
      nameMap = I_TYPE_NAME_18_PLUS;
    }

    let rankTextKey;
    switch (rank) {
      case 0:
        rankTextKey = 'rankOne';
        break;
      case 1:
        rankTextKey = 'rankTwo';
        break;
      case 2:
        rankTextKey = 'rankThree';
        break;
      case 3:
        rankTextKey = 'rankFour';
        break;
      case 4:
        rankTextKey = 'rankFive';
        break;
      case 5:
        rankTextKey = 'rankSix';
        break;
      default:
        rankTextKey = '';
        break;
    }

    return {
      name: nameMap[interests[rank].interest],
      percentage: interests[rank].percentage,
      color: I_TYPE_COLOR[interests[rank].interest],
      rankTextKey,
    };
  }

  render() {
    const { loggedMode, meData, userItestResults, userItestMode } = this.props;

    const interests = Object.keys(userItestResults.interestPercentages)
      .map((key) => ({
        interest: key,
        percentage: (userItestResults.interestPercentages as any)[key],
      }))
      .sort((i1, i2) => (i1.percentage < i2.percentage ? 1 : -1));

    const interestValues = this.getInterestDetails(interests);

    let doKey = 'do';
    let professionKey = 'profession';

    if (userItestMode === TEST_MODE_OLD) {
      doKey = 'do_18+';
      professionKey = 'profession_18+';
    }

    return (
      // eslint-disable-next-line
      <div className={s.interestScreenContainer}>
        <Header backUrl={`${ROUTE_PREFIX}/interests-overview`} />
        <ContentBody>
          <div className={s.contentHeader}>
            <h1 style={{ color: `${interestValues.color}` }}>
              {interestValues.name}
              {userItestMode === TEST_MODE_YOUNG && this.props.params.id === 'C'
                ? '*'
                : ''}
            </h1>
          </div>
          <div className={s.bar}>
            <InterestBar
              color={interestValues.color}
              percentage={interestValues.percentage}
              maxPercentage={interests[0].percentage}
            />
          </div>
          <h3
            className={s.rankText}
            style={{ color: `${interestValues.color}` }}
          >
            {RANK_TEXTS[interestValues.rankTextKey]}
          </h3>
          <Link
            className={s.playButtonLink}
            to={`${ROUTE_PREFIX}/interest-video/${this.props.params.id}`}
          >
            <button
              type="button"
              className={`btn ${this.props.params.id}-button`}
            >
              <FaPlay />
            </button>
          </Link>
          <div className={s.spacer} />
          <div className={s.section}>
            <h3>TÄTIGKEITEN</h3>
            <p>{I_TEXTS[this.props.params.id as string][doKey]}</p>
          </div>
          <div className={s.spacer} />
          <div className={s.section}>
            <h3>BERUFE</h3>
            <p>{I_TEXTS[this.props.params.id as string][professionKey]}</p>
          </div>
          {userItestMode === TEST_MODE_YOUNG &&
            this.props.params.id === 'C' && (
              <>
                <div className={s.spacer} />
                <div className={`${s.section} ${s.footnote}`}>
                  <p>
                    * Die Bezeichnung für diesen Interessenstyp wurde geändert.
                  </p>
                  <p>Vormals war die Bezeichnung „Datenverrückt“.</p>
                  <p>
                    Eventuell findest du manchmal noch die alte Bezeichnung in
                    Verwendung.
                  </p>
                </div>
              </>
            )}
        </ContentBody>
        <Footer loggedMode={loggedMode} meData={meData} />
      </div>
    );
  }
}

export default compose(
  withAuthentication,
  withInterestTestResults,
  testCompletionRequired,
)(InterestDetailsScreen);
