/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Link, NavigateFunction } from 'react-router-dom';
import { flowRight as compose } from 'lodash';
import { FaInfo } from 'react-icons/fa';

import { ROUTE_PREFIX } from '../../constants/router';
import s from './HomeScreen.module.scss';
import withAuthentication, {
  LOGGED_MODE_GUEST,
} from '../Auth/AuthenticationWrapper';
import withInterestTestResults, {
  itestResultsType,
} from './DataWrappers/InterestTestResultsWrapper';
import testCompletionRequired from './DataWrappers/TestCompletionRequiredWrapper';
import withSuggestedProfessions, {
  recommendedProfessionType,
} from './DataWrappers/SuggestedProfessionsWrapper';
import withFavoriteProfessions from './DataWrappers/FavoriteProfessionsWrapper';
import withBundesland from './DataWrappers/BundeslandWrapper';
import ContentBody from '../ContentBody';
import Header from '../Header';
import Footer from '../Footer';
import InterestBar from '../InterestBar';
import StatusBar from '../StatusBar';
import FavoriteProfessionsList from '../FavoriteProfessionsList';
import {
  I_TYPE_NAME,
  I_TYPE_NAME_18_PLUS,
  I_TYPE_COLOR,
} from '../../constants/interests';
import AccuracyModal from './AccuracyModal';
import RegisterModal from './RegisterModal';
import { TEST_MODE_YOUNG, TEST_MODE_OLD } from '../../constants/testModes';
import { withRouter } from '../../util/withRouter';

interface HomeScreenProps {
  loggedMode: string;
  meData?: any;
  userItestResults: itestResultsType;
  userItestMode: string;
  recommendedProfessions: Array<recommendedProfessionType>;
  favoriteProfessions: Array<string>;
  selectedBundesland?: string;
  navigate: NavigateFunction;
}

class HomeScreen extends React.Component<HomeScreenProps> {
  // eslint-disable-next-line react/state-in-constructor
  state: any;

  static defaultProps = {
    meData: null,
    selectedBundesland: '',
  };

  constructor(props: any) {
    super(props);

    this.state = {
      showRegisterModal: false,
      showAccuracyModal: false,
    };

    this.getTopInterests = this.getTopInterests.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  getTopInterests(interestsMap: any) {
    return Object.keys(interestsMap)
      .map((key) => ({ interest: key, percentage: interestsMap[key] }))
      .sort((i1, i2) => (i1.percentage < i2.percentage ? 1 : -1))
      .slice(0, 3);
  }

  render() {
    const {
      loggedMode,
      userItestResults,
      userItestMode,
      recommendedProfessions,
      favoriteProfessions,
      selectedBundesland,
    } = this.props;

    const { showRegisterModal, showAccuracyModal } = this.state;

    const topInterests = this.getTopInterests(
      userItestResults.interestPercentages,
    );

    // filter out favorite professions
    let favoriteProfessionsData = [];
    favoriteProfessionsData = recommendedProfessions.filter((p) =>
      favoriteProfessions.includes(p.key),
    );

    let orientationUrlPart = 'orientation';
    let nameMap = I_TYPE_NAME;

    if (userItestMode === TEST_MODE_OLD) {
      orientationUrlPart = 'reorientation';
      nameMap = I_TYPE_NAME_18_PLUS;
    }

    return (
      <div className={s.homeScreenContainer}>
        <Header withBackButton={false} />
        <ContentBody>
          <AccuracyModal
            showModal={showAccuracyModal}
            hideModal={() => {
              this.setState({ showAccuracyModal: false });
            }}
          />
          <RegisterModal
            showModal={showRegisterModal}
            closeModal={() => {
              this.setState({ showRegisterModal: false });
            }}
          />
          {loggedMode === LOGGED_MODE_GUEST && (
            <div className={s.guestBanner}>
              Du bist als Gast angemeldet. Registriere dich, um deine bisherigen
              Daten zu übernehmen.
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  this.setState({ showRegisterModal: true });
                }}
              >
                REGISTRIEREN
              </button>
            </div>
          )}
          <div className={s.results}>
            <Link to={`${ROUTE_PREFIX}/interests-overview`}>
              <button type="button" className="btn btn-secondary">
                DEINE INTERESSEN
              </button>
            </Link>
          </div>
          <div className={s.interestBars}>
            <div className={s.interestBarsInner}>
              <div
                className={s.column}
                onClick={() => {
                  this.props.navigate(
                    `${ROUTE_PREFIX}/interest/${topInterests[2].interest}`,
                  );
                }}
              >
                <div className={s.bar}>
                  <InterestBar
                    color={I_TYPE_COLOR[topInterests[2].interest]}
                    percentage={topInterests[2].percentage}
                    maxPercentage={topInterests[0].percentage}
                  />
                </div>
                <div
                  className={s.label}
                  style={{
                    color: `${I_TYPE_COLOR[topInterests[2].interest]}`,
                  }}
                >
                  {nameMap[topInterests[2].interest]}
                </div>
              </div>
              <div
                className={s.column}
                onClick={() => {
                  this.props.navigate(
                    `${ROUTE_PREFIX}/interest/${topInterests[0].interest}`,
                  );
                }}
              >
                <div className={s.bar}>
                  <InterestBar
                    color={I_TYPE_COLOR[topInterests[0].interest]}
                    percentage={topInterests[0].percentage}
                    maxPercentage={topInterests[0].percentage}
                  />
                </div>
                <div
                  className={s.label}
                  style={{
                    color: `${I_TYPE_COLOR[topInterests[0].interest]}`,
                  }}
                >
                  {nameMap[topInterests[0].interest]}
                </div>
              </div>
              <div
                className={s.column}
                onClick={() => {
                  this.props.navigate(
                    `${ROUTE_PREFIX}/interest/${topInterests[1].interest}`,
                  );
                }}
              >
                <div className={s.bar}>
                  <InterestBar
                    color={I_TYPE_COLOR[topInterests[1].interest]}
                    percentage={topInterests[1].percentage}
                    maxPercentage={topInterests[0].percentage}
                  />
                </div>
                <div
                  className={s.label}
                  style={{
                    color: `${I_TYPE_COLOR[topInterests[1].interest]}`,
                  }}
                >
                  {nameMap[topInterests[1].interest]}
                </div>
              </div>
            </div>
          </div>
          <div className={s.accuracyBarContainer}>
            <div className={s.accuracyBarContainerInner}>
              <div className={s.accuracyBarHeader}>
                <span>
                  {`Profilgenauigkeit: ${userItestResults.diffScore.percentage}%`}
                </span>
                <button
                  type="button"
                  onClick={() => {
                    this.setState({ showAccuracyModal: true });
                  }}
                >
                  <FaInfo />
                </button>
              </div>
              <StatusBar percentage={userItestResults.diffScore.percentage} />
            </div>
          </div>

          <div className={s.professions}>
            <Link to={`${ROUTE_PREFIX}/professions`}>
              <button type="button" className="btn btn-secondary">
                DEINE BERUFE
              </button>
            </Link>
          </div>
          <FavoriteProfessionsList professions={favoriteProfessionsData} />
          <div className={s.offers}>
            <Link
              to={`${ROUTE_PREFIX}/${orientationUrlPart}/bundesland-selection`}
            >
              <button type="button" className="btn btn-secondary">
                {userItestMode === TEST_MODE_YOUNG &&
                (selectedBundesland === 'oberösterreich' ||
                  selectedBundesland === 'burgenland' ||
                  selectedBundesland === 'steiermark')
                  ? 'DEIN PORTFOLIO'
                  : 'ANGEBOTE'}
              </button>
            </Link>
          </div>
        </ContentBody>
        <Footer loggedMode={loggedMode} meData={this.props.meData} />
      </div>
    );
  }
}

export default compose(
  withAuthentication,
  withInterestTestResults,
  testCompletionRequired,
  withSuggestedProfessions,
  (comp) => withFavoriteProfessions(comp, true),
  withBundesland,
  withRouter,
)(HomeScreen);
