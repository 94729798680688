import React from 'react';
import ReactPlayer from 'react-player';
import { flowRight as compose } from 'lodash';
import { FaVolumeUp, FaVolumeMute } from 'react-icons/fa';
import { NavigateFunction, Params } from 'react-router-dom';

import { ROUTE_PREFIX } from '../../constants/router';
import s from '../AKOOE/IntroVideoScreen/IntroVideoScreen.module.scss';
import withAuthentication from '../Auth/AuthenticationWrapper';
import { userInteractionData } from '../Layout';

const INTEREST_EXPLANATION_VIDEO_SKIP_BUTTON_TIMER_DURATION = 0;

interface InterestExplanationVideoScreenProps {
  params: Readonly<Params<string>>;
  location: Location;
  navigate: NavigateFunction;
  mode: string;
}

class InterestExplanationVideoScreen extends React.Component<InterestExplanationVideoScreenProps> {
  // eslint-disable-next-line react/state-in-constructor
  state: any;

  skipButtonTimeout: any = null;

  constructor(props: any) {
    super(props);

    this.state = {
      muted: !userInteractionData.siteInteraction,
      showSkipPlayer: false,
    };

    this.onToggleMute = this.onToggleMute.bind(this);
    this.onEnded = this.onEnded.bind(this);
  }

  componentDidMount() {
    this.skipButtonTimeout = setTimeout(() => {
      this.setState({ showSkipPlayer: true });
    }, INTEREST_EXPLANATION_VIDEO_SKIP_BUTTON_TIMER_DURATION);
  }

  componentWillUnmount() {
    if (this.skipButtonTimeout) {
      clearTimeout(this.skipButtonTimeout);
    }
  }

  onToggleMute() {
    const { muted } = this.state;

    this.setState({
      muted: !muted,
    });
  }

  onEnded() {
    const { mode, navigate } = this.props;
    const { interest } = this.props.params;
    const { search } = this.props.location;

    if (mode == null) {
      if (search != null && search.includes('return=overview')) {
        navigate(`${ROUTE_PREFIX}/interests-overview`);
      } else {
        navigate(`${ROUTE_PREFIX}/interest/${interest}`);
      }
    } else {
      navigate(`${ROUTE_PREFIX}/${mode}/offers/wien`);
    }
  }

  render() {
    const { interest } = this.props.params;
    let videoUrl = null;

    if (interest === 'R') {
      videoUrl = '/appimages/explanation-videos/7_Macherin_v4_22122020.mp4';
    } else if (interest === 'I') {
      videoUrl = '/appimages/explanation-videos/4_denkerin_v2_14122020.mp4';
    } else if (interest === 'A') {
      videoUrl = '/appimages/explanation-videos/3_gestalterin_v5_14122020.mp4';
    } else if (interest === 'S') {
      videoUrl = '/appimages/explanation-videos/5_Helferin_v2_14122020_1.mp4';
    } else if (interest === 'E') {
      videoUrl = '/appimages/explanation-videos/2_Überzeugerin_v6_14122020.mp4';
    } else if (interest === 'C') {
      videoUrl =
        '/appimages/explanation-videos/6_Datenverrückt_v2_14122020.mp4';
    }

    if (videoUrl === null) {
      return <div className={s.errorContainer}>Error: Kein Video gefunden</div>;
    }

    return (
      <div className={s.introVideoContainer}>
        <div className={s.player}>
          <ReactPlayer
            playing
            muted={this.state.muted}
            url={videoUrl}
            width="100%"
            height="100%"
            onEnded={this.onEnded}
          />
        </div>
        {this.state.showSkipPlayer === true ? (
          <div className={s.buttonsContainer}>
            <button
              type="button"
              className={s.buttonMute}
              onClick={this.onToggleMute}
            >
              {this.state.muted ? <FaVolumeMute /> : <FaVolumeUp />}
            </button>
            <button type="button" onClick={this.onEnded}>
              Überspringen
            </button>
          </div>
        ) : null}
      </div>
    );
  }
}

export default compose(withAuthentication)(InterestExplanationVideoScreen);
