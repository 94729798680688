/* eslint-disable no-nested-ternary */
import React from 'react';
import { NavigateFunction } from 'react-router-dom';

import { ROUTE_PREFIX } from '../../constants/router';
import s from './Offers.module.scss';
import Header from '../Header/index';
import Loading from '../Loading';
import { withRouter } from '../../util/withRouter';

interface OffersHeaderProps {
  withBackButton?: boolean;
  backUrl?: string;
  bundesland?: string;
  refetchSelectedBundesland?: () => Promise<void>;
  // eslint-disable-next-line no-unused-vars
  setSelectedBundesland?: (bundesland: string | null) => Promise<void>;
  navigate: NavigateFunction;
  location: Location;
}

class OffersHeader extends React.Component<OffersHeaderProps> {
  // eslint-disable-next-line react/state-in-constructor
  state: any;

  static defaultProps = {
    withBackButton: true,
    backUrl: `${ROUTE_PREFIX}/home`,
    bundesland: null,
    refetchSelectedBundesland: () => {},
    setSelectedBundesland: () => {},
  };

  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
    };

    this.setNullAndRefetchBundesland = this.setNullAndRefetchBundesland.bind(this); // eslint-disable-line prettier/prettier
  }

  async setNullAndRefetchBundesland() {
    const { location } = this.props;
    const mode = location.pathname.includes('reorientation')
      ? 'reorientation'
      : 'orientation';
    this.setState({ loading: true });
    if (this.props.setSelectedBundesland) {
      await this.props.setSelectedBundesland(null);
    }
    if (this.props.refetchSelectedBundesland) {
      await this.props.refetchSelectedBundesland();
    }
    this.props.navigate(`${ROUTE_PREFIX}/${mode}/bundesland-selection`);
  }

  render() {
    const { loading } = this.state;
    const { withBackButton, backUrl, bundesland } = this.props;

    return [
      <Header withBackButton={withBackButton} backUrl={backUrl} key="header">
        <button
          type="button"
          className={`btn btn-secondary ${s.noButton}`}
          onClick={() => {
            this.setNullAndRefetchBundesland();
          }}
        >
          {bundesland === 'wien' ? (
            <h1 className={s.headerLink}>AK WIEN</h1>
          ) : bundesland === 'salzburg' ? (
            <h1 className={s.headerLink}>AK SALZBURG</h1>
          ) : bundesland === 'vorarlberg' ? (
            <h1 className={s.headerLink}>AK VORARLBERG</h1>
          ) : bundesland === 'tirol' ? (
            <h1 className={s.headerLink}>AK TIROL</h1>
          ) : bundesland === 'steiermark' ? (
            <h1 className={s.headerLink}>AK STEIERMARK</h1>
          ) : bundesland === 'oberösterreich' ? (
            <h1 className={s.headerLink}>AK OBERÖSTERREICH</h1>
          ) : bundesland === 'niederösterreich' ? (
            <h1 className={s.headerLink}>AK NIEDERÖSTERREICH</h1>
          ) : bundesland === 'burgenland' ? (
            <h1 className={s.headerLink}>AK BURGENLAND</h1>
          ) : bundesland === 'kärnten' ? (
            <h1 className={s.headerLink}>AK KÄRNTEN</h1>
          ) : null}
        </button>
      </Header>,
      loading ? <Loading key="loading" /> : null,
    ];
  }
}

export default withRouter(OffersHeader);
