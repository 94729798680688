/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Modal } from 'react-bootstrap';
import { FiSquare, FiCheckSquare } from 'react-icons/fi';
import getAssetLink from '../../../../util/getAssetLink';

import s from './StrengthCardsModal.module.scss';

interface ImageExplanationModalProps {
  showModal: boolean;
  // eslint-disable-next-line no-unused-vars
  closeModal: (doNotShowAgainChecked: boolean) => void;
}

class ImageExplanationModal extends React.Component<ImageExplanationModalProps> {
  // eslint-disable-next-line react/state-in-constructor
  state: any;

  constructor(props: any) {
    super(props);

    this.state = {
      doNotShowAgainChecked: false,
    };
  }

  render() {
    const { doNotShowAgainChecked } = this.state;

    return (
      <div>
        <Modal
          centered
          show={this.props.showModal}
          backdrop="static"
          dialogClassName={s.modal}
        >
          <Modal.Header className={s.header}>
            <Modal.Title>
              <h3>Bilder hinzufügen</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className={s.body}>
            <div className={s.info}>
              Füge ein Bild von deiner Stärke hinzu. Klicke hierfür auf das
              Kamera-Icon in den Stärkekarten.
            </div>
            <div className={s.modalImageContainer}>
              <img src={getAssetLink('Camera.png')} alt="" />
            </div>
            <div className={s.buttonContainerTwoButtons}>
              <div className={s.selectWrapper}>
                <input
                  type="checkbox"
                  id="doNotShowAgain"
                  checked={this.state.doNotShowAgainChecked}
                  onChange={() => {
                    this.setState({
                      doNotShowAgainChecked: !doNotShowAgainChecked,
                    });
                  }}
                  hidden
                />
                <label htmlFor="doNotShowAgain">
                  {doNotShowAgainChecked ? <FiCheckSquare /> : <FiSquare />}
                  <span>Nicht mehr anzeigen</span>
                </label>
              </div>
              <button
                className="btn btn-primary"
                type="button"
                onClick={() => {
                  this.props.closeModal(doNotShowAgainChecked);
                }}
              >
                OK
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default ImageExplanationModal;
