import React from 'react';
import { flowRight as compose } from 'lodash';

import s from './ResultsExplanationScreen.module.scss';
import withAuthentication from '../Auth/AuthenticationWrapper';
import withInterestTestResults from '../HomeScreen/DataWrappers/InterestTestResultsWrapper';
import ContentBody from '../ContentBody';
import Header from '../Header';
import Footer from '../Footer';
import withAccessTracker from '../AccessTracker';

interface ResultsExplanationScreenProps {
  loggedMode: string;
  meData?: any;
}

class ResultsExplanationScreen extends React.Component<ResultsExplanationScreenProps> {
  static defaultProps = {
    meData: null,
  };

  constructor(props: any) {
    super(props);
    this.renderTexts = this.renderTexts.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  renderTexts() {
    return (
      <div>
        <div className={s.section}>
          <h3>Wissenschaftliche Grundlage von Jopsy</h3>
          <p>
            Die Grundlage von Jopsy ist das bewährte RIASEC-Modell von John
            Holland, das 6 verschiedene Interessenstypen umfasst:
            praktisch-technisch („Macher/in“), intellektuell-forschend
            („Denker/in“), künstlerisch-sprachlich („Gestalter/in“), sozial
            („Helfer/in“), unternehmerisch („Überzeuger/in“) und konventionell
            („Organisierte“). Die Ergebnisse von Jopsy sind valide und von
            Psycholog/-innen mit Hilfe von verschiedenen wissenschaftlichen
            Analysen abgesichert.
          </p>
        </div>
        <div className={s.section}>
          <h3>Prozentzahlen im Interessenprofil</h3>
          <p>
            Die Prozentangaben bei deinem Interessenprofil zeigen, wie gut diese
            zu dir passen. Dein Ergebnis wird mit jenen von anderen Jugendlichen
            verglichen. Es beschreibt also, wie stark oder gering deine
            Ausprägung im jeweiligen Bereich im Vergleich zu anderen
            Jugendlichen ist. Je höher die Zahl ist, desto besser passt der
            jeweilige Interessensbereich zu dir.
          </p>
        </div>
        <div className={s.section}>
          <h3>Profilgenauigkeit</h3>
          <p>
            Wie eindeutig deine Interessen ausgeprägt sind, kannst du auch an
            deiner Profilgenauigkeit erkennen. Je höher der Wert ist, desto
            eindeutiger sind deine Interessen. Bei einer niedrigen
            Profilgenauigkeit ist es genau umgekehrt, deine Interessen sind sehr
            breit gestreut und alle Interessenstypen passen in etwa gleich gut
            zu dir. Ist die Profilgenauigkeit zu gering um eindeutige Ergebnisse
            zu liefern, wird dir das in der App angezeigt.
          </p>
        </div>
        <div className={s.section}>
          <h3>Berufsvorschläge</h3>
          <p>
            Zu unterschiedlichen Tätigkeiten passen bestimmte Berufe. Daher kann
            Jopsy auf Basis deiner Angaben aus ca. 750 verschiedenen Berufen,
            jene vorschlagen, die zu deinen Interessen am besten passen. Für
            manche geht der richtige Weg zum Traumberuf über eine Lehre, für
            andere über eine weiterführende Schule und/oder ein Studium. Daher
            bietet Jopsy Berufsvorschläge auf allen Bildungsebenen an. In der
            App gibt es eine Filterfunktion, damit kann man die Berufsvorschläge
            nach Ausbildungslevel sortieren.
          </p>
          <p>
            In Jopsy findest du für jeden Beruf eine Kurzbeschreibung, basierend
            auf dem Berufsregister des AMS (Arbeitsmarktservice)&nbsp;
            <a href="http://www.berufslexikon.at">www.berufslexikon.at</a>. Hier
            findest du noch umfangreichere Informationen zu den Berufen.
          </p>
        </div>
        <div className={s.section}>
          <h3>Ergebnisse per Mail versenden</h3>
          <p>
            Die Ergebnisse können über die Mail-Funktion versendet werden. So
            kann man Feedback einholen und mit anderen Personen (Eltern,
            Freunde, Bildungs- und Berufsberatung, etc.) über die weiteren
            Schritte reden.
          </p>
        </div>
        <div className={s.section}>
          <h3>Favoritenfunktion</h3>
          <p>
            Das Herzsymbol bei den Berufsvorschlägen ermöglicht eine
            Favoritenliste für Berufe anzulegen, die dich am meisten
            interessieren. Diese Berufe werden bei deinem Jopsy-Ergebnis zuerst
            angezeigt.
          </p>
        </div>
      </div>
    );
  }

  render() {
    const { loggedMode, meData } = this.props;

    return (
      <div className={s.resultsExplanationContainer}>
        <Header />
        <ContentBody>
          <div className={s.resultsExplanationHeader}>
            <h1>ERKLÄRUNG DER ERGEBNISSE</h1>
          </div>
          {this.renderTexts()}
        </ContentBody>
        <Footer loggedMode={loggedMode} meData={meData} />
      </div>
    );
  }
}

export default compose(
  withAccessTracker('Ergebniserklärung'),
  withAuthentication,
  withInterestTestResults,
)(ResultsExplanationScreen);
