/* eslint-disable no-console */
import { ApolloClient, ApolloLink, from, HttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import createCache from './createCache';

const link = from([
  new ApolloLink((operation, forward) => {
    const token = sessionStorage.getItem('id_token');
    const authHeader = token ? `Bearer ${token}` : null;
    operation.setContext({
      headers: {
        Authorization: authHeader,
      },
    });

    return forward(operation);
  }),
  onError((test) => {
    const { graphQLErrors, networkError } = test;
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.warn(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        );
      });
    }
    if (networkError) console.warn(`[Network error]: ${networkError}`);
    if (graphQLErrors && graphQLErrors.length === 1 && networkError) {
      networkError.message = graphQLErrors[0].message;
    }
  }),
  new HttpLink({
    uri: '/graphql',
    credentials: 'include',
  }),
]);

const cache = createCache();

export default function createApolloClient() {
  return new ApolloClient({
    link,
    cache: cache.restore((window as any).App.apolloState),
    queryDeduplication: true,
    connectToDevTools: true,
    defaultOptions: {
      mutate: {
        errorPolicy: 'all',
      },
      query: {
        errorPolicy: 'all',
      },
    },
  });
}
