import React from 'react';
import { NavigateFunction } from 'react-router-dom';
import gql from 'graphql-tag';
import { flowRight as compose } from 'lodash';

import s from '../login/Login.module.scss';
import startScreenStyle from '../../StartScreen/StartScreen.module.scss';
import AppContext, { AppContextType } from '../../../context';
import { ROUTE_PREFIX } from '../../../constants/router';
import RegistrationForm from './RegistrationForm';
import { withLogin } from '../withLogin';
import withAuthentication, { LOGGED_MODE_USER } from '../AuthenticationWrapper';
import { evaluateRegisterInformation } from './evaluateRegistration';
import Loading from '../../Loading';
import { withRouter } from '../../../util/withRouter';
import getAssetLink from '../../../util/getAssetLink';
import { withSEO } from '../../SEO/withSEO';

const registrationMutation = gql`
  mutation insertUser($user: UserInput!) {
    insertUser(user: $user) {
      name
    }
  }
`;

interface RegisterProps {
  loggedMode?: string;
  // eslint-disable-next-line no-unused-vars
  login: (name: string, password: string) => Promise<boolean>;
  navigate: NavigateFunction;
}

class Register extends React.Component<RegisterProps> {
  static contextType = AppContext;

  // eslint-disable-next-line react/state-in-constructor
  state: any;

  static defaultProps = {
    loggedMode: null,
  };

  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
      errors: [],
    };

    this.register = this.register.bind(this);
    this.submitRegistrationForm = this.submitRegistrationForm.bind(this);
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  setErrors(errors: Array<any>) {
    this.setState({
      errors: errors.map((err) => err.message),
    });
  }

  async register(name: string, password: string) {
    const standardErrorMessage =
      'Beim Registrieren ist ein Fehler aufgetreten.';

    try {
      const response = await (this.context as AppContextType).client.mutate({
        mutation: registrationMutation,
        variables: {
          user: {
            name,
            password,
            favoriteProfessionsFilter: 'EO',
          },
        },
      });

      if (response.data && response.data.insertUser) {
        return true;
      }

      if (response.errors) {
        this.setState({
          loading: false,
          errors: response.errors.map((err) => err.message),
        });
      } else {
        this.setState({
          loading: false,
          errors: [standardErrorMessage],
        });
      }
      return false;
    } catch (error: any) {
      if (error.message) {
        let msg = error.message.split('Network error:');
        msg = msg.length > 1 ? msg[1] : standardErrorMessage;
        this.setState({
          loading: false,
          errors: [msg],
        });
      } else {
        this.setState({
          loading: false,
          errors: [standardErrorMessage],
        });
      }
      return false;
    }
  }

  async submitRegistrationForm({ username, password, confirmPassword }: any) {
    const evalData = evaluateRegisterInformation(
      username,
      password,
      confirmPassword,
    );

    if (!evalData.valid) {
      this.setState({
        loading: false,
        errors: evalData.errors,
      });
      return;
    }

    this.setState({
      loading: true,
      errors: [],
    });

    const registeredSuccessfully = await this.register(username, password);
    if (!registeredSuccessfully) {
      return;
    }

    const { navigate, login } = this.props;
    const result = await login(username, password);

    if (!result) {
      this.setState({
        loading: false,
        errors: ['Anmeldung fehlgeschlagen.'],
      });
      return;
    }

    this.setState({ errors: [] }, () => {
      navigate(`${ROUTE_PREFIX}/`);
    });
  }

  render() {
    const { loggedMode, navigate } = this.props;

    if (loggedMode === LOGGED_MODE_USER) {
      navigate(`${ROUTE_PREFIX}/`);
      return <Loading />;
    }

    return [
      <div
        className={startScreenStyle.startScreenContainer}
        key="registerScreenContainer"
        data-nosnippet
      >
        <img
          className={startScreenStyle.akLogo}
          src={getAssetLink('AKLogo.png')}
          alt=""
        />
        <div className={startScreenStyle.textContainer}>
          Deine Interessen.
          <br />
          Deine Entscheidung.
          <br />
          Dein Weg.
        </div>
        <div className={s.loginContainer}>
          <RegistrationForm
            onSubmit={this.submitRegistrationForm}
            errors={this.state.errors}
          />
        </div>
      </div>,
      this.state.loading && <Loading key="loading" />,
    ];
  }
}

export default compose(
  withSEO({
    title: 'Jopsy | Registrierung',
    description: 'Account erstellen',
  }),
  withAuthentication,
  withLogin,
  withRouter,
)(Register);
