import React from 'react';
import getAssetLink from '../../../util/getAssetLink';

import s from './JobExpectationCard.module.scss';

interface JobExpectationCardProps {
  id: string;
  value?: number;
  title: string;
  // eslint-disable-next-line no-unused-vars
  onChange: (id: string, value: number) => void;
}

class JobExpectationCard extends React.Component<JobExpectationCardProps> {
  static defaultProps = {
    value: null,
  };

  constructor(props: any) {
    super(props);

    this.onSmileyClick = this.onSmileyClick.bind(this); // eslint-disable-line prettier/prettier
  }

  onSmileyClick = (selection: number) => {
    const { id, onChange } = this.props;
    onChange(id, selection);
  };

  render() {
    const { value, title } = this.props;

    return (
      <div className={s.card}>
        <h2>{title}</h2>
        <button
          type="button"
          className={`btn btn-secondary ${s.noButton}`}
          onClick={() => {
            this.onSmileyClick(1);
          }}
        >
          <img
            src={getAssetLink(
              value === 1 ? 'smiley_1_selected.png' : 'smiley_1.png',
            )}
            alt=""
          />
        </button>
        <button
          type="button"
          className={`btn btn-secondary ${s.noButton}`}
          onClick={() => {
            this.onSmileyClick(2);
          }}
        >
          <img
            src={getAssetLink(
              value === 2 ? 'smiley_2_selected.png' : 'smiley_2.png',
            )}
            alt=""
          />
        </button>
        <button
          type="button"
          className={`btn btn-secondary ${s.noButton}`}
          onClick={() => {
            this.onSmileyClick(3);
          }}
        >
          <img
            src={getAssetLink(
              value === 3 ? 'smiley_3_selected.png' : 'smiley_3.png',
            )}
            alt=""
          />
        </button>
        <button
          type="button"
          className={`btn btn-secondary ${s.noButton}`}
          onClick={() => {
            this.onSmileyClick(4);
          }}
        >
          <img
            src={getAssetLink(
              value === 4 ? 'smiley_4_selected.png' : 'smiley_4.png',
            )}
            alt=""
          />
        </button>
      </div>
    );
  }
}

export default JobExpectationCard;
