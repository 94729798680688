/* eslint-disable no-nested-ternary */
import React from 'react';
import getAssetLink from '../../../util/getAssetLink';

import s from './FavoriteJobExpectationLine.module.scss';

interface FavoriteJobExpectationLineProps {
  id: string;
  value: number;
  text: string;
  isFavorite: boolean;
  // eslint-disable-next-line no-unused-vars
  onSetFavorite: (id: string, favorite: boolean) => void;
}

class FavoriteJobExpectationLine extends React.Component<FavoriteJobExpectationLineProps> {
  render() {
    const { id, value, text, isFavorite, onSetFavorite } = this.props;

    let smiley;
    switch (value) {
      case 1:
        smiley = getAssetLink('smiley_1_selected.png');
        break;
      case 2:
        smiley = getAssetLink('smiley_2_selected.png');
        break;
      case 3:
        smiley = getAssetLink('smiley_3_selected.png');
        break;
      case 4:
        smiley = getAssetLink('smiley_4_selected.png');
        break;
      default:
        break;
    }

    return (
      <div className={s.favoriteJobExpectationLine}>
        <img className={s.smiley} src={smiley} alt="" />
        <div className={s.text}>{text}</div>
        <button
          className={s.favoriteButton}
          type="button"
          onClick={() => {
            onSetFavorite(id, !isFavorite);
          }}
        >
          <img
            className={s.heart}
            src={
              isFavorite
                ? getAssetLink('heart_selected.png')
                : getAssetLink('heart.png')
            }
            alt=""
          />
        </button>
      </div>
    );
  }
}

export default FavoriteJobExpectationLine;
