import React from 'react';
import DesiredJobOrSchoolScreen from '../DesiredJobOrSchoolComponent';

const name = 'MEINE WUNSCHSCHULE';
const link = 'www.schuldatenbank.at';
class DesiredSchoolScreen extends React.Component {
  render() {
    return (
      <DesiredJobOrSchoolScreen schoolOrJob="school" name={name} link={link} />
    );
  }
}

export default DesiredSchoolScreen;
