import React from 'react';
import { Modal } from 'react-bootstrap';

import s from './StrengthCardsModal.module.scss';

interface FavoriteLimitReachedModalProps {
  showModal: boolean;
  closeModal: () => void;
}

class FavoriteLimitReachedModal extends React.Component<FavoriteLimitReachedModalProps> {
  render() {
    return (
      <div>
        <Modal
          centered
          show={this.props.showModal}
          backdrop="static"
          dialogClassName={s.modal}
        >
          <Modal.Header className={s.header}>
            <Modal.Title>
              <h3>Limit erreicht</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className={s.body}>
            <div className={s.info}>Maximal 5 Favoriten möglich.</div>
            <div className={s.buttonContainer}>
              <button
                className="btn btn-primary"
                type="button"
                onClick={this.props.closeModal}
              >
                OK
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default FavoriteLimitReachedModal;
