import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import React from 'react';

export interface AppContextType {
  client: ApolloClient<NormalizedCacheObject>;
  store: any;
  allowItestResultPosts: boolean;
}

export default React.createContext<AppContextType | null>(null);
