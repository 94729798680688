import React from 'react';
import gql from 'graphql-tag';

import AppContext, { AppContextType } from '../../../context';
import {
  LOGGED_MODE_USER,
  LOGGED_MODE_GUEST,
} from '../../Auth/AuthenticationWrapper';
import {
  getGuestLocalStorageData,
  setGuestLocalStorageData,
} from '../../Auth/withLogin';
import Loading from '../../Loading';

export interface selectedBundeslandType {
  key: string;
  bundesland: string;
}

const selectedBundeslandQuery = gql`
  query getSelectedBundesland {
    selectedBundesland
  }
`;

const setSelectedBundeslandMutation = gql`
  mutation setSelectedBundesland($selectedBundesland: String!) {
    setSelectedBundesland(selectedBundesland: $selectedBundesland)
  }
`;

const unsetSelectedBundeslandMutation = gql`
  mutation unsetSelectedBundesland {
    unsetSelectedBundesland
  }
`;

interface WithBundeslandProps {
  loggedMode: string;
}

const BundeslandWrapper = (WrappedComponent: any) => {
  class WithBundesland extends React.Component<WithBundeslandProps> {
    static contextType = AppContext;

    // eslint-disable-next-line react/state-in-constructor
    state: any;

    constructor(props: any) {
      super(props);

      this.state = {
        loadingSelectedBundesland: true,
        selectedBundesland: null,
      };

      /* eslint-disable prettier/prettier */
      this.fetchSelectedBundesland = this.fetchSelectedBundesland.bind(this);
      this.getSelectedBundeslandForUser = this.getSelectedBundeslandForUser.bind(this);
      this.getSelectedBundeslandForGuest = this.getSelectedBundeslandForGuest.bind(this);
      this.setSelectedBundesland = this.setSelectedBundesland.bind(this);
      /* eslint-enable prettier/prettier */
    }

    componentDidMount() {
      this.fetchSelectedBundesland(false);
    }

    // eslint-disable-next-line react/sort-comp
    async fetchSelectedBundesland(forceNetwork = true) {
      if (this.props.loggedMode === LOGGED_MODE_USER) {
        await this.getSelectedBundeslandForUser(forceNetwork);
      } else if (this.props.loggedMode === LOGGED_MODE_GUEST) {
        this.getSelectedBundeslandForGuest();
      }
    }

    async getSelectedBundeslandForUser(forceNetwork: boolean) {
      const response = await (this.context as AppContextType).client.query({
        query: selectedBundeslandQuery,
        fetchPolicy: forceNetwork ? 'network-only' : 'cache-first',
      });

      if (response && response.data && 'selectedBundesland' in response.data) {
        this.setState({
          loadingSelectedBundesland: false,
          selectedBundesland: response.data.selectedBundesland,
        });
      }
    }

    getSelectedBundeslandForGuest() {
      const guestData = getGuestLocalStorageData();

      if (guestData) {
        this.setState({
          loadingSelectedBundesland: false,
          selectedBundesland: guestData.selectedBundesland,
        });
      }
    }

    async setSelectedBundesland(selectedBundesland: string) {
      let success = false;

      // handle logged user
      if (this.props.loggedMode === LOGGED_MODE_USER) {
        if (selectedBundesland === null) {
          const response = await (this.context as AppContextType).client.mutate(
            {
              mutation: unsetSelectedBundeslandMutation,
            },
          );
          success =
            response.data && response.data.setSelectedBundesland === true;
        } else {
          const response = await (this.context as AppContextType).client.mutate(
            {
              mutation: setSelectedBundeslandMutation,
              variables: {
                selectedBundesland,
              },
            },
          );
          success =
            response.data && response.data.setSelectedBundesland === true;
        }
      }
      // handle guest user
      else if (this.props.loggedMode === LOGGED_MODE_GUEST) {
        const guestData = getGuestLocalStorageData();
        guestData.selectedBundesland = selectedBundesland;
        setGuestLocalStorageData(guestData);

        success = true;
      }

      return success;
    }

    render() {
      const { loadingSelectedBundesland, selectedBundesland } = this.state;

      if (loadingSelectedBundesland) {
        return <Loading />;
      }

      return (
        <WrappedComponent
          {...this.props}
          selectedBundesland={selectedBundesland}
          setSelectedBundesland={this.setSelectedBundesland}
          refetchSelectedBundesland={this.fetchSelectedBundesland}
        />
      );
    }
  }

  return WithBundesland;
};

export default BundeslandWrapper;
