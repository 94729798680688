import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import runtime from './runtime';
import redirect from './redirect';
import userTestData from './userTestData';
import modals from './modals';

export default combineReducers({
  runtime,
  form: formReducer,
  redirect,
  userTestData,
  modals,
});
