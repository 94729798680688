/* eslint-disable import/prefer-default-export */

export const SET_RUNTIME_VARIABLE = 'SET_RUNTIME_VARIABLE';

export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_LOGGED_IN = 'SET_LOGGED_IN';

export const SET_REDIRECT_SOURCE = 'SET_REDIRECT_SOURCE';
export const SET_REDIRECT_ACTION = 'SET_REDIRECT_ACTION';

export const SET_USER_INTEREST_ANSWER = 'SET_USER_INTEREST_ANSWER';

export const SET_PROFILE_ACCURACY_MODAL_STATE =
  'SET_PROFILE_ACCURACY_MODAL_STATE';
