import React from 'react';
import { flowRight as compose } from 'lodash';
import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import { Document, Page } from 'react-pdf';

import { ROUTE_PREFIX } from '../../../constants/router';
import s from '../../ExportScreen/ExportScreen.module.scss';
import { getGuestLocalStorageData } from '../../Auth/withLogin';
import withAuthentication, {
  LOGGED_MODE_USER,
  LOGGED_MODE_GUEST,
} from '../../Auth/AuthenticationWrapper';
import withInterestTestResults, {
  itestResultsType,
} from '../../HomeScreen/DataWrappers/InterestTestResultsWrapper';
import { checkIfTestResultsAreInvalid } from '../../HomeScreen/DataWrappers/TestCompletionRequiredWrapper';
import withBundesland from '../../HomeScreen/DataWrappers/BundeslandWrapper';
import withAkooeData from '../../HomeScreen/DataWrappers/AkooeDataWrapper';
import ContentBody from '../../ContentBody/index';
import Footer from '../../Footer';
import OffersHeader from '../../OffersScreen/OffersHeader';
import Loading from '../../Loading';
import { b64toBlob } from '../../ExportScreen';
import '../../ExportScreen/pdfWorkerFix';

export const akooePortfolioPdfQuery = gql`
  query createUserAkooePortfolioPdf {
    createUserAkooePortfolioPdf
  }
`;

export const akooePortfolioPdfForGuestQuery = gql`
  query createUserAkooePortfolioPdfForGuest(
    $akooeData: String!
    $selectedBundesland: String!
  ) {
    createUserAkooePortfolioPdfForGuest(
      akooeData: $akooeData
      selectedBundesland: $selectedBundesland
    )
  }
`;

interface AkooeExportScreenProps {
  loggedMode: string;
  meData?: any;
  userItestResults?: itestResultsType;
  refetchSelectedBundesland: () => Promise<void>;
  selectedBundesland: string;
  // eslint-disable-next-line no-unused-vars
  setSelectedBundesland: (bundesland: string) => Promise<void>;
  refetchAkooeData: () => Promise<void>;
  akooePortfolioPdfData?: {
    loading: boolean;
    createUserAkooePortfolioPdf?: string;
  };
  akooePortfolioPdfForGuestData?: {
    loading: boolean;
    createUserAkooePortfolioPdfForGuest?: string;
  };
}

class AkooeExportScreen extends React.Component<AkooeExportScreenProps> {
  // eslint-disable-next-line react/state-in-constructor
  state: any;

  static defaultProps = {
    meData: null,
    userItestResults: null,
    akooePortfolioPdfData: null,
    akooePortfolioPdfForGuestData: null,
  };

  constructor(props: any) {
    super(props);

    this.state = {
      numPages: null,
      blobUrl: null,
    };
  }

  componentDidUpdate(prevProps: any) {
    let data = null;

    if (
      this.props.loggedMode === LOGGED_MODE_USER &&
      this.props.akooePortfolioPdfData &&
      !this.props.akooePortfolioPdfData.loading &&
      this.props.akooePortfolioPdfData !== prevProps.akooePortfolioPdfData
    ) {
      data = this.props.akooePortfolioPdfData.createUserAkooePortfolioPdf;
    } else if (
      this.props.loggedMode === LOGGED_MODE_GUEST &&
      this.props.akooePortfolioPdfForGuestData &&
      !this.props.akooePortfolioPdfForGuestData.loading &&
      this.props.akooePortfolioPdfForGuestData !== prevProps.akooePortfolioPdfForGuestData // eslint-disable-line prettier/prettier
    ) {
      // eslint-disable-next-line prettier/prettier
      data = this.props.akooePortfolioPdfForGuestData.createUserAkooePortfolioPdfForGuest;
    }

    if (data) {
      const blob = b64toBlob(data, 'pdf');
      const blobUrl = URL.createObjectURL(blob);

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ blobUrl });
    }
  }

  onDocumentLoadSuccess = ({ numPages }: any) => {
    this.setState({ numPages });
  };

  akooePortfolioPdfViewer = (pdf: string) => (
    <Document
      key="akooePortfolioPdfViewer"
      file={`data:application/pdf;base64,${pdf}`}
      onLoadSuccess={this.onDocumentLoadSuccess}
      className={s.pdfContainer}
    >
      {Array.from(new Array(this.state.numPages), (el, index) => (
        <Page
          key={`page_${index + 1}`}
          pageNumber={index + 1}
          className={s.pageContainer}
          scale={1.4}
          renderTextLayer={false}
          renderAnnotationLayer={false}
        />
      ))}
    </Document>
  );

  render() {
    const {
      loggedMode,
      meData,
      userItestResults,
      refetchSelectedBundesland,
      selectedBundesland,
      setSelectedBundesland,
      akooePortfolioPdfData,
      akooePortfolioPdfForGuestData,
    } = this.props;

    if (
      (loggedMode === LOGGED_MODE_USER && akooePortfolioPdfData && akooePortfolioPdfData.loading) || // eslint-disable-line prettier/prettier
      (loggedMode === LOGGED_MODE_GUEST && akooePortfolioPdfForGuestData && akooePortfolioPdfForGuestData.loading) // eslint-disable-line prettier/prettier
    ) {
      return <Loading />;
    }

    let data = null;

    if (LOGGED_MODE_USER && akooePortfolioPdfData) {
      data = akooePortfolioPdfData.createUserAkooePortfolioPdf;
    } else if (LOGGED_MODE_USER && akooePortfolioPdfForGuestData) {
      data = akooePortfolioPdfForGuestData.createUserAkooePortfolioPdfForGuest;
    }

    const testResultsInvalid = checkIfTestResultsAreInvalid(userItestResults);
    const finishedTest = !testResultsInvalid;

    return (
      <div className={s.exportScreenContainer}>
        <OffersHeader
          bundesland={selectedBundesland}
          backUrl={`${ROUTE_PREFIX}/orientation/offers/${selectedBundesland}`}
          setSelectedBundesland={setSelectedBundesland}
          refetchSelectedBundesland={refetchSelectedBundesland}
        />
        <ContentBody>
          {data && [
            this.akooePortfolioPdfViewer(data),
            <div
              key="downloadButtonWrapper"
              className={s.downloadButtonWrapper}
            >
              <a href={this.state.blobUrl} download="E-Portfolio.pdf">
                <button type="button" className={`btn btn-primary ${s.button}`}>
                  Download PDF
                </button>
              </a>
            </div>,
          ]}
        </ContentBody>
        <Footer
          loggedMode={loggedMode}
          meData={meData}
          finishedTest={finishedTest}
          forceShowExport
          exportUrl={`${ROUTE_PREFIX}/orientation/offers/${selectedBundesland}/export`}
          withEPortfolio
          refetchAkooeData={this.props.refetchAkooeData}
        />
      </div>
    );
  }
}

export default compose(
  withAuthentication,
  withInterestTestResults,
  withBundesland,
  withAkooeData,
  graphql(akooePortfolioPdfQuery, {
    name: 'akooePortfolioPdfData',
    options: {
      fetchPolicy: 'no-cache',
    },
    skip: (props: any) => props.loggedMode !== LOGGED_MODE_USER,
  }),
  graphql(akooePortfolioPdfForGuestQuery, {
    name: 'akooePortfolioPdfForGuestData',
    options: () => {
      const guestData = getGuestLocalStorageData();
      return {
        variables: {
          akooeData:
            guestData && guestData.userAkooeData
              ? JSON.stringify(guestData.userAkooeData)
              : '{}',
          selectedBundesland: guestData
            ? guestData.selectedBundesland || ''
            : '',
        },
        fetchPolicy: 'no-cache',
      };
    },
    skip: (props: any) => props.loggedMode !== LOGGED_MODE_GUEST,
  }),
)(AkooeExportScreen);
