/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import SwipeableViews from 'react-swipeable-views';

import s from './StrengthCardsList.module.scss';
import DraggableCard from '../StrengthCards/DraggableCard';
import getAssetLink from '../../../../util/getAssetLink';

interface StrengthCardsListProps {
  children: React.ReactNode;
  childIds: Array<string>;
  cardWidth: number;
  withCreateCustomCard?: boolean;
  withDrag?: boolean;
  dragDirection?: 'up' | 'down';
  // eslint-disable-next-line no-unused-vars
  onDragSuccess?: (index: number) => void;
  // eslint-disable-next-line no-unused-vars
  onIndexChanged?: (newIndex: number) => void;
}

class StrengthCardsList extends React.Component<StrengthCardsListProps> {
  // eslint-disable-next-line react/state-in-constructor
  state: any;

  static defaultProps = {
    withCreateCustomCard: false,
    withDrag: true,
    dragDirection: 'down',
    onDragSuccess: () => {},
    onIndexChanged: () => {},
  };

  constructor(props: any) {
    super(props);

    this.state = {
      currentIndex: 0,
      zIndex: 0,
    };

    this.prevSlide = this.prevSlide.bind(this);
    this.nextSlide = this.nextSlide.bind(this);
    this.indexChanged = this.indexChanged.bind(this);
  }

  componentDidUpdate() {
    const { children } = this.props;
    const { currentIndex } = this.state;

    if (currentIndex > Math.max(0, (children as any).length - 1)) {
      this.indexChanged(Math.max(0, (children as any).length - 1));
    }
  }

  prevSlide() {
    const { children } = this.props;
    const { currentIndex } = this.state;

    this.indexChanged(
      Math.max(0, Math.min((children as any).length - 1, currentIndex - 1)),
    );
  }

  nextSlide() {
    const { children } = this.props;
    const { currentIndex } = this.state;

    this.indexChanged(
      Math.max(0, Math.min((children as any).length - 1, currentIndex + 1)),
    );
  }

  indexChanged(newIndex: number) {
    const { onIndexChanged } = this.props;

    this.setState({
      currentIndex: newIndex,
    });

    if (onIndexChanged) {
      onIndexChanged(newIndex);
    }
  }

  render() {
    const {
      childIds,
      children,
      cardWidth,
      withCreateCustomCard,
      withDrag,
      dragDirection,
      onDragSuccess,
    } = this.props;

    const { currentIndex, zIndex } = this.state;

    return (
      <div className={s.strengthCardsList} style={{ zIndex }}>
        <SwipeableViews
          style={{
            // eslint-disable-next-line prettier/prettier
            padding: `${cardWidth * 0.05}px calc((100% - ${cardWidth * 1.05}px) / 2)`,
            minHeight: cardWidth * 1.54 * 1.1,
          }}
          slideClassName={s.cardSlide}
          index={currentIndex}
          onChangeIndex={this.indexChanged}
          enableMouseEvents
          threshold={1}
        >
          {(children as any).map((child: any, index: number) =>
            withDrag &&
            (!withCreateCustomCard || (withCreateCustomCard && index !== 0)) ? (
              // eslint-disable-next-line react/jsx-indent
              <DraggableCard
                // eslint-disable-next-line react/no-array-index-key
                key={`draggable-card-${
                  childIds[withCreateCustomCard ? index - 1 : index]
                }`}
                cardWidth={cardWidth}
                dragDirection={dragDirection as 'up' | 'down'}
                onDragStart={() => {
                  this.setState({ zIndex: 100 });
                }}
                onDragEnd={() => {
                  this.setState({ zIndex: 0 });
                }}
                onDragSuccess={() => {
                  if (onDragSuccess) {
                    onDragSuccess(withCreateCustomCard ? index - 1 : index);
                  }
                }}
              >
                {child}
              </DraggableCard>
            ) : (
              child
            ),
          )}
        </SwipeableViews>
        <button
          className={s.previousSlideButton}
          type="button"
          onClick={this.prevSlide}
          disabled={currentIndex <= 0}
        >
          <img src={getAssetLink('arrow_left.png')} alt="" />
        </button>
        <button
          className={s.nextSlideButton}
          type="button"
          onClick={this.nextSlide}
          disabled={currentIndex >= (children as any).length - 1}
        >
          <img src={getAssetLink('arrow_right.png')} alt="" />
        </button>
      </div>
    );
  }
}

export default StrengthCardsList;
