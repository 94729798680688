import { ROUTE_PREFIX } from '../constants/router';
import StartScreen from '../components/StartScreen';
import LoginScreen from '../components/Auth/login';
import RegistrationScreen from '../components/Auth/register';
import ExplanationVideoScreen from '../components/ExplanationVideoScreen';
import InterestExplanationVideoScreen from '../components/InterestExplanationVideoScreen';
import InfographicScreen from '../components/InfographicScreen';
import InterestsTestScreen from '../components/InterestsTestScreen';
import HomeScreen from '../components/HomeScreen';
import InterestsOverviewScreen from '../components/InterestsOverviewScreen';
import Faq from '../components/Faq';
import InterestDetailsScreen from '../components/InterestDetailsScreen';
import ImprintScreen from '../components/ImprintScreen';
import DataProtectionInfoScreen from '../components/DataProtectionInfoScreen';
import ResultsExplanationScreen from '../components/ResultsExplanationScreen';
import BundeslandSelection from '../components/BundeslandSelection';
import FavoriteProfessions from '../components/FavoriteProfessions';
import ProfessionDetailScreen from '../components/ProfessionDetailScreen';
import ExportScreen from '../components/ExportScreen';
import Offers from '../components/OffersScreen';
import ReorientationOffers from '../components/ReorientationOffers';
import ITestShareScreen from '../components/ShareScreens/ITestShareScreen';
import PortfolioShareScreen from '../components/ShareScreens/PortfolioShareScreen';
import AdminDashboard from '../components/AdminDashboard';
import ModeSelectionForTest from '../components/ModeSelectionForTest';
import ModeSelectionForOffers from '../components/ModeSelectionForOffers';
import AKkooeDesiredJobOrSchool from '../components/AKOOE/DesiredJobOrSchoolScreen';
import AKkooeDesiredJob from '../components/AKOOE/DesiredJobOrSchoolScreen/DesiredJobScreen';
import AKkooeDesiredSchool from '../components/AKOOE/DesiredJobOrSchoolScreen/DesiredSchoolScreen';
import AkooeOverviewScreen from '../components/AKOOE/OverviewScreen';
import AkooeIntroVideoScreen from '../components/AKOOE/IntroVideoScreen';
import AkooeJobExpectationsScreen from '../components/AKOOE/JobExpectationsScreen';
import AkooeFavoriteJobExpectationsScreen from '../components/AKOOE/JobExpectationsScreen/FavoriteJobExpectations';
import AkooeActivityModule from '../components/AKOOE/ActivitiesScreen';
import AkooeNextStepsScreen from '../components/AKOOE/NextStepsScreen';
import AkooeStrengthsScreen from '../components/AKOOE/StrengthsScreen';
import AkooeFavoriteStrengthsScreen from '../components/AKOOE/StrengthsScreen/FavoriteStrengths/FavoriteStrengths';
import AkooeExportScreen from '../components/AKOOE/AkooeExportScreen';
import IdentificationScreen from '../components/IdentificationScreen';

export interface RouteType {
  path: string;
  component: any;
  props?: any;
}

const akooeAddonRoutes: Array<RouteType> = [
  {
    path: `${ROUTE_PREFIX}/orientation/offers/oberösterreich/intro-video`,
    component: AkooeIntroVideoScreen,
    props: { mode: 'orientation', bundesland: 'oberösterreich' },
  },
];

['oberösterreich', 'burgenland', 'steiermark'].forEach((bundesland) => {
  const bundeslandRoutes = [
    {
      path: `${ROUTE_PREFIX}/orientation/offers/${bundesland}`,
      component: AkooeOverviewScreen,
      props: { mode: 'orientation', bundesland },
    },
    {
      path: `${ROUTE_PREFIX}/orientation/offers/${bundesland}/modules/job-expectations`,
      component: AkooeJobExpectationsScreen,
      props: { mode: 'orientation', bundesland },
    },
    {
      path: `${ROUTE_PREFIX}/orientation/offers/${bundesland}/modules/job-expectations/favorites`,
      component: AkooeFavoriteJobExpectationsScreen,
      props: { mode: 'orientation', bundesland },
    },
    {
      path: `${ROUTE_PREFIX}/orientation/offers/${bundesland}/modules/activities`,
      component: AkooeActivityModule,
      props: { mode: 'orientation', bundesland },
    },
    {
      path: `${ROUTE_PREFIX}/orientation/offers/${bundesland}/modules/desired-job-or-school`,
      component: AKkooeDesiredJobOrSchool,
      props: { mode: 'orientation', bundesland },
    },
    {
      path: `${ROUTE_PREFIX}/orientation/offers/${bundesland}/modules/desired-job-or-school/job`,
      component: AKkooeDesiredJob,
      props: { mode: 'orientation', bundesland },
    },
    {
      path: `${ROUTE_PREFIX}/orientation/offers/${bundesland}/modules/desired-job-or-school/school`,
      component: AKkooeDesiredSchool,
      props: { mode: 'orientation', bundesland },
    },
    {
      path: `${ROUTE_PREFIX}/orientation/offers/${bundesland}/modules/next-steps`,
      component: AkooeNextStepsScreen,
      props: { mode: 'orientation', bundesland },
    },
    {
      path: `${ROUTE_PREFIX}/orientation/offers/${bundesland}/modules/strengths`,
      component: AkooeStrengthsScreen,
      props: { mode: 'orientation', bundesland },
    },
    {
      path: `${ROUTE_PREFIX}/orientation/offers/${bundesland}/modules/strengths/favorites`,
      component: AkooeFavoriteStrengthsScreen,
      props: { mode: 'orientation', bundesland },
    },
    {
      path: `${ROUTE_PREFIX}/orientation/offers/${bundesland}/export`,
      component: AkooeExportScreen,
      props: { mode: 'orientation', bundesland },
    },
  ];
  akooeAddonRoutes.push(...bundeslandRoutes);
});

const routes: Array<RouteType> = [
  {
    path: `${ROUTE_PREFIX}/`,
    component: StartScreen,
  },
  {
    path: `${ROUTE_PREFIX}/login`,
    component: LoginScreen,
  },
  {
    path: `${ROUTE_PREFIX}/registration`,
    component: RegistrationScreen,
  },
  {
    path: `${ROUTE_PREFIX}/admin/dashboard`,
    component: AdminDashboard,
  },
  {
    path: `${ROUTE_PREFIX}/intro-video`,
    component: ExplanationVideoScreen,
  },
  {
    path: `${ROUTE_PREFIX}/interest-video/:interest`,
    component: InterestExplanationVideoScreen,
  },
  {
    path: `${ROUTE_PREFIX}/orientation/interest-video/:interest`,
    component: InterestExplanationVideoScreen,
    props: { mode: 'orientation' },
  },
  {
    path: `${ROUTE_PREFIX}/reorientation/interest-video/:interest`,
    component: InterestExplanationVideoScreen,
    props: { mode: 'reorientation' },
  },
  {
    path: `${ROUTE_PREFIX}/mode-select/interests-test`,
    component: ModeSelectionForTest,
  },
  {
    path: `${ROUTE_PREFIX}/mode-select/offers`,
    component: ModeSelectionForOffers,
  },
  {
    path: `${ROUTE_PREFIX}/infographic`,
    component: InfographicScreen,
  },
  {
    path: `${ROUTE_PREFIX}/interests-test`,
    component: InterestsTestScreen,
  },
  {
    path: `${ROUTE_PREFIX}/home`,
    component: HomeScreen,
  },
  {
    path: `${ROUTE_PREFIX}/interests-overview`,
    component: InterestsOverviewScreen,
  },
  {
    path: `${ROUTE_PREFIX}/interest/:id`,
    component: InterestDetailsScreen,
  },
  {
    path: `${ROUTE_PREFIX}/faq`,
    component: Faq,
  },
  {
    path: `${ROUTE_PREFIX}/results-explanation`,
    component: ResultsExplanationScreen,
  },
  {
    path: `${ROUTE_PREFIX}/imprint`,
    component: ImprintScreen,
  },
  {
    path: `${ROUTE_PREFIX}/datenschutz`,
    component: DataProtectionInfoScreen,
  },
  {
    path: `${ROUTE_PREFIX}/orientation/bundesland-selection`,
    component: BundeslandSelection,
    props: { mode: 'orientation' },
  },
  {
    path: `${ROUTE_PREFIX}/reorientation/bundesland-selection`,
    component: BundeslandSelection,
    props: { mode: 'reorientation' },
  },
  ...akooeAddonRoutes,
  {
    path: `${ROUTE_PREFIX}/orientation/offers/:bundesland`,
    component: Offers,
    props: { mode: 'orientation' },
  },
  {
    path: `${ROUTE_PREFIX}/reorientation/offers/:bundesland`,
    component: ReorientationOffers,
    props: { mode: 'reorientation' },
  },
  {
    path: `${ROUTE_PREFIX}/professions`,
    component: FavoriteProfessions,
  },
  {
    path: `${ROUTE_PREFIX}/profession/:id`,
    component: ProfessionDetailScreen,
  },
  {
    path: `${ROUTE_PREFIX}/export`,
    component: ExportScreen,
  },
  {
    path: `${ROUTE_PREFIX}/share/:shareId/results`,
    component: ITestShareScreen,
  },
  {
    path: `${ROUTE_PREFIX}/share/:shareId/portfolio`,
    component: PortfolioShareScreen,
  },
  {
    path: `${ROUTE_PREFIX}/normalization`,
    component: StartScreen,
  },
  {
    path: `${ROUTE_PREFIX}/normalization/mode-select/interests-test`,
    component: ModeSelectionForTest,
  },
  {
    path: `${ROUTE_PREFIX}/normalization/identification`,
    component: IdentificationScreen,
  },
  {
    path: `${ROUTE_PREFIX}/normalization/infographic`,
    component: InfographicScreen,
  },
  {
    path: `${ROUTE_PREFIX}/normalization/interests-test`,
    component: InterestsTestScreen,
  },
  {
    path: `${ROUTE_PREFIX}/verification`,
    component: StartScreen,
  },
  {
    path: `${ROUTE_PREFIX}/verification/mode-select/interests-test`,
    component: ModeSelectionForTest,
  },
  {
    path: `${ROUTE_PREFIX}/verification/infographic`,
    component: InfographicScreen,
  },
  {
    path: `${ROUTE_PREFIX}/verification/interests-test`,
    component: InterestsTestScreen,
  },
];

export default routes;
