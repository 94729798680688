import React from 'react';
import gql from 'graphql-tag';

import AppContext, { AppContextType } from '../../../context';
import {
  LOGGED_MODE_USER,
  LOGGED_MODE_GUEST,
} from '../../Auth/AuthenticationWrapper';
import {
  getGuestLocalStorageData,
  setGuestLocalStorageData,
} from '../../Auth/withLogin';
import Loading from '../../Loading';

export interface favoriteProfessionsType {
  key: string;
  title: string;
  description: string;
  education: Array<string>;
  university: Array<string>;
  college: Array<string>;
  interests: Array<string>;
  codes: Array<string>;
  imageName: string;
}

const favoriteProfessionsQuery = gql`
  query getFavoriteProfessions {
    favoriteProfessions
  }
`;

const setFavoriteProfessionMutation = gql`
  mutation setFavoriteProfession($professionId: String!, $favorite: Boolean!) {
    setFavoriteProfession(professionId: $professionId, favorite: $favorite)
  }
`;

const favoriteProfessionsFilterQuery = gql`
  query getFavoriteProfessionsFilter {
    favoriteProfessionsFilter
  }
`;

const setFavoriteProfessionsFilterMutation = gql`
  mutation setFavoriteProfessionsFilter($filter: String!) {
    setFavoriteProfessionsFilter(filter: $filter)
  }
`;

/*
export const refetchFavoriteProfessionsQuery = async (context) => {
  await context.client.query({
    query: favoriteProfessionsQuery,
    fetchPolicy: 'network-only',
  });
};

export const refetchFavoriteProfessionsFilterQuery = async (context) => {
  await context.client.query({
    query: favoriteProfessionsFilterQuery,
    fetchPolicy: 'network-only',
  });
};
*/

interface WithFavoriteProfessionsProps {
  loggedMode: string;
}

const FavoriteProfessionsWrapper = (
  WrappedComponent: any,
  withInitalForceNetwork = false,
) => {
  class WithFavoriteProfessions extends React.Component<WithFavoriteProfessionsProps> {
    static contextType = AppContext;

    // eslint-disable-next-line react/state-in-constructor
    state: any;

    constructor(props: any) {
      super(props);

      this.state = {
        loadingFavoriteProfessions: true,
        loadingFavoriteProfessionsFilter: true,
        favoriteProfessions: null,
        favoriteProfessionsFilter: null,
      };

      /* eslint-disable prettier/prettier */
      this.fetchFavoriteProfessions = this.fetchFavoriteProfessions.bind(this);
      this.getFavoriteProfessionsForUser = this.getFavoriteProfessionsForUser.bind(this);
      this.getFavoriteProfessionsForGuest = this.getFavoriteProfessionsForGuest.bind(this);
      this.setFavoriteProfession = this.setFavoriteProfession.bind(this);
      this.fetchFavoriteProfessionsFilter = this.fetchFavoriteProfessionsFilter.bind(this);
      this.getFavoriteProfessionsFilterForUser = this.getFavoriteProfessionsFilterForUser.bind(this);
      this.getFavoriteProfessionsFilterForGuest = this.getFavoriteProfessionsFilterForGuest.bind(this);
      this.setFavoriteProfessionsFilter = this.setFavoriteProfessionsFilter.bind(this);
      /* eslint-enable prettier/prettier */
    }

    componentDidMount() {
      this.fetchFavoriteProfessions(withInitalForceNetwork);
      this.fetchFavoriteProfessionsFilter(withInitalForceNetwork);
    }

    // eslint-disable-next-line react/sort-comp
    async fetchFavoriteProfessions(forceNetwork = true) {
      if (this.props.loggedMode === LOGGED_MODE_USER) {
        await this.getFavoriteProfessionsForUser(forceNetwork);
      } else if (this.props.loggedMode === LOGGED_MODE_GUEST) {
        this.getFavoriteProfessionsForGuest();
      }
    }

    async getFavoriteProfessionsForUser(forceNetwork: boolean) {
      const response = await (this.context as AppContextType).client.query({
        query: favoriteProfessionsQuery,
        fetchPolicy: forceNetwork ? 'network-only' : 'cache-first',
      });

      if (response && response.data && 'favoriteProfessions' in response.data) {
        this.setState({
          loadingFavoriteProfessions: false,
          favoriteProfessions: response.data.favoriteProfessions,
        });
      }
    }

    getFavoriteProfessionsForGuest() {
      const guestData = getGuestLocalStorageData();

      if (guestData) {
        this.setState({
          loadingFavoriteProfessions: false,
          favoriteProfessions: guestData.favoriteProfessions,
        });
      }
    }

    async setFavoriteProfession(professionId: string, favorite: boolean) {
      let success = false;

      // handle logged user
      if (this.props.loggedMode === LOGGED_MODE_USER) {
        const response = await (this.context as AppContextType).client.mutate({
          mutation: setFavoriteProfessionMutation,
          variables: {
            professionId,
            favorite,
          },
        });

        success = response.data && response.data.setFavoriteProfession === true;
      }
      // handle guest user
      if (this.props.loggedMode === LOGGED_MODE_GUEST) {
        const guestData = getGuestLocalStorageData();

        const newFavoriteProfessions = guestData.favoriteProfessions
          ? JSON.parse(guestData.favoriteProfessions)
          : [];

        const index = newFavoriteProfessions.indexOf(professionId);

        if (index === -1) {
          newFavoriteProfessions.push(professionId);
        } else {
          newFavoriteProfessions.splice(index, 1);
        }

        const newGuestData = {
          ...guestData,
          favoriteProfessions: JSON.stringify(newFavoriteProfessions),
        };

        setGuestLocalStorageData(newGuestData);

        success = true;
      }

      return success;
    }

    // eslint-disable-next-line react/sort-comp
    async fetchFavoriteProfessionsFilter(forceNetwork = true) {
      if (this.props.loggedMode === LOGGED_MODE_USER) {
        await this.getFavoriteProfessionsFilterForUser(forceNetwork);
      } else if (this.props.loggedMode === LOGGED_MODE_GUEST) {
        this.getFavoriteProfessionsFilterForGuest();
      }
    }

    async getFavoriteProfessionsFilterForUser(forceNetwork: boolean) {
      const response = await (this.context as AppContextType).client.query({
        query: favoriteProfessionsFilterQuery,
        fetchPolicy: forceNetwork ? 'network-only' : 'cache-first',
      });

      if (
        response &&
        response.data &&
        'favoriteProfessionsFilter' in response.data
      ) {
        this.setState({
          loadingFavoriteProfessionsFilter: false,
          favoriteProfessionsFilter: response.data.favoriteProfessionsFilter,
        });
      }
    }

    getFavoriteProfessionsFilterForGuest() {
      const guestData = getGuestLocalStorageData();

      if (guestData) {
        this.setState({
          loadingFavoriteProfessionsFilter: false,
          favoriteProfessionsFilter: guestData.favoriteProfessionsFilter,
        });
      }
    }

    async setFavoriteProfessionsFilter(filter: string) {
      let success = false;

      // handle logged user
      if (this.props.loggedMode === LOGGED_MODE_USER) {
        const response = await (this.context as AppContextType).client.mutate({
          mutation: setFavoriteProfessionsFilterMutation,
          variables: {
            filter,
          },
        });

        success = response.data && response.data.setFavoriteProfessionsFilter;
      }
      // handle guest user
      if (this.props.loggedMode === LOGGED_MODE_GUEST) {
        const guestData = getGuestLocalStorageData();

        const newGuestData = {
          ...guestData,
          favoriteProfessionsFilter: filter,
        };

        setGuestLocalStorageData(newGuestData);

        success = true;
      }

      return success;
    }

    render() {
      const {
        loadingFavoriteProfessions,
        loadingFavoriteProfessionsFilter,
        favoriteProfessions,
        favoriteProfessionsFilter,
      } = this.state;

      if (loadingFavoriteProfessions || loadingFavoriteProfessionsFilter) {
        return <Loading />;
      }

      return (
        <WrappedComponent
          {...this.props}
          favoriteProfessions={
            favoriteProfessions && favoriteProfessions !== ''
              ? JSON.parse(favoriteProfessions)
              : []
          }
          setFavoriteProfession={this.setFavoriteProfession}
          refetchFavoriteProfessions={this.fetchFavoriteProfessions}
          favoriteProfessionsFilter={favoriteProfessionsFilter}
          setFavoriteProfessionsFilter={this.setFavoriteProfessionsFilter}
          refetchFavoriteProfessionsFilter={this.fetchFavoriteProfessionsFilter}
        />
      );
    }
  }

  return WithFavoriteProfessions;
};

export default FavoriteProfessionsWrapper;
