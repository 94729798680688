/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import gql from 'graphql-tag';
import { flowRight as compose } from 'lodash';
import { connect } from 'react-redux';
import { submit } from 'redux-form';

import s from './RegisterModal.module.scss';
import AppContext, { AppContextType } from '../../../context';
import { ROUTE_PREFIX } from '../../../constants/router';
import RegistrationForm from '../../Auth/register/RegistrationForm';
import { evaluateRegisterInformation } from '../../Auth/register/evaluateRegistration';
import { withLogin, getGuestLocalStorageData } from '../../Auth/withLogin';
import Loading from '../../Loading';
import { withRouter } from '../../../util/withRouter';

const registrationAsGuestMutation = gql`
  mutation insertUserWithGuestData($user: UserInput!, $guestData: String!) {
    insertUserWithGuestData(user: $user, guestData: $guestData) {
      name
    }
  }
`;

interface RegisterModalProps {
  showModal: boolean;
  closeModal: () => void;
  // eslint-disable-next-line no-unused-vars
  login: (name: string, password: string) => Promise<boolean>;
  submitFrom: () => void;
}

class RegisterModal extends React.Component<RegisterModalProps> {
  static contextType = AppContext;

  // eslint-disable-next-line react/state-in-constructor
  state: any;

  constructor(props: any) {
    super(props);

    this.state = {
      tryingToRegister: false,
      errors: [],
    };

    this.register = this.register.bind(this);
    this.submitRegistrationForm = this.submitRegistrationForm.bind(this);
  }

  async register(name: string, password: string, guestData: any) {
    try {
      const response = await (this.context as AppContextType).client.mutate({
        mutation: registrationAsGuestMutation,
        variables: {
          user: {
            name,
            password,
          },
          guestData,
        },
      });

      if (response.data && response.data.insertUserWithGuestData) {
        return true;
      }

      if (response.errors) {
        this.setState({
          errors: response.errors.map((err) => err.message),
          tryingToRegister: false,
        });
      } else {
        this.setState({
          errors: ['Beim Registrieren ist ein Fehler aufgetreten.'],
          tryingToRegister: false,
        });
      }
      return false;
    } catch (errors) {
      this.setState({
        errors: ['Beim Registrieren ist ein Fehler aufgetreten.'],
        tryingToRegister: false,
      });
      return false;
    }
  }

  async submitRegistrationForm({ username, password, confirmPassword }: any) {
    const evalData = evaluateRegisterInformation(
      username,
      password,
      confirmPassword,
    );

    if (!evalData.valid) {
      this.setState({
        errors: evalData.errors,
      });
      return;
    }

    this.setState({ tryingToRegister: true });

    const guestData = getGuestLocalStorageData();

    const registeredSuccessfully = await this.register(
      username,
      password,
      JSON.stringify(guestData),
    );
    if (!registeredSuccessfully) return;

    const { login } = this.props;
    const result = await login(username, password);

    if (!result) {
      this.setState({
        errors: ['Anmeldung fehlgeschlagen.'],
        tryingToRegister: false,
      });
      return;
    }

    this.setState({
      errors: [],
    });

    this.setState({ errors: [] }, () => {
      // eslint-disable-next-line no-restricted-globals
      location.href = `${ROUTE_PREFIX}/home`;
    });
  }

  render() {
    const { showModal, closeModal, submitFrom } = this.props;
    const { tryingToRegister, errors } = this.state;

    return [
      <Modal
        key="registerModal"
        centered
        show={showModal}
        backdrop="static"
        dialogClassName={s.registerModal}
      >
        <Modal.Header className={s.header}>
          <Modal.Title>
            <h3>
              <strong>Registrieren mit Gastdaten</strong>
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={s.body}>
          <RegistrationForm
            onSubmit={this.submitRegistrationForm}
            errors={errors}
            withHeader={false}
            withLoginLink={false}
            withRegisterButton={false}
          />
        </Modal.Body>
        <Modal.Footer className={s.footer}>
          <Button variant="btn btn-secondary" onClick={closeModal}>
            ABBRECHEN
          </Button>
          <Button variant="btn btn-primary" onClick={submitFrom}>
            REGISTRIEREN
          </Button>
        </Modal.Footer>
      </Modal>,
      tryingToRegister ? <Loading key="loading" /> : null,
    ];
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  submitFrom: () => dispatch(submit('registration')),
});

export default compose(
  connect(null, mapDispatchToProps),
  withLogin,
  withRouter,
)(RegisterModal);
