import React from 'react';
import { Modal } from 'react-bootstrap';

import s from './StrengthCardsModal.module.scss';
import CreateCustomStrengthCardForm from './CreateCustomStrengthCardForm';

interface CreateCustomStrengthCardModalProps {
  showModal: boolean;
  closeModal: () => void;
  // eslint-disable-next-line no-unused-vars
  onSubmit: (data: any) => void;
}

class CreateCustomStrengthCardModal extends React.Component<CreateCustomStrengthCardModalProps> {
  render() {
    const { closeModal, onSubmit } = this.props;

    return (
      <div>
        <Modal
          centered
          show={this.props.showModal}
          backdrop="static"
          dialogClassName={s.modal}
        >
          <Modal.Header className={s.header}>
            <Modal.Title>
              <h3>Erstelle deine eigene Stärkenkarte</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className={s.body}>
            <CreateCustomStrengthCardForm
              onSubmit={onSubmit}
              onCancel={closeModal}
            />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default CreateCustomStrengthCardModal;
