/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Modal, Button } from 'react-bootstrap';

import s from './AccuracyModal.module.scss';

interface AccuracyModalProps {
  showModal: boolean;
  hideModal: () => void;
}

class AccuracyModal extends React.Component<AccuracyModalProps> {
  render() {
    return (
      <div>
        <Modal
          centered
          show={this.props.showModal}
          backdrop="static"
          dialogClassName={s.accuracyModal}
        >
          <Modal.Header className={s.header}>
            <Modal.Title>
              <h3>
                <strong>Profilgenauigkeit</strong>
              </h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className={s.body}>
            Je höher der Wert, desto eindeutiger sind die Ergebnisse. Ein hoher
            Wert in der Profilgenauigkeit sagt also aus, dass du klare
            Interessenschwerpunkte hast. Ein niedriger Wert deutet darauf hin,
            dass deine Interessen eher breit gestreut und nicht eindeutig
            zuordenbar sind. Falls deine Profilgenauigkeit niedrig ist, hilft es
            vielleicht, wenn du dich mit verschiedenen Berufen auseinandersetzt
            und ein genaueres Bild davon bekommst, was dir gefallen könnte!
          </Modal.Body>
          <Modal.Footer className={s.footer}>
            <Button
              variant="btn OK-button"
              onClick={() => {
                this.props.hideModal();
              }}
            >
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default AccuracyModal;
